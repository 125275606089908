/**
 *
 * Checkbox
 * @format
 * @flow
 *
 * @deprecated
 * will replace this component with the one in components/core/Checkbox
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Checkbox as MUICheckbox,
} from '@mui/material';
import React, { forwardRef, memo, useState } from 'react';

import { CheckboxContainer } from './styles';
import { Close as CloseIcon } from '@mui/icons-material';
import type { PropsType } from './types';

const Checkbox = (props: PropsType, ref): Node => {
  const {
    id,
    label,
    value,
    indeterminate,
    onCheck,
    contentDialog,
    formControlLabelStyle,
    ...rest
  } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const onOpenDialog = () => setIsOpenDialog(true);

  const onCloseDialog = () => setIsOpenDialog(false);

  const renderDialog = () => {
    return (
      <Dialog open={isOpenDialog} onClose={onCloseDialog} maxWidth="md">
        <DialogTitle>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{contentDialog}</DialogContent>
      </Dialog>
    );
  };

  return (
    <CheckboxContainer>
      {/* $FlowFixMe */}
      <FormControlLabel
        id={id}
        value={value}
        control={
          <MUICheckbox
            color="primary"
            checked={value}
            indeterminate={indeterminate}
            onChange={(e) => onCheck(id, e.target.checked)}
          />
        }
        onClick={() => {
          if (contentDialog && !value) {
            onOpenDialog();
          }
        }}
        label={label}
        style={formControlLabelStyle}
        {...rest}
        ref={ref}
      />
      {contentDialog && renderDialog()}
    </CheckboxContainer>
  );
};

export default (memo(forwardRef(Checkbox)): AbstractComponent<
  PropsType,
  mixed
>);
