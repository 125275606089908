/**
 *
 * ProjectsView
 * @format
 * @flow
 *
 */

import { Grid } from '@mui/material';
import type { Node } from 'react';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import React from 'react';

const ProjectsView = (): Node => {
  return (
    <>
      <Grid container flexDirection="column">
        <Grid item xs={12}>
          <ProjectsTable />
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectsView;
