/**
 *
 * Textarea
 * @format
 * @flow
 *
 * @deprecated
 * will replace this component with the one in components/core/Textarea
 *
 */

import { Box, Stack, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect, useState } from 'react';
import { MainContainer, TextareaStyled } from './styles';

import { useTheme } from '@mui/material/styles';
import Tooltip from 'app/components/Tooltip/Tooltip';
import type { PropsType } from './types';

const Textarea: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    id,
    label = '',
    value,
    placeholder,
    minRows,
    maxRows,
    required,
    disabled,
    readOnly,
    onValueChange,
    onClick,
    onFocus,
    onBlur,
    onKeyPress,
    style,
    containerStyle,
    tooltipTrigger = 'click',
    tooltipPlacement = 'top',
    tooltipArrow = true,
    tooltipTitle,
    tooltipBody,
    controlPanel,
    maxLength,
  } = props;
  const theme = useTheme();

  const valueType = typeof value;
  if (valueType !== 'string' && valueType !== 'undefined') {
    throw Error(`Textarea received non string value type: ${valueType}`);
  }

  const [textValue, setTextValue] = useState(value || '');

  useEffect(() => {
    setTextValue(value || '');
  }, [value]);

  const onTextChange = (e) => {
    const inputVal = e.target.value;

    setTextValue(inputVal);

    if (onValueChange) {
      onValueChange(id, inputVal);
    }
  };

  const onTextBlur = () => {
    if (onBlur) {
      onBlur(id, String(textValue));
    }
  };

  const renderTextarea = (
    <TextareaStyled
      id={id}
      value={textValue}
      placeholder={placeholder}
      minRows={minRows}
      maxRows={maxRows}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      onChange={onTextChange}
      onClick={onClick}
      onKeyPress={onKeyPress}
      onFocus={() => {
        if (onFocus) {
          onFocus();
        }
      }}
      onBlur={onTextBlur}
      style={style}
    />
  );

  return (
    <MainContainer style={containerStyle}>
      {label && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: '8px',
            color: disabled
              ? theme.palette.grey['600']
              : theme.palette.grey['800'],
            width: '100%',
          }}
        >
          {label}
        </Typography>
      )}
      {tooltipTitle || tooltipBody ? (
        <Tooltip
          {...{
            trigger: tooltipTrigger,
            placement: tooltipPlacement,
            arrow: tooltipArrow,
            title: tooltipTitle,
            body: tooltipBody,
          }}
        >
          {renderTextarea}
        </Tooltip>
      ) : (
        <>{renderTextarea}</>
      )}
      <Box
        sx={{
          position: 'absolute',
          right: 14,
          bottom: 9,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          {controlPanel && <>{controlPanel}</>}
          {maxLength && (
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey['700'] }}
            >
              {/* $FlowFixMe */}
              {`${value ? value?.length : 0}/${maxLength}`}
            </Typography>
          )}
        </Stack>
      </Box>
    </MainContainer>
  );
};

export default (memo(Textarea): AbstractComponent<PropsType, mixed>);
