import React, { memo, useContext, useEffect } from 'react';
import { LinkDataContext } from 'app/components/LinkData/contexts/LinkDataContext';
import { ContractContext } from 'app/contexts/Contract/Contract';

const LinkDataWrapper = ({ children, contractTermKeyName }) => {
  const { contractTermProps } = useContext(ContractContext);
  const { onSetComponentName, onSetLinkDataProps, linkDataProps } =
    useContext(LinkDataContext);

  const { linkData } = contractTermProps[contractTermKeyName] || {};

  useEffect(() => {
    onSetComponentName(contractTermKeyName);
    onSetLinkDataProps({
      ...linkDataProps,
      linkData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{children}</>;
};

export default (memo(LinkDataWrapper): AbstractComponent<mixed>);
