/**
 *
 * @format
 * @flow
 *
 */

const replacePlaceholders = (
  textPlaceholder: Array<string>,
  contractData: Array<strinf>
) => {
  if (textPlaceholder.length === 0 || contractData.length === 0) {
    return [];
  }

  const placeholder = textPlaceholder[0];

  const replacedPlaceholder = placeholder.replace(
    /\{(\d+)\}/g,
    (match, index) => {
      const dataIndex = parseInt(index);
      if (!isNaN(dataIndex) && dataIndex < contractData.length) {
        if (placeholder.includes('$')) {
          return (
            contractData[dataIndex]
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''
          );
        }
        return contractData[dataIndex] || '';
      } else {
        return match;
      }
    }
  );

  return [
    replacedPlaceholder,
    ...replacePlaceholders(textPlaceholder.slice(1), contractData),
  ];
};

const getData = (dataLocation: Array<string>, contractData: Array<string>) =>
  dataLocation.map((d) =>
    d?.split('.')?.reduce((acc, curr) => acc && acc[curr], contractData)
  );

export { replacePlaceholders, getData };
