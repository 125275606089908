import { gql } from '@apollo/client';

export default gql`
  fragment contractTerms on Contract {
    terms {
      termId
      name
      uiComponentName
      uiComponentSectionIndex
      props
      stringData
      boolData
      numberData
      objectData
      arrayData
    }
  }
`;
