/**
 *
 * CompanyContractsView
 * @format
 * @flow
 *
 */

import type { ContractDetailsReturnType } from 'contract/graphQL/useCompanyContractsQuery';
import ContractsTable from 'contract/components/ContractsTable/ContractsTable';
import { Grid } from '@mui/material';
import type { Node } from 'react';
import React from 'react';
import { useCompanyContractsQuery } from 'contract/graphQL/useCompanyContractsQuery';

const CompanyContractsView = (): Node => {
  const { loading, error, data }: ContractDetailsReturnType =
    useCompanyContractsQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  const { contracts } = data;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContractsTable data={contracts} />
      </Grid>
    </Grid>
  );
};

export default CompanyContractsView;
