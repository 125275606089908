/**
 *
 * ContractTemplateDialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CheckIcon, CloseIcon } from './styles';
import React, { memo } from 'react';

import Dialog from 'app/components/core/Dialog/Dialog';
import type { PropsType } from './types';
// $FlowFixMe
import ReactMarkdown from 'react-markdown';
// $FlowFixMe
import rehypeRaw from 'rehype-raw';
// $FlowFixMe
import remarkGfm from 'remark-gfm';
import { useTheme } from '@mui/material/styles';

const ContractTemplateDialog: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const {
      dialogRef,
      title = '',
      fullDescription = '',
      useFor = [],
      dontUseFor = [],
      baInfoLinks = [],
      alternatives = '',
      usingThisTemplate = '',
      onClickOne,
    } = props;
    const theme = useTheme();

    const renderInfoBox = (background = '', boxTitle = '', children) => {
      return (
        <Box
          sx={{
            mt: '16px',
            padding: '10px 12px',
            borderRadius: '6px',
            background,
          }}
        >
          <Typography variant="body2" sx={{ mb: '4px' }}>
            {boxTitle}
          </Typography>
          {children}
        </Box>
      );
    };

    return (
      <Dialog
        // $FlowFixMe
        ref={dialogRef}
        title={title}
        labelOne="Use Template"
        showButtonTwo={false}
        onClickOne={onClickOne}
      >
        {fullDescription && (
          <ReactMarkdown
            children={fullDescription}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        )}

        {useFor.length > 0 && (
          <>
            {renderInfoBox(
              theme.palette.primary.backgroundLight,
              'Use for',
              useFor.map((item) => (
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  sx={{ mt: '8px' }}
                  gap="10px"
                  key={item}
                >
                  <div>
                    <CheckIcon />
                  </div>
                  <div>
                    <ReactMarkdown
                      children={item}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                  </div>
                </Stack>
              ))
            )}
          </>
        )}

        {dontUseFor.length > 0 && (
          <>
            {renderInfoBox(
              theme.palette.error.light,
              `Don't use for`,
              dontUseFor.map((item) => (
                <Stack direction="row" gap="10px" key={item}>
                  <div>
                    <CloseIcon />
                  </div>
                  <ReactMarkdown
                    children={item}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                </Stack>
              ))
            )}
          </>
        )}
        {alternatives && (
          <>
            {renderInfoBox(
              theme.palette.grey['200'],
              'Alternatives',
              <ReactMarkdown
                children={alternatives}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            )}
          </>
        )}
        {usingThisTemplate && (
          <>
            {renderInfoBox(
              theme.palette.grey['200'],
              'Using this template',
              <ReactMarkdown
                children={usingThisTemplate}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            )}
          </>
        )}
        {baInfoLinks.length > 0 && (
          <>
            {renderInfoBox(
              theme.palette.grey['200'],
              'Business Affairs Info',
              baInfoLinks.map((item) => (
                <Stack
                  direction="row"
                  sx={{ mt: '8px' }}
                  gap="10px"
                  alignItems="center"
                  key={item.text}
                >
                  <Box
                    sx={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      background: theme.palette.text.primary,
                    }}
                  />
                  <a href={item.href}>
                    <Typography variant="body2" color="primary.main">
                      {item.text}
                    </Typography>
                  </a>
                </Stack>
              ))
            )}
          </>
        )}
      </Dialog>
    );
  }
);
export default ContractTemplateDialog;
