/**
 *
 * TwoTextAreaSuggestionCheckbox
 * @format
 * @flow
 *
 */
import type { AbstractComponent, Node } from 'react';
import type { ComponentPropsType, ComponentDataType } from './types';
import React, { memo, useContext } from 'react';
import { ContractContext } from 'app/contexts/Contract/Contract';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const TwoTextAreaSuggestionCheckbox = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    suggestionValues1 = [],
    suggestionValues2 = [],
    textareaLabel1,
    textareaLabel2,
    showCheckbox,
    checkboxLabel,
    checkboxText,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    textareaValue1,
    textareaValue2,
    checkboxTextValue,
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      textareaValue1: '',
      textareaValue2: '',
      checkboxTextValue: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <TextAreaSuggestions
        id={`textareaValue1`}
        label={textareaLabel1 || ''}
        value={textareaValue1}
        suggestions={suggestionValues1 || []}
        onSubmitDescription={(id, val) => {
          onUpdateData(id, val);
        }}
        onChangeTextarea={(id, val) => {
          onUpdateData(id, val);
        }}
      />
      <TextAreaSuggestions
        id={`textareaValue2`}
        label={textareaLabel2 || ''}
        value={textareaValue2}
        suggestions={suggestionValues2 || []}
        onSubmitDescription={(id, val) => {
          onUpdateData(id, val);
        }}
        onChangeTextarea={(id, val) => {
          onUpdateData(id, val);
        }}
      />
      {showCheckbox && (
        <TextGeneratingCheckbox
          id={`checkboxTextValue`}
          label={checkboxLabel}
          value={checkboxTextValue}
          textGenerating={checkboxText}
          onCheck={(id, val) => onUpdateData(id, val)}
        />
      )}
    </>
  );
};

export default (memo(TwoTextAreaSuggestionCheckbox): AbstractComponent<
  PropsType,
  mixed
>);
