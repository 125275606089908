/**
 *
 *
 * @format
 * @flow
 *
 */

import type { ReturnType } from './types';

export const DEFAULT_VALUE: ReturnType = {
  contractTermData: {},
  contractTermProps: {},
  contractTermDefaultData: {},
  contractStandardTermSections: [],
  isErrorSavingContract: false,
  isLoadingSavingContract: false,
  isSuccessSavingContract: false,
  onSetContractData: () => {},
  onUpdateContractTermData: () => {},
  onSetContractTermDefaultData: () => {},
  onSetContractStandardTermSections: () => {},
  onSaveContractData: () => {},
  onSaveContractAndCreateWordDocument: () => {},
  isLoadingSaveAndCreateWordDoc: false,
  isErrorCreatingWordDoc: false,
  isSuccessCreatingWordDocument: false,
};
