const useExtractErrorCode = (error) => {
  let code = '';
  let errors = [];
  if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    errors = [...error.graphQLErrors];
    const { extensions } = error.graphQLErrors[0];
    if (extensions && extensions.code) {
      code = extensions.code;
    }
  }

  return { code, errors };
};

export default useExtractErrorCode;
