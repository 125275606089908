import { gql } from '@apollo/client';

export const projectUISelectValuesFragment = gql`
  fragment selectValue on SelectValue {
    idGroup
    displayValue
    order
    typeInfo {
      id
      type
    }
  }

  fragment projectUISelectValues on UISelectValuesConfig {
    _id
    projectDetailSelectValues {
      genreValues {
        ...selectValue
      }
      releasePlatformValues {
        ...selectValue
      }
      projectStages {
        ...selectValue
      }
    }
    contractDetailSelectValues {
      predictiveTextFieldCrewRoleValues {
        ...selectValue
      }
    }
    settingsSelectValues {
      paymentTermsPeriodicValues {
        ...selectValue
      }
      paymentTermsInvoiceValues {
        ...selectValue
      }
      paymentTermsAccountingPeriodValues {
        ...selectValue
      }
      paymentTermsJurisdictionLawValues {
        ...selectValue
      }
    }
  }
`;
