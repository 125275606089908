/**
 *
 * EditableTextArea
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Box, Grid } from '@mui/material';
import type {
  ComponentDataType,
  ComponentPropsType,
  TermComponentDataType,
} from './types';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import Dialog from 'app/components/core/Dialog/Dialog';
import { Edit as EditIcon } from 'app/assets/icons';
import IconButton from 'app/components/core/IconButton/IconButton';
import type { PropsType } from '../types';
import { Replay as RefreshIcon } from '@mui/icons-material';
import Textarea from 'app/components/Textarea/Textarea';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import { useTheme } from '@mui/material/styles';

const EditableTextArea = ({ contractTermKeyName }: PropsType): Node => {
  const dialogRef = useRef(null);
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);

  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { switchVal = true }: TermComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const { defaultWording, fieldLabel = '' }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const { textareaValue = defaultWording }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useEffect(() => {
    if (!switchVal) {
      setIsEditing(false);
    }
  }, [switchVal]);

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      textareaValue: defaultWording,
    },
  });

  const handleEditingRefreshAction = () => {
    if (isEditing) {
      dialogRef?.current?.open(true);
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const onChangeTextarea = (fieldName, fieldValue) => {
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });
  };

  return (
    <>
      <Dialog
        // $FlowFixMe
        ref={dialogRef}
        title="Default Wording"
        labelTwo="Yes"
        labelOne="No"
        onClickTwo={() => {
          dialogRef?.current?.open(false);
          onChangeTextarea('textareaValue', defaultWording);
        }}
        showClose={false}
      >
        Replace with Bam default term?
      </Dialog>
      <Grid container direction="row" sx={{ position: 'relative' }}>
        <Grid item xs={12}>
          <Textarea
            id="textareaValue"
            label={fieldLabel}
            value={textareaValue}
            multiline
            maxRows={5}
            minRows={2}
            disabled={!isEditing}
            onBlur={(id, val) => {
              onChangeTextarea(id, val);
            }}
            controlPanel={
              <Box
                sx={{
                  borderRadius: '6px',
                  border: `1px solid ${theme.palette.grey['400']}`,
                  background: theme.palette.background.default,
                }}
              >
                <IconButton
                  onClick={() => {
                    handleEditingRefreshAction();
                  }}
                  size="small"
                >
                  {!isEditing ? <EditIcon /> : <RefreshIcon />}
                </IconButton>
              </Box>
            }
            style={{
              color: isEditing
                ? theme.palette.grey['800']
                : theme.palette.grey['600'],
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default (memo(EditableTextArea): AbstractComponent<PropsType, mixed>);
