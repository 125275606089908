/**
 *
 * TurnaroundCustom
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import React, { memo, useContext, useState } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import { Tune as TuneIcon } from '@mui/icons-material';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const TurnaroundCustom = ({ contractTermKeyName }: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    numberOfPeriod = '',
    termsOfPeriod = '',
    turnAroundFee = '',
    feeOption = '',
    netBudgetShare = '',
    specify = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const [feeOptionFromValue, setFeeOptionFromValue] = useState(feeOption);
  const [checked, setChecked] = useState(false);

  // @todo: fetch from the database
  const feeOptions = [
    {
      id: '1',
      name: 'Fixed amount payable on signature of turnaround agreement',
    },
    {
      id: '2',
      name: 'Fixed amount if the work is used in an AVP',
    },
    {
      id: '3',
      name: 'Same share of net budget as payable to contributor',
    },
    {
      id: '4',
      name: 'Other % of net budget',
    },
    {
      id: '5',
      name: 'Specify own',
    },
  ];

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      numberOfPeriod: '',
      termsOfPeriod: '',
      turnAroundFee: '',
      feeOption: '',
      netBudgetShare: '',
      specify: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  const renderField = () => {
    switch (feeOption) {
      case feeOptions[0].id:
      case feeOptions[1].id:
        return (
          <Grid item xs={3}>
            <TextField
              id="turnAroundFee"
              label="Turnaround fee"
              value={turnAroundFee}
              formatMoney
              numberOnly
              startAdornment={<>$</>}
              onBlur={onUpdateData}
              tooltipBody={
                feeOptions[0].id === feeOption
                  ? 'Payable on signature of turnaround agreement'
                  : 'Payable on 1st day of PP of an AVP using the Work'
              }
            />
          </Grid>
        );
      case feeOptions[2].id:
        return (
          <Grid item xs={4}>
            <Typography variant="body1" sx={{ m: 1 }}>
              {feeOptions[2].name}
            </Typography>
          </Grid>
        );
      case feeOptions[3].id:
        return (
          <Grid item xs={1.5} style={{ paddingLeft: 0, marginLeft: '32px' }}>
            <TextField
              id="netBudgetShare"
              label="Net Budget Share"
              numberOnly
              value={netBudgetShare}
              endAdornment={<>%</>}
              onBlur={onUpdateData}
            />
          </Grid>
        );
      case feeOptions[4].id:
        return (
          <Grid item xs={4}>
            <TextField
              id="specify"
              label="Specify"
              value={specify}
              onBlur={onUpdateData}
            />
          </Grid>
        );
      default:
        return (
          <Grid item xs={3}>
            <TextField
              id="turnAroundFee"
              label="Turnaround fee"
              value={turnAroundFee}
              formatMoney
              numberOnly
              startAdornment={<>$</>}
              onBlur={onUpdateData}
              tooltipBody="Payable on signature of turnaround agreement"
            />
          </Grid>
        );
    }
  };

  const renderDialog = () => {
    return (
      <Dialog open={checked} onClose={() => setChecked(checked)}>
        <DialogContent>
          <RadioButtonGroup
            id="feeOption"
            value={feeOptionFromValue}
            onValueChange={(_, val) => setFeeOptionFromValue(val)}
          >
            {feeOptions.map(({ id, name }) => (
              <RadioButton key={name} id={name} label={name} value={id} />
            ))}
          </RadioButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChecked(!checked)}>Cancel</Button>
          <Button
            onClick={() => {
              setChecked(!checked);
              onUpdateData('feeOption', feeOptionFromValue);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={4}>
            <TextField
              id="numberOfPeriod"
              label="Turnaround Period"
              value={numberOfPeriod}
              numberOnly
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              id="termsOfPeriod"
              value={termsOfPeriod}
              onSelectOption={(item) => {
                !Array.isArray(item) &&
                  onUpdateData('termsOfPeriod', item.value);
              }}
              options={[
                { value: 'months', label: 'months' },
                { value: 'years', label: 'years' },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          {renderField()}
          <Grid item>
            <Button
              onClick={() => setChecked(true)}
              size="large"
              startIcon={<TuneIcon />}
            ></Button>
          </Grid>
        </Grid>
      </Grid>
      {renderDialog()}
    </>
  );
};

export default (memo(TurnaroundCustom): AbstractComponent<PropsType, mixed>);
