/**
 *
 * SelectDescription
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { CheckboxSubValueType, PropsType } from './types';
import React, { memo, useCallback, useEffect, useState } from 'react';

import BulletPlainText from 'app/components/core/BulletPlainText/BulletPlainText';
import Checkbox from 'app/components/core/Checkbox/Checkbox';
import Dialog from 'app/components/core/Dialog/Dialog';
import { Grid } from '@mui/material';
import { isEmpty } from 'ramda';

const SelectDescription = (props: PropsType): Node => {
  const { dialogRef, value = '', options = [], onUpdate, isArrayObj } = props;
  const [checkBoxValue, setCheckboxValue] = useState({});

  useEffect(() => {
    if (value) {
      let newValue;
      if (isArrayObj) {
        const setValues = value.split('\n').map((value) => {
          value = value.replace(':', '');
          return value;
        });
        let newObjectValue = {};
        newValue = options.reduce((prev, cur) => {
          if (setValues.includes(cur.heading) === true) {
            newObjectValue = {
              ...prev,
              [cur.heading]: {
                isCheck: true,
                subValues: cur.values,
              },
            };
          }
          return newObjectValue;
        }, {});
      } else {
        //this line convert the string with newline `val1\nval2` into an object {val1 : false , val2: false ...}
        newValue = value.split('\n').reduce((prev, cur) => {
          return { ...prev, [cur]: true };
        }, {});
      }
      //this line convert the string with newline `val1\nval2` into an object {val1 : false , val2: false ...}
      setCheckboxValue(newValue);
    }
  }, [value, isArrayObj, options]);

  const onCheckboxChange = (
    property: string,
    eventCheck: boolean,
    subValues?: Array<string>
  ) => {
    let newCheckboxValues: CheckboxSubValueType = {};
    newCheckboxValues.isCheck = eventCheck;
    if (isArrayObj) {
      newCheckboxValues.subValues = subValues;
    }
    setCheckboxValue({
      ...checkBoxValue,
      [property]: isArrayObj ? newCheckboxValues : eventCheck,
    });
  };

  const handleCheckAll = (checkVal) => {
    let newCheckBoxValue;
    if (isArrayObj) {
      newCheckBoxValue = options.reduce((prev, cur) => {
        return {
          ...prev,
          [cur.heading]: {
            isCheck: checkVal,
            subValues: cur.values,
          },
        };
      }, {});
    } else {
      newCheckBoxValue = options.reduce(
        (prev, cur) => ({ ...prev, [cur]: checkVal }),
        {}
      );
    }
    setCheckboxValue(newCheckBoxValue);
  };

  const isAllCheckboxSelected = useCallback(() => {
    let currentOptions;
    if (isArrayObj) {
      currentOptions = options.reduce((prev, cur) => {
        return {
          ...prev,
          [cur.heading]: {
            isCheck: true,
            subValues: cur.values,
          },
        };
      }, {});
    } else {
      currentOptions = options.reduce(
        (prev, cur) => ({ ...prev, [cur]: true }),
        {}
      );
    }

    if (JSON.stringify(currentOptions) === JSON.stringify(checkBoxValue)) {
      return true;
    }

    return false;
  }, [checkBoxValue, options, isArrayObj]);

  const onSubmit = () => {
    // filter the selected vals (val === true)
    let newValArray = [];
    Object.keys(checkBoxValue).map((key) => {
      //collect the true values
      if (isArrayObj) {
        if (checkBoxValue[key].isCheck === true) {
          const setObj = {
            heading: key,
            subValues: checkBoxValue[key].subValues,
          };
          newValArray.push(setObj);
        }
      } else {
        if (checkBoxValue[key] === true) {
          newValArray.push(key);
        }
      }
      return newValArray;
    });
    //Reset the data.
    setCheckboxValue({});
    onUpdate(newValArray);
    dialogRef?.current.open(false);
  };

  return (
    <Dialog
      // $FlowFixMe
      ref={dialogRef}
      title="Select items and edit as required"
      labelTwo="Add Items"
      onClickTwo={onSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Checkbox
            id="all"
            label="Select all"
            value={isAllCheckboxSelected()}
            onCheck={(_, val) => {
              handleCheckAll(val);
            }}
          />
        </Grid>
        {options.map((option, i) => {
          let setOption = option;
          let checkValue = checkBoxValue[option];
          if (option?.heading || option?.values) {
            setOption = option.heading;
            if (!isEmpty(checkBoxValue)) {
              if (
                checkBoxValue.hasOwnProperty(option.heading) &&
                checkBoxValue[option.heading].hasOwnProperty('isCheck')
              ) {
                checkValue = checkBoxValue[option.heading].isCheck;
              } else if (checkBoxValue.hasOwnProperty(option.heading)) {
                checkValue = checkBoxValue[option.heading];
              }
            }
          }
          checkValue = checkValue === undefined ? false : checkValue;
          return (
            <Grid item xs={12} key={i}>
              <Checkbox
                id={setOption}
                label={setOption}
                value={checkValue || false}
                subValue={option?.values}
                onCheck={(id, val, subVal) => onCheckboxChange(id, val, subVal)}
              />
              {option?.values?.length > 0 &&
                option?.values?.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <BulletPlainText
                      value={item}
                      isSelected={checkValue || false}
                    />
                  </Grid>
                ))}
            </Grid>
          );
        })}
      </Grid>
    </Dialog>
  );
};

export default (memo(SelectDescription): AbstractComponent<PropsType, mixed>);
