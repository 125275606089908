/**
 *
 * Checkbox
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import React, { forwardRef, memo } from 'react';

import { CheckboxContainer } from './styles';
import type { PropsType } from './types';

const Checkbox = (props: PropsType, ref): Node => {
  const {
    id,
    label,
    value,
    indeterminate,
    onCheck,
    formControlLabelStyle,
    subValue,
    ...rest
  } = props;

  return (
    <CheckboxContainer checked={value} className="checkbox-container">
      {/* $FlowFixMe */}
      <FormControlLabel
        id={id}
        value={value}
        control={
          <MUICheckbox
            color="primary"
            checked={value}
            indeterminate={indeterminate}
            onChange={(e) => onCheck(id, e.target.checked, subValue)}
          />
        }
        label={label}
        style={formControlLabelStyle}
        {...rest}
        ref={ref}
      />
    </CheckboxContainer>
  );
};

export default (memo(forwardRef(Checkbox)): AbstractComponent<
  PropsType,
  mixed
>);
