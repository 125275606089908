import { gql } from '@apollo/client';

export const infoPageFragment = gql`
  fragment infoPageItems on InfoPageItems {
    slug
    title
    content
  }

  fragment infoPageItemsRecursive on InfoPageItems {
    ...infoPageItems
    items {
      ...infoPageItems
      items {
        ...infoPageItems
        items {
          ...infoPageItems
        }
      }
    }
  }

  fragment infoPageDetails on InfoPage {
    _id
    slug
    title
    content
    items {
      ...infoPageItemsRecursive
    }
  }
`;
