/**
 *
 * PaySuper
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useState } from 'react';

import Checkbox from 'app/components/Checkbox/Checkbox';
import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import TwoButtonDialogBox from 'app/components/TwoButtonDialogBox/TwoButtonDialogBox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PaySuper: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const { superPay = true }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      superPay: true,
    },
  });

  const onOpenDialog = () => setIsOpenDialog(true);
  const onCloseDialog = () => setIsOpenDialog(false);

  const onUpdateData = (fieldName: string, fieldValue: boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <TwoButtonDialogBox
          open={isOpenDialog}
          onClickOne={onCloseDialog}
          labelButtonOne="Ok, I understand"
        />
        <Checkbox
          id="superPay"
          label="Pay super on top"
          value={superPay}
          onCheck={(id, checked) => {
            if (checked) {
              onOpenDialog();
            }
            onUpdateData(id, checked);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(PaySuper): AbstractComponent<PropsType, mixed>);
