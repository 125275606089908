import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { enableSentryDebug } from '../../utils/config';

const sentryOpts = {
  dsn: process.env.REACT_APP_SENTRY_CLIENT_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  debug: enableSentryDebug,
  environment: process.env.NODE_ENV,
};

if (process.env.REACT_APP_SENTRY_RELEASE) {
  sentryOpts.release = process.env.REACT_APP_SENTRY_RELEASE;
}

Sentry.init(sentryOpts);

export default Sentry;
