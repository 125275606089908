/**
 *
 * RateDetails
 * @format
 * @flow
 *
 */

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useCallback, useContext, useState } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import TextGeneratingRadioButtonGroup from 'app/components/TextGeneratingRadioButtonGroup/TextGeneratingRadioButtonGroup';
import TwoButtonDialogBox from 'app/components/TwoButtonDialogBox/TwoButtonDialogBox';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import type { PropsType } from '../types';

const RateDetails = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    radioValue = '',
    textValue1 = '',
    textValue2,
    totalFee = '',
    baseFee = '',
    otherDetails = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const radioButton1Text = 'Per Week';
  const radioButton2Text = 'Per Day';

  const {
    radioLabel1 = radioButton1Text,
    radioValue1 = radioButton1Text,
    textGenerating1 = radioButton1Text,
    radioLabel2 = radioButton2Text,
    radioValue2 = radioButton2Text,
    textGenerating2 = radioButton2Text,
    suggestionValues = [],
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      radioValue: radioValue1,
      textValue1: textGenerating1,
      textValue2: '',
      totalFee: '',
      baseFee: '',
      otherDetails: '',
    },
  });

  const [hasBreakdown, setHasBreakdown] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const updateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeBreakdownDetails = () => {
    const data = {
      baseFee: '',
      otherDetails: '',
    };

    onUpdateData(data);
    setHasBreakdown(false);
    setIsOpenDialog(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} mb={1}>
          <CurrencyField
            id="totalFee"
            label="Total Fee"
            value={totalFee}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={updateData}
          />
        </Grid>
      </Grid>
      <TextGeneratingRadioButtonGroup
        radioValue={radioValue}
        textValue1={textValue1}
        textValue2={textValue2}
        radioLabel1={radioLabel1}
        radioValue1={radioValue1}
        textGenerating1={textGenerating1}
        radioLabel2={radioLabel2}
        radioValue2={radioValue2}
        textGenerating2={textGenerating2}
        onUpdate={onUpdateData}
      />
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        sx={{ marginTop: 3, marginBottom: 3 }}
      >
        {!hasBreakdown && (
          <Grid item>
            <Button
              onClick={() => {
                setHasBreakdown(true);
              }}
              size="large"
              startIcon={<AddIcon />}
              sx={{ minWidth: 10, width: 30 }}
            ></Button>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant="body2" sx={{ marginTop: 1.2 }}>
            Fee Breakdown
          </Typography>
        </Grid>
        {hasBreakdown && (
          <Grid item>
            <Button
              onClick={() => {
                setIsOpenDialog(true);
              }}
              size="large"
              startIcon={<DeleteIcon />}
            ></Button>
          </Grid>
        )}
      </Grid>
      {hasBreakdown && (
        <>
          <Grid item xs={12}>
            <CurrencyField
              id="baseFee"
              label="Base Fee"
              value={baseFee}
              formatMoney
              numberOnly
              startAdornment={<>$</>}
              onBlur={(id, val) => updateData(id, val)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaSuggestions
              id={'otherDetails'}
              label="Other Details"
              value={otherDetails}
              suggestions={suggestionValues || []}
              onSubmitDescription={(id, val) => {
                updateData(id, val);
              }}
              onChangeTextarea={(id, val) => {
                updateData(id, val);
              }}
            />
          </Grid>
        </>
      )}
      <TwoButtonDialogBox
        open={isOpenDialog}
        onClickOne={() => setIsOpenDialog(false)}
        onClickTwo={removeBreakdownDetails}
        labelButtonOne="Cancel"
        labelButtonTwo="OK"
        titleDialog="Changing selection will delete the data for this term"
      />
    </>
  );
};

export default (memo(RateDetails): AbstractComponent<PropsType, mixed>);
