/**
 *
 * ConditionsPrecedent
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button, Grid } from '@mui/material';
import {
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import Textarea from 'app/components/Textarea/Textarea';
import { largestItemId } from 'app/utils/general.js';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const ConditionsPrecedent = ({ contractTermKeyName }: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const { items = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      items: [{ itemId: '1', condition: '' }],
    },
  });

  const handleAddClick = () => {
    const itemId = parseInt(largestItemId(items), 10) + 1;
    const defaultDataElement = { itemId: itemId.toString(), condition: '' };
    onUpdate(contractTermKeyName, {
      items: [...items, defaultDataElement],
    });
  };

  const handleDelete = (index) => {
    // Filter out the removed item, then re-assign optionId numbers
    const newItems = items
      .filter((item, i) => i !== index)
      .sort((a, b) => parseInt(a.itemId, 10) - parseInt(b.itemId, 10))
      .map((item, index) => ({ ...item, itemId: (index + 1).toString() }));
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const handlePrecedentData = (fieldValue, fieldName, i) => {
    const newPrecedent = [...items];
    newPrecedent[i][fieldName] = fieldValue;
    onUpdate(contractTermKeyName, { items: newPrecedent });
  };

  return (
    <Grid container>
      {items.map((item, i) => {
        const { condition } = item;
        return (
          <React.Fragment key={i}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Textarea
                  id="condition"
                  label={`Condition Precedent ${i + 1}`}
                  maxRows={5}
                  minRows={2}
                  value={condition}
                  onBlur={(id, val) => {
                    handlePrecedentData(val, id, i);
                  }}
                />
              </Grid>
              {i >= 1 && (
                <Grid item xs={1}>
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(i)}
                  ></Button>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12}>
        <Button
          onClick={() => handleAddClick()}
          startIcon={<ControlPointIcon />}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default (memo(ConditionsPrecedent): AbstractComponent<PropsType, mixed>);
