import { gql } from '@apollo/client';

export default gql`
  fragment contractMeta on Contract {
    _id
    title
    contractTemplateName
    sectionNames
    documents {
      _id
      fileName
      fileType
      createdAt
      fileData {
        downloadUrl
      }
    }
  }
`;
