/**
 *
 * AccountSetupStepTwo
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import type { PropsType } from './types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';

const AccountSetupStepTwo: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const { companyName, companyType, handleStateChange } = props;

    return (
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="body">
            Add the production company name
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="companyName"
            label="Company Name"
            value={companyName}
            onBlur={handleStateChange}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Select
            id="companyType"
            label="Type"
            value={companyType}
            onSelectOption={(item) => {
              !Array.isArray(item) &&
                handleStateChange('companyType', item.value);
            }}
            options={[
              {
                value: EntityTypeValues.Company,
                label: 'Company (e.g. Pty Ltd)',
              },
              {
                value: EntityTypeValues.Individual,
                label: 'No Company use my details',
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  }
);
export default AccountSetupStepTwo;
