/**
 *
 * GuaranteedFeeAdditionalAmounts
 *
 * This component stores its values in an array of objects to support the requirements of the
 * document builder. As the values for this component are dynamic (i.e. the props define what
 * values are available in the dropdown), the data written to the contract is also dynamic (i.e.
 * if an option is not selected it will not be present in the data). The document builder requires
 * a key to be present in the contract to access its data. As such, dynamic data such as this needs
 * to be stored in an array. The key for the array is always present so the document builder can
 * point to that and use its array iteration functionality to print a table row for each element
 * contained in the array. Each element needs to be an object so the document builder can index into
 * it to find the value it needs to use in the interpolated string.
 *
 * In this case the document builder will use the paymentType and paymentValue. The optionId is
 * stored for the purposes of this component re-constructing the UI only.
 *
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useCallback, useContext, useMemo } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import Grid from '@mui/material/Grid';
import type { PropsType } from '../types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import { difference } from 'lodash';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const GuaranteedFeeAdditionalAmounts = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { optionsSelect = [] }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const { values = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      values: [],
    },
  });

  const selectedOptionIds = useMemo(
    () => values.map((x) => x.optionId),
    [values]
  );

  const getLabelForOptionsSelect = useCallback(
    (id) => (optionsSelect.filter((x) => x.id === id)[0] || {}).label,
    [optionsSelect]
  );

  // Check if the option has been defined as a text input
  const isText = useCallback(
    (id) => (optionsSelect.filter((x) => x.id === id)[0] || {}).isText,
    [optionsSelect]
  );

  const optionSelected = (
    selectedItems: Array<{ value: string, label: string }>
  ) => {
    const updatedContractData = window.structuredClone(
      contractTermData[contractTermKeyName] || {}
    );
    const selectedItemValues = selectedItems.map((item) => item.value);
    const keysToRemove = difference(selectedOptionIds, selectedItemValues);
    const keysToAdd = difference(selectedItemValues, selectedOptionIds);

    updatedContractData.values = updatedContractData.values.filter(
      (x) => !keysToRemove.includes(x.optionId)
    );

    keysToAdd.forEach((key) => {
      // default our non-text inputs to a number
      const defaultVal = isText(key) ? '' : '0';
      updatedContractData.values.push({
        paymentType: getLabelForOptionsSelect(key),
        paymentValue: defaultVal,
        optionId: key,
      });
    });

    onUpdate(contractTermKeyName, updatedContractData, true);
  };

  const optionRemoved = (currentValues, selectedValue) => {
    const updatedContractData = window.structuredClone(
      contractTermData[contractTermKeyName] || {}
    );
    updatedContractData.values = updatedContractData.values.filter(
      (x) => x.optionId !== selectedValue
    );
    onUpdate(contractTermKeyName, updatedContractData, true);
  };

  const updateData = (fieldName: string, fieldValue: string[] | string, i) => {
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });
  };

  // convert our optionsSelect objects to objects that the Select options is
  // expecting
  const selectOptions = optionsSelect.map((x) => ({
    value: x.id,
    label: x.label,
  }));

  return (
    <>
      <Select
        id=""
        value={selectedOptionIds}
        multiple
        onSelectOption={(selectedItems) => {
          if (Array.isArray(selectedItems)) {
            optionSelected(selectedItems);
          }
        }}
        onRemoveSelectedValue={(currentValues, selectedValue) => {
          optionRemoved(currentValues, selectedValue);
        }}
        options={selectOptions}
      />

      {values.map((x, i) => {
        const { paymentType, paymentValue, optionId } = x;
        const isTextBox = isText(optionId);

        return (
          <Grid item xs={isTextBox ? 12 : 1.5} key={i}>
            <TextField
              id={optionId}
              label={paymentType}
              value={paymentValue}
              numberOnly={isTextBox ? false : true}
              onBlur={(fieldName, fieldValue) => {
                // update the paymentValue attribute of the object in the values array who's
                // optionId matches the fieldName, then update the data.
                const valuesCpy = window.structuredClone(values);
                const elIndex = valuesCpy.findIndex(
                  (obj) => obj.optionId === fieldName
                );
                if (elIndex >= 0) {
                  valuesCpy[elIndex].paymentValue = fieldValue;
                  updateData('values', valuesCpy);
                }
              }}
              endAdornment={!isTextBox && <>%</>}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default (memo(GuaranteedFeeAdditionalAmounts): AbstractComponent<
  PropsType,
  mixed
>);
