/**
 *
 * Menu
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Menu as MUIMenu } from '@mui/material';
import React, { memo } from 'react';

import { PropsType } from './types';

const Menu: AbstractComponent<PropsType> = (props: PropsType): Node => {
    return (
      <MUIMenu
        {...props}
      />
    );
};

export default (memo(Menu): AbstractComponent<PropsType, mixed>);