import React, { useContext } from 'react';
import { Stack, Box, Divider } from '@mui/material';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { replacePlaceholders, getData } from './utils';

const ReadOnlyComponent = ({
  termName,
  contractTermKeyName,
  termTemplates,
  showDivider,
}) => {
  const { contractTermData, contractTermProps } = useContext(ContractContext);

  const contractData = contractTermData[contractTermKeyName] || {};

  const template = termTemplates?.find(
    (template) => template.name === termName
  );
  const termProps = contractTermProps[contractTermKeyName];

  const updatedTemplate =
    contractData?.switchVal !== false &&
    template?.rows
      ?.map((row) => {
        return {
          ...row,
          cellData: row.cellData.filter((c) => {
            // if additionalDetail is present in the renderCondition, check if the value is not empty
            return !c.renderCondition || contractData[c.renderCondition];
          }),
        };
      })
      .filter((row) => row.cellData.length > 0);

  return (
    <>
      <Stack pb={2}>
        {Array.isArray(updatedTemplate) &&
          updatedTemplate?.map((row, rowIndex) => {
            const rowType = !updatedTemplate
              .map((t) => t.arrayDataLocation)
              .every((a) => !a)
              ? 'array'
              : 'text';

            const stringDataRowStyle = {
              display: 'grid',
              gridTemplateColumns: rowType === 'array' ? '175px' : '175px 3fr',
              gap: 2,
            };

            const arrayDataRowStyle = {
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            };

            return (
              <Stack
                key={rowIndex}
                sx={rowType === 'text' ? stringDataRowStyle : arrayDataRowStyle}
                mt={rowIndex !== 0 && 2} // indent except the first row
              >
                {row.cellData.map((cellData, cellIndex) => {
                  const cellType =
                    row.arrayDataLocation?.length > 0 ? 'array' : 'text';

                  return (
                    <>
                      {renderComponent(
                        cellIndex,
                        cellData,
                        cellType,
                        contractData,
                        rowIndex,
                        rowType,
                        row,
                        termProps,
                        termName
                      )}
                    </>
                  );
                })}
              </Stack>
            );
          })}
      </Stack>
      {showDivider && contractData?.switchVal !== false && (
        <Divider
          className={`${termName}`}
          sx={{
            mb: 2,
          }}
        />
      )}
    </>
  );
};

const renderComponent = (
  cellIndex,
  { text, dataLocation, renderCondition },
  cellType,
  contractData,
  rowIndex,
  rowType,
  { arrayDataLocation = [], cellData },
  termProps,
  termName
) => {
  switch (cellType) {
    case 'text':
      const stringData = getData(dataLocation, contractData);
      const boxStyle = { pl: cellIndex !== 0 && 0, minWidth: '175px' };

      if (dataLocation.length > 0) {
        const value = replacePlaceholders(text, stringData).map(
          (item, index) => (
            <Box mb={1} key={index}>
              {item}
            </Box>
          )
        );
        return (
          <Box sx={boxStyle} pb={1}>
            <pre
              style={{
                whiteSpace: 'pre-line',
                fontFamily: 'Manrope',
                margin: 0,
              }}
            >
              {value}
            </pre>
          </Box>
        );
      }

      return (
        <Box
          sx={{ minWidth: '175px' }}
          pb={2}
          pl={rowIndex !== 0 && cellIndex === 0 ? 2 : 0} // indent except the first row
        >
          {text}
        </Box>
      );
    case 'array':
      const mapArrayData = contractData[arrayDataLocation] || [];
      const arrayData = mapArrayData.map((a) =>
        dataLocation.map((d) => a?.[d])
      );

      return (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: `${cellData?.length > 2 ? '175px' : 'auto'}`,
            minWidth: '175px',
          }}
          pb={1}
        >
          {arrayData?.map((a, aIndex) => {
            return (
              <Box
                sx={{
                  minWidth: '175px',
                  height: `${cellData?.length <= 2 ? 'auto' : '40px'}`,
                }}
                pb={2}
              >
                {replacePlaceholders(text, arrayData[aIndex]).map((t) => (
                  <Box pl={cellIndex === 0 && 2}>{t}</Box>
                ))}
              </Box>
            );
          })}
        </Stack>
      );
    default:
      return text;
  }
};

export default ReadOnlyComponent;
