import {
  AddContract,
  AddProject,
  ArrowDown,
  PlusSmall,
} from 'app/assets/icons';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'app/components/core/Button/Button';
import { AddProjectIconSvg, AddContractIconSvg } from './styles.js';
import { useTheme } from '@mui/material/styles';

const NewMenu = () => {
  const theme = useTheme();
  const history = useHistory();
  const { cyid } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const menus = [
    {
      icon: <AddProjectIconSvg><AddProject/></AddProjectIconSvg>,
      title: 'New Project',
      subtitle: 'Start a new project right away',
      url: `/dashboard/${cyid}/project/new`,
    },
    {
      icon: <AddContractIconSvg><AddContract /></AddContractIconSvg>,
      title: 'New Contract',
      subtitle: 'Create a new contract',
      url: `/dashboard/${cyid}/contract-templates`,
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu-new"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="secondary"
        startIcon={<PlusSmall />}
        endIcon={<ArrowDown />}
        minWidth={104}
      >
        New
      </Button>
      <Menu
        id="simple-menu-new"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ '.MuiPaper-root': { padding: '8px' } }}
      >
        {menus.map((menu) => {
          return (
            <MenuItem
              key={menu.title}
              sx={{ width: '100%' }}
              onClick={() => {
                handleClose();
                history.push(menu.url);
              }}
            >
              <Stack
                direction="row"
                gap="2px"
                alignItems="center"
                padding="12px 16px"
                borderRadius="8px"
                width="100%"
              >
                {menu.icon}
                <Stack direction="column">
                  <Typography
                    variant="title"
                    fontWeight="bold"
                    color={theme.palette.grey[800]}
                  >
                    {menu.title}
                  </Typography>
                  <Typography variant="caption" color={theme.palette.grey[750]}>
                    {menu.subtitle}
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default NewMenu;
