/**
 *
 * PaymentSchedule
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import { Grid, IconButton, Typography } from '@mui/material';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import CustomMilestones from 'app/components/CustomMilestones/CustomMilestones';
import OptionalMilestone from 'app/components/OptionalMilestone/OptionalMilestone';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import { Tune as TuneIcon } from '@mui/icons-material';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PaymentSchedule: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    milestoneOptionsValues = [],
    dates = [],
    specifies = [],
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const { allowedMilestoneNames = [] }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const [open, setOpen] = useState(false);

  // @todo find a way how to efficiently capture the component data of other terms
  const shortDescGenKeyName = Object.keys(contractTermData).filter((i) =>
    i.startsWith('ShortDescGen')
  )[0];
  const shortDescGenTermData = contractTermData[shortDescGenKeyName];

  const milestoneFromShortDescGenTerm = useMemo(
    () =>
      Array.from(
        {
          length: shortDescGenTermData?.switchVal
            ? shortDescGenTermData?.deliveryItems?.length || 0
            : 0,
        },
        (_, i) => `Delivery of Delivery Materials (Item ${i + 1})`
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shortDescGenTermData?.switchVal,
      shortDescGenTermData?.deliveryItems?.length,
    ]
  );
  const [allAllowedMilestoneNames, setAllAllowedMilestoneNames] = useState(
    allowedMilestoneNames
  );

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      milestoneOptionsValues: [],
      dates: [],
      specifies: [],
    },
  });

  useEffect(() => {
    if (allowedMilestoneNames.length) {
      setAllAllowedMilestoneNames([...allowedMilestoneNames]);
    }
  }, [allowedMilestoneNames]);

  useEffect(() => {
    setAllAllowedMilestoneNames([
      ...allowedMilestoneNames,
      ...milestoneFromShortDescGenTerm,
    ]);

    onUpdate(contractTermKeyName, {
      milestoneOptionsValues: [
        ...milestoneOptionsValues.filter(
          (item) => !item.name?.includes('Delivery of Delivery Materials (Item')
        ),
        ...milestoneFromShortDescGenTerm.map((item) => ({
          name: item,
          percentage: '',
          amount: '',
        })),
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestoneFromShortDescGenTerm]);

  const toggleDialog = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCustomValueDate = (dates) => {
    onUpdate(contractTermKeyName, { dates });
  };

  const handleCustomValueSpecify = (specifies) => {
    onUpdate(contractTermKeyName, { specifies });
  };

  const handleCustomValueMilestone = (milestoneOptionsValues) => {
    onUpdate(contractTermKeyName, {
      milestoneOptionsValues,
    });
  };

  const getNewRecord = (name) => ({
    name,
    specialInput: '',
    percentage: 0,
    amount: 0,
  });

  const handleOptionalMilestonesData = (targetValue, property, i) => {
    const newMilestoneValue = [...milestoneOptionsValues];
    newMilestoneValue[i][property] = targetValue;
    onUpdate(contractTermKeyName, {
      milestoneOptionsValues: newMilestoneValue,
    });
  };

  return (
    <Grid container>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Typography>Payment Schedule</Typography>
        <IconButton
          onClick={toggleDialog}
          size="large"
          color="secondary"
          sx={{ marginLeft: 2 }}
        >
          <TuneIcon />
        </IconButton>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={4.5}>
          <Typography variant="body1">Milestone</Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>

        {milestoneOptionsValues.map((milestoneValue, i) => (
          <React.Fragment key={i}>
            {milestoneOptionsValues[i].name === 'Select Date' ? (
              <Grid item xs={12}>
                <CustomMilestones
                  customValues={dates}
                  onDataUpdated={handleCustomValueDate}
                  conditionalProp="date"
                  name={milestoneValue.name}
                />
              </Grid>
            ) : milestoneOptionsValues[i].name === 'Specify Own' ? (
              <Grid item xs={12}>
                <CustomMilestones
                  customValues={specifies}
                  onDataUpdated={handleCustomValueSpecify}
                  conditionalProp="specify"
                  name={milestoneValue.name}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={4}>
                  <Typography variant="body1">{milestoneValue.name}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <TextField
                    id="percentage"
                    numberOnly
                    value={milestoneValue.percentage}
                    endAdornment={<>%</>}
                    onBlur={(id, val) => {
                      handleOptionalMilestonesData(val, id, i);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="amount"
                    value={milestoneValue.amount}
                    formatMoney
                    numberOnly
                    startAdornment={<>$</>}
                    onBlur={(id, val) => {
                      handleOptionalMilestonesData(val, id, i);
                    }}
                  />
                </Grid>
              </>
            )}
          </React.Fragment>
        ))}
      </Grid>

      <Grid item xs={12}>
        <OptionalMilestone
          onDialogClose={toggleDialog}
          dialogOpen={open}
          allowedNamesArr={allAllowedMilestoneNames}
          optionalMilestonesValues={milestoneOptionsValues}
          onUpdate={handleCustomValueMilestone}
          getNewRecord={getNewRecord}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(PaymentSchedule): AbstractComponent<PropsType, mixed>);
