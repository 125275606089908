/**
 *
 * @format
 * @flow
 *
 */

export type RightsGrantedToThirdPartyItemType = {
  rightsType: string,
  specify: string,
  grantedTo: string,
  prodCoCheckboxTextValue: string,
};

export type RightsRetainByContributorItemType = {
  rightsType: string,
  specify: string,
  holdbackCheckboxTextValue: string,
  holdbackUntil: string,
};

export type DropDownValueType = {
  label: string,
  options: Array<string>,
};

export type SuggestionValueType = {
  label: string,
  options: Array<string>,
};

export type ComponentDataType = {
  switchVal?: boolean,
  radioValue?: string,
  textValue1?: string,
  textValue2?: string,
  rightsGrantedToThirdPartyCheckboxTextValue?: string,
  rightsRetainedByContributorCheckboxTextValue?: string,
  items1?: Array<RightsGrantedToThirdPartyItemType>,
  items2?: Array<RightsRetainByContributorItemType>,
};

export type ComponentPropsType = {
  radioLabel1?: string,
  radioValue1?: string,
  textGenerating1?: string,
  radioLabel2?: string,
  radioValue2?: string,
  textGenerating2?: string,
  rightsGrantedToThirdPartyCheckboxLabel?: string,
  rightsGrantedToThirdPartyCheckboxText?: string,
  rightsRetainedByContributorCheckboxLabel?: string,
  rightsRetainedByContributorCheckboxText?: string,
  prodCoCheckboxLabel?: string,
  prodCoCheckboxText?: string,
  holdbackCheckboxLabel?: string,
  holdbackCheckboxText?: string,
  dropDownValues: Array<DropDownValueType>,
  suggestionValues: Array<SuggestionValueType>,
};

export type RightsGrantedToThirdPartyItemPropsType = {
  item: RightsGrantedToThirdPartyItemType,
  index: number,
  dropDownVal?: DropDownValueType,
  prodCoCheckboxLabel: string,
  prodCoCheckboxText: string,
  onDataUpdate: (val: string, id: string, index: number) => void,
};

export type RightsRetainByContributorItemPropsType = {
  item: RightsRetainByContributorItemType,
  index: number,
  holdbackCheckboxLabel: string,
  holdbackCheckboxText: string,
  dropDownVal?: DropDownValueType,
  suggestionVal?: SuggestionValueType,
  onDataUpdate: (val: string, id: string, index: number) => void,
};

export const RightsType = {
  Other: 'Other',
};
