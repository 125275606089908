/**
 *
 * PartyDetailsContractor
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PartyDetailsContractor: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    partyType = '',
    companyName = '',
    firstName = '',
    lastName = '',
    address = '',
    address2 = '',
    city = '',
    state = '',
    postcode = '',
    country = '',
    abn = '',
    businessName = '',
    email = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      partyType: '',
      partyName: '',
      companyName: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      abn: '',
      businessName: '',
      email: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) => {
    // Behind the scenes we want to create a partyName attribute that contains the value of either
    // the firstName + lastName, or companyName, depending on the partyType. This makes it easier
    // for the document builder to pull out the contracting party identification.
    const updateObj: { [fieldName: string]: any } = {
      [fieldName]: fieldValue,
    };

    if (
      ['partyType', 'firstName', 'lastName', 'companyName'].includes(fieldName)
    ) {
      if (fieldName === 'partyType') {
        // We also want to delete the company data if the person data is selected and vice-versa
        if (fieldValue === 'company') {
          updateObj.firstName = '';
          updateObj.lastName = '';
          updateObj.partyName = companyName;
        } else {
          updateObj.companyName = '';
          updateObj.partyName = `${firstName} ${lastName}`;
        }
      } else if (partyType === 'company' && fieldName === 'companyName') {
        updateObj.partyName = fieldValue;
      } else if (partyType === 'person') {
        updateObj.partyName =
          fieldName === 'firstName'
            ? `${fieldValue} ${lastName}`
            : `${firstName} ${fieldValue}`;
      }
    }

    onUpdate(contractTermKeyName, updateObj);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" color="primary">
          Contributor
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <RadioButtonGroup
            id="partyType"
            value={partyType}
            onValueChange={onUpdateData}
          >
            <RadioButton id="person" label="person" value="person" />
            <RadioButton
              id="company (e.g Pty Ltd)"
              label="company (e.g Pty Ltd)"
              value="company"
            />
          </RadioButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {partyType === 'person' ? (
            <>
              <Grid item xs={6}>
                <TextField
                  id="firstName"
                  label="First Name"
                  value={firstName}
                  onBlur={onUpdateData}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastName"
                  label="Last Name"
                  value={lastName}
                  onBlur={onUpdateData}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <TextField
                id="companyName"
                label="Company Name"
                value={companyName}
                onBlur={onUpdateData}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              id="address"
              label="Address line 1"
              value={address}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              label="Address line 2"
              value={address2}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              label="City/Suburb"
              value={city}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              label="State"
              value={state}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="postcode"
              label="Postcode"
              value={postcode}
              numberOnly
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              label="Country"
              value={country}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField id="abn" label="ABN" value={abn} onBlur={onUpdateData} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="businessName"
              label="Business Name"
              value={businessName}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              type="email"
              label="Email"
              value={email}
              onBlur={onUpdateData}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(PartyDetailsContractor): AbstractComponent<
  PropsType,
  mixed
>);
