/**
 *
 * Contract Context
 * @format
 * @flow
 *
 */

import type {
  Contract,
  StandardTermSection,
} from 'app/services/graphQL/generated-types';
import type { PropsType, ReturnType } from './types';
import React, { useState } from 'react';
import {
  parseComponentData,
  prepareContractDetails,
} from '../../utils/contract';

import { DEFAULT_VALUE } from './config';
import type { Node } from 'react';
import type { SaveContractAndCreateWordDocReturnType } from 'contract/graphQL/useSaveContractAndCreateWordDocMutation';
import type { UpdateContractReturnType } from 'contract/graphQL/useUpdateContractMutation';
import useExtractErrorCode from 'app/hooks/useExtractErrorCode';
import { useSaveContractAndCreateWordDocMutation } from 'contract/graphQL/useSaveContractAndCreateWordDocMutation';
import { useUpdateContractMutation } from 'contract/graphQL/useUpdateContractMutation';

export const ContractContext: React$Context<ReturnType> =
  React.createContext<ReturnType>(DEFAULT_VALUE);

const ContractProvider = (props: PropsType): Node => {
  //$FlowFixMe
  const [contractDetails, setContractDetails] = useState<Contract>({});
  const [contractTermData, setContractTermData] = useState({});
  const [contractTermDefaultData, setContractTermDefaultData] = useState({});
  const [contractTermProps, setContractTermProps] = useState({});
  const [contractStandardTermSections, setContractStandardTermSections] =
    useState<StandardTermSection[]>([]);

  const [contractExpandedPanels, setContractExpandedPanels] = useState({
    expandedPanels: [],
    termId: undefined,
    contractSection: {},
  });

  const [defaultEngagementPeriods, setDefaultEngagementPeriods] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    data: dataSavingContract,
    updateContract,
    loading: isLoadingSavingContract,
    error,
  }: UpdateContractReturnType = useUpdateContractMutation();
  const {
    data: saveAndCreateWordDocData,
    saveAndCreateWordDoc,
    loading: isLoadingSaveAndCreateWordDoc,
    error: saveAndCreateWordDocError,
  }: SaveContractAndCreateWordDocReturnType = useSaveContractAndCreateWordDocMutation();
  const { code: updateContractErrCode } = useExtractErrorCode(error);
  const { code: createWordDocErrCode } = useExtractErrorCode(
    saveAndCreateWordDocError
  );
  const isErrorSavingContract = !!updateContractErrCode;
  const isSuccessSavingContract = !!dataSavingContract;
  const isErrorCreatingWordDoc = !!createWordDocErrCode;
  const isSuccessCreatingWordDocument = !!saveAndCreateWordDocData;
  const { children } = props;

  const onSetContractData = (contractData: Contract) => {
    if (contractData) {
      const contractTermData = {};
      const contractTermProps = {};
      const { terms, standardTermSections = [] } = contractData || {};

      if (Array.isArray(terms)) {
        terms.forEach((term) => {
          const {
            uiComponentName: componentName,
            props: componentProps,
            termId,
            stringData,
            boolData,
            numberData,
            objectData,
            arrayData,
          } = term;

          // @todo report the error to 3rd party error logger
          if (componentName && termId) {
            const contractTermKeyName = `${componentName}-${termId}`;
            contractTermData[contractTermKeyName] = {
              ...parseComponentData(stringData || []),
              ...parseComponentData(boolData || []),
              ...parseComponentData(numberData || []),
              ...parseComponentData(objectData || []),
              ...parseComponentData(arrayData || []),
            };
            contractTermProps[contractTermKeyName] = componentProps || {};
          }
        });

        setContractTermData(contractTermData);
        setContractTermProps(contractTermProps);
        setContractDetails(contractData);
        // $FlowFixMe
        setContractStandardTermSections(standardTermSections);
      }
    }
  };

  const onUpdateContractTermData = (
    contractTermKeyName: string,
    contractTermComponentData: Object = {},
    replaceObject: boolean = false
  ) => {
    if (contractTermKeyName && contractTermComponentData) {
      setContractTermData((prevState) => ({
        ...prevState,
        [contractTermKeyName]: {
          ...(replaceObject === false && {
            ...prevState[contractTermKeyName],
          }),
          ...contractTermComponentData,
        },
      }));
    }
  };

  const onSetContractTermDefaultData = (
    contractTermKeyName: string,
    contractTermComponentDefaultData: Object = {}
  ) => {
    if (contractTermKeyName && contractTermComponentDefaultData) {
      setContractTermDefaultData((prevState) => ({
        ...prevState,
        [contractTermKeyName]: {
          ...contractTermComponentDefaultData,
        },
      }));
    }
  };

  const onSetContractStandardTermSections = (data: StandardTermSection[]) => {
    setContractStandardTermSections(data);
  };

  const onSaveContractData = () => {
    const { _id, sectionNames, terms, title, standardTermSections } =
      prepareContractDetails(
        contractDetails,
        contractTermData,
        contractStandardTermSections
      );

    updateContract({
      variables: {
        input: {
          contract: {
            _id,
            sectionNames,
            terms,
            title,
            standardTermSections,
          },
        },
      },
    });
  };

  const onSaveContractAndCreateWordDocument = (
    tags: Array<string>,
    deleteMissingObjectAttributes = true
  ) => {
    const { _id, sectionNames, terms, title } = prepareContractDetails(
      contractDetails,
      contractTermData,
      contractStandardTermSections
    );
    saveAndCreateWordDoc({
      variables: {
        createWordDocumentInput: {
          contractId: _id,
          tags,
        },
        updateContractInput: {
          contract: { _id, sectionNames, terms, title },
          deleteMissingObjectAttributes,
        },
      },
    });
  };

  const onSetExpandedContractSectionTerms = (expandedPanels) => {
    setContractExpandedPanels({
      ...expandedPanels,
    });
  };

  const onSetEngagementPeriods = (periods) => {
    setDefaultEngagementPeriods(periods);
  };

  const onSetEditMode = (isEditMode) => {
    setIsEditMode(isEditMode);
  };

  return (
    <ContractContext.Provider
      value={{
        contractTermData,
        contractTermProps,
        contractTermDefaultData,
        contractStandardTermSections,
        isErrorSavingContract,
        isLoadingSavingContract,
        isSuccessSavingContract,
        onSetContractData,
        onUpdateContractTermData,
        onSetContractTermDefaultData,
        onSetContractStandardTermSections,
        onSaveContractData,
        onSaveContractAndCreateWordDocument,
        isLoadingSaveAndCreateWordDoc,
        isErrorCreatingWordDoc,
        isSuccessCreatingWordDocument,
        onSetExpandedContractSectionTerms,
        contractExpandedPanels,
        onSetEngagementPeriods,
        defaultEngagementPeriods,
        onSetEditMode,
        isEditMode,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};
export default ContractProvider;
