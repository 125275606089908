// @flow

import React from 'react';
import { Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

type ViewRouterInfo = {
  path: string | Array<string>,
  component: any,
  exact: ?boolean,
  collapse: ?boolean,
  redirect: ?boolean,
  redirectPathTo: ?string,
  props: ?any,
};

type Props = {
  routeArray: Array<ViewRouterInfo>,
};

const ViewRouter = ({ routeArray }: Props): React$Element<any> => (
  <Fragment>
    <Switch>
      {routeArray.map((prop, key) => {
        if (prop.redirect && prop.redirectPathTo) {
          return (
            <Redirect
              from={typeof prop.path === 'string' ? prop.path : prop.path[0]}
              to={
                prop.redirectPathTo === undefined ||
                prop.redirectPathTo === null
                  ? ''
                  : prop.redirectPathTo
              }
              key={key}
            />
          );
        }

        const propsSpread = prop.props || {};
        return (
          <Route
            exact={
              prop.exact === undefined || prop.exact === null
                ? false
                : prop.exact
            }
            path={prop.path}
            render={(props) => <prop.component {...props} {...propsSpread} />}
            key={key}
          />
        );
      })}
    </Switch>
  </Fragment>
);

export default ViewRouter;
