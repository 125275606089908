/**
 *
 * OptionTerms
 * @format
 * @flow
 *
 */

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Divider, Grid, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useEffect } from 'react';
import type {
  ComponentDataType,
  ComponentPropsType,
  OptionTermsItemType,
} from './types';

import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import Select from 'app/components/Select/Select';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import { largestItemId } from 'app/utils/general.js';
import { ordinal } from 'app/utils/intl';
import type { PropsType } from '../types';

const OptionTerms: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { feeDeductibleCheckboxText = '' }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const { items = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const optionTermsDefaultItem: OptionTermsItemType = {
    itemId: '1',
    optionFee: '',
    numberOfPeriod: '',
    termsOfPeriod: '',
    feeDeductibleCheckboxTextValue: '',
  };

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      items: [],
    },
  });

  useEffect(() => {
    if (!items.length) {
      onUpdate(contractTermKeyName, { items: [optionTermsDefaultItem] });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const onAddOptionTermsItem = () => {
    const itemId = parseInt(largestItemId(items), 10) + 1;
    const optionTermsItem: OptionTermsItemType = {
      ...optionTermsDefaultItem,
      itemId: itemId.toString(),
    };
    onUpdate(contractTermKeyName, { items: [...items, optionTermsItem] });
  };

  const onRemoveOptionTermsItem = (index) => {
    // Filter out the removed item, then re-assign itemId numbers
    const newItems = items
      .filter((item, i) => i !== index)
      .sort((a, b) => parseInt(a.itemId, 10) - parseInt(b.itemId, 10))
      .map((item, index) => ({ ...item, itemId: (index + 1).toString() }));
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const onUpdateData = (
    fieldName: string,
    fieldValue: string,
    index: number
  ) => {
    const newItems = [...items];
    const currItem = newItems[index];
    const newItem = {
      ...currItem,
      [fieldName]: fieldValue,
    };

    newItems[index] = newItem;
    onUpdate(contractTermKeyName, { items: newItems });
  };

  return (
    <Grid container spacing={2}>
      {items.map((item, index) => {
        const {
          optionFee,
          numberOfPeriod,
          termsOfPeriod,
          itemId,
          feeDeductibleCheckboxTextValue = '',
        } = item;
        let numberOfPeriodToInt = 0;
        if (numberOfPeriod?.length) {
          numberOfPeriodToInt = parseInt(numberOfPeriod);
        }
        return (
          <Grid item xs={12} key={index.toString()}>
            <Grid container justifyContent="space-between">
              <Grid item xs>
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                  {ordinal(parseInt(itemId, 10))} Option
                </Typography>
              </Grid>
              {index >= 1 && (
                <Grid item>
                  <Button
                    onClick={() => onRemoveOptionTermsItem(index)}
                    size="large"
                    startIcon={<DeleteIcon />}
                  ></Button>
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={6} mb={1}>
                <CurrencyField
                  id="optionFee"
                  label="Option Fee"
                  value={optionFee}
                  formatMoney
                  numberOnly
                  startAdornment={<>$</>}
                  onBlur={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} mb={1.5}>
                <TextGeneratingCheckbox
                  id="feeDeductibleCheckboxTextValue"
                  label="Fee deductible from purchase price?"
                  value={feeDeductibleCheckboxTextValue}
                  textGenerating={feeDeductibleCheckboxText}
                  onCheck={(id, val) => onUpdateData(id, val, index)}
                />
              </Grid>
              <Grid container direction="row" alignItems="flex-end" spacing={2}>
                <Grid item xs={6}>
                  <CurrencyField
                    id="numberOfPeriod"
                    label="Option Period"
                    value={numberOfPeriod}
                    numberOnly
                    onBlur={(id, val) => {
                      onUpdateData(id, val, index);
                    }}
                  />
                </Grid>
                <Grid item xs={6} pb={2}>
                  <Select
                    id="termsOfPeriod"
                    value={termsOfPeriod}
                    onSelectOption={(item) => {
                      !Array.isArray(item) &&
                        onUpdateData('termsOfPeriod', item.value, index);
                    }}
                    options={[
                      {
                        value: `month${numberOfPeriodToInt > 1 ? 's' : ''}`,
                        label: `Month${numberOfPeriodToInt > 1 ? 's' : ''}`,
                      },
                      {
                        value: `year${numberOfPeriodToInt > 1 ? 's' : ''}`,
                        label: `Year${numberOfPeriodToInt > 1 ? 's' : ''}`,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            {items.length > 1 && items.length !== index + 1 && (
              <Grid mt={1.5}>
                <Divider />
              </Grid>
            )}
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <Button
          onClick={() => onAddOptionTermsItem()}
          startIcon={<AddIcon />}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default (memo(OptionTerms): AbstractComponent<PropsType, mixed>);
