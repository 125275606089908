/**
 *
 * NamedTextArea
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import Textarea from 'app/components/Textarea/Textarea';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const NamedTextArea = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    label = '',
    placeholder = '',
    minRows = 2,
    maxRows = 5,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const { value = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      value: '',
    },
  });

  const updateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid item xs={12}>
      <Textarea
        id="value"
        label={label}
        value={value}
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        onBlur={updateData}
      />
    </Grid>
  );
};

export default (memo(NamedTextArea): AbstractComponent<PropsType, mixed>);
