/**
 *
 * TextGeneratingCheckbox
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useState } from 'react';

import Checkbox from 'app/components/Checkbox/Checkbox';
import type { PropsType } from './types';
import TwoButtonDialogBox from 'app/components/TwoButtonDialogBox/TwoButtonDialogBox';

const TextGeneratingCheckbox = (props: PropsType): Node => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const {
    id,
    label,
    value,
    textGenerating,
    onCheck,
    showDialogOnUncheck = false,
  } = props;

  return (
    <>
      <Checkbox
        id={id}
        label={label}
        value={!!value}
        onCheck={(id, checked) => {
          if (showDialogOnUncheck && !checked) {
            setIsOpenDialog(true);
          } else {
            onCheck(id, checked ? textGenerating : '');
          }
        }}
      />
      <TwoButtonDialogBox
        open={isOpenDialog}
        onClickOne={() => {
          setIsOpenDialog(false);
        }}
        onClickTwo={() => {
          onCheck(id, '');
          setIsOpenDialog(false);
        }}
        labelButtonOne="Cancel"
        labelButtonTwo="OK"
        titleDialog="Changing selection will delete the data for this term"
      />
    </>
  );
};

export default (memo(TextGeneratingCheckbox): AbstractComponent<
  PropsType,
  mixed
>);
