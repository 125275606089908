/**
 *
 * Intl
 * @format
 * @flow
 *
 */

export const ordinal = (num: number): string => {
  // $FlowFixMe
  const ordinalRules = new Intl.PluralRules('en', {
    type: 'ordinal',
  });
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  };
  // $FlowFixMe
  const suffix = suffixes[ordinalRules.select(num)];
  return num + suffix;
};
