import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AccountDetails from 'company/CompanyAccount/AccountDetails/AccountDetails';
import ContractingParties from 'company/CompanyAccount/ContractingParties/ContractingParties';
import DefaultTerms from 'company/CompanyAccount/DefaultTerms/DefaultTerms';
import Tabs from 'app/components/Tabs/Tabs';
// import Uploads from 'company/CompanyAccount/Uploads/Uploads';
import Users from 'company/CompanyAccount/Users/Users';
import queryString from 'query-string';

const CompanyAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState('0');

  useEffect(() => {
    const searchValues = queryString.parse(location?.search || {});

    setTabValue(searchValues?.tab || '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="company-view" style={{ width: '100%', marginTop: '25px' }}>
      <Tabs
        value={tabValue}
        onChange={(tabVal) => {
          const params = new URLSearchParams({ tab: tabVal.toString() });
          history.replace({
            pathname: location.pathname,
            search: params.toString(),
          });
        }}
        tabs={[
          {
            label: 'Contracting Parties',
            component: <ContractingParties />,
          },
          {
            label: 'Default Contract Terms',
            component: <DefaultTerms />,
          },
          // hide this for now
          // {
          //   label: 'Uploads',
          //   component: <Uploads />,
          // },
          {
            label: 'Account Details',
            component: <AccountDetails />,
          },
          {
            label: 'Users',
            component: <Users />,
          },
        ]}
      />
    </div>
  );
};

export default CompanyAccount;
