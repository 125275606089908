import { CircularProgress, Grid } from '@mui/material';

import { styled } from '@mui/system';

export const GridStyled = styled(Grid)`
  && {
    position: relative;
    display: inline-flex;
    margin-top: 10px;
  }
`;

export const CircularProgressStyled = styled(CircularProgress)`
  && {
    position: absolute;
    top: 10%;
    left: calc(50% - 10px);
    color: ${({ theme }) => theme.palette.grey[100]};
  }
`;
