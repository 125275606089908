/**
 *
 * PaymentSchedule
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect } from 'react';

import { Grid } from '@mui/material';
import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import GeneralMilestones from 'app/components/GeneralMilestones/GeneralMilestones';
import type { PropsType } from './types';

const PaymentSchedule = (props: PropsType): Node => {
  // $FlowFixMe
  const { value, onUpdate, calculateTotals } = props;
  const {
    milestoneOptionsValues,
    fee = 0,
    // $FlowFixMe
    totalAmount,
    // $FlowFixMe
    totalPercentage,
  } = value;

  useEffect(() => {
    const milestones = milestoneOptionsValues.map((item) => {
      let { amount, percentage } = item;
      amount = (+percentage * +fee) / 100;
      return {
        ...item,
        amount,
      };
    });

    // $FlowFixMe
    onUpdate('milestoneOptionsValues', milestones);
    // We remove the other dependencies cost trigger an infinite loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fee]);

  const handleMilestones = (fieldName: string, data) => {
    // $FlowFixMe
    onUpdate('milestoneOptionsValues', data);
    calculateTotals(data);
  };

  return (
    <Grid container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <GeneralMilestones
            generalValues={milestoneOptionsValues || []}
            onDataUpdated={(data) =>
              handleMilestones('generalMilestones', data)
            }
            fee={+fee}
            showAmount={true}
            onUpdate={onUpdate}
            calculateTotals={calculateTotals}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={1.5}
      >
        <Grid item xs={3}>
          <CurrencyField
            id="totalPercentage"
            label="Total Percentage"
            numberOnly
            readOnly
            value={isNaN(totalPercentage) ? 0 : totalPercentage}
            error={totalPercentage > 100}
            helperText={totalPercentage > 100 && 'Error'}
            endAdornment={<>%</>}
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyField
            id="totalAmount"
            label="Total Amount"
            formatMoney
            numberOnly
            readOnly
            value={totalAmount}
            error={+fee < totalAmount}
            helperText={+fee < totalAmount && 'Error'}
            startAdornment={<>$</>}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(PaymentSchedule): AbstractComponent<PropsType, mixed>);
