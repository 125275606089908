/**
 *
 * DefaultTerms
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Alert, Grid, Paper, Snackbar, Typography } from '@mui/material';
import type { DefaultTermsInfoType, PropsType } from './types';
import React, { memo, useEffect, useRef, useState } from 'react';

import Dialog from 'app/components/core/Dialog/Dialog';
import { InfoFilledICon } from './styles';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import PredictiveTextField from 'app/components/PredictiveTextField/PredictiveTextField';
// $FlowFixMe
import ReactMarkdown from 'react-markdown';
// $FlowFixMe
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import Tooltip from 'app/components/Tooltip/Tooltip';
import type { UpdateCompanyDetailsReturnType } from 'company/graphQL/useUpdateCompanyDetailsMutation';
import { filterObjectDataRemovingNulls } from 'app/utils/general';
// $FlowFixMe
import rehypeRaw from 'rehype-raw';
// $FlowFixMe
import remarkGfm from 'remark-gfm';
// $FlowFixMe
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import { useParams } from 'react-router-dom';
// $FlowFixMe
import { useTheme } from '@mui/material/styles';
import { useUISelectValuesConfigQuery } from 'project/graphQL/useUISelectValuesConfigQuery';
import { useUpdateCompanyDetailsMutation } from 'company/graphQL/useUpdateCompanyDetailsMutation';

const staticHtmlPeriodic =
  '<h2>Payment Terms</h2>\\n\\n<ul>\\n<li>\\n<div><span style=\\"font-size:16px\\">The period within which the Prod Co must pay the Contributor (from the relevant milestone).&nbsp;&nbsp;</span></div>\\n</li>\\n<li>\\n<div><span style=\\"font-size:16px\\">Payment terms from the company account details are used by default.</span></div>\\n</li>\\n<li>\\n<div><span style=\\"font-size:16px\\">You can change the payment terms here (just for this contract), or in the company account details (for all, or selected contracts)</span></div>\\n</li>\\n</ul>';

const staticHtmlInvoice =
  '<h2>Payment Terms</h2>\\n\\n<ul>\\n<li>\\n<div><span style=\\"font-size:16px\\">The period within which the Prod Co must pay the Contributor (from the relevant milestone).&nbsp;&nbsp;</span></div>\\n</li>\\n<li>\\n<div><span style=\\"font-size:16px\\">Payment terms from the company account details are used by default.</span></div>\\n</li>\\n<li>\\n<div><span style=\\"font-size:16px\\">You can change the payment terms here (just for this contract), or in the company account details (for all, or selected contracts)</span></div>\\n</li>\\n</ul>';

const staticHtmlAccountingPeriod =
  '<h2>Net Profit Share</h2>\\n\\n<ul>\\n<li>\\n<div><span style=\\"font-size:16px\\">Pay a percentage of&nbsp;<a href=\\"https://www.screeninnovation.com.au/\\" id=\\"NetProfitDef-STC\\" target=\\"_blank\\" title=\\"BusinessAffairs.com Info - Standard Terms\\">Prod Co&#39;s Net Profit</a>&nbsp;to the Contributor.</span></div>\\n</li>\\n<li>\\n<div><span style=\\"font-size:16px\\">Proportional Use - this is a term sometimes applied to an agreement for a script. Where the only part of that script is used in the final Project, the Prod Co may reduce the Contributor&rsquo;s Net Profit Share proportionately. &nbsp;<a href=\\"https://www.screeninnovation.com.au/\\" target=\\"_blank\\" title=\\"BusinessAffairs.com Info - Writer Contracts\\">Writer Contracts</a>.</span></div>\\n</li>\\n</ul>';

const staticHtmlJurisdictionLaw =
  '<h2>Jurisdiction</h2>\\n\\n<ul>\\n<li>\\n<div><span style=\\"font-size:16px\\">The parties agree to the contract being governed by the laws of the selected state and that the courts of that state will have non-exclusive jurisdiction.</span></div>\\n</li>\\n<li>\\n<div><span style=\\"font-size:16px\\">See <a href=\\"https://www.screeninnovation.com.au/\\" target=\\"_blank\\" title=\\"BusinessAffairs.com Info\\">Jurisdiction</a>&nbsp;for further info&nbsp;</span></div>\\n</li>\\n</ul>\\n';

const DefaultTerms: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const routeParams = useParams();
  const theme = useTheme();
  const dialogRef = useRef(null);
  const cyid = routeParams.cyid || '';
  const {
    data: companyDetailsData,
    loading,
    error,
  } = useCompanyDetailsQuery({
    _id: cyid,
  });
  const {
    updateCompanyDetails,
    data: updateCompanyDetailsData,
    loading: companyDetailsIsSaving,
  }: UpdateCompanyDetailsReturnType = useUpdateCompanyDetailsMutation();

  const { data: configData } = useUISelectValuesConfigQuery({
    skip: false,
  });

  const paymentTermsInvoiceValues =
    configData?.uiSelectValuesConfig?.settingsSelectValues?.[
      'paymentTermsInvoiceValues'
    ] || [];

  const paymentTermsPeriodicValues =
    configData?.uiSelectValuesConfig?.settingsSelectValues?.[
      'paymentTermsPeriodicValues'
    ] || [];

  const paymentTermsAccountingPeriodValues =
    configData?.uiSelectValuesConfig?.settingsSelectValues?.[
      'paymentTermsAccountingPeriodValues'
    ] || [];

  const paymentTermsJurisdictionLawValues =
    configData?.uiSelectValuesConfig?.settingsSelectValues?.[
      'paymentTermsJurisdictionLawValues'
    ] || [];

  const [data, setData] = useState<DefaultTermsInfoType>({
    periodicPaymentTerms: '',
    invoicePaymentTerms: '',
    accountingPeriod: '',
    jurisdiction: '',
  });
  const [originalData, setOriginalData] = useState<DefaultTermsInfoType>({
    periodicPaymentTerms: '',
    invoicePaymentTerms: '',
    accountingPeriod: '',
    jurisdiction: '',
  });
  const {
    periodicPaymentTerms,
    invoicePaymentTerms,
    accountingPeriod,
    jurisdiction,
  } = data;

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  useEffect(() => {
    if (companyDetailsData) {
      const company = companyDetailsData.company || {};
      // $FlowFixMe
      const { __typename, ...restCompanyData } = company;

      const filteredCompanyDataWithNullsRemoved = filterObjectDataRemovingNulls(
        restCompanyData,
        [
          'periodicPaymentTerms',
          'invoicePaymentTerms',
          'accountingPeriod',
          'jurisdiction',
        ]
      );
      // $FlowFixMe
      setData((prevState) => ({
        ...prevState,
        ...filteredCompanyDataWithNullsRemoved,
      }));
      // $FlowFixMe
      setOriginalData((prevState) => ({
        ...prevState,
        ...filteredCompanyDataWithNullsRemoved,
      }));
    }
  }, [companyDetailsData]);

  useEffect(() => {
    if (!!updateCompanyDetailsData?.updateCompanyDetails?.company) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the default contract terms!',
      });
      setSnackbarOpen(true);
    }
    if (updateCompanyDetailsData?.updateCompanyDetails?.company === null) {
      setAlertInfo({
        severity: 'error',
        message: 'Error saving the default contract terms!',
      });
      setSnackbarOpen(true);
    }
  }, [updateCompanyDetailsData]);

  const handleCheckChanges = () => {
    const orgItem = JSON.stringify(originalData);
    const dataItem = JSON.stringify(data);
    if (orgItem === dataItem) {
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
  };
  useEffect(() => {
    handleCheckChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!companyDetailsData?.company) return <p>Not found</p>;

  const handleStateChange = (fieldName: string, fieldValue: any) => {
    setData((prevState) => {
      let newData = {
        ...prevState,
        [fieldName]: fieldValue,
      };

      return newData;
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateCompanyDetails = () => {
    updateCompanyDetails({
      variables: {
        input: {
          _id: cyid,
          periodicPaymentTerms,
          invoicePaymentTerms,
          accountingPeriod,
          jurisdiction,
        },
      },
    });
  };

  return (
    <>
      <Dialog
        // $FlowFixMe
        ref={dialogRef}
        title="Cancel Changes"
        labelTwo="Yes"
        labelOne="No"
        onClickTwo={() => {
          dialogRef?.current?.open(false);
          setHasChanges(false);
          setData(originalData);
        }}
        showClose={false}
      >
        Are you sure want to cancel the changes?
      </Dialog>
      <Grid item>
        <Paper elevation={1}>
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.grey['800'],
                width: '100%',
              }}
            >
              Default Terms
            </Typography>
          </Grid>
          <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: 2 }}
            >
              <Tooltip
                body="For employees and any others paid periodically"
                placement="right-end"
              >
                <Typography variant="body2">
                  Payment Terms (periodic)
                </Typography>
              </Tooltip>
              <Tooltip
                trigger="hover"
                htmlBody={
                  <ReactMarkdown
                    children={JSON.parse(`"${staticHtmlPeriodic || ''}"`)}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                }
              >
                <InfoFilledICon />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <PredictiveTextField
                id="periodicPaymentTerms"
                label=""
                freeSolo
                sortByGroup
                options={paymentTermsPeriodicValues.map((item) => ({
                  // $FlowFixMe
                  idGroup: item.idGroup,
                  selectVal: item.displayValue,
                }))}
                inputValue={periodicPaymentTerms}
                onInputChange={(selected, newInputValue) =>
                  handleStateChange('periodicPaymentTerms', newInputValue)
                }
              />
            </Grid>
          </Paper>

          <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: 2 }}
            >
              <Tooltip
                body="Payment of invoices (subject to milestones and terms of agreements)"
                placement="right-end"
              >
                <Typography variant="body2">Payment Terms (invoice)</Typography>
              </Tooltip>
              <Tooltip
                trigger="hover"
                htmlBody={
                  <ReactMarkdown
                    children={JSON.parse(`"${staticHtmlInvoice || ''}"`)}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                }
              >
                <InfoFilledICon />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <PredictiveTextField
                id="invoicePaymentTerms"
                label=""
                freeSolo
                sortByGroup
                options={paymentTermsInvoiceValues.map((item) => ({
                  // $FlowFixMe
                  idGroup: item.idGroup,
                  selectVal: item.displayValue,
                }))}
                inputValue={invoicePaymentTerms}
                onInputChange={(selected, newInputValue) =>
                  handleStateChange('invoicePaymentTerms', newInputValue)
                }
              />
            </Grid>
          </Paper>

          <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: 2 }}
            >
              <Tooltip
                body="When you pay others their share of revenue received from distribution. See suggestions for example terms)"
                placement="right-end"
              >
                <Typography variant="body2">Accounting Period</Typography>
              </Tooltip>
              <Tooltip
                trigger="hover"
                htmlBody={
                  <ReactMarkdown
                    children={JSON.parse(
                      `"${staticHtmlAccountingPeriod || ''}"`
                    )}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                }
              >
                <InfoFilledICon />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextAreaSuggestions
                id="accountingPeriod"
                label=""
                value={accountingPeriod}
                suggestions={paymentTermsAccountingPeriodValues.map(
                  (item) => item.displayValue
                )}
                onSubmitDescription={(id, val) => {
                  handleStateChange(id, val);
                }}
                onChangeTextarea={(id, val) => {
                  handleStateChange(id, val);
                }}
              />
            </Grid>
          </Paper>

          <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginBottom: 2 }}
            >
              <Typography variant="body2">Jurisdiction and Law</Typography>
              <Tooltip
                trigger="hover"
                htmlBody={
                  <ReactMarkdown
                    children={JSON.parse(
                      `"${staticHtmlJurisdictionLaw || ''}"`
                    )}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                }
              >
                <InfoFilledICon />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <PredictiveTextField
                id="jurisdiction"
                label=""
                freeSolo
                sortByGroup
                options={paymentTermsJurisdictionLawValues.map((item) => ({
                  // $FlowFixMe
                  idGroup: item.idGroup,
                  selectVal: item.displayValue,
                }))}
                inputValue={jurisdiction}
                onInputChange={(selected, newInputValue) =>
                  handleStateChange('jurisdiction', newInputValue)
                }
              />
            </Grid>
          </Paper>
        </Paper>
      </Grid>
      <Grid container xs justifyContent="right">
        <LoadingButton
          isLoading={companyDetailsIsSaving}
          loadingText="Cancel"
          defaultText="Cancel"
          // $FlowFixMe
          onClick={() => dialogRef?.current?.open(true)}
          disabled={companyDetailsIsSaving ? true : !hasChanges}
          containerStyle={{ marginRight: 10 }}
        />
        <LoadingButton
          isLoading={companyDetailsIsSaving}
          loadingText="Saving..."
          defaultText="Save"
          onClick={handleUpdateCompanyDetails}
          disabled={!hasChanges}
        />
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default (memo(DefaultTerms): AbstractComponent<PropsType, mixed>);
