import ContainerBase from '@mui/material/Container';
import { styled } from '@mui/system';

const Container = styled(ContainerBase)(({ theme, mobile }) => ({
  position: 'sticky',
  flexBasis: '100%',
  flexGrow: '1',
  left: '256px',
  width: 'calc(100% - 256px)',
  padding: '20px 24px',
  top: '80px',
  background: theme.palette.background.paper,
  zIndex: '2',
  borderBottom: `1px solid ${theme.palette.grey['300']}`,
  [theme.breakpoints.down('lg')]: {
    left: '70px',
    width: '100%',
  },
}));

export { Container };
