/**
 *
 * useUpdateUserMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { userDetailsFragment } from 'user/graphQL/fragments/userFragments';

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...userDetails
      }
    }
  }
  ${userDetailsFragment}
`;

export type UpdateUserReturnType = {
  updateUser: ({
    variables: UpdateUserMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateUserMutation,
  error: any,
};

export const useUpdateUserMutation = (): UpdateUserReturnType => {
  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER);

  return {
    updateUser,
    loading,
    data,
    error,
  };
};
