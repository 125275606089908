/**
 *
 * EquipHire
 * @format
 * @flow
 *
 */

import {
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Button, Divider, Grid, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import Select from 'app/components/Select/Select';
import Textarea from 'app/components/Textarea/Textarea';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import type { PropsType } from '../types';

const EquipHire = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { items = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const { typeOfFee = [] }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      items: [{ equipment: '', fee: '', feeType: '' }],
    },
  });

  const handleAddClick = () => {
    const defaultDataElement = { equipment: '', fee: '', feeType: '' };
    const newItems = [...items, defaultDataElement];
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const handleDelete = (index) => {
    const newItems = items.filter((item, i) => i !== index);
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const onUpdateData = (
    fieldName: string,
    fieldValue: string | boolean,
    index
  ) => {
    const newItems = [...items];
    newItems[index][fieldName] = fieldValue;
    onUpdate(contractTermKeyName, { items: newItems });
  };

  return (
    <Grid
      container
      direction="row-reverse"
      justifyContent="space-between"
      alignItems="center"
    >
      {items.map((item, i) => {
        const { equipment, fee, feeType } = item;
        return (
          <React.Fragment key={i}>
            {i >= 1 && (
              <Grid item p={4} xs={12}>
                <Divider />
              </Grid>
            )}
            <br />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                  Item {i + 1}
                </Typography>
              </Grid>
              {i >= 1 && (
                <Grid item>
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(i)}
                  ></Button>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Textarea
                  id="equipment"
                  label="Equipment"
                  maxRows={5}
                  minRows={2}
                  value={equipment}
                  onBlur={(id, val) => onUpdateData(id, val, i)}
                />
              </Grid>

              <Grid item xs={6}>
                <CurrencyField
                  id="fee"
                  label="Fee"
                  value={fee}
                  formatMoney
                  numberOnly
                  startAdornment={<>$</>}
                  onBlur={(id, val) => onUpdateData(id, val, i)}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  id="feeType"
                  label="Fee Type"
                  value={feeType || ''}
                  forceReset
                  onSelectOption={(item) => {
                    !Array.isArray(item) &&
                      onUpdateData('feeType', item.value, i);
                  }}
                  options={typeOfFee.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12}>
        <Button
          onClick={() => handleAddClick()}
          startIcon={<ControlPointIcon />}
          sx={{ justifyContent: 'start' }}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default (memo(EquipHire): AbstractComponent<PropsType, mixed>);
