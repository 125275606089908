/**
 *
 * GeneralMilestone
 * @format
 * @flow
 *
 */

import { Box, Button, Grid, Typography } from '@mui/material';
import { Delete, PlusSmall } from 'app/assets/icons';
import type { AbstractComponent, Node } from 'react';
import { default as React, memo } from 'react';

import { useTheme } from '@mui/material/styles';
import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import DragDropContainer from 'app/components/DragDropContainer/DragDropContainer';
import TextField from 'app/components/TextField/TextField';
import type { PropsType } from './types';

const GeneralMilestone = (props: PropsType): Node => {
  const theme = useTheme();
  const {
    fee = 0,
    generalValues = [],
    onDataUpdated,
    showAmount = true,
    // $FlowFixMe
    onUpdate,
    // $FlowFixMe
    calculateTotals,
  } = props;

  const updateMilestoneData = (targetValue, id, index) => {
    const generalMilestoneCopy = window.structuredClone(generalValues);
    generalMilestoneCopy[index][id] = targetValue;
    if (id === 'percentage') {
      generalMilestoneCopy[index]['amount'] = (+targetValue * +fee) / 100;
    }
    if (id === 'amount') {
      generalMilestoneCopy[index]['percentage'] = (+targetValue * 100) / +fee;
    }
    onDataUpdated(generalMilestoneCopy);
  };

  const onAddCustomMilestone = (type: string) => {
    const newCustomMilestone = {
      name: '',
      type,
      specialInput: '',
      isCustomMilestone: true,
    };

    const index = generalValues.findIndex(
      // $FlowFixMe
      (val) => val.type === type && val.customIndex === 0
    );

    if (index !== -1) {
      // add new custom milestone after the last custom milestone of the same type
      generalValues.splice(index + 1, 0, newCustomMilestone);
    }

    onUpdate('milestoneOptionsValues', generalValues);
  };

  const onDeleteCustomMilestone = (index) => {
    onUpdate(
      'milestoneOptionsValues',
      generalValues.filter((_, i) => i !== index)
    );
    calculateTotals(generalValues.filter((_, i) => i !== index));
  };

  const onChangeCustomMilestone = (id, val, index) => {
    const updatedValues = generalValues.map((milestone, idx) => {
      if (idx === index) {
        return {
          ...milestone,
          name: val,
        };
      }
      return milestone;
    });
    onUpdate('milestoneOptionsValues', updatedValues);
  };

  const reorder = (milestones, startIndex, endIndex) => {
    const newMilestones = Array.from(milestones);
    const [removedPeriod] = newMilestones.splice(startIndex, 1);
    newMilestones.splice(endIndex, 0, removedPeriod);

    return newMilestones;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedMilestones = reorder(
      generalValues,
      result.source.index,
      result.destination.index
    );

    onUpdate('milestoneOptionsValues', reorderedMilestones);
  };

  const milestones = Array.isArray(generalValues)
    ? generalValues?.map((general, i) => {
        const {
          name = '',
          percentage = 0,
          amount = 0,
          // $FlowFixMe
          isCustomMilestone = false,
          // $FlowFixMe
          type = '',
          // $FlowFixMe
          customIndex,
        } = general;
        return {
          ...general,
          isDragDisabled: !isCustomMilestone ? true : false,
          content: (
            <Box
              sx={{
                border: `1px solid ${theme.palette.grey['400']}`,
                borderRadius: '6px',
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={i}
                flexWrap="noWrap"
                p={2}
              >
                <Grid container direction="row" flexWrap="noWrap">
                  {isCustomMilestone ? (
                    <>
                      <TextField
                        id="name"
                        // $FlowFixMe
                        type={type}
                        value={name}
                        onBlur={(id, val) =>
                          onChangeCustomMilestone(id, val, i)
                        }
                        containerStyle={{ marginBottom: 0 }}
                      />
                      {customIndex <= 0 ? (
                        <Button
                          startIcon={<PlusSmall />}
                          onClick={() => onAddCustomMilestone(type)}
                        ></Button>
                      ) : (
                        <Button
                          startIcon={<Delete />}
                          onClick={() => onDeleteCustomMilestone(i)}
                        ></Button>
                      )}
                    </>
                  ) : (
                    <Typography variant="body1">{name}</Typography>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap={3}
                >
                  <Grid item xs={4} pt={0}>
                    <CurrencyField
                      id="percentage"
                      numberOnly
                      endAdornment={<>%</>}
                      value={percentage}
                      forceReset
                      onValueChange={(id, val) =>
                        updateMilestoneData(val, id, i)
                      }
                      containerStyle={{ marginBottom: 0 }}
                    />
                  </Grid>
                  {showAmount && (
                    <Grid item xs={4}>
                      <CurrencyField
                        id="amount"
                        formatMoney
                        numberOnly
                        startAdornment={<>$</>}
                        value={amount}
                        forceReset
                        onValueChange={(id, val) =>
                          updateMilestoneData(val, id, i)
                        }
                        containerStyle={{ marginBottom: 0 }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          ),
        };
      })
    : [];

  return (
    <>
      <DragDropContainer items={milestones} onDragEnd={onDragEnd} />
    </>
  );
};

export default (memo(GeneralMilestone): AbstractComponent<PropsType, mixed>);
