/**
 *
 * Switch
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { FormControlLabelStyled, Switch as MUISwitch } from './styles';
import type { PropsType } from './types';

const Switch = (props: PropsType): Node => {
  const {
    id,
    label,
    disabled,
    labelPlacement = 'start',
    checked,
    color = 'secondary',
    onChange,
    labelStyle,
    switchStyle,
    display = '',
  } = props;

  return (
    <FormControlLabelStyled
      id={id}
      label={label}
      control={
        <MUISwitch
          checked={checked}
          color={color}
          onChange={(e) => onChange(id, e.target.checked)}
          style={switchStyle}
          sx={{ display: display }}
        />
      }
      disabled={disabled}
      labelPlacement={labelPlacement}
      style={labelStyle}
    />
  );
};

export default (memo(Switch): AbstractComponent<PropsType, mixed>);
