/**
 *
 * ExclusivePeriod
 * @format
 * @flow
 *
 */
import { Grid, IconButton, Paper } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useLayoutEffect, useState } from 'react';

import { Delete as DeleteIcon } from 'app/assets/icons';
import Checkbox from 'app/components/Checkbox/Checkbox';
import DatePicker from 'app/components/DatePicker/DatePicker';
import PredictiveTextField from 'app/components/PredictiveTextField/PredictiveTextField';
import TextField from 'app/components/TextField/TextField';
import TwoButtonDialogBox from 'app/components/TwoButtonDialogBox/TwoButtonDialogBox';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { formatDate } from 'app/utils/general';
import { format } from 'date-fns';
import type { ExclusivePeriodsPropsType } from './types';

const ExclusivePeriod: AbstractComponent<ExclusivePeriodsPropsType> = ({
  index,
  description: periodDescription,
  textFieldLabel1,
  textFieldLabel2,
  contractTermKeyName,
  onUpdateData,
  exclusivitySelect,
  isCustomPeriodProp,
  customPeriodId,
  onDeleteCustomPeriod,
  linkDataProps
}: ExclusivePeriodsPropsType): Node => {
  const { contractTermData } = useContext(ContractContext);

  const { items = [] } = contractTermData[contractTermKeyName] || {};
  const selectedPeriod =
    items?.find(
      (i) =>
        (!isCustomPeriodProp && i.description === periodDescription) ||
        (isCustomPeriodProp && i.id === customPeriodId)
    ) || {};

  const {
    description,
    exclusivity,
    otherDetails,
    isSelected,
    isCustomPeriod,
    startDate,
    endDate,
  } = selectedPeriod;

  const { renderLinkData } = useLinkDataComponent();

  const [showFields, setShowFields] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  // Use current date as default value for start date and end date
  const currentDate = format(new Date(new Date().getTime()), 'dd MMM yyyy');

  const exclusivitySelectOptions = exclusivitySelect?.map((o) => ({
    idGroup: '',
    selectVal: o,
  }));

  useLayoutEffect(() => {
    setShowFields(isSelected);
    setChecked(isSelected);
  }, [isSelected]);

  // date of format to save dd-MM-yyyy
  const formattedStartDate = startDate ? formatDate(startDate) : currentDate;

  const formattedEndDate = endDate ? formatDate(endDate) : currentDate;

  const syncDates = (date, fieldName) => {
    const updatedPeriods = items.map((period, id) => {
      if (period.description === periodDescription) {
        return {
          ...period,
          // $FlowFixMe
          [fieldName]: format(new Date(date), 'dd MMM yyyy'),
        };
      }
      return period;
    });
    onUpdateData(updatedPeriods);
  };

  const onSelectPeriod = (checked) => {
    const updatedItems = [...items];

    if (checked) {
      updatedItems.push({
        description: periodDescription,
        isSelected: checked,
        exclusivity: '',
        otherDetails: '',
        startDate: '',
        endDate: '',
      });
    } else {
      const idx = updatedItems.findIndex((i) => i.description === description);
      if (idx !== -1) {
        updatedItems.splice(idx, 1);
      }
    }

    onUpdateData(updatedItems);
  };

  const onChangeData = (
    field,
    value,
    periodDescription,
    isCustomPeriod = false
  ) => {
    const updatedItems = items?.map((item, id) => {
      if (
        item.description === periodDescription ||
        (item.isCustomPeriod && item.id === customPeriodId)
      ) {
        return {
          ...item,
          // $FlowFixMe
          [field]: value,
        };
      }

      return item;
    });

    onUpdateData(updatedItems);
  };

  return (
    <Paper elevation={3}>
      <Grid container mb={showFields ? 5 : 0}>
        <Grid item>
          {!isCustomPeriod && (
            <Checkbox
              id="period"
              label={!isCustomPeriod && periodDescription}
              checked={checked || false}
              onCheck={(id, checked) => {
                if (!checked) {
                  setIsOpenDialog(true);
                } else {
                  setShowFields(checked);
                  setChecked(checked);
                  onSelectPeriod(checked);
                }
              }}
              formControlLabelStyle={{
                marginRight: isCustomPeriod ? '0' : 'initial',
              }}
            />
          )}
        </Grid>
        {isCustomPeriod && (
          <>
            {' '}
            <Grid item ml="auto">
              <IconButton
                onClick={() => {
                  setIsOpenDialog(true);
                }}
                sx={{ padding: '0' }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid xs={12} item mt={1}>
              <TextField
                id="description"
                label="Description"
                value={description || ''}
                containerStyle={{ marginBottom: '0' }}
                onBlur={(id, val) => {
                  onChangeData(
                    'description',
                    val,
                    periodDescription,
                    isCustomPeriod
                  );
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      {showFields && (
        <>
          <Grid container spacing={1}>
            <Grid item xs>
              <DatePicker
                id="startDate"
                label="Start"
                value={formattedStartDate}
                onValueChange={(id, val) => {
                  onChangeData(
                    'startDate',
                    format(new Date(val), 'dd MMM yyyy'),
                    periodDescription
                  );
                }}
                inputFormat={'dd MMM yyyy'}
                renderLinkData={
                  !isCustomPeriod &&
                  renderLinkData(
                    formattedStartDate,
                    'startDate',
                    (projectDetailValue) => {
                      if (!startDate) {
                        if (projectDetailValue) {
                          syncDates(projectDetailValue, 'startDate');
                        } else {
                          syncDates(currentDate, 'startDate');
                        }
                      }
                    },
                    { fieldName: 'description', fieldValue: description }
                  )
                }
              />
            </Grid>
            <Grid item xs>
              <DatePicker
                id="endDate"
                label="End"
                value={formattedEndDate}
                onValueChange={(id, val) => {
                  onChangeData(
                    'endDate',
                    format(new Date(val), 'dd MMM yyyy'),
                    periodDescription
                  );
                }}
                inputFormat={'dd MMM yyyy'}
                renderLinkData={
                  !isCustomPeriod &&
                  renderLinkData(
                    formattedEndDate,
                    'endDate',
                    (projectDetailValue) => {
                      if (!endDate) {
                        if (projectDetailValue) {
                          syncDates(projectDetailValue, 'endDate');
                        } else {
                          syncDates(currentDate, 'endDate');
                        }
                      }
                    },
                    { fieldName: 'description', fieldValue: description }
                  )
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs>
              <PredictiveTextField
                id="exclusivity"
                freeSolo
                label={textFieldLabel1}
                options={exclusivitySelectOptions}
                onInputChange={(selected, newInputValue) => {
                  onChangeData('exclusivity', newInputValue, periodDescription);
                }}
                inputValue={exclusivity}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={textFieldLabel2}
                value={otherDetails || ''}
                onBlur={(_id, val) => {
                  onChangeData('otherDetails', val, periodDescription);
                }}
              />
            </Grid>
          </Grid>
          <TwoButtonDialogBox
            open={isOpenDialog}
            onClickOne={() => {
              setIsOpenDialog(false);
            }}
            onClickTwo={() => {
              if (isCustomPeriod) {
                onDeleteCustomPeriod(customPeriodId, items);
              } else {
                setChecked(false);
                setShowFields(false);
                onSelectPeriod(false);
              }
              setIsOpenDialog(false);
            }}
            labelButtonOne="Cancel"
            labelButtonTwo="OK"
            titleDialog="Changing selection will delete the data for this period"
          />
        </>
      )}
    </Paper>
  );
};
export default (memo(ExclusivePeriod): AbstractComponent<mixed>);
