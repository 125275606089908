/**
 *
 * Dialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';
import React, { forwardRef, memo, useImperativeHandle, useState } from 'react';

import Button from 'app/components/core/Button/Button';
import { Close } from 'app/assets/icons';
import IconButton from 'app/components/core/IconButton/IconButton';
import type { PropsType } from './types';

const Dialog = (props: PropsType, ref: any): Node => {
  const {
    title = '',
    labelOne,
    labelTwo,
    showButtonOne = true,
    showButtonTwo = true,
    showClose = true,
    onClickOne,
    onClickTwo,
    children,
  } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  const onCloseDialog = () => {
    setOpen(false);
  };

  const buttonOneLabel = labelOne ? labelOne : 'Cancel';
  const buttonTwoLabel = labelTwo ? labelTwo : 'Add anyway';
  const buttonOneClickAction = onClickOne ? onClickOne : onCloseDialog;
  const buttonTwoClickAction = onClickTwo ? onClickTwo : onCloseDialog;

  useImperativeHandle(
    ref,
    () => ({
      open: (val: boolean) => setOpen(val),
    }),
    []
  );

  return (
    <MuiDialog
      open={isOpen}
      onClose={onCloseDialog}
      aria-labelledby="dialog-title"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">{title}</Typography>
          {showClose && (
            <IconButton onClick={onCloseDialog}>
              <Close />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: '20px 24px' }}>{children}</DialogContent>
      <DialogActions sx={{ marginTop: '32px' }}>
        {showButtonOne && (
          <Button variant="secondary" onClick={buttonOneClickAction}>
            {buttonOneLabel}
          </Button>
        )}
        {showButtonTwo && (
          <Button variant="primary" onClick={buttonTwoClickAction}>
            {buttonTwoLabel}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default (memo(forwardRef(Dialog)): AbstractComponent<PropsType, mixed>);
