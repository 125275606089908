/**
 *
 * FormInputText
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

// $FlowFixMe
import { Controller } from "react-hook-form";
import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';

const FormInputText = (props: PropsType): Node => {
  const { control, name, label } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
            id={name}
            label={label}
            value={value}
            error={!!error}
            onChange={onChange}
            helperText={error ? error.message : null}
          />
      )}
    />
  );
};

export default (memo(FormInputText): AbstractComponent<PropsType, mixed>);
