/**
 *
 * Expenses
 * @format
 * @flow
 *
 */

import {
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { Button, Grid, Paper } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import {
  addSupplier,
  changeLookupItemReference,
  removeLookupItem,
} from './utils';

import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import TextField from 'app/components/TextField/TextField';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import { isNil } from 'ramda';
import type { PropsType } from '../types';

const Expenses = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    supplierButtonLabel = 'Prod Co pays supplier directly',
    contractingPartyButtonLabel = 'Prod Co pays contracting party',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    suppliers = [],
    contractingParties = [],
    lookup = [],
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      lookup: [{ supplierIdx: 0 }],
      suppliers: [],
      contractingParties: [],
    },
  });

  const addSupplierButtonClicked = () => {
    const updateObj = addSupplier(lookup, suppliers);
    onUpdate(contractTermKeyName, updateObj);
  };

  const removeExpenseItem = (index: number) => {
    const updateObj = removeLookupItem(
      index,
      lookup,
      contractingParties,
      suppliers
    );
    onUpdate(contractTermKeyName, updateObj);
  };

  const radioButtonSelectionChange = (index, val) => {
    const updateObj = changeLookupItemReference(
      index,
      lookup,
      contractingParties,
      suppliers,
      val
    );
    onUpdate(contractTermKeyName, updateObj);
  };

  const updateSupplierData = (value: string, index: number) => {
    const suppliersCpy = window.structuredClone(suppliers);
    const lookupItem = lookup[index];
    const supplierIndex = lookupItem.supplierIdx;
    if (isNil(supplierIndex)) {
      throw new Error('No supplierIndex where there should be one');
    }
    suppliersCpy[supplierIndex] = { description: value };
    onUpdate(contractTermKeyName, { suppliers: suppliersCpy });
  };

  const updateContractingPartyData = (
    fieldName: string,
    value: string,
    index: number
  ) => {
    const contractingPartiesCpy = window.structuredClone(contractingParties);
    const lookupItem = lookup[index];
    const contractingPartiesIndex = lookupItem.contractingPartiesIdx;
    if (isNil(contractingPartiesIndex)) {
      throw new Error('No contractingPartiesIndex where there should be one');
    }
    contractingPartiesCpy[contractingPartiesIndex][fieldName] = value;
    onUpdate(contractTermKeyName, {
      contractingParties: contractingPartiesCpy,
    });
  };

  return (
    <>
      {lookup.map((item, index) => {
        const { supplierIdx, contractingPartiesIdx } = item;
        return (
          <Paper style={{ padding: 20, marginTop: 20 }} key={index}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {index >= 1 && (
                <Grid item>
                  <Button
                    onClick={() => removeExpenseItem(index)}
                    size="large"
                    startIcon={<DeleteIcon />}
                  ></Button>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={5}>
                <RadioButtonGroup
                  row
                  id="payType"
                  value={
                    isNil(supplierIdx) ? 'contractingParty' : 'supplyDirectly'
                  }
                  onValueChange={(id, val) => {
                    radioButtonSelectionChange(index, val);
                  }}
                >
                  <Grid item>
                    <RadioButton
                      id={supplierButtonLabel}
                      value="supplyDirectly"
                      label={supplierButtonLabel}
                    />
                  </Grid>
                  <Grid item xs>
                    <RadioButton
                      id={contractingPartyButtonLabel}
                      value="contractingParty"
                      label={contractingPartyButtonLabel}
                    />
                  </Grid>
                </RadioButtonGroup>
              </Grid>
              {!isNil(supplierIdx) && (
                <Grid item xs={7}>
                  <TextField
                    id="paySupplier"
                    label="Description"
                    placeholder="e.g. economy flights and hotel"
                    value={suppliers[supplierIdx]?.description}
                    onBlur={(id, val) => {
                      updateSupplierData(val, index);
                    }}
                  />
                </Grid>
              )}
              {!isNil(contractingPartiesIdx) && (
                <>
                  <Grid item xs={3}>
                    <CurrencyField
                      id="amount"
                      label="Amount"
                      value={contractingParties[contractingPartiesIdx]?.amount}
                      formatMoney
                      numberOnly
                      startAdornment={<>$</>}
                      onBlur={(id, val) => {
                        updateContractingPartyData(id, val, index);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="description"
                      label="Description"
                      placeholder="e.g. economy flights and hotel"
                      value={
                        contractingParties[contractingPartiesIdx]?.description
                      }
                      onBlur={(id, val) => {
                        updateContractingPartyData(id, val, index);
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        );
      })}
      <Grid item xs={12} mt={3}>
        <Button
          onClick={addSupplierButtonClicked}
          size="large"
          startIcon={<ControlPointIcon />}
          sx={{ justifyContent: 'start' }}
        ></Button>
      </Grid>
    </>
  );
};

export default (memo(Expenses): AbstractComponent<PropsType, mixed>);
