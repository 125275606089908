/**
 *
 * PaymentScheduleNetBudget
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext, useState } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import CustomMilestones from 'app/components/CustomMilestones/CustomMilestones';
import OptionalMilestone from 'app/components/OptionalMilestone/OptionalMilestone';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import { Tune as TuneIcon } from '@mui/icons-material';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PaymentScheduleNetBudget: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    milestoneOptionsValues = [],
    dates = [],
    specifies = [],
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const { allowedMilestoneNames = [], netBudgetProp = '' }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const [open, setOpen] = useState(false);

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      milestoneOptionsValues: [],
      dates: [],
      specifies: [],
    },
  });

  const toggleDialog = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCustomValueDate = (dates) => {
    onUpdate(contractTermKeyName, { dates });
  };

  const handleCustomValueSpecify = (specifies) => {
    onUpdate(contractTermKeyName, { specifies });
  };

  const handleCustomValueMilestone = (milestoneOptionsValues) => {
    onUpdate(contractTermKeyName, {
      milestoneOptionsValues,
    });
  };

  const getNewRecord = (name) => ({
    name,
    specialInput: '',
    percentage: 0,
    amount: 0,
  });

  const handleOptionalMilestonesData = (targetValue: string, property, i) => {
    const newMilestoneValue = [...milestoneOptionsValues];
    newMilestoneValue[i][property] = targetValue;
    onUpdate(contractTermKeyName, {
      milestoneOptionsValues: newMilestoneValue,
    });
  };

  return (
    <Grid container>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={6}>
          <Typography variant="body1">Payment Schedule Net Budget</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={toggleDialog}>
            <TuneIcon>Optional Milestones</TuneIcon>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={5}>
          <Typography variant="body1">Milestone</Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        {milestoneOptionsValues.map((milestoneValue, index) => (
          <React.Fragment key={index}>
            {milestoneOptionsValues[index].name === 'Select Date' ? (
              <Grid item xs={12}>
                <CustomMilestones
                  netBudget={netBudgetProp}
                  customValues={dates}
                  onDataUpdated={handleCustomValueDate}
                  name={milestoneValue.name}
                  conditionalProp="date"
                />
              </Grid>
            ) : milestoneOptionsValues[index].name === 'Specify Own' ? (
              <Grid item xs={12}>
                <CustomMilestones
                  netBudget={netBudgetProp}
                  customValues={specifies}
                  onDataUpdated={handleCustomValueSpecify}
                  name={milestoneValue.name}
                  conditionalProp="specify"
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={4}>
                  <Typography variant="body1">{milestoneValue.name}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <TextField
                    id="percentage"
                    numberOnly
                    value={milestoneValue.percentage}
                    endAdornment={<>%</>}
                    onBlur={(id, val) => {
                      handleOptionalMilestonesData(val, id, index);
                    }}
                  />
                </Grid>
                <Grid item xs={5}></Grid>
              </>
            )}
          </React.Fragment>
        ))}
      </Grid>

      <Grid item xs={12}>
        <OptionalMilestone
          onDialogClose={toggleDialog}
          dialogOpen={open}
          allowedNamesArr={allowedMilestoneNames}
          optionalMilestonesValues={milestoneOptionsValues}
          onUpdate={handleCustomValueMilestone}
          getNewRecord={getNewRecord}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(PaymentScheduleNetBudget): AbstractComponent<
  PropsType,
  mixed
>);
