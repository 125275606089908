import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import { GET_USER_DATA } from '../queries';
import Icon from '@mui/material/Icon';
import Loading from 'app/components/Loading/Loading';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useExtractErrorCode from 'app/hooks/useExtractErrorCode';
import { useQuery } from '@apollo/client';

const PREFIX = 'AccountView';

const classes = {
  root: `${PREFIX}-root`,
  media: `${PREFIX}-media`,
  btnColor: `${PREFIX}-btnColor`,
  field: `${PREFIX}-field`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    maxWidth: 345,
  },
  [`& .${classes.media}`]: {
    height: 140,
  },
  [`& .${classes.btnColor}`]: {
    backgroundColor: 'purple',
  },

  [`& .${classes.field}`]: {
    marginTop: 20,
    marginBottom: 20,
  },
});

const AccountView = () => {


  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    if (title === '' || details === '') {
      isError = true;
    }
    setTitleError(isError);
    setDetailsError(isError);
  };
  const { loading, error, data } = useQuery(GET_USER_DATA);
  const { code } = useExtractErrorCode(error);

  if (loading || !data) {
    return <Loading />;
  }

  if (error) {
    console.log('ERROR valid - checking', { error, code, loading, data });
    if (code === 'UNAUTHENTICATED') {
      console.log(
        `GQL unauthenticated error returned, login is likely in progress so waiting...`
      );
      return <Loading />;
    }
    return <div>An error occurred :(</div>;
  }

  const { user: gQLUser } = data || {};
  // const { accountIdentifier, emails, familyName, givenName, idProvider, _id } =
  //   gQLUser;
  const { familyName, givenName } = gQLUser;
  const userName =
    givenName && familyName ? `${givenName} ${familyName}` : 'Hello';

  return (
    (<Root>
      <Typography variant="h3" color="primary" align="center" gutterBottom>
        Account View
      </Typography>
      <Typography align="justify" paragraph>
        This is the account view. A view is something that exists inside a page,
        i.e. a portion of the page that is swapped out depending on the route.
      </Typography>
      <Typography align="justify" paragraph>
        This is a placeholder page and as such should be replaced with something
        that shows the user account details. e.g. the various companies they
        belong to.
      </Typography>
      <Typography align="justify">
        The following data is returned from a private GraphQL API request (i.e.
        you need to be logged in to do it):
        <br />
        <br />
        {`${JSON.stringify(gQLUser, null, 2)}`}
        <br />
        <br />
      </Typography>
      <div>
        {/* <img
            // src={} TODO: get the user's account picture from the app database
            alt={userName}
            width="200"
            height="200"
          /> */}
        <Typography variant="h3">{`${userName}`}</Typography>
        <Typography>{gQLUser.email}</Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          onChange={(e) => setTitle(e.target.value)}
          className={classes.field}
          id="outlined-basic"
          label="Send Input Test"
          variant="outlined"
          fullWidth
          multiline
          error={titleError}
        />

        <TextField
          onChange={(e) => setDetails(e.target.value)}
          className={classes.field}
          id="outlined-basic"
          label="Details"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          error={detailsError}
        />
        <Button
          variant="contained"
          type="send"
          color="primary"
          endIcon={<Icon>send</Icon>}
        >
          send
        </Button>
      </form>
      <Button variant="text" color="primary" startIcon={<DeleteIcon />}>
        Deleted
      </Button>
      <Button variant="outlined" color="secondary">
        Primary
      </Button>
      <br />
      <ButtonGroup
        color="secondary"
        variant="contained"
        className={classes.field}
      >
        <Button className={classes.btnColor}>One</Button>
        <Button>two</Button>
      </ButtonGroup>
    </Root>)
  );
};

export default AccountView;
