/**
 *
 * EngagementPeriodExcl
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import ExclusivePeriod from 'app/containers/ExclusivePeriod/ExclusivePeriod';
import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const EngagementPeriodExcl: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { items = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const { conditionalProp }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: { items: [] },
  });

  const handleData = (newItems) => {
    onUpdate(contractTermKeyName, { items: newItems });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">Services Period & Exclusivity</Typography>
      </Grid>
      <Grid item xs={12}>
        <ExclusivePeriod
          conditionalProp={conditionalProp}
          exclusivePeriods={items}
          onUpdateExclusivePeriod={handleData}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(EngagementPeriodExcl): AbstractComponent<
  PropsType,
  mixed
>);
