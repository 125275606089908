/**
 *
 * @format
 * @flow
 *
 */

import * as React from 'react';

import { ChevronDown } from 'app/assets/icons';
import type { ExpandMenuButtonPropsType } from './types';
import { ExpandMore } from './styles';

const ExpandMenuButton: React.AbstractComponent<ExpandMenuButtonPropsType> = ({
  expand,
  onClick,
  visibility,
}: ExpandMenuButtonPropsType): React.Node => {
  return (
    <ExpandMore
      id="expandMenuButton"
      expand={expand}
      onClick={onClick}
      aria-expanded={expand}
      aria-label="expand menu"
    >
      <ChevronDown
        visibility={visibility}
        strokeWidth="1.7"
        style={{ width: '1em' }}
      />
    </ExpandMore>
  );
};

export default (React.memo(ExpandMenuButton): React.AbstractComponent<
  ExpandMenuButtonPropsType,
  mixed
>);
