// @flow

import {
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import Link from '@mui/material/Link';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useAccountActions from 'app/services/auth/useAccountActions';
import { useTheme } from '@mui/material';

const Copyright = () => {
  return (
    <Typography variant="subtitle2" color="text.secondary" align="right">
      {'Copyright © '}
      <a
        target="_blank"
        href="https://www.businessaffairs.com/"
        rel="noopener noreferrer"
      >
        Business Affairs
      </a>
      {' ' + new Date().getFullYear()}
    </Typography>
  );
};

type Props = {
  autoComplete: string,
  name: string,
  variant: string,
  id: string,
  label: string,
  type: string,
};

const LandingPage = ({
  autoComplete,
  name,
  variant,
  id,
  label,
  type,
}: Props): React$Element<any> => {
  const theme = useTheme();
  const [signupEnabled, setSignupEnabled] = useState(false);
  const { login, register, loggingIn } = useAccountActions();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  // $FlowFixMe
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div
        style={{
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h3" align="center">
          Business Affairs Maker
        </Typography>
        <form
          style={{
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(3),
          }}
          noValidate
        >
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <LoadingButton
                isLoading={loggingIn}
                href="#"
                disabled={!signupEnabled}
                variant="primary"
                onClick={() => {
                  login();
                }}
                sx={{ margin: theme.spacing(3, 0, 2) }}
                defaultText="Sign in"
                loadingText="Loading..."
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={signupEnabled}
                    onChange={() => setSignupEnabled(!signupEnabled)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="subtitle2" align="justify">
                    "I have read and agreed to Business Affairs{' '}
                    <Link
                      href="https://www.businessaffairs.com/termsofservice"
                      variant="subtitle2"
                      underline="always"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      terms of service
                    </Link>
                    "
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <Link
                component="button"
                aria-describedby={popoverId}
                variant="button"
                underline="always"
                onClick={(e) => {
                  e.preventDefault();
                  if (signupEnabled) {
                    register();
                  } else {
                    handleClick(e);
                  }
                }}
              >
                No account? Create Account
              </Link>
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography sx={{ p: 0 }}>
                  Please read the terms of service and agree to them by clicking
                  the checkbox.
                </Typography>
              </Popover>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LandingPage;
