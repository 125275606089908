/**
 *
 * AmendmentInputDelete
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import Textarea from 'app/components/Textarea/Textarea';
import IconButton from 'app/components/core/IconButton/IconButton';
import type { PropsType } from './types';

const AmendmentInputDelete: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { sectionIdx, termIdx, value, onTextBlur, onDelete, isEditMode } =
    props;

  return (
    <Grid
      item
      xs={12}
      sx={{
        '.MuiGrid-item': {
          paddingLeft: '10px',
          paddingTop: '0px',
          marginTop: 1,
        },
      }}
    >
      <Grid container direction="row" alignItems="center">
        {isEditMode ? (
          <>
            <Grid item xs={11}>
              <Textarea
                id="amendment"
                label="* Amendment"
                maxRows={5}
                minRows={2}
                value={value || ''}
                onBlur={(_, val) => {
                  onTextBlur(sectionIdx, termIdx, val);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  onDelete(sectionIdx, termIdx);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        ) : (
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                * Amendment
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body">{value}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default (memo(AmendmentInputDelete): AbstractComponent<
  PropsType,
  mixed
>);
