/**
 *
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { CompanySwitchContainer, CompanySwitchTitle } from './styles';

import type { CompanySwitchPropsType } from './types';
import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import React from 'react';
import { getUserColor } from 'app/utils/general.js';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useUserQuery } from 'user/graphQL/useUserQuery';

const CompanySwitchMenu: AbstractComponent<CompanySwitchPropsType> =
  (): Node => {
    const theme = useTheme();
    const history = useHistory();
    const { data: userData } = useUserQuery();
    const hasCompanies = userData?.user?.companies?.length;
    const userColor = getUserColor();

    const setAvatarText = () => {
      let stringValue = '';
      const getSelectedCompany = userData?.user?.companies?.find(
        (item) => item._id === userData?.user?.selectedCompany
      );
      // $FlowFixMe
      if (getSelectedCompany.type === EntityTypeValues.Company) {
        // $FlowFixMe
        const firstString = getSelectedCompany.name.charAt(0);
        // $FlowFixMe
        const stringSeparator = getSelectedCompany.name.search(' ');
        // $FlowFixMe
        const secondString = getSelectedCompany.name.charAt(
          stringSeparator + 1
        );
        stringValue = `${firstString}${secondString}`;
      } else {
        // $FlowFixMe
        const { givenName, familyName } = userData?.user;
        // $FlowFixMe
        const firstString = givenName.charAt(0);
        // $FlowFixMe
        const secondString = familyName.charAt(0);
        stringValue = `${firstString}${secondString}`;
      }
      return stringValue;
    };

    return (
      <>
        <Box
          sx={{ marginBottom: '24px', flexGrow: 1, overflow: 'hidden' }}
          onClick={(e) => {
            history.push(`/`);
          }}
        >
          <CompanySwitchContainer
            container
            wrap="nowrap"
            gap={1}
            alignItems="center"
          >
            {hasCompanies ? (
              <>
                <Grid item>
                  <Avatar
                    sx={{
                      gridArea: 'companyLogo',
                      height: '35px',
                      width: '35px',
                      marginRight: '1px',
                      bgcolor: userColor,
                      color: theme.palette.grey['700'],
                      borderRadius: '5px',
                    }}
                    variant="square"
                  >
                    {setAvatarText()}
                  </Avatar>
                </Grid>
                <Grid container display="flex" direction="column" width="250px">
                  <Grid item xs zeroMinWidth>
                    <CompanySwitchTitle
                      variant="subtitle2"
                      className="expMenuItem"
                      sx={{
                        fontSize: '10px',
                        fontWeight: '700',
                        color: theme.palette.grey['600'],
                        textTransform: 'uppercase',
                      }}
                    >
                      {userData?.user?.companies?.find(
                        (item) => item._id === userData?.user?.selectedCompany
                      )?.type === EntityTypeValues.Company
                        ? 'Organization'
                        : 'Individual'}
                    </CompanySwitchTitle>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <CompanySwitchTitle
                      variant="subtitle1"
                      className="expMenuItem"
                      noWrap
                    >
                      {
                        userData?.user?.companies?.find(
                          (item) => item._id === userData?.user?.selectedCompany
                        )?.name
                      }
                    </CompanySwitchTitle>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="body">No Organisation Yet</Typography>
            )}
          </CompanySwitchContainer>
          <Divider
            variant="middle"
            sx={{
              marginLeft: {
                lg: '10px',
                sm: '5px',
              },
              marginRight: {
                sm: '0',
              },
              marginTop: '8px',
            }}
          />
        </Box>
      </>
    );
  };

export default CompanySwitchMenu;
