/**
 *
 * DropdownAddOwn
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { Grid } from '@mui/material';
import type { PropsType } from './types';
import Select from 'app/components/Select/Select';
import Textarea from 'app/components/Textarea/Textarea';

const DropdownAddOwn = (props: PropsType): Node => {
  const { selectId, textAreaId, selectValue, textAreaValue, config, onUpdate } =
    props;
  const { label, options } = config;

  const onUpdateDropdown = (fieldName, fieldValue) => {
    onUpdate(fieldName, fieldValue);
  };

  return (
    <Grid item xs={12}>
      <Select
        id={selectId}
        label={label}
        value={selectValue}
        onSelectOption={(item) => {
          !Array.isArray(item) && onUpdateDropdown(selectId, item.value);
        }}
        options={options.map((item) => ({
          value: item,
          label: item,
        }))}
      />
      {selectValue === 'Specify' && (
        <Textarea
          id={textAreaId}
          placeholder="add details"
          maxRows={5}
          minRows={2}
          value={textAreaValue}
          onBlur={(id, val) => onUpdateDropdown(id, val)}
        />
      )}
    </Grid>
  );
};

export default (memo(DropdownAddOwn): AbstractComponent<PropsType, mixed>);
