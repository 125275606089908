/**
 *
 * ProjectsTable
 * @format
 * @flow
 *
 */

import * as IconLib from 'app/assets/icons';

import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Menu from 'app/components/core/Menu/Menu';
import Table from 'app/components/core/Table/Table';
import type { ContractDetailsReturnType } from 'contract/graphQL/useCompanyContractsQuery';
import { useCompanyContractsQuery } from 'contract/graphQL/useCompanyContractsQuery';
import type { ProjectsViewProjectsReturnType } from 'project/graphQL/useProjectsViewProjectsQuery';
import { useProjectsViewProjectsQuery } from 'project/graphQL/useProjectsViewProjectsQuery';
import type { Node } from 'react';

const ProjectsTable = (): Node => {
  const { cyid } = useParams();
  const {
    loading,
    error,
    data,
    refetch: refetchProjects,
  }: ProjectsViewProjectsReturnType = useProjectsViewProjectsQuery();

  const {
    data: companyContractsData,
    refetch: refetchCompanyContracts,
  }: ContractDetailsReturnType = useCompanyContractsQuery();

  const theme = useTheme();
  const [actionsEl, setActionsEl] = useState(null);

  const contractItems = useCallback(
    (_id) => {
      if (
        companyContractsData !== undefined &&
        companyContractsData?.contracts.length > 0
      ) {
        const getProjectContracts = companyContractsData?.contracts.filter(
          (contract) => contract?.project?._id === _id
        );
        return getProjectContracts.length;
      } else return;
    },
    [companyContractsData]
  );

  const columnOptions = {
    sort: false,
  };
  const columns = [
    {
      name: '_id',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        ...columnOptions,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _id = tableMeta.rowData[0];
          const title = tableMeta.rowData[1];
          const numberAndDuration = tableMeta.rowData[4]; // 200 x 20
          const [episodes, duration] = numberAndDuration.split('x') || [];
          const Icon = IconLib['GreyFile'];
          return (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '32px 1fr',
                gridTemplateAreas: `"icon projectName"
                ". numberAndDuration"`,
                gap: 2,
                width: '100%',
              }}
            >
              <Box sx={{ gridArea: 'icon' }}>
                <Icon
                  style={{
                    height: '32px',
                    width: '32px',
                    padding: '6px',
                    background: theme.palette.grey['200'],
                    borderRadius: '8px',
                  }}
                />
              </Box>
              <Box sx={{ gridArea: 'projectName' }}>
                <Link
                  // $FlowFixMe
                  to={`/dashboard/${cyid}/project/${_id}`}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.primary,
                  }}
                >
                  {' '}
                  <Typography style={{ fontWeight: '600' }}>{title}</Typography>
                </Link>
                <Typography variant="body1">
                  {episodes} episodes | {duration} minutes
                </Typography>
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: 'genreTypeNames',
      label: 'Genre',
      options: {
        ...columnOptions,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={1}>
              {value?.map((item) => {
                return <Chip label={item} size="small" />;
              })}
            </Stack>
          );
        },
      },
    },
    {
      name: 'initialReleasePlatformTypeNames',
      label: 'Release',
      options: {
        ...columnOptions,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={1}>
              {value?.map((item) => {
                return <Chip label={item} size="small" />;
              })}
            </Stack>
          );
        },
      },
    },
    {
      name: 'initialReleasePlatform',
      label: 'Contracts',
      options: {
        ...columnOptions,
        customBodyRender: (value, tableMeta) => {
          const _id = tableMeta.rowData[0];
          const DocumentIcon = IconLib['Document'];

          /**
           * TODO: Contracts - get total count of contracts for the given project.
           * Temporarily added total count of contracts for user logged in
           */
          return (
            <Grid container spacing={0.5} direction="row">
              <Grid item xs={6}>
                <Grid direction="row" style={{ display: 'flex', gap: '6px' }}>
                  <DocumentIcon
                    color={theme.palette.text.disabled}
                    width="20px"
                    height="20px"
                  />
                  <span>{contractItems(_id)}</span>
                </Grid>
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        ...columnOptions,
        customBodyRender: () => {
          // TODO: Action buttons
          const Icon = IconLib['MoreVertical'];
          return (
            <>
              <Icon
                aria-controls="table-actions"
                aria-haspopup="true"
                onClick={(e) => setActionsEl(e.currentTarget)}
                style={{ cursor: 'pointer' }}
              />
              {/* $FlowFixMe */}
              <Menu
                id="table-actions"
                anchorEl={actionsEl}
                keepMounted
                open={Boolean(actionsEl)}
                onClose={() => setActionsEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {/* TODO: Add actions here */}
              </Menu>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: false,
    pagination: true,
  };

  useEffect(() => {
    refetchProjects();
    refetchCompanyContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyid]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data) return <p>Not found</p>;

  const { projects } = data;

  return (
    <Grid>
      <Table
        data={projects?.map((item) => {
          const {
            _id = '',
            title = '',
            genreTypeNames,
            numberAndDuration,
            initialReleasePlatformTypeNames,
          } = item || {};
          // $FlowFixMe
          return [
            _id,
            title,
            genreTypeNames,
            initialReleasePlatformTypeNames,
            numberAndDuration,
          ];
        })}
        // $FlowFixMe
        columns={columns}
        tableOptions={options}
        tableTitle={
          <Stack direction="row">
            <Typography
              variant="body2"
              sx={{ marginBottom: 2, color: '#747685' }}
            >
              All Projects
            </Typography>
            <Chip
              label={projects?.length || 0}
              size="small"
              sx={{ borderRadius: '4px', marginLeft: 1, fontWeight: '600' }}
            />
          </Stack>
        }
      />
    </Grid>
  );
};

export default ProjectsTable;
