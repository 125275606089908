/**
 *
 * RightToWriteThisProject
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const RightToWriteThisProject = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    suggestionValues = [],
    approvalCheckboxText = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    work = '',
    fee = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      work: '',
      fee: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  const suggestionValForWork = suggestionValues.find(
    (item) => item.label === 'Work'
  );

  const suggestionValForFee = suggestionValues.find(
    (item) => item.label === 'Fee'
  );

  return (
    <>
      <TextAreaSuggestions
        id="work"
        label={suggestionValForWork?.label || ''}
        value={work}
        suggestions={suggestionValForWork?.options || []}
        onSubmitDescription={(id, val) => {
          onUpdateData(id, val);
        }}
        onChangeTextarea={(id, val) => {
          onUpdateData(id, val);
        }}
      />
      <TextAreaSuggestions
        id="fee"
        label={suggestionValForFee?.label || ''}
        value={fee}
        suggestions={suggestionValForFee?.options || []}
        onSubmitDescription={(id, val) => {
          onUpdateData(id, val);
        }}
        onChangeTextarea={(id, val) => {
          onUpdateData(id, val);
        }}
      />
      <TextGeneratingCheckbox
        id="approvalCheckboxTextValue"
        label="Right subject to financier approval"
        value={approvalCheckboxTextValue}
        textGenerating={approvalCheckboxText}
        onCheck={(id, val) => onUpdateData(id, val)}
      />
    </>
  );
};

export default (memo(RightToWriteThisProject): AbstractComponent<
  PropsType,
  mixed
>);
