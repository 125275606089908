/**
 *
 * Spv
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect, useState } from 'react';
import type { PropsType, SpvType } from './types';

import { Grid } from '@mui/material';
import TextField from 'app/components/TextField/TextField';

const Spv: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const { spvs, onUpdateData } = props;

  const [combinedState, setCombinedState] = useState<SpvType>({
    companyName: '',
    type: '',
    abn: '',
    acn: 0,
    address: {
      number: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
  });

  useEffect(() => {
    if (spvs?.length) {
      const { companyName, type, abn, acn, address } = spvs[0];
      const {
        number = 0,
        street = '',
        suburb = '',
        state = '',
        postcode = '',
        country = '',
      } = address || {};

      const spvItem = {
        companyName,
        type,
        abn,
        acn: Number(acn),
        address: {
          number,
          street,
          suburb,
          state,
          postcode,
          country,
        },
      };

      // $FlowFixMe
      setCombinedState((prevState) => {
        const newCombinedState: SpvType = { ...prevState, ...spvItem };
        onUpdateData(newCombinedState);
        return newCombinedState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateDateData = (
    fieldName: string,
    fieldValue: number | string
  ) => {
    // $FlowFixMe
    setCombinedState((prevState) => {
      let newData = {};

      if (
        ['street', 'suburb', 'state', 'postcode', 'country'].includes(fieldName)
      ) {
        newData = {
          ...prevState,
          address: { ...prevState.address, [fieldName]: fieldValue },
        };
      } else {
        newData = { ...prevState, [fieldName]: fieldValue };
      }

      onUpdateData(newData);
      return newData;
    });
  };

  const { companyName, abn, acn, address } = combinedState;
  const { street, suburb, state, postcode, country } = address || {};

  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="companyName"
            label="Company full name"
            value={companyName || ''}
            onBlur={handleUpdateDateData}
            tooltipBody="Include full name company name here (including Pty Ltd etc)."
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="street"
            label="Street"
            value={street || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            id="suburb"
            label="City / Suburb"
            value={suburb || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            id="state"
            label="State"
            value={state || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            id="country"
            label="Country"
            value={country || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            id="postcode"
            label="Postcode"
            placeholder="0000"
            value={postcode || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            id="abn"
            label="ABN"
            placeholder="00 000 000 000"
            value={abn || ''}
            numberOnly
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <TextField
            id="acn"
            label="ACN"
            placeholder="000 000 000"
            value={acn || ''}
            numberOnly
            onBlur={handleUpdateDateData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(Spv): AbstractComponent<PropsType, mixed>);
