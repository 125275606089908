/**
 *
 * useCompanyUsersQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import type { GetCompanyUsersQuery } from 'app/services/graphQL/generated-types';
import { userDetailsFragment } from 'user/graphQL/fragments/userFragments';

const COMPANY_USERS = gql`
  query GetCompanyUsers {
    companyUsers {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export type CompanyUsersReturnType = {
  loading: boolean,
  data: GetCompanyUsersQuery,
  error: any,
  refetch: () => void,
};

export const useCompanyUsersQuery = (): CompanyUsersReturnType => {
  const { loading, data, error, refetch } =
    useQuery<GetCompanyUsersQuery>(COMPANY_USERS);

  return {
    loading,
    data,
    error,
    refetch,
  };
};

export type LazyCompanyUsersReturnType = [
  any,
  { loading: boolean, data: GetCompanyUsersQuery, error: any }
];

export const useLazyCompanyUsersQuery = (): LazyCompanyUsersReturnType => {
  const query = useLazyQuery<GetCompanyUsersQuery>(COMPANY_USERS);

  return query;
};
