/**
 *
 * Select
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Box, Chip, FormHelperText, MenuItem, Typography } from '@mui/material';
import {
  Cancel as CancelIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { FormControl, Select as MUISelect, SelectContainer } from './styles';
import type { OptionType, PropsType } from './types';
import React, { memo } from 'react';

const Select: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    id,
    label,
    defaultValue,
    value,
    options,
    required,
    fullWidth,
    error,
    helperText,
    disabled,
    multiple,
    renderOption,
    onSelectOption,
    renderValue,
    onRemoveSelectedValue,
    formControlStyle,
    selectStyle,
    renderLinkData,
  } = props;

  const defaultRenderOption = (option: OptionType) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  };

  const defaultRenderValue = (selectedValue: Array<string>) => {
    const selectedItems = options.filter(
      (i) => selectedValue.indexOf(i.value) !== -1
    );

    const selectedItemsValues = selectedItems.map((item) => item.value);

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selectedItems?.map((item, i) => {
          return (
            <Chip
              key={item.value}
              label={item.label}
              clickable
              deleteIcon={
                <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
              }
              onDelete={(event) => {
                onRemoveSelectedValue &&
                  onRemoveSelectedValue(selectedItemsValues || [], item.value);
              }}
              sx={{
                '&.MuiChip-root svg': { position: 'unset' },
              }}
            />
          );
        })}
      </Box>
    );
  };

  const renderValueProp = (selectedValue) => {
    if (renderValue) {
      return renderValue(selectedValue);
    } else {
      return defaultRenderValue(selectedValue);
    }
  };

  const handleChange = (e) => {
    const targetVal = e.target.value;
    if (Array.isArray(targetVal)) {
      const items = options.filter((i) => targetVal?.indexOf(i.value) !== -1);
      onSelectOption(items);
    } else {
      const item = options.find((i) => i.value === targetVal);
      onSelectOption(item || options[0]);
    }
  };

  return (
    <FormControl
      required={required}
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      style={formControlStyle}
    >
      {label && (
        <Typography
          id={id}
          variant="body2"
          sx={{ marginBottom: 1, color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {label}
        </Typography>
      )}
      <SelectContainer>
        <MUISelect
          id={id}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          multiple={multiple}
          IconComponent={(iconProps) =>
            iconProps.className.includes('MuiSelect-iconOpen') ? (
              <ExpandLessIcon className="expandMore" />
            ) : (
              <ExpandMoreIcon className="expandMore" />
            )
          }
          style={selectStyle}
          {...(multiple && {
            renderValue: renderValueProp,
          })}
        >
          {options.map((item) => {
            if (renderOption) {
              return renderOption(item);
            }
            return defaultRenderOption(item);
          })}
        </MUISelect>
        {helperText && (
          <FormHelperText sx={{ marginTop: '16px' }}>
            {helperText}
          </FormHelperText>
        )}
        {renderLinkData && renderLinkData}
      </SelectContainer>
    </FormControl>
  );
};

export default (memo(Select): AbstractComponent<PropsType, mixed>);
