import { Button, Grid, Paper } from '@mui/material';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DatePicker from 'app/components/DatePicker/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';

const ExclusivePeriod = ({
  exclusivePeriods,
  onUpdateExclusivePeriod,
  conditionalProp,
}) => {
  const handlePeriodData = (targetValuePeriod, property, i) => {
    const newPeriodValue = [...exclusivePeriods];
    newPeriodValue[i][property] = targetValuePeriod;
    onUpdateExclusivePeriod(newPeriodValue);
  };

  const onSelectChange = (value, index) => {
    const newSelect = [...exclusivePeriods];
    newSelect[index].selectExclusive = value;
    onUpdateExclusivePeriod(newSelect);
  };

  const handleDeletedPeriod = (index) => {
    onUpdateExclusivePeriod(exclusivePeriods.filter((item, i) => i !== index));
  };

  const handleAddPeriod = () => {
    const newExclusivePeriod = {
      description: '',
      startDate: '',
      endDate: '',
      selectExclusive: '',
    };
    onUpdateExclusivePeriod([...exclusivePeriods, newExclusivePeriod]);
  };

  //FIXME: this uses DatePicker and we need to make sure we are giving it a number

  return (
    <Grid container spacing={4}>
      {exclusivePeriods.map((exclusivePeriod, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Paper elevation={3} sx={{ padding: 2 }}>
              <Grid container spacing={3} direction="row" alignItems="center">
                <Grid item xs={11}>
                  <TextField
                    label="Description"
                    multiline
                    id="description"
                    value={exclusivePeriod.description || ''}
                    onBlur={(id, val) => handlePeriodData(val, id, index)}
                    sx={{ marginBottom: 2 }}
                  />
                </Grid>
                {index >= 1 && (
                  <Grid item xs={1}>
                    <Button
                      onClick={() => handleDeletedPeriod(index)}
                      size="large"
                      startIcon={<DeleteIcon />}
                    ></Button>
                  </Grid>
                )}
              </Grid>

              <Grid container>
                <Grid item xs={11}>
                  <DatePicker
                    id="startDate"
                    label="Start"
                    value={exclusivePeriod.startDate || ''}
                    disableMaskedInput
                    hidePlaceHolder
                    onValueChange={(id, val) => {
                      handlePeriodData(val, id, index);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={11}>
                  <DatePicker
                    id="endDate"
                    label="End"
                    value={exclusivePeriod.endDate || ''}
                    disableMaskedInput
                    hidePlaceHolder
                    onValueChange={(id, val) => {
                      handlePeriodData(val, id, index);
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid item xs={11}>
                {conditionalProp && (
                  <Select
                    fullWidth
                    label=""
                    id="selectExclusive"
                    value={exclusivePeriod.selectExclusive || ''}
                    onSelectOption={(item) => onSelectChange(item.value, index)}
                    options={[
                      { value: 'Exclusive', label: 'Exclusive' },
                      {
                        value: 'Non-Exclusive 1st priority',
                        label: 'Non-Exclusive 1st priority',
                      },
                      {
                        value: 'Non-Exclusive no priority',
                        label: 'Non-Exclusive no priority',
                      },
                    ]}
                  />
                )}
              </Grid>
            </Paper>
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <Button
          onClick={() => handleAddPeriod()}
          size="large"
          startIcon={<ControlPointIcon />}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default ExclusivePeriod;
