import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { PropsType } from '../types';
import { SYNC_PROPS_DEFAULT_VALUE } from './config';

export const LinkDataContext: React$Context<PropsType> = React.createContext();

const LinkDataProvider = ({ children }) => {
  const { ptid, cyid } = useParams();
  const [isOpenNotSynchronizeDialog, setIsOpenNotSynchronizeDialog] =
    useState(false);

  const [notSynchronizeOptionVal, setNotSynchronizeOptionVal] = useState('');

  const [isOpenChangeDetailsDialog, setIsOpenChangeDetailsDialog] =
    useState(false);

  const [isOpenAffectedContractsDialog, setIsOpenAffectedContractsDialog] =
    useState(false);

  const [componentName, setComponentName] = useState(''); // contractTermKeyName

  const [linkDataProps, setLinkDataProps] = useState(SYNC_PROPS_DEFAULT_VALUE);

  const [selectedAffectedContracts, setSelectedAffectedContracts] = useState(
    []
  );

  const [allAffectedContractsData, setAllAffectedContractsData] = useState({
    data: [],
    loading: false,
  });

  const [indeterminate, setIndeterminate] = useState(false);

  const [updateAll, setUpdateAll] = useState(false);

  const [sourceData, setSourceData] = useState(); // collection from database

  const [newFieldValue, setNewFieldValue] = useState(); // new value for updating affected contracts

  const [fieldName, setFieldName] = useState(); // field name to get linkData props of the field

  const [isDataLoading, setIsDataLoading] = useState(false);

  const [additionalLinkDataPropsFilter, setAdditionalLinkDataPropsFilter] =
    useState({});

  const onSetIsOpenNotSynchronizeDialog = (v: boolean) => {
    setIsOpenNotSynchronizeDialog(v);
  };

  const onSetNotSynchronizeOptionVal = (v: string | number) => {
    setNotSynchronizeOptionVal(v);
  };

  const onSetIsOpenChangeDetailsDialog = (v: boolean) => {
    setIsOpenChangeDetailsDialog(v);
  };

  const onSetComponentName = (contractTermKeyName: string) => {
    setComponentName({
      component: contractTermKeyName.split('-')[0] || '',
      contractTermKeyName,
    });
  };

  const onSetLinkDataProps = (v) => {
    setLinkDataProps(v);
  };

  const onSetIsOpenAffectedContractsDialog = (v: boolean) => {
    setIsOpenAffectedContractsDialog(v);
  };

  const onSetAllAffectedContractsData = (data: any, loading: boolean) => {
    setAllAffectedContractsData({ data, loading });
  };

  const onSelectAffectedContracts = (
    contractId: string,
    isChecked: boolean
  ) => {
    let newSelectedAffectedContracts = [];
    if (!selectedAffectedContracts?.includes(contractId) && isChecked) {
      newSelectedAffectedContracts = [
        ...(selectedAffectedContracts || []),
        contractId,
      ];
    } else if (selectedAffectedContracts?.includes(contractId) && !isChecked) {
      newSelectedAffectedContracts = [
        ...(selectedAffectedContracts?.filter((item) => item !== contractId) ||
          []),
      ];
    }
    setSelectedAffectedContracts(newSelectedAffectedContracts);
    setIndeterminate(
      !!newSelectedAffectedContracts.length &&
        // $FlowFixMe
        newSelectedAffectedContracts.length <
          allAffectedContractsData?.data?.length
    );
    setUpdateAll(
      newSelectedAffectedContracts.length ===
        allAffectedContractsData?.data?.length
    );
  };

  const onSelectAllAffectedContracts = (
    isChecked,
    allAffectedContractsData
  ) => {
    setSelectedAffectedContracts(
      isChecked ? allAffectedContractsData?.data?.map((item) => item._id) : []
    );
    setIndeterminate(false);
    setUpdateAll(isChecked);
  };

  const onSetSourceData = (v) => {
    setSourceData(v);
  };

  const onReset = () => {
    setSelectedAffectedContracts();
    setAllAffectedContractsData();
    setUpdateAll();
  };

  const onSetIsDataLoading = (v: boolean) => {
    setIsDataLoading(v);
  };

  const onSetAdditionalLinkDataPropsFilter = (v: Record<string, string>) => {
    setAdditionalLinkDataPropsFilter(v);
  };

  return (
    <LinkDataContext.Provider
      value={{
        ptid,
        cyid,
        isOpenNotSynchronizeDialog,
        onSetIsOpenNotSynchronizeDialog,
        notSynchronizeOptionVal,
        onSetNotSynchronizeOptionVal,
        isOpenChangeDetailsDialog,
        onSetIsOpenChangeDetailsDialog,
        componentName,
        onSetComponentName,
        linkDataProps,
        onSetLinkDataProps,
        isOpenAffectedContractsDialog,
        onSetIsOpenAffectedContractsDialog,
        onSelectAffectedContracts,
        selectedAffectedContracts,
        onSelectAllAffectedContracts,
        onSetAllAffectedContractsData,
        indeterminate,
        updateAll,
        allAffectedContractsData,
        sourceData,
        onSetSourceData,
        onReset,
        newFieldValue,
        setNewFieldValue,
        fieldName,
        setFieldName,
        isDataLoading,
        onSetIsDataLoading,
        onSetAdditionalLinkDataPropsFilter,
        additionalLinkDataPropsFilter,
      }}
    >
      {children}
    </LinkDataContext.Provider>
  );
};

export default LinkDataProvider;
