import { TextField as MUITextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextFieldStyled = styled(MUITextField)({
  width: '100%',
  flexBasis: '100%',
});

export const MainContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 16,
  position: 'relative',
});

export const DatePickerContainer = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});
