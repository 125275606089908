/**
 *
 * Table
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';
import MUIDataTable from 'mui-datatables';

import { PropsType, TableOptionsType } from './types';

const Table: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const { tableTitle, tableOptions, ...rest } = props;

  // Default options
  const {
    selectableRows = true,
    pagination = true,
    download = true,
    search = true,
    print = true,
    viewColumns = true,
    filter = true,
  }: TableOptionsType = tableOptions || {};
  return (
    <>
      {tableTitle && tableTitle}
      <MUIDataTable
        {...rest}
        options={{
          selectableRows,
          pagination,
          download,
          search,
          print,
          viewColumns,
          filter,
        }}
        className="table"
      />
    </>
  );
};

export default (memo(Table): AbstractComponent<PropsType, mixed>);
