import { styled } from '@mui/system';

export const BulletPlainTextContainer = styled('div', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'selected',
})(
  ({ selected, theme }) => `
    background: ${selected ? theme.palette.primary.backgroundLight : 'none'};
  `
);
