/**
 *
 * CurrencyField
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import TextField from 'app/components/TextField/TextField';
import type { PropsType } from './types';

const CurrencyField: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const {
    label,
    value,
    formatMoney,
    numberOnly,
    placeholder,
    helperText,
    startAdornment,
    endAdornment,
    onBlur,
    renderLinkData,
    id,
    onValueChange,
    readOnly,
  } = props;

  return (
    <>
      <TextField
        id={id}
        label={label}
        value={value}
        formatMoney={formatMoney}
        numberOnly={numberOnly}
        placeholder={placeholder}
        helperText={helperText}
        startAdornment={<>{startAdornment}</>}
        endAdornment={<>{endAdornment}</>}
        onBlur={onBlur}
        onValueChange={onValueChange}
        renderLinkData={renderLinkData}
        readOnly={readOnly}
      />
    </>
  );
};

export default (memo(CurrencyField): AbstractComponent<PropsType, mixed>);
