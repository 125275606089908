import { styled } from '@mui/system';
import { ArrowRight as Icon } from 'app/assets/icons';

const ArrowRight = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  position: 'fixed',
  top: '450px',
  left: '244px',
  zIndex: '1000',
  background: theme.palette.grey['100'],
  border: `1px solid ${theme.palette.grey['300']}`,
  borderRadius: '20px',
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  [theme.breakpoints.down('lg')]: {
    left: '55px',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  '& path': {
    stroke: theme.palette.grey['700'],
  },
}));

const ContractNavBarContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: open ? '256px' : '75px',
}));

const ContractDataContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  width: open ? '75%' : '80%',
  margin: '24px 32px',
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  paddingTop: '20px',
}));

export { ContractNavBarContainer, ContractDataContainer, ArrowRight };
