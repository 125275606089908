import './App.css';

import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AuthenticatedApolloProvider from 'app/components/AuthenticatedApolloProvider/AuthenticatedApolloProvider';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorPage from './pages/ErrorPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import PageRouter from 'app/routes/PageRouter';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Sentry } from './services/logging';
import { disableAuthentication } from './utils/config';
import pageRoutes from 'app/routes/pageRoutes';

const muiTheme = require('./muiTheme.json');
const baTheme = createTheme(muiTheme);

if (disableAuthentication) {
  console.warn(
    'initialising with authentication disabled (see REACT_APP_DISABLE_AUTHENTICATION env variable)'
  );
}

const App = () => {
  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <ErrorPage
          h1Text="Sorry, something went wrong. Please close this browser tab and try again."
          h2Text="Our development team has been notified of this issue and will fix it a.s.a.p."
        />
      )}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={baTheme}>
            <Router>
              <AuthenticatedApolloProvider>
                <CssBaseline />
                <PageRouter routeArray={pageRoutes} />
              </AuthenticatedApolloProvider>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
