/**
 *
 * AdditionalDetailDialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { Delete } from 'app/assets/icons';
import Dialog from 'app/components/core/Dialog/Dialog';
import { Grid } from '@mui/material';
import IconButton from 'app/components/core/IconButton/IconButton';
import type { PropsType } from './types';
import Textarea from 'app/components/Textarea/Textarea';

const AdditionalDetailDialog: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const {
      dialogRef,
      value = '',
      onClickTwo,
      onBlurData,
      onResetData,
      showInputField,
    } = props;

    return (
      <>
        <Dialog
          // $FlowFixMe
          ref={dialogRef}
          title="Add Additional Detail"
          labelTwo="Add Detail"
          onClickTwo={onClickTwo}
        >
          This will affect this contract detail only. If you want to override a
          standard term, add that in "Amendments" below.
        </Dialog>

        {showInputField && (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              <Textarea
                id="additionalDetail"
                label="*Additional Detail"
                maxRows={5}
                minRows={2}
                value={value}
                onBlur={(id, val) => onBlurData(id, val)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={onResetData}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);
export default AdditionalDetailDialog;
