/**
 *
 * PassivePayments
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import Grid from '@mui/material/Grid';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PassivePayments = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermProps,
    contractTermData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    budgetLabel = 'Net Budget Share',
    profitLabel = 'Net Profit Share',
    checkboxLabel = 'Reduced for Proportional Use of Script',
    approvalCheckboxText = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    budget = '',
    profit = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      approvalCheckboxTextValue: '',
      budget: '',
      profit: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid item xs={1.5}>
        <TextField
          id="budget"
          label={budgetLabel}
          value={budget}
          formatMoney
          numberOnly
          endAdornment={<>%</>}
          onBlur={onUpdateData}
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextField
          id="profit"
          label={profitLabel}
          value={profit}
          formatMoney
          numberOnly
          endAdornment={<>%</>}
          onBlur={onUpdateData}
        />
      </Grid>
      <TextGeneratingCheckbox
        id="approvalCheckboxTextValue"
        label={checkboxLabel}
        value={approvalCheckboxTextValue}
        textGenerating={approvalCheckboxText}
        onCheck={onUpdateData}
      />
    </>
  );
};

export default (memo(PassivePayments): AbstractComponent<PropsType, mixed>);
