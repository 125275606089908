/**
 *
 * useInfoPageQuery
 * @format
 * @flow
 *
 */

import { gql, useQuery } from '@apollo/client';

import type { GetInfoPageQuery } from 'app/services/graphQL/generated-types';
import { infoPageFragment } from './fragments/infoPageFragment';

const INFOPAGE = gql`
  query GetInfoPage {
    infoPage {
      ...infoPageDetails
    }
  }
  ${infoPageFragment}
`;

export type InfoPageReturnType = {
  loading: boolean,
  data: GetInfoPageQuery,
  error: any,
  refetch: () => void,
};

export const useInfoPageQuery = (): InfoPageReturnType => {
  const { loading, data, error, refetch } =
    useQuery<GetInfoPageQuery>(INFOPAGE);

  return {
    loading,
    data,
    error,
    refetch,
  };
};
