/**
 *
 * IconButton
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { IconButton as MuiIconButton } from '@mui/material';
import type { PropsType } from './types';

const IconButton: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const { disabled = false, onClick, children } = props;

  return (
    <>
      <MuiIconButton
        disabled={disabled}
        onClick={onClick}
        disableRipple
        disableFocusRipple
        size="small"
      >
        {children}
      </MuiIconButton>
    </>
  );
};

export default (memo(IconButton): AbstractComponent<PropsType, mixed>);
