/**
 *
 * CreditContribItem
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

import type { CreditContribItemPropsType } from './types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import TextField from 'app/components/TextField/TextField';

const CreditContribItem = ({
  item,
  index,
  creditDataUpdate,
}: CreditContribItemPropsType): Node => {
  const { creditWording, creditName, creditPosition, creditType, cardType } =
    item;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            id="creditWording"
            value={creditWording}
            label="Credit Wording"
            onBlur={(id, val) => creditDataUpdate(val, id, index)}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="creditName"
            value={creditName}
            label="Name"
            onBlur={(id, val) => creditDataUpdate(val, id, index)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">Credit Position</Typography>
        </Grid>

        <Grid item xs={8}>
          <RadioButtonGroup
            row
            id="creditPosition"
            value={creditPosition}
            onValueChange={(id, val) => creditDataUpdate(val, id, index)}
          >
            <RadioButton
              id="End Credit"
              label="End Credit"
              value="End Credit"
            />
            <RadioButton
              id="Opening Credit"
              label="Opening Credit"
              value="Opening Credit"
            />
          </RadioButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">Credit Type</Typography>
        </Grid>

        <Grid item xs={5}>
          <RadioButtonGroup
            row
            id="creditType"
            value={creditType}
            onValueChange={(id, val) => creditDataUpdate(val, id, index)}
          >
            <RadioButton
              id="Rolling"
              label="Rolling"
              value="Rolling"
              containerStyle={{ marginRight: 27 }}
            />
            <RadioButton id="Static" label="Static" value="Static" />
          </RadioButtonGroup>
        </Grid>

        {
          <Grid item xs={4}>
            {creditType === 'Static' && (
              <RadioButtonGroup
                row
                id="cardType"
                value={cardType}
                onValueChange={(id, val) => creditDataUpdate(val, id, index)}
              >
                <RadioButton
                  id="staticSingleCard"
                  label="Single Card"
                  value="Single Card"
                />
                <RadioButton
                  id="staticSharedCard"
                  label="Shared Card"
                  value="Shared Card"
                />
              </RadioButtonGroup>
            )}
          </Grid>
        }
      </Grid>
    </>
  );
};

export default (memo(CreditContribItem): AbstractComponent<
  CreditContribItemPropsType,
  mixed
>);
