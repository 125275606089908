import { Grid, Typography } from '@mui/material';

import DatePicker from 'app/components/DatePicker/DatePicker';
import Paper from 'app/components/Paper/Paper';
import React from 'react';
import Select from 'app/components/Select/Select';
import Textarea from 'app/components/Textarea/Textarea';

const DeliveryItemWritten = ({
  deliveryMaterials,
  numberOfDrafts,
  updateDeliveryMaterialsArray,
}) => {
  const updateDeliverymaterials = (value, property, i) => {
    const newDeliveryItem = [...deliveryMaterials];
    newDeliveryItem[i][property] = value;
    updateDeliveryMaterialsArray(newDeliveryItem, property);
  };

  const setNumberOfDeliveryMaterialItems = (numberOfDeliveryMaterials) => {
    let deliveryMaterialsItemsArray = [];
    for (let i = 0; i < numberOfDeliveryMaterials; i++) {
      deliveryMaterialsItemsArray.push({
        description: '',
        date: '',
      });
    }

    updateDeliveryMaterialsArray(deliveryMaterialsItemsArray);
  };

  //FIXME: this uses DatePicker and we need to make sure we are giving it a number

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          id="numberOfDrafts"
          label="Number of Drafts"
          value={deliveryMaterials.length}
          onSelectOption={(item) =>
            setNumberOfDeliveryMaterialItems(item.value)
          }
          options={numberOfDrafts.map((item) => ({
            value: item,
            label: item,
          }))}
        />
      </Grid>

      {deliveryMaterials.map((deliveryMaterial, i) => {
        const { description, date } = deliveryMaterial;
        return (
          <Grid item xs={12} key={i}>
            <Paper>
              <Grid container spacing={3} justifyContent="flex-start">
                <Grid item xs={12}>
                  <Typography variant="body1">{`Draft ${i + 1}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Textarea
                    id="description"
                    label="Short description"
                    value={description}
                    multiline
                    minRows={3}
                    onBlur={(id, val) => {
                      updateDeliverymaterials(val, id, i);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  id="date"
                  label="Delivery Date"
                  disableMaskedInput
                  hidePlaceHolder
                  value={date}
                  onValueChange={(id, val) => {
                    updateDeliverymaterials(val, id, i);
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DeliveryItemWritten;
