import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import {
  Account,
  Check,
  PencilIcon as EditIcon,
  Collapse as LogoutIcon,
  Organisation,
  Search as SearchIcon
} from 'app/assets/icons';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CompanyListContainer,
  CompanySwitchMenuContainer,
  PlusSmall,
} from '../../../app/pages/DashboardPage/SideMenu/styles';
import { ProfileMenuContainer, SearchIconOnly, SearchInput } from './styles';

import HelpIcon from '@mui/icons-material/Help';
import { useTheme } from '@mui/material/styles';
import AccountSetupDialog from 'app/components/AccountSetupDialog/AccountSetupDialog';
import IconWithMenu from 'app/containers/IconWithMenu/IconWithMenu';
import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import { getUserColor } from 'app/utils/general.js';
import { useUpdateUserMutation } from 'user/graphQL/useUpdateUserMutation';
import { useUserQuery } from 'user/graphQL/useUserQuery';
import NewMenu from './NewMenu/NewMenu';

const IconsMenuBar = ({ onToggleDrawClick }) => {
  const theme = useTheme();
  const { data: userData } = useUserQuery();
  let { pathname } = useLocation();
  const history = useHistory();
  const dialogRef = useRef(null);
  const { updateUser } = useUpdateUserMutation();
  const [searchAnchorElement, setSearchAnchorElement] = useState(null);
  const [actionsEl, setActionsEl] = useState(null);
  const [actionsProfileEl, setActionsProfileEl] = useState(null);
  // const [navbarCollapse, setNavbarCollapse] = useState(true);
  const { givenName, familyName, emails } = userData?.user;
  const firstString = givenName.charAt(0);
  const secondString = familyName.charAt(0);

  const onCompanyChange = (selectedCompanyId) => {
    updateUser({
      variables: {
        input: {
          _id: userData?.user?._id || '',
          selectedCompany: selectedCompanyId,
        },
      },
    })?.then(() => {
      // navigate to selected company
      history.push(`/dashboard/${selectedCompanyId}/projects`);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const helpIconData = {
    icon: <HelpIcon sx={{ fontSize: '1em' }} />,
    menuData: [
      {
        type: 'menu_item',
        text: 'Help',
        url: '/help',
      },
      {
        type: 'menu_item',
        text: 'This contract',
        url: '/contract/help',
      },
      {
        type: 'menu_item',
        text: 'Wiki',
        url: '/Wiki',
      },
      {
        type: 'menu_item',
        text: 'Ask a Lawyer',
        url: '/Wiki',
      },
      {
        type: 'divider',
      },
      {
        type: 'menu_item',
        text: 'Tech Support',
        url: '/support',
      },
      {
        type: 'menu_item',
        text: 'BusinessAffairs.com',
        url: '/businessAffairs',
      },
    ],
  };

  const userColor = getUserColor();

  const UserAvatar = (
    <Avatar
      sx={{
        width: '48px',
        height: '48px',
        bgcolor: userColor,
        color: theme.palette.grey['700'],
      }}
    >
      {firstString + secondString}
    </Avatar>
  )

  const profileIconData = {
    icon: UserAvatar,
    menuData: [
      {
        type: 'menu_item',
        text: 'Your Profile',
        url: '/dashboard/profile',
        icon: <Account />,
      },
      {
        type: 'functional_menu_item',
        text: 'Organisation',
        icon: <Organisation />,
        leftIcon: true,
      },
      // {
      //   type: 'divider',
      // },
      // {
      //   type: 'menu_item',
      //   text: 'Company Name',
      //   url: '/company/1',
      // },
      // {
      //   type: 'divider',
      // },
      // {
      //   type: 'menu_item',
      //   text: 'All Projects Dashboard',
      //   url: '/company/1/projects',
      // },
      // {
      //   type: 'functional_menu_item',
      //   text: 'Logout',
      //   onClick: () => {
      //     instance.logoutRedirect({
      //       postLogoutRedirectUri:
      //         process.env.REACT_APP_AZ_B2C_POST_LOGOUT_REDIRECT_URI,
      //     });
      //   },
      // },
      {
        type: 'logout_menu_item',
        text: 'Log Out',
        icon: <LogoutIcon />,
      },
    ],
  };



  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      sx={{ flexGrow: 1 }}
    >
      <SearchInput>
      <Grid item xs={6}>
        {/* hide this for now */}
        {/* <TextField
          sx={{ width: '100%' }}
          placeholder="search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ {
    fontSize: 30,
    '& path': {
      stroke: theme.palette.grey['600'],
    },
  }}} />
              </InputAdornment>
            ),
          }}
        /> */}
      </Grid>
      </SearchInput>

      <SearchIconOnly>
        <Grid item>
          <IconButton
            aria-controls="simple-menu-search"
            aria-haspopup="true"
            onClick={(e) => setSearchAnchorElement(e.currentTarget)}
            color="inherit"
            size="large"
          >
            <SearchIconOnly>
              <SearchIcon />
            </SearchIconOnly>
          </IconButton>
          <Menu
            id="simple-menu-search"
            anchorEl={searchAnchorElement}
            keepMounted
            open={Boolean(searchAnchorElement)}
            onClose={() => setSearchAnchorElement(null)}
          >
            <MenuItem>
              <TextField
                sx={{ width: '100%' }}
                fullWidth
                variant="outlined"
                placeholder="search..."
                label="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </MenuItem>
          </Menu>
        </Grid>
      </SearchIconOnly>
      <Grid item display="flex" gap={2}>
        {pathname !== '/dashboard/account' && <NewMenu />}
        <IconWithMenu
          iconAndMenu={profileIconData}
          toggleIconMenu={onToggleDrawClick}
          setActionsEl={setActionsEl}
          actionsEl={actionsEl}
          setActionsProfileEl={setActionsProfileEl}
          actionsProfileEl={actionsProfileEl}
        />
      </Grid>
      <CompanySwitchMenuContainer
        id="company-switch-actions"
        anchorEl={actionsEl}
        keepMounted
        open={Boolean(actionsEl)}
        onClose={() => setActionsEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {userData?.user?.companies?.map((company) => (
          <CompanyListContainer
            container
            gap={1.5}
            key={company?._id}
            onClick={() => {
              onCompanyChange(company?._id);
            }}
          >
            <Grid item>
              <Avatar
                sx={{
                  gridArea: 'companyLogo',
                  height: '32px',
                  width: '32px',
                }}
                variant="square"
              >
                RP
              </Avatar>
            </Grid>
            <Grid item>
              <Grid container display="flex" direction="column">
                <Box sx={{ gridArea: 'company' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: '10px',
                      fontWeight: '700',
                      color: theme.palette.grey['600'],
                      textTransform: 'uppercase',
                    }}
                  >
                    {company.type === EntityTypeValues.Company
                      ? 'Organisation'
                      : 'Individual'}
                  </Typography>
                </Box>
                <Box sx={{ gridArea: 'companyName' }}>
                  <Typography variant="subtitle1">{company.name}</Typography>
                </Box>
              </Grid>
            </Grid>
            {company._id === userData?.user?.selectedCompany && (
              <Grid item sx={{ mt: 'auto', mr: '0', mb: '0', ml: 'auto' }}>
                <Check style={{ height: '24px', width: '24px' }} />
              </Grid>
            )}
          </CompanyListContainer>
        ))}
        <Divider variant="middle" sx={{ marginTop: '8px', my: '16px' }} />
        <CompanyListContainer
          createOrg={true}
          onClick={() => {
            setActionsEl(null);
            dialogRef?.current?.open(true);
          }}
        >
          <Grid item>
            <PlusSmall style={{ height: '24px', width: '24px' }} />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Create Organisation</Typography>
          </Grid>
        </CompanyListContainer>
      </CompanySwitchMenuContainer>
      <ProfileMenuContainer
        id="profile-menu-actions"
        anchorEl={actionsProfileEl}
        keepMounted
        open={Boolean(actionsProfileEl)}
        onClose={() => setActionsProfileEl(null)}
        transformOrigin={{
          vertical: -120,
          horizontal: 'left',
        }}
      >
        <Grid container>
          <Grid
            container
            flexDirection='row'
            justifyContent="space-between"
            alignItems="center"
            flexWrap="noWrap"
          >
            <Grid sx={{marginLeft: '165px'}}>{UserAvatar}</Grid>
            <IconButton
              aria-controls="user-profile-edit"
              aria-haspopup="true"
              color="inherit"
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid m={3} mt={5} justifyContent="center" container>
            <Typography sx={{ fontSize: 20, fontWeight: '500' }} variant="title">
              {`${givenName} ${familyName}`}
            </Typography>
          </Grid>
          <Grid m={2} container justifyContent="center">
            <Typography variant="title">
              {emails[0]}
            </Typography>
          </Grid>
        </Grid>
      </ProfileMenuContainer>
      <AccountSetupDialog
        dialogRef={dialogRef}
        userId={userData?.user?._id || ''}
        userGivenName={userData?.user?.givenName || ''}
        userFamilyName={userData?.user?.familyName || ''}
      />
    </Grid>
  );
};

export default IconsMenuBar;
