/**
 *
 * BlankTerm
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import type { PropsType } from '../types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const BlankTerm: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}): Node => {
  const { contractTermProps } = useContext(ContractContext);

  const { textLabel } = contractTermProps[contractTermKeyName];

  useSetContractTermDefaultData({
    contractTermKeyName,
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        {textLabel && <Typography variant="body1">{textLabel}</Typography>}
      </Grid>
    </Grid>
  );
};

export default (memo(BlankTerm): AbstractComponent<PropsType, mixed>);
