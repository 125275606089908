/**
 *
 * RightsRetainedByContributorItem
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useState } from 'react';

import { Grid, Box, Chip } from '@mui/material';
import type { RightsRetainByContributorItemPropsType } from './types';
import { RightsType } from './types';
import Select from 'app/components/Select/Select';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import TextField from 'app/components/TextField/TextField';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import TwoButtonDialogBox from 'app/components/TwoButtonDialogBox/TwoButtonDialogBox';
import { Clear as ClearIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const RightsRetainedByContributorItem = ({
  item,
  index,
  holdbackCheckboxLabel,
  holdbackCheckboxText,
  dropDownVal,
  suggestionVal,
  onDataUpdate,
}: RightsRetainByContributorItemPropsType): Node => {
  const theme = useTheme();

  const { rightsType, specify, holdbackCheckboxTextValue, holdbackUntil } =
    item;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [hasAddYourOwn, setHasAddYourOwn] = useState(
    rightsType?.toLowerCase() === RightsType.Other.toLowerCase() ? true : false
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            id="rightsType"
            label=""
            value={rightsType.split(',') || []}
            onSelectOption={(selectedItem) => {
              const newValues = selectedItem
                ?.map((item) => item.value)
                .join(',');

              onDataUpdate(newValues, 'rightsType', index);

              if (
                selectedItem.some(
                  (selItem) =>
                    selItem?.value?.toLowerCase() ===
                    RightsType.Other.toLowerCase()
                )
              ) {
                setIsOpenDialog(true);
              } else {
                setHasAddYourOwn(false);
              }
            }}
            options={
              dropDownVal?.options.map((item) => ({
                value: item,
                label: item,
              })) || []
            }
            multiple={true}
            renderValue={(selected) => {
              return (
                <Box mt={1.5} sx={{ display: 'flex', gap: '8px' }}>
                  {selected?.map((item, i) => (
                    <>
                      {item && (
                        <Chip
                          key={item}
                          label={item}
                          clickable
                          onDelete={(e) => {
                            const newValues = selected
                              ?.filter((i) => i !== item)
                              .join(',');
                            onDataUpdate(newValues, 'rightsType', index);
                          }}
                          deleteIcon={
                            <ClearIcon
                              onMouseDown={(event) => event.stopPropagation()}
                              fontSize="small"
                            />
                          }
                          sx={{
                            flexDirection: 'row',
                            backgroundColor: theme.palette.grey['200'],
                            height: '25px',
                            borderRadius: '6px',
                            bottom: '6px',
                          }}
                        />
                      )}
                    </>
                  ))}
                </Box>
              );
            }}
          />
        </Grid>
        {rightsType?.toLowerCase() === RightsType.Other.toLowerCase() &&
          hasAddYourOwn && (
            <Grid item xs={12}>
              <TextField
                id="specify"
                value={specify}
                label=""
                onBlur={(id, val) => onDataUpdate(val, id, index)}
              />
            </Grid>
          )}
        <Grid item xs={12}>
          <TextGeneratingCheckbox
            id="holdbackCheckboxTextValue"
            label={holdbackCheckboxLabel}
            value={holdbackCheckboxTextValue}
            textGenerating={holdbackCheckboxText}
            onCheck={(id, val) => onDataUpdate(val, id, index)}
          />
        </Grid>
        {holdbackCheckboxTextValue && (
          <Grid item xs={12}>
            <TextAreaSuggestions
              id="holdbackUntil"
              label={suggestionVal?.label || ''}
              value={holdbackUntil}
              suggestions={suggestionVal?.options || []}
              onSubmitDescription={(id, val) => {
                onDataUpdate(val, id, index);
              }}
              onChangeTextarea={(id, val) => {
                onDataUpdate(val, id, index);
              }}
            />
          </Grid>
        )}
      </Grid>
      <TwoButtonDialogBox
        open={isOpenDialog}
        onClickOne={() => {
          setIsOpenDialog(false);
        }}
        onClickTwo={() => {
          setIsOpenDialog(false);
          setHasAddYourOwn(true);
        }}
        labelButtonOne="Cancel"
        labelButtonTwo="Yes"
        titleDialog={`Add your own?
            Note that this may be a problem for financiers. Check with a lawyer if you are not sure`}
      />
    </>
  );
};

export default (memo(RightsRetainedByContributorItem): AbstractComponent<
  RightsRetainByContributorItemPropsType,
  mixed
>);
