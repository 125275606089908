import React from 'react';
import PropTypes from 'prop-types';
//import BrokenPC from '../../components/BrokenPCSvg';

const ErrorPage = ({ error, h1Text, h2Text, hideLogo }) => {
  return (
    <>
      <h1>This is a placeholder for an error page - it needs updating</h1>
      <ul>
        <li>{h1Text}</li>
        <li>{h2Text}</li>
        <li>{error}</li>
      </ul>
    </>
  );
};

ErrorPage.propTypes = {
  h1Text: PropTypes.string.isRequired,
  h2Text: PropTypes.string.isRequired,
  error: PropTypes.object,
  hideLogo: PropTypes.bool,
};

ErrorPage.defaultProps = {
  error: null,
  hideLogo: false,
};

export default ErrorPage;
