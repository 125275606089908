/**
 *
 * @format
 * @flow
 *
 */

import * as React from 'react';

import { AppBar, Toolbar } from './styles';

import IconButton from '@mui/material/IconButton';
import IconsMenuBar from 'app/containers/IconsMenuBar/IconsMenuBar';
import MenuIcon from '@mui/icons-material/Menu';
import type { PropsType } from './types';

const TopMenu: React.AbstractComponent<PropsType> = ({
  onToggleDrawClick,
}: PropsType): React.Node => {
  return (
    <AppBar position="fixed" elevation={0}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onToggleDrawClick}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <IconsMenuBar onToggleDrawClick={onToggleDrawClick} />
      </Toolbar>
    </AppBar>
  );
};

export default (React.memo(TopMenu): React.AbstractComponent<PropsType, mixed>);
