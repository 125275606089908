/**
 *
 * CustomMilestone
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, Box } from '@mui/material';
import { default as React, memo } from 'react';

import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';
import { useTheme } from '@mui/material/styles';

const CustomMilestones = (props: PropsType): Node => {
  const theme = useTheme();
  const {
    fee,
    netBudget,
    conditionalProp,
    customValues = [],
    onDataUpdated,
  } = props;

  const milestonesData = (targetValue, id, i) => {
    const customMilestoneCopy = window.structuredClone(customValues);
    customMilestoneCopy[i][id] = targetValue;
    if (id === 'percentage') {
      customMilestoneCopy[i]['amount'] = (+targetValue * +fee) / 100;
    }
    if (id === 'amount') {
      customMilestoneCopy[i]['percentage'] = (+targetValue * 100) / +fee;
    }
    onDataUpdated(customMilestoneCopy);
  };

  const addMilestone = () => {
    const newCustomMilestone = {
      ...(conditionalProp === 'date'
        ? { name: 'Select Date' }
        : { name: 'Specify Own' }),
      specialInput: '',
      percentage: 0,
      amount: 0,
    };
    const addCustomValue = [...customValues, newCustomMilestone];
    onDataUpdated(addCustomValue);
  };

  const deleteMilestone = (index) => {
    const removeCustomValue = customValues.filter((_, i) => i !== index);
    onDataUpdated(removeCustomValue);
  };

  const textFieldType = conditionalProp === 'date' ? 'date' : null;

  return (
    <>
      {customValues.map((custom, i) => {
        const { specialInput = '', percentage = 0, amount = 0 } = custom;
        return (
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey['400']}`,
              borderRadius: '6px',
            }}
            my={2}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              key={i}
              flexWrap="noWrap"
              p={2}
            >
              <>
                <Grid item xs={5}>
                  <TextField
                    id="specialInput"
                    type={textFieldType}
                    value={specialInput}
                    onBlur={(id, val) => milestonesData(val, id, i)}
                  />
                </Grid>
              </>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={3}
              >
                {i <= 0 ? (
                  <Grid item xs={1}>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => addMilestone()}
                    ></Button>
                  </Grid>
                ) : (
                  <Grid item xs={1}>
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => deleteMilestone(i)}
                    ></Button>
                  </Grid>
                )}
                <Grid item xs={3}>
                  <TextField
                    id="percentage"
                    numberOnly
                    endAdornment={<>%</>}
                    value={percentage}
                    forceReset
                    onValueChange={(id, val) => milestonesData(val, id, i)}
                  />
                </Grid>
                {netBudget !== 'netBudget' && (
                  <Grid item xs={4}>
                    <TextField
                      id="amount"
                      formatMoney
                      numberOnly
                      startAdornment={<>$</>}
                      value={amount}
                      forceReset
                      onValueChange={(id, val) => milestonesData(val, id, i)}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </>
  );
};

export default (memo(CustomMilestones): AbstractComponent<PropsType, mixed>);
