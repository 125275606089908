import { TextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextareaStyled = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: 10,
  font: 'inherit',
  borderRadius: 4,
  ':focus': {
    outlineColor: theme.palette.primary.main,
    outlineWidth: 2,
  },
}));

export const MainContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 16,
  position: 'relative',
});
