import { Check, Close } from 'app/assets/icons';

import { styled } from '@mui/material/styles';

export const CheckIcon = styled(Check)(({ theme }) => ({
  '& path': {
    stroke: theme.palette.primary.main,
  },
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  '& path': {
    stroke: theme.palette.error.main,
  },
}));
