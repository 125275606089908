import { gql } from '@apollo/client';

export const projectDetailsFragment = gql`
  fragment projectDetails on Project {
    _id
    company {
      _id
    }
    title
    genreTypeNames
    single
    noOfEpisodes
    duration
    numberAndDuration
    currency
    developmentBudgets {
      amount
      documents {
        _id
        company
        contract
        project
        tags
        fileName
        fileType
        mimeType
        uploadedBy
        notes
        whoDownloaded {
          downloadedBy
          downloadedDate
        }
        partyName
        contractType
        status
        fileData {
          uploadUrl
          downloadUrl
        }
      }
    }
    productionBudget {
      amount
      documents {
        _id
        company
        contract
        project
        tags
        fileName
        fileType
        mimeType
        uploadedBy
        notes
        whoDownloaded {
          downloadedBy
          downloadedDate
        }
        partyName
        contractType
        status
        fileData {
          uploadUrl
          downloadUrl
        }
      }
    }
    form
    initialReleasePlatformTypeNames
    shortDescription
    shootLocation
    companySPVs {
      givenName
      familyName
      companyName
      type
      abn
      acn
      email
      address {
        number
        street
        suburb
        state
        postcode
        country
      }
      bankAccounts {
        name
        number
        bsb
        signatory
        label
        address {
          number
          street
          suburb
          state
          postcode
          country
        }
      }
    }
    schedule {
      stageTypeId
      stage
      preProductionStart
      preProductionEnd
      productionStart
      productionEnd
      postProductionStart
      postProductionEnd
      finalMaterialsDeliveryStart
      finalMaterialsDeliveryEnd
      roughCutDelivery {
        name
        date
      }
      fineCutDelivery {
        name
        date
      }
    }
    genreValues {
      displayValue
      order
      typeInfo {
        id
        type
      }
    }
    releasePlatformValues {
      displayValue
      order
      typeInfo {
        id
        type
      }
    }
  }
`;

export const createProjectDetailsFragment = gql`
  fragment createProjectDetails on Project {
    _id
    company {
      _id
    }
    title
    genreTypeNames
    single
    noOfEpisodes
    duration
    numberAndDuration
    currency
    form
    initialReleasePlatformTypeNames
    shortDescription
    shootLocation
    companySPVs {
      givenName
      familyName
      companyName
      type
      abn
      acn
      email
      address {
        number
        street
        suburb
        state
        postcode
        country
      }
    }
    schedule {
      stageTypeId
      stage
      preProductionStart
      preProductionEnd
      productionStart
      productionEnd
      postProductionStart
      postProductionEnd
      finalMaterialsDeliveryStart
      finalMaterialsDeliveryEnd
      roughCutDelivery {
        name
        date
      }
      fineCutDelivery {
        name
        date
      }
    }
  }
`;

export const projectSummaryFragment = gql`
  fragment projectSummary on Project {
    _id
    title
    genreTypeNames
    numberAndDuration
    initialReleasePlatformTypeNames
    duration
    noOfEpisodes
    shootLocation
  }
`;
