/**
 *
 * NetBudgetShareMilestones
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext, useState } from 'react';

import Checkbox from 'app/components/Checkbox/Checkbox';
import { ContractContext } from 'app/contexts/Contract/Contract';
import NetBudget from 'app/components/NetBudget/NetBudget';
import OptionalMilestone from 'app/components/OptionalMilestone/OptionalMilestone';
import PercentagePaySchedule from 'app/components/PercentagePaySchedule/PercentagePaySchedule';
import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const NetBudgetShareMilestones = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    checkboxLabel = 'Less and amounts paid to Contributor before production',
    defaultsMilestonesList = [],
    generalMilestonesNames,
    customMilestonesNames,
    dateMilestonesNames,
    optionalTitle,
    maxLabel,
    minLabel,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    checkApproval = false,
    budget = '',
    minBudget = '',
    maxBudget = '',
    generalMilestones,
    dateMilestones,
    customMilestones,
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const [open, setOpen] = useState(false);

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      budget: '',
      minBudget: '',
      maxBudget: '',
      generalMilestones: defaultsMilestonesList.map((defaultMilestones) => ({
        name: defaultMilestones,
        percentage: 0,
        amount: 0,
      })),
      dateMilestones: [],
      customMilestones: [],
      checkApproval: '',
    },
    dependency: [defaultsMilestonesList],
  });

  const toggleDialog = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const getNewRecord = (name) => ({
    name,
    specialInput: '',
    percentage: 0,
  });

  const updateData = (fieldName, fieldValue) => {
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });
  };

  return (
    <>
      <OptionalMilestone
        onDialogClose={toggleDialog}
        dialogOpen={open}
        allowedPropNames={{
          generalMilestonesNames,
          customMilestonesNames,
          dateMilestonesNames,
        }}
        onUpdate={updateData}
        getNewRecord={getNewRecord}
        optionalTitle={optionalTitle}
        optionalMilestones={generalMilestones || []}
      />
      <NetBudget
        maxLabel={maxLabel}
        minLabel={minLabel}
        budget={budget}
        minBudget={minBudget}
        maxBudget={maxBudget}
        onUpdate={updateData}
      />
      <Checkbox
        id="checkApproval"
        label={checkboxLabel}
        value={checkApproval}
        onCheck={updateData}
      />
      <br />
      <PercentagePaySchedule
        generalMilestones={generalMilestones}
        dateMilestones={dateMilestones}
        customMilestones={customMilestones}
        onUpdate={updateData}
      />
    </>
  );
};

export default (memo(NetBudgetShareMilestones): AbstractComponent<
  PropsType,
  mixed
>);
