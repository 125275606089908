/**
 *
 * PercentagePaySchedule
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import GeneralMilestones from 'app/components/GeneralMilestones/GeneralMilestones';
import { Grid } from '@mui/material';
import PercentageCustomMilestones from 'app/components/PercentageCustomMilestones/PercentageCustomMilestones';
import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';

const PercentagePaySchedule = (props: PropsType): Node => {
  const {
    generalMilestones = [],
    dateMilestones = [],
    customMilestones = [],
    onUpdate,
  } = props;

  const calTotal = () => {
    let percentageArr = [];
    [...generalMilestones, ...dateMilestones, ...customMilestones].forEach(
      (val) => {
        const { percentage } = val;
        percentageArr.push(+percentage);
      }
    );

    const sumPercentage = percentageArr.reduce((prev, cur) => prev + cur, 0);

    return sumPercentage;
  };

  const updateMilestones = (fieldName: string, data) =>
    onUpdate(fieldName, data);

  return (
    <Grid container>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <GeneralMilestones
            generalValues={generalMilestones}
            onDataUpdated={(data) =>
              updateMilestones('generalMilestones', data)
            }
            showAmount={false}
          />
        </Grid>
        <Grid item xs={12}>
          <PercentageCustomMilestones
            customValues={customMilestones}
            onDataUpdated={(data) => updateMilestones('customMilestones', data)}
          />
        </Grid>
        <Grid item xs={12}>
          <PercentageCustomMilestones
            customValues={dateMilestones}
            onDataUpdated={(data) => updateMilestones('dateMilestones', data)}
            showDate={true}
          />
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1.5}>
          <TextField
            id="totalPercentage"
            label="Total"
            numberOnly
            readOnly
            value={isNaN(calTotal()) ? 0 : calTotal()}
            error={calTotal() > 100}
            helperText={calTotal() > 100 && 'Error'}
            endAdornment={<>%</>}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(PercentagePaySchedule): AbstractComponent<
  PropsType,
  mixed
>);
