import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { primaryTitleStyle, secondaryTitleStyle } from './styles';

import { useTheme } from '@mui/material/styles';
import { ArrowDown } from 'app/assets/icons';
import { HashLink } from 'react-router-hash-link';

const InfoMenuList = ({ infoPageDataList }) => {
  const theme = useTheme();
  const expandedPanelsData = Array(infoPageDataList?.length).fill(false);
  const [expandedPanels] = useState(expandedPanelsData);
  const [hashActiveId, setHashActiveId] = useState('');

  useEffect(() => {
    const hashText = window.location.hash;
    if (infoPageDataList.length > 0 && hashText) {
      setHashActiveId(hashText);
      document.querySelector(hashText).scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [infoPageDataList]);

  const hasItems = (dataItem) =>
    dataItem?.items !== null && dataItem?.items.length;

  return (
    <Box sx={{ height: '100%' }}>
      {infoPageDataList?.map((infoPageItem, infoPageIndex) => {
        return (
          <Accordion
            key={infoPageIndex}
            expanded={expandedPanels[infoPageIndex]}
            elevation={0}
            sx={{
              '&:before': {
                backgroundColor: 'transparent',
              },
            }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ArrowDown color={theme.palette.grey['700']} />}
              aria-controls={`panel-${infoPageIndex + 1}-content`}
              id={`panel-${infoPageIndex + 1}-header`}
            >
              <HashLink
                smooth
                to={`/info#${infoPageItem.slug}`}
                onClick={() => setHashActiveId(`#${infoPageItem.slug}`)}
              >
                <Typography
                  variant="menuTitle"
                  sx={{
                    color:
                      `#${infoPageItem.slug}` === hashActiveId
                        ? theme.palette.primary['main']
                        : theme.palette.grey['750'],
                    ...primaryTitleStyle,
                  }}
                >
                  {infoPageItem.title}
                </Typography>
              </HashLink>
            </AccordionSummary>
            <AccordionDetails>
              {infoPageItem?.items.map((infoItem, infoIndex) => {
                const withItems = hasItems(infoItem);
                return (
                  <Accordion
                    key={infoIndex}
                    expanded={!withItems ? true : expandedPanels[infoIndex]}
                    elevation={0}
                    sx={{
                      '&:before': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    disableGutters
                  >
                    {withItems ? (
                      <AccordionSummary
                        expandIcon={
                          <ArrowDown color={theme.palette.grey['700']} />
                        }
                        aria-controls={`panel-${infoIndex + 1}-content`}
                        id={`panel-${infoIndex + 1}-header`}
                      >
                        <HashLink
                          smooth
                          to={`/info#${infoItem.slug}`}
                          onClick={() => setHashActiveId(`#${infoItem.slug}`)}
                        >
                          <Typography
                            variant="menuTitle"
                            sx={{
                              color:
                                `#${infoItem.slug}` === hashActiveId
                                  ? theme.palette.primary['main']
                                  : theme.palette.grey['750'],
                              ...secondaryTitleStyle,
                            }}
                          >
                            {infoItem.title}
                          </Typography>
                        </HashLink>
                      </AccordionSummary>
                    ) : (
                      <List sx={{ pl: 1, pt: 0, pb: 0 }}>
                        <HashLink
                          smooth
                          to={`/info#${infoItem.slug}`}
                          onClick={() => setHashActiveId(`#${infoItem.slug}`)}
                        >
                          <ListItem
                            key={`termComponent-${infoIndex}`}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                color: theme.palette.primary.main,
                              },
                              height: '20px',
                              color:
                                `#${infoItem.slug}` === hashActiveId
                                  ? theme.palette.primary['main']
                                  : theme.palette.grey['750'],
                              display: 'list-item',
                              padding: 1,
                              ...secondaryTitleStyle,
                            }}
                          >
                            <ListItemText>{infoItem.title}</ListItemText>
                          </ListItem>
                        </HashLink>
                      </List>
                    )}
                    <AccordionDetails>
                      {hasItems(infoItem) &&
                        infoItem?.items.map((subInfo, indexSubInfo) => {
                          const withItems = hasItems(subInfo);
                          return (
                            <Accordion
                              key={indexSubInfo}
                              elevation={0}
                              expanded={
                                !withItems ? true : expandedPanels[indexSubInfo]
                              }
                              sx={{
                                '&:before': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                              disableGutters
                            >
                              {withItems ? (
                                <AccordionSummary
                                  expandIcon={
                                    <ArrowDown
                                      color={theme.palette.grey['700']}
                                    />
                                  }
                                  aria-controls={`panel-${
                                    infoIndex + 1
                                  }-content`}
                                  id={`panel-${infoIndex + 1}-header`}
                                >
                                  <HashLink
                                    smooth
                                    to={`/info#${subInfo.slug}`}
                                    onClick={() =>
                                      setHashActiveId(`#${subInfo.slug}`)
                                    }
                                  >
                                    <Typography
                                      variant="menuTitle"
                                      sx={{
                                        color:
                                          `#${subInfo.slug}` === hashActiveId
                                            ? theme.palette.primary['main']
                                            : theme.palette.grey['750'],
                                        ...secondaryTitleStyle,
                                      }}
                                    >
                                      {subInfo.title}
                                    </Typography>
                                  </HashLink>
                                </AccordionSummary>
                              ) : (
                                <List sx={{ pl: 1, pt: 0, pb: 0 }}>
                                  <HashLink
                                    key={indexSubInfo}
                                    smooth
                                    to={`/info#${subInfo.slug}`}
                                    onClick={() =>
                                      setHashActiveId(`#${subInfo.slug}`)
                                    }
                                  >
                                    <ListItem
                                      key={indexSubInfo}
                                      sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                          color: theme.palette.primary.main,
                                        },
                                        height: '20px',
                                        color:
                                          `#${subInfo.slug}` === hashActiveId
                                            ? theme.palette.primary['main']
                                            : theme.palette.grey['750'],
                                        display: 'list-item',
                                        padding: 1,
                                        ...secondaryTitleStyle,
                                      }}
                                    >
                                      <ListItemText>
                                        {subInfo.title}
                                      </ListItemText>
                                    </ListItem>
                                  </HashLink>
                                </List>
                              )}
                              <AccordionDetails>
                                {hasItems(subInfo) && (
                                  <List sx={{ pl: 1, pt: 0, pb: 0 }}>
                                    {subInfo.items.map((subItem, subindex) => (
                                      <HashLink
                                        key={subindex}
                                        smooth
                                        to={`/info#${subItem.slug}`}
                                        onClick={() =>
                                          setHashActiveId(`#${subItem.slug}`)
                                        }
                                      >
                                        <ListItem
                                          key={subindex}
                                          sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                              color: theme.palette.primary.main,
                                            },
                                            color:
                                              `#${subItem.slug}` ===
                                              hashActiveId
                                                ? theme.palette.primary['main']
                                                : theme.palette.grey['750'],
                                            display: 'list-item',
                                            padding: 1,
                                            ...secondaryTitleStyle,
                                          }}
                                        >
                                          <ListItemText>
                                            {subItem.title}
                                          </ListItemText>
                                        </ListItem>
                                      </HashLink>
                                    ))}
                                  </List>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default InfoMenuList;
