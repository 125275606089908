/**
 *
 * CustomMilestone
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { default as React, memo } from 'react';

import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';

const PercentageCustomMilestones = (props: PropsType): Node => {
  const { showDate = false, customValues = [], onDataUpdated } = props;

  const addMilestone = () => {
    const newCustomMilestone = {
      ...(showDate ? { name: 'Select Date' } : { name: 'Specify Own' }),
      specialInput: '',
      percentage: 0,
    };
    const addCustomValue = [...customValues, newCustomMilestone];
    onDataUpdated(addCustomValue);
  };

  const deleteMilestone = (index) => {
    const removeCustomValue = customValues.filter((_, i) => i !== index);
    onDataUpdated(removeCustomValue);
  };

  const updateMilestoneData = (targetValue, id, i) => {
    const customMilestoneCopy = window.structuredClone(customValues);
    customMilestoneCopy[i][id] = targetValue;
    onDataUpdated(customMilestoneCopy);
  };

  return (
    <>
      {customValues.map((custom, i) => {
        const { name = '', specialInput = '', percentage = 0 } = custom;
        return (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            key={i}
          >
            <>
              <Grid item xs={3}>
                <Typography variant="body1">{name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  id="specialInput"
                  type={showDate ? 'date' : null}
                  value={specialInput}
                  onBlur={(id, val) => updateMilestoneData(val, id, i)}
                />
              </Grid>
            </>
            <Grid item xs={1.5}>
              <TextField
                id="percentage"
                numberOnly
                endAdornment={<>%</>}
                value={percentage}
                forceReset
                onValueChange={(id, val) => updateMilestoneData(val, id, i)}
              />
            </Grid>
            {i <= 0 ? (
              <Grid item xs={1}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => addMilestone()}
                ></Button>
              </Grid>
            ) : (
              <Grid item xs={1}>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => deleteMilestone(i)}
                ></Button>
              </Grid>
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default (memo(PercentageCustomMilestones): AbstractComponent<
  PropsType,
  mixed
>);
