import { gql } from '@apollo/client';

export const userInfoFragment = gql`
  fragment userInfo on User {
    _id
    accountIdentifier
    emails
    givenName
    familyName
    idProvider
  }
`;
