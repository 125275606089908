/**
 *
 * NetBudgetShareCustom
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { FormControl, FormGroup, Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import Checkbox from 'app/components/Checkbox/Checkbox';
import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const NetBudgetShareCustom: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    netBudget = '',
    minBudget = '',
    maxBudget = '',
    feesContributor = false,
    contingency = true,
    bond = true,
    finance = true,
    legal = true,
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      netBudget: '',
      minBudget: '',
      maxBudget: '',
      feesContributor: true,
      contingency: true,
      bond: true,
      finance: true,
      legal: true,
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={1.5}>
          <TextField
            id="netBudget"
            numberOnly
            endAdornment={<>%</>}
            value={netBudget}
            onBlur={onUpdateData}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body1" sx={{ paddingTop: 0 }}>
            of Net Budget
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <TextField
            id="minBudget"
            label="Minimum"
            value={minBudget}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={onUpdateData}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="maxBudget"
            label="Maximum"
            value={maxBudget}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={onUpdateData}
          />
        </Grid>
      </Grid>
      <br />

      <Grid>
        <Checkbox
          id="feesContributor"
          label="Less any fees paid to Contributor prior to production"
          value={feesContributor}
          defaultValue={feesContributor}
          onCheck={onUpdateData}
        />
      </Grid>
      <br />
      <Grid item xs={6}>
        <Typography variant="body1">Net Budget excludes</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormGroup>
            <Checkbox
              id="contingency"
              label="Contingency"
              value={contingency}
              onCheck={onUpdateData}
            />
            <Checkbox
              id="bond"
              label="Completion Bond"
              value={bond}
              onCheck={onUpdateData}
            />
            <Checkbox
              id="finance"
              label="Finance"
              value={finance}
              onCheck={onUpdateData}
            />
            <Checkbox
              id="legal"
              label="Legal"
              value={legal}
              onCheck={onUpdateData}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default (memo(NetBudgetShareCustom): AbstractComponent<
  PropsType,
  mixed
>);
