/**
 *
 * useUpdateProjectContractsMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateProjectContractsMutation,
  UpdateProjectContractsMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { projectSummaryFragment } from 'project/graphQL/fragments/projectFragments';

const UPDATE_PROJECT_CONTRACTS = gql`
  mutation UpdateProjectContracts($input: UpdateProjectContractsInput!) {
    updateProjectContracts(input: $input) {
      project {
        ...projectSummary
        schedule {
          preProductionStart
          preProductionEnd
          productionStart
          productionEnd
          postProductionStart
          postProductionEnd
          finalMaterialsDeliveryStart
          finalMaterialsDeliveryEnd
        }
      }
    }
  }
  ${projectSummaryFragment}
`;

export type UpdateProjectContractsReturnType = {
  updateProjectContracts: ({
    variables: UpdateProjectContractsMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateProjectContractsMutation,
  error: any,
};

export const useUpdateProjectContractsMutation =
  (): UpdateProjectContractsReturnType => {
    const [updateProjectContracts, { data, loading, error }] = useMutation(
      UPDATE_PROJECT_CONTRACTS
    );

    return {
      updateProjectContracts,
      loading,
      data,
      error,
    };
  };
