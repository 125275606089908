/**
 *
 * Contract Top Bar
 * @format
 * @flow
 *
 */

import {
  Breadcrumbs,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Button,
} from '@mui/material';
import {
  GreyContract as ContractIcon,
  MoreVertical as MoreActionsIcon,
} from 'app/assets/icons';
import React, { useContext, useState, useLayoutEffect } from 'react';

import { Container } from './styles';
import { ContractContext } from 'app/contexts/Contract/Contract';
import DownloadDocsModal from './DownloadDocsModal';
import { Link } from 'react-router-dom';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import Menu from 'app/components/core/Menu/Menu';
import { useTheme } from '@mui/material/styles';

const ContractTopBar = ({ contractData, ptid, cyid, partyName }) => {
  const {
    onSaveContractData,
    isLoadingSavingContract,
    isLoadingSaveAndCreateWordDoc,
    onSaveContractAndCreateWordDocument,
    onSetEditMode,
    isEditMode,
    isSuccessSavingContract,
  } = useContext(ContractContext);
  const theme = useTheme();
  const [actionsEl, setActionsEl] = useState(null);
  const [docsModalOpen, setDocsModalOpen] = useState(false);

  useLayoutEffect(() => {
    if (isSuccessSavingContract) {
      onSetEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSavingContract]);

  return (
    <Container maxWidth="false">
      {ptid ? (
        <>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
              >
                <Grid item>
                  <Breadcrumbs aria-label="breadcrumb" separator="/">
                    <Link to={`/dashboard/${cyid}/projects`}>Projects</Link>
                    <Link to={`/dashboard/${cyid}/project/${ptid}?tab=0`}>
                      Contributor Contracts
                    </Link>
                    <Typography color="text.primary">
                      {contractData?.contractTemplateName || ''}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <Grid container columnSpacing={1} alignItems="center">
                        <Grid item>
                          {isEditMode ? (
                            <LoadingButton
                              isLoading={isLoadingSavingContract}
                              loadingText="Saving..."
                              defaultText="Save Draft"
                              containerStyle={{ marginTop: '0' }}
                              onClick={onSaveContractData}
                              variant="secondary"
                            />
                          ) : (
                            <Button
                              onClick={() => onSetEditMode(true)}
                              variant="outlined"
                              sx={{ textTransform: 'none', fontSize: '16px' }}
                            >
                              Edit
                            </Button>
                          )}
                        </Grid>
                        {/* TODO: Add more actions; Share and Sign button */}
                        <Grid item>
                          <IconButton
                            onClick={(e) => setActionsEl(e.currentTarget)}
                            sx={{
                              padding: '0',
                              height: '40px',
                              width: '40px',
                              border: `1px solid ${theme.palette.grey['400']}`,
                            }}
                          >
                            <MoreActionsIcon />
                          </IconButton>
                          <Menu
                            id="saved-documents"
                            anchorEl={actionsEl}
                            keepMounted
                            open={Boolean(actionsEl)}
                            onClose={() => setActionsEl(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            sx={{
                              '& .MuiPaper-root': {
                                padding: '16px',
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setDocsModalOpen(true);
                                setActionsEl(null);
                              }}
                            >
                              Saved documents
                            </MenuItem>
                          </Menu>
                          <DownloadDocsModal
                            documents={contractData?.documents || []}
                            modalOpen={docsModalOpen}
                            onClose={() => setDocsModalOpen(false)}
                          />
                        </Grid>
                        <Grid item>
                          <LoadingButton
                            isLoading={isLoadingSaveAndCreateWordDoc}
                            loadingText="Creating..."
                            defaultText="Create Contract"
                            onClick={() =>
                              onSaveContractAndCreateWordDocument([
                                'category:Other',
                                'subCategory:Contracts',
                              ])
                            }
                            containerStyle={{ marginTop: '0' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt justifyContent="space-between">
            <Grid item xs={4}>
              <Grid container spacing={2} wrap="no-wrap">
                <Grid item>
                  <ContractIcon
                    style={{
                      height: '32px',
                      width: '32px',
                      padding: '6px',
                      background: theme.palette.grey['200'],
                      borderRadius: '8px',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h1" color="text.primary">
                    {contractData?.contractTemplateName || ''} | {partyName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link to={`/dashboard/${cyid}/contracts`}>Contracts</Link>
          <Typography color="text.primary">
            {contractData?.contractTemplateName || ''}
          </Typography>
        </Breadcrumbs>
      )}
    </Container>
  );
};

export default ContractTopBar;
