import { styled } from '@mui/system';

export const CheckboxContainer = styled('div', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== 'checked',
})(
  ({ checked, theme }) => `
    background: ${checked ? theme.palette.primary.backgroundLight : 'none'};
  `
);
