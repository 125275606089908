// @flow
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import React from 'react';

type Props = {
  children: any,
};

/**
 * This component returns an ApolloProvider with an ApolloClient that passes server auth credentials using the browser cookie
 *
 *
 * @param {*} param0
 * @returns
 */
const AuthenticatedApolloProvider = ({
  children,
}: Props): React$Element<any> => {
  // setting credentials to use cookie based session management (ory)
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    credentials: 'include',
  });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;
