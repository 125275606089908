import {
  type Identity,
  type Session,
  getSession,
  loginOrRedirectUrl,
  logout as oryLogout,
  register as oryRegister,
} from 'app/services/ory/api';

import { disableAuthentication } from 'app/utils/config';
import { randomColorPicker } from 'app/utils/general.js';
import { useState } from 'react';

const useAccountActions = (apolloClient) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [session, setSession] = useState(null);

  const logout = async (apolloClient) => {
    if (disableAuthentication) return;
    if (apolloClient) {
      await apolloClient.resetStore();
    }
    localStorage.clear();
    return oryLogout();
  };
  const login = async () => {
    let result = { session: null, redirectUrl: null };
    if (disableAuthentication) return result;
    try {
      setLoggingIn(true);
      result = await loginOrRedirectUrl();
      setSession(result.session);
      if (result.redirectUrl) {
        window.location.replace(result.redirectUrl);
      }
      const setColor = randomColorPicker();
      localStorage.setItem('user-color', setColor);
    } catch (error) {
      setLoggingIn(false);
      console.error(error);
    }
  };

  const register = async () => {
    let result = { session: null, redirectUrl: null };
    if (disableAuthentication) return result;
    try {
      setLoggingIn(true);
      result = await oryRegister();
      setSession(result.session);
      if (result.redirectUrl) {
        window.location.replace(result.redirectUrl);
      }
    } catch (error) {
      setLoggingIn(false);
      console.error(error);
    }
  };

  const checkSession = async () => {
    try {
      const session = await getSession();
      setSession(session);
    } catch (error) {
      setSession(null);
    }
  };

  return {
    logout,
    login,
    checkSession,
    session,
    loggingIn,
    register,
  };
};

export type { Identity, Session };

export default useAccountActions;
