/**
 *
 * TextGeneratingCheck
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const TextGeneratingCheck = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { textValue = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const { checkboxLabel = '', checkboxText = '' }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      textValue: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextGeneratingCheckbox
          id="textValue"
          label={checkboxLabel}
          value={textValue}
          textGenerating={checkboxText}
          onCheck={onUpdateData}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(TextGeneratingCheck): AbstractComponent<PropsType, mixed>);
