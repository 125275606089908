/**
 *
 * UsersModal
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import type { PropsType, UserInfoType } from './types';
import React, { memo, useEffect, useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import type { CompanyUsersReturnType } from 'company/graphQL/useCompanyUsersQuery';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import TextField from 'app/components/TextField/TextField';
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import { useCompanyUsersQuery } from 'company/graphQL/useCompanyUsersQuery';
import { useCreateUserMutation } from 'user/graphQL/useCreateUserMutation';
import { useParams } from 'react-router-dom';

const UsersModal: AbstractComponent<PropsType> = ({
  open,
  onClose,
}: PropsType): Node => {
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';

  const {
    data: companyDetailsData,
    loading,
    error,
  } = useCompanyDetailsQuery({
    _id: cyid,
  });

  const {
    createUser,
    data: createUserData,
    loading: createUserLoading,
  } = useCreateUserMutation();

  const { refetch }: CompanyUsersReturnType = useCompanyUsersQuery();

  // eslint-disable-next-line no-unused-vars
  const [combinedState, setCombinedState] = useState<UserInfoType>({
    givenName: '',
    familyName: '',
    email: '',
  });

  useEffect(() => {
    if (!!createUserData?.createUser?.user) {
      refetch();
      setCombinedState({
        givenName: '',
        familyName: '',
        email: '',
      });
      onClose(new Event('user created'), 'user created');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserData]);

  if (open && loading) return <p>Loading...</p>;
  if (open && error) return <p>Error :(</p>;
  if (open && !companyDetailsData?.company)
    return <p>Company details not found!</p>;

  const handleStateChange = (fieldName: string, fieldValue: any) => {
    // $FlowFixMe
    setCombinedState((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const handleCreateUser = () => {
    const { givenName, familyName, email } = combinedState;

    createUser({
      variables: {
        input: {
          givenName,
          familyName,
          email,
        },
      },
    });
  };

  const { givenName, familyName, email } = combinedState;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="title">Add a user</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item lg={5.5} md={5.5} xs={12}>
                  <TextField
                    id="givenName"
                    label="First Name"
                    value={givenName}
                    onBlur={handleStateChange}
                  />
                </Grid>
                <Grid item lg={5.5} md={5.5} xs={12}>
                  <TextField
                    id="familyName"
                    label="Last Name"
                    value={familyName}
                    onBlur={handleStateChange}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    value={email}
                    onBlur={handleStateChange}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid container>
                  <LoadingButton
                    isLoading={createUserLoading}
                    loadingText="Inviting..."
                    defaultText="Invite"
                    onClick={handleCreateUser}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default (memo(UsersModal): AbstractComponent<PropsType, mixed>);
