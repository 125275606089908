/**
 *
 * ContractPartyEmployee
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const ContractPartyEmployee: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    firstName = '',
    lastName = '',
    addressOne = '',
    addressTwo = '',
    citySub = '',
    state = '',
    postCode = '',
    country = '',
    email = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      firstName: '',
      lastName: '',
      addressOne: '',
      addressTwo: '',
      citySub: '',
      state: '',
      postCode: '',
      country: '',
      email: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container spacing={2}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="body1">Contributor</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              label="First Name"
              value={firstName}
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name"
              value={lastName}
              onBlur={onUpdateData}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="address"
              label="Address line 1"
              value={addressOne}
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="addressTwo"
              label="Address line 2"
              value={addressTwo}
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="citySub"
              label="City/Suburb"
              value={citySub}
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              label="State"
              value={state}
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="postCode"
              label="Postcode"
              value={postCode}
              numberOnly
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              label="Country"
              value={country}
              onBlur={onUpdateData}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              type="email"
              label="Email"
              value={email}
              onBlur={onUpdateData}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(ContractPartyEmployee): AbstractComponent<
  PropsType,
  mixed
>);
