import { List, ListItem } from '@mui/material';

import { BulletPlainTextContainer } from './styles';
import React from 'react';

const BulletPlainText: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { value, isSelected, bulletTextStyle } = props;
  return (
    <BulletPlainTextContainer selected={isSelected} className="textarea">
      <List
        sx={{
          listStyleType: 'disc',
          listStylePosition: 'inside',
          marginLeft: 5,
        }}
        style={bulletTextStyle}
      >
        <ListItem sx={{ display: 'list-item' }}>{value}</ListItem>
      </List>
    </BulletPlainTextContainer>
  );
};

export default BulletPlainText;
