/**
 *
 * ProjectView
 * @format
 * @flow
 *
 */

import { Breadcrumbs, Chip, Grid, Stack, Typography } from '@mui/material';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import type { ContractDetailsReturnType } from 'contract/graphQL/useProjectContractsQuery';
import { GreyContract as ContractIcon } from 'app/assets/icons';
import type { Node } from 'react';
import ProjectContributorContracts from './ProjectContributorContracts/ProjectContributorContracts';
import ProjectDetails from './ProjectDetails/ProjectDetails';
// import ProjectExternalDocs from './ProjectExternalDocs/ProjectExternalDocs';
import Tabs from 'app/components/Tabs/Tabs';
// $FlowFixMe
import queryString from 'query-string';
import { useProjectContractsQuery } from 'contract/graphQL/useProjectContractsQuery';
import { useProjectDetailsQuery } from 'project/graphQL/useProjectDetailsQuery';
import { useTheme } from '@mui/material/styles';

const ProjectView = (): Node => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState('0');
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const ptid = routeParams.ptid || '';
  const {
    data,
    loading,
    // eslint-disable-next-line no-unused-vars
    error,
  } = useProjectDetailsQuery({
    _id: ptid,
  });

  const { data: projectContractData }: ContractDetailsReturnType =
    useProjectContractsQuery({ _id: ptid ?? '' });

  useEffect(() => {
    const searchValues = queryString.parse(location?.search || {});

    setTabValue(searchValues?.tab || '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!data?.project) return <p>Not found</p>;

  const { project: projectData } = data;

  return (
    <div className="project-view" style={{ width: '100%' }}>
      <Grid container>
        <Grid
          container
          direction="column"
          spacing={3}
          sx={{
            marginTop: 0,
            padding: '24px',
            background: theme.palette.background.paper,
          }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                  <Link to={`/dashboard/${cyid}/projects`}>Projects</Link>
                  <Typography color="text.primary">
                    {projectData?.title || ''}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <ContractIcon
                      style={{
                        height: '32px',
                        width: '32px',
                        padding: '6px',
                        background: theme.palette.grey['200'],
                        borderRadius: '8px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="text.primary">
                      {projectData?.title || ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Tabs
          value={tabValue}
          onChange={(tabVal) => {
            const params = new URLSearchParams({ tab: tabVal.toString() });
            history.replace({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
          tabs={[
            {
              label: (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Typography variant="body" color="inherit">
                    Contracts
                  </Typography>
                  <Chip
                    label={projectContractData?.projectContracts?.length || 0}
                    size="small"
                    sx={{
                      '.MuiChip-label': {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: theme.palette.grey['800'],
                      },
                      borderRadius: '4px',
                      marginLeft: 1,
                    }}
                  />
                </Stack>
              ),
              component: <ProjectContributorContracts />,
            },
            {
              label: 'Details',
              component: <ProjectDetails />,
            },
            // hide this for now
            // {
            //   label: 'Uploads',
            //   component: <ProjectExternalDocs />,
            // },
          ]}
        />
      </Grid>
    </div>
  );
};

export default ProjectView;
