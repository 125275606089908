/**
 *
 * EngagementPeriod
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, {
  memo,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { IconButton, Grid } from '@mui/material';
import type { PropsType } from '../types';
import DragDropContainer from 'app/components/DragDropContainer/DragDropContainer';
import ExclusivePeriod from './ExclusivePeriod';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { PlusIcon } from './styles';
import type { ComponentDataType } from './types';
import { omit } from 'lodash';
import { format } from 'date-fns';

const EngagementPeriod: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermProps, contractTermData, onUpdateContractTermData } =
    useContext(ContractContext);

  const { items = [], order = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const initialData = {
    exclusivity: '',
    otherDetails: '',
    startDate: '',
    endDate: '',
    isDragDisabled: true,
    isSelected: false,
    employmentType: '',
  };

  // period list is used to render each of the period
  const [periodList, setPeriodList] = useState([
    {
      description: 'Pre-Production',
      ...initialData,
    },
    {
      description: 'Production',
      ...initialData,
    },
    {
      description: 'Post-Production',
      ...initialData,
    },
    {
      description: 'Delivery',
      ...initialData,
    },
  ]);

  // only save selected items
  const saveSelectedItems = (items) => {
    return items
      ?.filter((item) => item.isSelected)
      .map((item) => omit(item, ['content', 'isDragDisabled']));
  };

  const onAddCustomPeriod = async () => {
    const customPeriods = items?.filter((item) => item.isCustomPeriod);
    const lastId =
      customPeriods.length > 0 ? customPeriods[customPeriods.length - 1].id : 0;

    let newPeriod = {
      description: '',
      startDate: format(new Date().getTime(), 'dd MMM yyyy'),
      endDate: format(new Date().getTime(), 'dd MMM yyyy'),
      exclusivity: '',
      otherDetails: '',
      isCustomPeriod: true,
      isSelected: true,
      id: lastId + 1,
    };
    newPeriod = {
      ...newPeriod,
      content: renderExclusivePeriod(periodList.length, newPeriod, true),
    };
    const updatedItems = [newPeriod, ...items];

    onUpdateData(updatedItems);
    setPeriodList([newPeriod, ...periodList]);
  };

  const reorder = (periods, startIndex, endIndex) => {
    const newPeriods = Array.from(periods);
    const [removedPeriod] = newPeriods.splice(startIndex, 1);
    newPeriods.splice(endIndex, 0, removedPeriod);

    return newPeriods;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedPeriods = reorder(
      periodList,
      result.source.index,
      result.destination.index
    );

    setPeriodList(reorderedPeriods);
  };

  const onUpdateData = (updatedItems) => {
    onUpdateContractTermData(contractTermKeyName, {
      items: saveSelectedItems(updatedItems),
    });
  };

  const onDeleteCustomPeriod = (customPeriodId, updatedItems) => {
    const newItems = [...updatedItems];
    const filteredItems = newItems.filter((i) => i?.id !== customPeriodId);

    setPeriodList((currentPeriodList) => {
      return currentPeriodList
        .filter((period) =>
          period.isCustomPeriod ? period.id !== customPeriodId : period
        )
        .map((period, id) => ({
          ...period,
          content: renderExclusivePeriod(id, period, period.isCustomPeriod),
        }));
    });

    onUpdateData(filteredItems);
  };

  const renderExclusivePeriod = (
    index,
    { description, id: customPeriodId },
    isCustomPeriod = false
  ) => {
    return (
      <ExclusivePeriod
        index={index}
        description={description}
        onDeleteCustomPeriod={onDeleteCustomPeriod}
        isCustomPeriodProp={isCustomPeriod}
        contractTermKeyName={contractTermKeyName}
        onUpdateData={onUpdateData}
        customPeriodId={customPeriodId}
        {...contractTermProps[contractTermKeyName]}
      />
    );
  };

  // we need to set content to periodList to render the component
  const onSetDefaultContentToPeriodList = () => {
    const updatedPeriodList = periodList?.map((period, index) => {
      return {
        ...period,
        content: renderExclusivePeriod(index, period, period?.isCustomPeriod),
        isCustomPeriod: period?.isCustomPeriod,
        isSelected: period?.isSelected,
      };
    });
    setPeriodList(updatedPeriodList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useLayoutEffect(() => {
    onUpdateContractTermData(contractTermKeyName, {
      order: periodList.map((period) =>
        period.isCustomPeriod ? period.id.toString() : period.description
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodList]);

  useEffect(() => {
    const newItems = [...items];
    const sortedItems = newItems.sort(
      (a, b) =>
        order?.indexOf(a?.isCustomPeriod ? a?.id.toString() : a.description) -
        order?.indexOf(b?.isCustomPeriod ? b?.id.toString() : b.description)
    );

    // sort the items based on the order so that it will appear the same or in the document
    onUpdateContractTermData(contractTermKeyName, {
      items: sortedItems,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const onUpdatePeriodList = (updatedItems) => {
    const newPeriodList = [...periodList];

    const periodIndexMap = {};
    newPeriodList.forEach((period, index) => {
      periodIndexMap[period.description] = index;
    });

    updatedItems.forEach((item) => {
      // insert the custom period to the correct index in periodList
      if (item.isCustomPeriod) {
        const customPeriodIndex = order.indexOf(item.id.toString());
        newPeriodList.splice(customPeriodIndex, 0, item);
      }
    });

    const updatedPeriodList = newPeriodList?.map((period, id) => ({
      ...period,
      content: renderExclusivePeriod(id, period, period.isCustomPeriod),
    }));

    setPeriodList(updatedPeriodList);
  };

  useEffect(() => {
    if (items?.length >= 1) {
      onUpdatePeriodList(items);
    } else {
      onSetDefaultContentToPeriodList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <IconButton onClick={onAddCustomPeriod}>
          <PlusIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <DragDropContainer items={periodList} onDragEnd={onDragEnd} />
      </Grid>
    </Grid>
  );
};

export default (memo(EngagementPeriod): AbstractComponent<mixed>);
