import {
  FormControlLabel as MUIFormControlLabel,
  Switch as MUISwitch,
} from '@mui/material';
import { styled } from '@mui/system';

export const FormControlLabelStyled = styled(MUIFormControlLabel)`
  && {
    margin-left: 0px;
    gap: 12px;
    .MuiTypography-root {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.87);
      margin-left: 0px;
    }
  }
`;

export const Switch = styled((props: SwitchProps) => (
  <MUISwitch disableRipple {...props} />
))(({ theme }) => ({
  width: '36px',
  height: '20px',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '2px',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '16px',
    height: '16px',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey['400'],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
