/**
 *
 * NetBudgetShare
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import NetBudget from 'app/components/NetBudget/NetBudget';
import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const NetBudgetShare = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    checkboxLabel = 'Less and amounts paid to Contributor before production',
    showCheckbox = true,
    maxLabel = 'Maximum',
    minLabel = 'Minimum',
    approvalCheckboxText = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    budget = '',
    minBudget = '',
    maxBudget = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      budget: '',
      minBudget: '',
      maxBudget: '',
      approvalCheckboxTextValue: '',
    },
  });

  const updateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <NetBudget
        maxLabel={maxLabel}
        minLabel={minLabel}
        checkboxLabel={checkboxLabel}
        showCheckbox={showCheckbox}
        approvalCheckboxText={approvalCheckboxText}
        budget={budget}
        minBudget={minBudget}
        maxBudget={maxBudget}
        approvalCheckboxTextValue={approvalCheckboxTextValue}
        onUpdate={updateData}
      />
    </>
  );
};

export default (memo(NetBudgetShare): AbstractComponent<PropsType, mixed>);
