/**
 *
 * DirectorsCut
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';

import type { ComponentDataType, ComponentPropsType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';

const DirectorsCut: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    onUpdateContractTermData: onUpdate,
    contractTermProps,
  } = useContext(ContractContext);

  const {
    firstCutCheckboxTextValue = '',
    secondCutCheckboxTextValue = '',
    firstCutDescription = '',
    secondCutDescription = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const {
    suggestionValues1 = [],
    suggestionValues2 = [],
    suggestionTextLabel = '',
    firstCutCheckboxText = '',
    secondCutCheckboxText = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      firstCutDescription: '',
      secondCutDescription: '',
      firstCutCheckboxTextValue: '',
      secondCutCheckboxTextValue: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container>
      <Grid item xs={2}>
        <TextGeneratingCheckbox
          id="firstCutCheckboxTextValue"
          label="First Cut"
          value={firstCutCheckboxTextValue}
          textGenerating={firstCutCheckboxText}
          onCheck={(id, val) => onUpdateData(id, val)}
        />
      </Grid>
      <Grid item xs={10}>
        <TextAreaSuggestions
          id="firstCutDescription"
          label={suggestionTextLabel}
          value={firstCutDescription}
          suggestions={suggestionValues1 || []}
          onSubmitDescription={(id, val) => {
            onUpdateData(id, val);
          }}
          onChangeTextarea={(id, val) => {
            onUpdateData(id, val);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextGeneratingCheckbox
          id="secondCutCheckboxTextValue"
          label="Second Cut"
          value={secondCutCheckboxTextValue}
          textGenerating={secondCutCheckboxText}
          onCheck={(id, val) => onUpdateData(id, val)}
        />
      </Grid>
      <Grid item xs={10}>
        <TextAreaSuggestions
          id="secondCutDescription"
          label={suggestionTextLabel}
          value={secondCutDescription}
          suggestions={suggestionValues2 || []}
          onSubmitDescription={(id, val) => {
            onUpdateData(id, val);
          }}
          onChangeTextarea={(id, val) => {
            onUpdateData(id, val);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(DirectorsCut): AbstractComponent<PropsType, mixed>);
