import { AppBar as AppBarBase, Toolbar as ToolbarBase } from '@mui/material';
import { styled } from '@mui/system';

const AppBar = styled(AppBarBase)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 200,
  width: 'calc(100% - 256px)',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const Toolbar = styled(ToolbarBase)(({ theme }) => ({
  marginLeft: 0,
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
  backgroundColor: theme.palette.background.paper,
  height: '80px',
  borderBottom: `1px solid ${theme.palette.grey['300']}`,
}));

export { AppBar, Toolbar };
