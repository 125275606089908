/**
 *
 * CreditContrib
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button, Grid, Typography, Divider } from '@mui/material';
import type { ComponentDataType, ComponentPropsType } from './types';
import {
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import React, { memo, useCallback, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import CreditContribItem from './CreditContribItem';
import type { PropsType } from '../types';
import TextGeneratingRadioButtonGroup from 'app/components/TextGeneratingRadioButtonGroup/TextGeneratingRadioButtonGroup';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const CreditContrib = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const radioButton1Text = "Credit details in Prod Co's reasonable discretion";
  const radioButton2Text = 'Specific credit';

  const defaultData = {
    creditWording: '',
    creditName: '',
    creditPosition: 'End Credit',
    creditType: 'Rolling',
    cardType: '',
  };

  const {
    radioLabel1 = radioButton1Text,
    radioValue1 = radioButton1Text,
    textGenerating1 = radioButton1Text,
    radioLabel2 = radioButton2Text,
    radioValue2 = radioButton2Text,
    textGenerating2 = radioButton2Text,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    radioValue = radioValue1,
    textValue1 = textGenerating1,
    textValue2,
    items = [],
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      radioValue: radioValue1,
      textValue1: textGenerating1,
      textValue2: '',
      items: [],
    },
  });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCredit = () => {
    onUpdateData({ items: [...items, defaultData] });
  };

  const deleteCredit = (index) => {
    const newItems = items.filter((creditData, i) => i !== index);
    onUpdateData({ items: newItems });
  };

  const creditDataUpdate = useCallback(
    (targetValue, id, i) => {
      const newDataCredit = cloneDeep(items);

      if (id === 'creditType' && targetValue === 'Rolling') {
        newDataCredit[i]['cardType'] = '';
      }

      newDataCredit[i][id] = targetValue;
      onUpdateData({ items: newDataCredit });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const checkRadio2DataIsEmpty = () => {
    const { items } = contractTermData[contractTermKeyName] || {};
    const termData = { items };
    const termDefaultData = {
      items: [defaultData],
    };

    return isEqual(termData, termDefaultData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextGeneratingRadioButtonGroup
          radioValue={radioValue}
          textValue1={textValue1}
          textValue2={textValue2}
          radioLabel1={radioLabel1}
          radioValue1={radioValue1}
          textGenerating1={textGenerating1}
          radioLabel2={radioLabel2}
          radioValue2={radioValue2}
          textGenerating2={textGenerating2}
          radio2ResetData={{
            items: [],
          }}
          checkRadio2DataIsEmpty={checkRadio2DataIsEmpty}
          setRadio2DefaultData={() => {
            if (!items?.length) {
              addCredit();
            }
          }}
          onUpdate={onUpdateData}
        />
      </Grid>
      {radioValue === 'Specific credit' && (
        <>
          {items.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {index >= 1 && (
                  <Grid item p={4} xs={12}>
                    <Divider />
                  </Grid>
                )}
                <br />
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                        Credit {index + 1}
                      </Typography>
                    </Grid>
                    {index >= 1 && (
                      <Grid item>
                        <Button
                          onClick={() => deleteCredit(index)}
                          size="large"
                          startIcon={<DeleteIcon />}
                        ></Button>
                      </Grid>
                    )}
                  </Grid>
                  <CreditContribItem
                    item={item}
                    index={index}
                    creditDataUpdate={creditDataUpdate}
                  />
                </Grid>
              </React.Fragment>
            );
          })}

          <Grid item xs={12}>
            <Button
              onClick={() => addCredit()}
              size="large"
              startIcon={<ControlPointIcon />}
            ></Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default (memo(CreditContrib): AbstractComponent<PropsType, mixed>);
