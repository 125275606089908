/**
 *
 * TwoButtonDialogBox
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';

const TwoButtonDialogBox = (props: PropsType): Node => {
  const {
    open,
    titleDialog = '',
    onClickOne,
    onClickTwo,
    labelButtonOne = '',
    labelButtonTwo = '',
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{titleDialog}</DialogTitle>
      <DialogActions>
        <Button onClick={onClickOne}>{labelButtonOne}</Button>
        <Button onClick={onClickTwo}>{labelButtonTwo}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default (memo(TwoButtonDialogBox): AbstractComponent<PropsType, mixed>);
