/**
 *
 * useCreateUserMutation
 * @format
 * @flow
 *
 */

import type {
  CreateUserMutation,
  CreateUserMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { userDetailsFragment } from 'user/graphQL/fragments/userFragments';

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...userDetails
      }
    }
  }
  ${userDetailsFragment}
`;

export type CreateUserReturnType = {
  createUser: ({
    variables: CreateUserMutationVariables,
  }) => void,
  loading: boolean,
  data: CreateUserMutation,
  error: any,
};

export const useCreateUserMutation = (): CreateUserReturnType => {
  const [createUser, { data, loading, error }] = useMutation(CREATE_USER);

  return {
    createUser,
    loading,
    data,
    error,
  };
};
