/**
 *
 * useProjectContractsQuery
 * @format
 * @flow
 *
 */

import type {
  GetProjectContractsQuery,
  GetProjectContractsQueryVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useQuery } from '@apollo/client';

import contractSummaryFragment from 'contract/graphQL/fragments/contractSummaryFragment';

const PROJECT_CONTRACTS = gql`
  query GetProjectContracts($_id: ObjectID!) {
    projectContracts(_id: $_id) {
      ...contractSummary
    }
  }
  ${contractSummaryFragment}
`;

export type ContractDetailsReturnType = {
  loading: boolean,
  data: GetProjectContractsQuery,
  error: any,
};

export const useProjectContractsQuery = ({
  _id,
}: GetProjectContractsQueryVariables): ContractDetailsReturnType => {
  const { loading, data, error } = useQuery<GetProjectContractsQuery>(
    PROJECT_CONTRACTS,
    { variables: { _id } }
  );

  return {
    loading,
    data,
    error,
  };
};
