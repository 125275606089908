/**
 *
 * @format
 * @flow
 *
 */

import * as React from 'react';

import {
  Backdrop,
  Box,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import { BusinessIcon, HelpIcon } from 'app/assets/icons';
import { Link, NavLink } from 'react-router-dom';
import { Drawer, List, MobileDraw } from './styles';

import { useTheme } from '@mui/material/styles';
import CompanySwitchMenu from './CompanySwitchMenu';
import SideMenuItems from './SideMenuItems';
import type { SideMenuPropsType } from './types';

const SideMenu: React.AbstractComponent<SideMenuPropsType> = ({
  data,
  mobile,
  open,
  onClose,
  container,
}: SideMenuPropsType): React.Node => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const actions = [
    { icon:
      <Link
        to={{ pathname: process.env?.REACT_APP_BA_INFO_LINK || 'https://app-staging.businessaffairs.com/info' }}
        target={"_blank"}
        style={{
          padding: '4px 9px',
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          color: theme.palette.text.secondary,
        }}
      >
        <BusinessIcon />
      </Link>, name: 'Business Affairs Info' },
    { icon: <HelpIcon />, name: 'Help with Bam App (coming soon)' }
  ];

  if (mobile) {
    return (
      <MobileDraw
        container={container}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={open}
        onClose={onClose}
        sx={{
          display: { xs: 'block', sm: 'none' },
          zIndex: 1000,
        }}
      >
        <List>
          <SideMenuItems data={data} />
        </List>
      </MobileDraw>
    );
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        zIndex: 1000,
      }}
      open
    >
      <List>
        <CompanySwitchMenu />
        <SideMenuItems data={data} />
      </List>
      <Box>
        <Backdrop open={isOpen} />
        <SpeedDial
          FabProps={{ variant: "circular", color: 'inherit', size: 'small' }}
          ariaLabel="SpeedDial tooltip example"
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16
          }}
          icon={
          <NavLink
            to={'/'}
            style={{
              padding: '4px 9px',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              color: theme.palette.text.secondary,
            }}
          >
            <HelpIcon />
          </NavLink>
          }
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={handleClose}
              tooltipPlacement='right'
            />
          ))}
        </SpeedDial>
      </Box>
    </Drawer>
  );
};

export default (React.memo(SideMenu): React.AbstractComponent<
  SideMenuPropsType,
  mixed
>);
