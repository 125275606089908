import { Paper as MUIPaper, AppBar as MuiAppBar } from '@mui/material';

import { styled } from '@mui/system';

const drawerWidth = 400;

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: open ? 0 : 200,
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  alignContents: 'center',
}));

export const HeadingsContainerStyled = styled('div')`
  && {
    a {
      color: #4f6fa6;
      text-decoration: none;
    }

    li.active > a {
      color: red;
    }

    li > a:hover {
      color: red;
    }
  }
`;

export const PaperStyled = styled(MUIPaper)`
  && {
    border-radius: 8px;
    background-color: #fff;
    margin-top: 16px;
    margin-right: 10px;
    width: 100%;
  }
`;

export const primaryTitleStyle = { fontWeight: '500', fontFamily: 'Manrope', fontSize: '16px', lineHeight: '20px', width: '288px', height: '20px' }
export const secondaryTitleStyle = {
  fontWeight: '400',
  fontFamily: 'Manrope',
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: 0,
  marginBottom: 0,
}
