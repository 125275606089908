/**
 *
 * WrittenWorkType
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type {
  ComponentDataType,
  ComponentPropsType,
  DeliveryMaterialsItemType,
} from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import DeliveryItemWritten from 'app/containers/DeliveryItemWritten/DeliveryItemWritten';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import Textarea from 'app/components/Textarea/Textarea';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const WrittenWorkType: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    writtenWorkType = '',
    otherWrittenWorkType = '',
    writtenShortDescription = '',
    specifications = '',
    deliveryMaterials = [],
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const {
    writtenWorkTypes = [],
    numberOfDrafts = [],
    writtenLabel = 'Written Work Type',
    specifyLabel = 'Specify',
    shortDescLabel = 'Short description',
    specificationLabel = 'Further specification, description & delivery materials',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      writtenWorkType: '',
      otherWrittenWorkType: '',
      writtenShortDescription: '',
      specifications: '',
      deliveryMaterials: [{ date: {}, description: '' }],
    },
  });

  const onUpdateData = (
    fieldName: string,
    fieldValue: string | boolean | Array<DeliveryMaterialsItemType>
  ) => onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Select
          id="writtenWorkType"
          label={writtenLabel}
          value={writtenWorkType}
          onSelectOption={(item) => {
            !Array.isArray(item) && onUpdateData('writtenWorkType', item.value);
          }}
          options={writtenWorkTypes.map((item) => ({
            value: item,
            label: item,
          }))}
        />
      </Grid>

      {writtenWorkType === 'Other (specify)' && (
        <Grid item xs={12}>
          <TextField
            id="otherWrittenWorkType"
            label={specifyLabel}
            value={otherWrittenWorkType}
            onBlur={onUpdateData}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Textarea
          id="writtenShortDescription"
          label={shortDescLabel}
          value={writtenShortDescription}
          multiline
          maxRows={5}
          minRows={2}
          onBlur={onUpdateData}
        />
      </Grid>

      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Textarea
              id="specifications"
              label={specificationLabel}
              value={specifications}
              multiline
              maxRows={5}
              minRows={2}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <DeliveryItemWritten
              updateDeliveryMaterialsArray={(items) =>
                onUpdateData('deliveryMaterials', items)
              }
              deliveryMaterials={deliveryMaterials}
              numberOfDrafts={numberOfDrafts}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(WrittenWorkType): AbstractComponent<PropsType, mixed>);
