/**
 *
 * @format
 * @flow
 *
 */

import type { ContractingParty, DataLookupType, Supplier } from './types';

import { isNil } from 'ramda';

/**
 * Adds a new supplier to the end of the supplier array and a reference into
 * the lookup array
 */
const addSupplier = (
  lookup: Array<DataLookupType>,
  suppliers: Array<Supplier>
) => {
  const newSuppliers = [...suppliers, { description: '' }];
  const newLookup = [...lookup, { supplierIdx: newSuppliers.length - 1 }];
  return { suppliers: newSuppliers, lookup: newLookup };
};

/**
 * Given an index that has been removed from either the contractingParties or
 * suppliers arrays, this iterates over the lookupArray and adjusts the index
 * values to account for the removed index
 */
const updateLookupIndexes = (
  contractingPartyIndexRemoved?: number,
  supplierIndexRemoved?: number,
  lookupArray: Array<DataLookupType>
) => {
  for (let index = 0; index < lookupArray.length; index++) {
    const lookup = lookupArray[index];
    if (
      !isNil(lookup.contractingPartiesIdx) &&
      !isNil(contractingPartyIndexRemoved) &&
      lookup.contractingPartiesIdx > contractingPartyIndexRemoved
    ) {
      lookup.contractingPartiesIdx -= 1;
    }

    if (
      !isNil(lookup.supplierIdx) &&
      !isNil(supplierIndexRemoved) &&
      lookup.supplierIdx > supplierIndexRemoved
    ) {
      lookup.supplierIdx -= 1;
    }
  }
};

/**
 * Returns the adjusted lookup, suppliers, and contractingParties arrays
 * after removing the lookup item at the given index. i.e. it removes the
 * item and also removes the data the item references in either the suppliers
 * or contractingParties arrays then updates their indexes to account for the
 * removed index.
 */
const removeLookupItem = (
  index: number,
  lookup: Array<DataLookupType>,
  contractingParties: Array<ContractingParty>,
  suppliers: Array<Supplier>
) => {
  const lookupCpy = window.structuredClone(lookup);
  const lookupItem = lookupCpy[index];
  const suppliersCpy = window.structuredClone(suppliers);
  const contractingPartiesCpy = window.structuredClone(contractingParties);
  let newLookup;
  if (!isNil(lookupItem.supplierIdx)) {
    // remove item from suppliers array at index
    suppliersCpy.splice(lookupItem.supplierIdx, 1);
    newLookup = lookupCpy.filter((x) => x !== lookupItem);
    updateLookupIndexes(undefined, lookupItem.supplierIdx, newLookup);
  } else if (!isNil(lookupItem.contractingPartiesIdx)) {
    // remove item from contractingParties at index
    contractingPartiesCpy.splice(lookupItem.contractingPartiesIdx, 1);
    newLookup = lookupCpy.filter((x) => x !== lookupItem);
    updateLookupIndexes(lookupItem.contractingPartiesIdx, undefined, newLookup);
  } else {
    throw new Error(`Invalid reference in lookupItem at index ${index}`);
  }

  return {
    lookup: newLookup,
    suppliers: suppliersCpy,
    contractingParties: contractingPartiesCpy,
  };
};

/**
 * Changes the data the lookup item at the given index references. It does this
 * by deleting the data that is referenced (in either suppliers or contractingParties)
 * then creating new data in the other array and referencing that
 */
const changeLookupItemReference = (
  index: number,
  lookup: Array<DataLookupType>,
  contractingParties: Array<ContractingParty>,
  suppliers: Array<Supplier>,
  val: string
) => {
  const lookupCpy = window.structuredClone(lookup);
  const suppliersCpy = window.structuredClone(suppliers);
  const contractingPartiesCpy = window.structuredClone(contractingParties);
  const lookupItem = lookupCpy[index];
  let returnObj = {
    lookup: lookupCpy,
    suppliers: suppliersCpy,
    contractingParties: contractingPartiesCpy,
  };
  if (
    (val === 'supplyDirectly' && !isNil(lookupItem.supplierIdx)) ||
    (val === 'contractingParty' && !isNil(lookupItem.contractingPartiesIdx))
  ) {
    return returnObj;
  }

  if (val === 'supplyDirectly') {
    contractingPartiesCpy.splice(lookupItem.contractingPartiesIdx, 1);
    suppliersCpy.push({ description: '' });
    lookupItem.supplierIdx = suppliersCpy.length - 1;
    updateLookupIndexes(lookupItem.contractingPartiesIdx, undefined, lookupCpy);
    delete lookupItem.contractingPartiesIdx;
  } else if (val === 'contractingParty') {
    suppliersCpy.splice(lookupItem.supplierIdx, 1);
    contractingPartiesCpy.push({ amount: '', description: '' });
    lookupItem.contractingPartiesIdx = contractingPartiesCpy.length - 1;
    updateLookupIndexes(undefined, lookupItem.supplierIdx, lookupCpy);
    delete lookupItem.supplierIdx;
  } else {
    throw new Error(`Invalid reference in lookupItem at index ${index}`);
  }

  return {
    lookup: lookupCpy,
    suppliers: suppliersCpy,
    contractingParties: contractingPartiesCpy,
  };
};

export { addSupplier, removeLookupItem, changeLookupItemReference };
