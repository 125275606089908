/**
 *
 * ProjectDetails
 * @format
 * @flow
 *
 */

import { useProjectDetailsQuery } from 'project/graphQL/useProjectDetailsQuery';
import type { Node } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import Creative from './Creative/Creative';
import ProductionSchedule from './ProductionSchedule/ProductionSchedule';
import Spv from './Spv/Spv';

const ProjectDetails = (): Node => {
  const routeParams = useParams();
  const ptid = routeParams.ptid || '';
  const { data, loading, error } = useProjectDetailsQuery({
    _id: ptid,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data?.project) return <p>Not found</p>;

  const { project: projectData } = data;
  const { schedule, companySPVs, ...restProjectDetails } = projectData ?? {};

  return (
    <>
      <Creative
        // $FlowFixMe
        projectDetails={restProjectDetails || {}}
      />

      <ProductionSchedule
        // $FlowFixMe
        scheduleDetails={schedule || {}}
      />
      <Spv
        // $FlowFixMe
        spvs={companySPVs || []}
      />
    </>
  );
};

export default ProjectDetails;
