import { alpha, styled } from '@mui/material/styles';

import Menu from 'app/components/core/Menu/Menu';

export const SearchInput = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(1),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(9),
  },
}));

export const SearchIconOnly = styled('div')(({ theme }) => ({
  fontSize: '1em',
  color: 'white',
  [theme.breakpoints.up('md')]: { display: 'none' },
}));

export const ProfileMenuContainer = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '400px',
    padding: '10px',
    height: '250px'
  },
}));
