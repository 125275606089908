/**
 *
 * OptionalMilestone
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Dialog,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';

import Checkbox from 'app/components/Checkbox/Checkbox';
import type { PropsType } from './types';

const OptionalMilestone = (props: PropsType): Node => {
  const {
    optionalTitle = 'Optional Milestones',
    onUpdate,
    onDialogClose,
    dialogOpen = false,
    allowedPropNames,
    optionalMilestones,
    isCustom,
    calculateTotals,
  } = props;

  const {
    generalMilestonesNames = [],
    customMilestonesNames = [],
    dateMilestonesNames = [],
  } = allowedPropNames || {};

  const isItemChecked = (id, dataArray) => {
    const milestones = Array.isArray(dataArray)
      ? dataArray
      : [...dataArray?.generalMilestones];
    const checkItem = milestones?.some(
      (item, i) =>
        item.name.toLowerCase() === id.toLowerCase() ||
        item.id?.toLowerCase() === id.toLowerCase()
    );
    return checkItem;
  };

  const milestonesCheck = (
    id,
    nameIndex,
    checked,
    milestonesArr,
    fieldName,
    milestonesNameArr
  ) => {
    let updatedMilestones = [...milestonesArr];
    const isCustomMilestone = !generalMilestonesNames.includes(id);
    const milestone = {
      name: !isCustomMilestone ? id : '',
      percentage: 0,
      amount: 0,
      ...(isCustomMilestone ? { id } : {}),
    };

    const type = id === 'Specify Own' ? 'text' : 'date';

    if (isCustomMilestone) {
      milestone.isCustomMilestone = isCustomMilestone;
      milestone.type = type;
      milestone.customIndex = 0;
    }

    const isNewMilestoneExists = milestonesArr.find(
      (milestone) => milestone.name === id
    );

    if (checked && !isNewMilestoneExists) {
      updatedMilestones.push(milestone);
    } else {
      // for custom milestones, when unchecking, remove all custom milestones of the same type
      updatedMilestones = updatedMilestones.filter((milestone) =>
        isCustomMilestone
          ? milestone.type !== type
          : milestone.name.toLowerCase() !== id?.toLowerCase()
      );
    }

    const customMilestones = updatedMilestones.filter(
      (item) => item.isCustomMilestone
    );

    const sortUpdatedMilestones = milestonesNameArr.reduce((acc, curr) => {
      const isMilestoneExists = updatedMilestones.find(
        (milestone) => milestone.name === curr
      );
      if (isMilestoneExists) {
        acc.push(isMilestoneExists);
      }
      return acc;
    }, []);

    // Re-insert isCustomMilestone items at their original positions
    customMilestones.forEach((customMilestone) => {
      const index = updatedMilestones.findIndex((a) => a === customMilestone);
      if (index !== -1) {
        sortUpdatedMilestones.splice(index, 0, customMilestone);
      }
    });

    onUpdate(fieldName, sortUpdatedMilestones);
    calculateTotals(sortUpdatedMilestones);
  };

  const milestones = isCustom
    ? [...customMilestonesNames, ...dateMilestonesNames]
    : generalMilestonesNames;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Dialog
        onClose={onDialogClose}
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{optionalTitle}</DialogTitle>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <Grid container spacing={2} style={{ padding: '0 30px' }}>
              {milestones.map((milestoneName, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Checkbox
                      id={milestoneName}
                      label={milestoneName}
                      value={isItemChecked(milestoneName, optionalMilestones)}
                      onCheck={(id, checked) =>
                        milestonesCheck(
                          id,
                          i,
                          checked,
                          optionalMilestones,
                          'generalMilestones',
                          generalMilestonesNames
                        )
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              px={3}
              pt={2}
            >
              <Grid item>
                {isCustom && (
                  <Typography sx={{ fontStyle: 'italic' }}>
                    Add your own and drag to order
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Button onClick={onDialogClose}>OK</Button>
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </Dialog>
    </Grid>
  );
};

export default (memo(OptionalMilestone): AbstractComponent<PropsType, mixed>);
