import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DrawerHeader, Main } from './styles';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import InfoMenuList from './InfoMenuList';
import { useInfoPageQuery } from './graphQL/useInfoPageQuery';

const drawerWidth = 400;

export default function InfoPage() {
  const [open, setOpen] = React.useState(true);
  const { data: baInfoData } = useInfoPageQuery();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (width < 900) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const renderTraverseContent = (arrData = []) => {
    return (
      <>
        {arrData.map((heading) => (
          <div key={heading.slug} style={{ marginLeft: 30}}>
            <Typography variant="h4" id={heading.slug}>
              {heading.title}
            </Typography>
            {heading?.content && (
              <ReactMarkdown
                children={heading.content}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            )}
            {heading?.items?.length > 0 &&
              renderTraverseContent(heading?.items)}
          </div>
        ))}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography variant="h5" sx={{ marginLeft: 8, marginBottom: 0 }}>
              Table of Contents
            </Typography>
          <IconButton onClick={handleDrawer}>
            {open ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <InfoMenuList infoPageDataList={baInfoData?.infoPage || []} />
      </Drawer>
      <Main open={open}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{ mr: 2, top: 40, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        {renderTraverseContent(baInfoData?.infoPage || [])}
      </Main>
    </Box>
  );
}
