import { AddDetails, InfoFilled } from 'app/assets/icons';

import { styled } from '@mui/material/styles';

export const InfoFilledICon = styled(InfoFilled)(({ theme }) => ({
  '& path': {
    fill: theme.palette.grey['750'],
  },
}));

export const AddDetailsIcon = styled(AddDetails)(({ theme }) => ({
  '& path': {
    fill: theme.palette.grey['750'],
    cursor: 'pointer',
  },
}));
