/**
 *
 * AccountSetupStepOne
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';

const AccountSetupStepOne: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const { givenName, familyName, handleStateChange } = props;

    return (
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="body">
            Add your name (so you are the default admin user and control the
            account)
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="givenName"
            label="Given Name"
            value={givenName}
            onBlur={handleStateChange}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="familyName"
            label="Family Name"
            value={familyName}
            onBlur={handleStateChange}
          />
        </Grid>
      </Grid>
    );
  }
);
export default AccountSetupStepOne;
