/**
 *
 * ProductionSchedule
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';
// $FlowFixMe
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';

const ProductionSchedule: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const theme = useTheme();
  const { scheduleDetails } = props;

  const {
    preProductionStart,
    preProductionEnd,
    productionStart,
    productionEnd,
    postProductionStart,
    postProductionEnd,
    roughCutDelivery,
    fineCutDelivery,
    finalMaterialsDeliveryStart,
    finalMaterialsDeliveryEnd,
  } = scheduleDetails || {};

  const formatDate = (date) => format(new Date(date), 'dd MMM yyyy');

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        sx={{ marginBottom: '16px' }}
      >
        <Typography variant="h2" color={theme.palette.grey['800']}>
          Production Schedule
        </Typography>
        <></>
      </Grid>
      <Paper sx={{ width: '100%', marginBottom: '32px' }} elevation={0}>
        <Grid container gap="24px">
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Pre-Production
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {`${
                  preProductionStart ? formatDate(preProductionStart) : '__'
                } — ${preProductionEnd ? formatDate(preProductionEnd) : '__'}`}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Production
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {`${productionStart ? formatDate(productionStart) : '__'} — ${
                  productionEnd ? formatDate(productionEnd) : '__'
                }`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Post Production
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {`${
                  postProductionStart ? formatDate(postProductionStart) : '__'
                } — ${
                  postProductionEnd ? formatDate(postProductionEnd) : '__'
                }`}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Delivery (start)
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {`${
                  finalMaterialsDeliveryStart
                    ? formatDate(finalMaterialsDeliveryStart)
                    : '__'
                }`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Rough cut delivery
              </Typography>
              {roughCutDelivery?.length > 0 ? (
                <>
                  {roughCutDelivery?.map((item) => {
                    const { date: roughCutDeliveryDate } = item || {};
                    return (
                      <Typography
                        key={roughCutDeliveryDate}
                        variant="body"
                        color={theme.palette.grey['800']}
                        sx={{ fontWeight: '600' }}
                      >
                        {`${
                          roughCutDeliveryDate
                            ? formatDate(roughCutDeliveryDate)
                            : '__'
                        }`}
                      </Typography>
                    );
                  })}
                </>
              ) : (
                <Typography
                  variant="body"
                  color={theme.palette.grey['800']}
                  sx={{ fontWeight: '600' }}
                >
                  __
                </Typography>
              )}
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Fine cut delivery
              </Typography>
              {fineCutDelivery?.length > 0 ? (
                <>
                  {fineCutDelivery?.map((item) => {
                    const { date: fineCutDeliveryDate } = item || {};
                    return (
                      <Typography
                        key={fineCutDeliveryDate}
                        variant="body"
                        color={theme.palette.grey['800']}
                        sx={{ fontWeight: '600' }}
                      >
                        {`${
                          fineCutDeliveryDate
                            ? formatDate(fineCutDeliveryDate)
                            : '__'
                        }`}
                      </Typography>
                    );
                  })}
                </>
              ) : (
                <Typography
                  variant="body"
                  color={theme.palette.grey['800']}
                  sx={{ fontWeight: '600' }}
                >
                  __
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Delivery (end)
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {`${
                  finalMaterialsDeliveryEnd
                    ? formatDate(finalMaterialsDeliveryEnd)
                    : '__'
                }`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default (memo(ProductionSchedule): AbstractComponent<PropsType, mixed>);
