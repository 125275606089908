import { gql } from '@apollo/client';

export const documentDetailsFragment = gql`
  fragment documentDetailsFragment on Document {
    company
    contract
    project
    tags
    fileName
    fileType
    mimeType
    uploadedBy
    whoDownloaded {
      downloadedBy
      downloadedDate
    }
    notes
    fileData {
      downloadUrl
    }
    createdAt
    updatedAt
  }
`;
