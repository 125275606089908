/**
 *
 * AddressInfo
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { Grid } from '@mui/material';
import TextField from 'app/components/TextField/TextField';
import type { PropsType } from './types';

const AddressInfo: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const {
      street = '',
      suburb = '',
      state = '',
      postcode = '',
      country = '',
      currFieldIndex = 0,
      onUpdateData,
    } = props;

    return (
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="street"
            label="Street"
            value={street || ''}
            autoComplete="none"
            onBlur={(id, val) => {
              onUpdateData(id, val, currFieldIndex);
            }}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="suburb"
            label="City / Suburb"
            value={suburb || ''}
            autoComplete="none"
            onBlur={(id, val) => {
              onUpdateData(id, val, currFieldIndex);
            }}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="state"
            label="State"
            value={state || ''}
            autoComplete="none"
            onBlur={(id, val) => {
              onUpdateData(id, val, currFieldIndex);
            }}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="postcode"
            label="Postcode"
            value={postcode || ''}
            autoComplete="none"
            onBlur={(id, val) => {
              onUpdateData(id, val, currFieldIndex);
            }}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="country"
            label="Country"
            value={country || ''}
            autoComplete="none"
            onBlur={(id, val) => {
              onUpdateData(id, val, currFieldIndex);
            }}
          />
        </Grid>
      </Grid>
    );
  }
);
export default AddressInfo;
