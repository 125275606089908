import { gql } from '@apollo/client';

export default gql`
  fragment contractStandardTermSections on Contract {
    standardTermSections {
      title
      terms {
        name
        amendment
        clauseParagraphs {
          text
          isList
        }
        references {
          termId
          termDataIndex
          operator
          conditionalValue
        }
      }
    }
  }
`;
//TODO: Need to add detail, stage and status - this also needs adding to the API!
