import { gql } from '@apollo/client';

export const userDetailsFragment = gql`
  fragment userDetails on User {
    _id
    emails
    givenName
    familyName
    selectedCompany
    roles {
      _id
      name
      rules {
        action
        subject
        conditions
        fields
        inverted
      }
    }
    rules {
      action
      subject
      conditions
      fields
      inverted
    }
    companies {
      _id
      name
      type
    }
  }
`;
