/**
 *
 * PayTerms
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PayTerms: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const { payTerms = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      payTerms: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="body2">Within</Typography>
      </Grid>

      <Grid item xs={7}>
        <TextField
          id="payTerms"
          label="Payment terms"
          value={payTerms}
          onBlur={onUpdateData}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">days of above milestones</Typography>
      </Grid>
    </Grid>
  );
};

export default (memo(PayTerms): AbstractComponent<PropsType, mixed>);
