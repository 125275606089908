/**
 *
 * CrewRate
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useCallback, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import { Grid } from '@mui/material';
import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import Select from 'app/components/Select/Select';
import TextGeneratingRadioButtonGroup from 'app/components/TextGeneratingRadioButtonGroup/TextGeneratingRadioButtonGroup';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import isEqual from 'lodash/isEqual';
import type { PropsType } from '../types';

const CrewRate: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const radioButton1Text = 'Rate';
  const radioButton2Text = 'Flat Fee';

  const {
    radioLabel1 = radioButton1Text,
    radioValue1 = radioButton1Text,
    textGenerating1 = radioButton1Text,
    radioLabel2 = radioButton2Text,
    radioValue2 = radioButton2Text,
    textGenerating2 = radioButton2Text,
    rateOptions = [],
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    radioValue = '',
    textValue1 = '',
    textValue2,
    pay = '',
    flatFeePerPeriod = '',
    ratePerPeriod = '',
    baseHourlyRate = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      radioValue: radioValue1,
      textValue1: textGenerating1,
      textValue2: '',
      pay: '',
      flatFeePerPeriod: '',
      ratePerPeriod: '',
      baseHourlyRate: '',
    },
  });

  const checkRadio2DataIsEmpty = () => {
    const { flatFeePerPeriod } = contractTermData[contractTermKeyName] || {};
    const termData = { flatFeePerPeriod };
    const termDefaultData = { flatFeePerPeriod: '' };
    return isEqual(termData, termDefaultData);
  };

  const checkRadio1DataIsEmpty = () => {
    const { baseHourlyRate, ratePerPeriod } =
      contractTermData[contractTermKeyName] || {};
    const termData = {
      baseHourlyRate,
      ratePerPeriod,
    };
    const termDefaultData = {
      baseHourlyRate: '',
      ratePerPeriod: '',
    };
    return isEqual(termData, termDefaultData);
  };

  const updateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CurrencyField
            id="pay"
            value={pay}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={updateData}
          />
        </Grid>
        <Grid item xs={12}>
          <TextGeneratingRadioButtonGroup
            radioValue={radioValue}
            radioValue1={radioValue1}
            textValue1={textValue1}
            radioLabel1={radioLabel1}
            textGenerating1={textGenerating1}
            radio1ResetData={{ flatFeePerPeriod: '' }}
            radioValue2={radioValue2}
            textValue2={textValue2}
            radioLabel2={radioLabel2}
            textGenerating2={textGenerating2}
            radio2ResetData={{
              ratePerPeriod: '',
              baseHourlyRate: '',
            }}
            checkRadio1DataIsEmpty={checkRadio1DataIsEmpty}
            checkRadio2DataIsEmpty={checkRadio2DataIsEmpty}
            onUpdate={onUpdateData}
            row={true}
          />
          {radioValue === 'Flat Fee' && (
            <Grid item xs={12} mt={3.5}>
              <Select
                id="flatFeePerPeriod"
                value={flatFeePerPeriod}
                onSelectOption={(item) => {
                  if (!Array.isArray(item)) {
                    updateData('flatFeePerPeriod', item.value);
                  }
                }}
                options={[
                  { value: 'per Week', label: 'per Week' },
                  { value: 'per Day', label: 'per Day' },
                ]}
              />
            </Grid>
          )}
          {radioValue === 'Rate' && (
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-end"
              direction="row"
              spacing={3}
            >
              <Grid item xs={7} mb={4}>
                <Select
                  id="ratePerPeriod"
                  value={ratePerPeriod}
                  onSelectOption={(item) => {
                    !Array.isArray(item) &&
                      updateData('ratePerPeriod', item.value);
                  }}
                  options={rateOptions || []}
                />
              </Grid>
              <Grid item xs={4} pb={2}>
                <CurrencyField
                  fullWidth
                  id="baseHourlyRate"
                  label="Base hourly rate"
                  value={baseHourlyRate}
                  formatMoney
                  numberOnly
                  startAdornment={<>$</>}
                  onBlur={updateData}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default (memo(CrewRate): AbstractComponent<PropsType, mixed>);
