/**
 *
 * ChangeDetailsDialog
 * @format
 * @flow
 *
 */

import React, { memo, useContext, useState } from 'react';
import type { AbstractComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import type { PropsType } from './types';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { LinkDataContext } from '../../contexts/LinkDataContext';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { format } from 'date-fns';

const ChangeDetailsDialog: AbstractComponent<PropsType> = () => {
  const {
    renderComponent,
    getLinkDataProps,
    isValidDate,
    getTermDataToUpdate,
    getMutationVariables,
    getMutationFunction,
  } = useLinkDataComponent();

  const {
    isOpenChangeDetailsDialog,
    onSetIsOpenChangeDetailsDialog,
    componentName: { contractTermKeyName },
    linkDataProps: { linkData },
    newFieldValue,
    fieldName,
    setNewFieldValue,
    additionalLinkDataPropsFilter,
  } = useContext(LinkDataContext);

  const [isLoading, setIsLoading] = useState(false);

  const { fieldLabel, fieldId, componentType, sourceFieldId, source } =
    getLinkDataProps(linkData, fieldName, additionalLinkDataPropsFilter);

  const { contractTermProps, onUpdateContractTermData, contractTermData } =
    useContext(ContractContext);
  const renderComponentProps = contractTermProps[contractTermKeyName];

  const termData = contractTermData[contractTermKeyName] || {};
  const termDataValue = termData[fieldId];
  const mutation = {
    mutationFunction: getMutationFunction(source),
    variables: getMutationVariables({
      source,
      fieldId,
      value: newFieldValue,
      sourceFieldId,
    }),
  };
  const handleOnSaveDetails = async () => {
    setIsLoading(true);

    mutation
      ?.mutationFunction({
        variables: {
          ...mutation?.variables,
        },
      })
      .then((res) => {
        setIsLoading(false);

        let fieldValue = newFieldValue;
        if (isValidDate(newFieldValue)) {
          fieldValue = format(new Date(newFieldValue), 'dd MMM yyyy');
        }

        onUpdateContractTermData(contractTermKeyName, {
          [fieldId]: getTermDataToUpdate(
            termDataValue,
            fieldName,
            fieldValue,
            additionalLinkDataPropsFilter
          ),
        });

        onSetIsOpenChangeDetailsDialog(false);
      });
  };

  return (
    <Dialog
      open={isOpenChangeDetailsDialog}
      onClose={() => {
        onSetIsOpenChangeDetailsDialog(false);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {`Change ${fieldLabel}`}
        </Typography>
        {renderComponent(componentType, {
          value: newFieldValue,
          onChange: (_id, val) => {
            setNewFieldValue(val);
          },
          ...renderComponentProps,
        })}
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center', gap: '8px' }}>
        <Button
          onClick={() => {
            onSetIsOpenChangeDetailsDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          loadingText="Saving..."
          defaultText="Save"
          onClick={handleOnSaveDetails}
          containerStyle={{
            marginTop: '0',
          }}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

export default (memo(ChangeDetailsDialog): AbstractComponent<PropsType, mixed>);
