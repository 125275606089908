/**
 *
 * AffectedContractsDialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { memo, useContext, useState } from 'react';

import Checkbox from 'app/components/Checkbox/Checkbox';
import { LinkDataContext } from '../../contexts/LinkDataContext';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import { Typography } from '@mui/material';

const AffectedContractsDialog = ({ value }): Node => {
  const {
    isOpenAffectedContractsDialog,
    onSetIsOpenAffectedContractsDialog,
    updateAll,
    indeterminate,
    onSelectAllAffectedContracts,
    allAffectedContractsData,
    selectedAffectedContracts,
    onSelectAffectedContracts,
    mutationHook,
    onReset,
  } = useContext(LinkDataContext);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={isOpenAffectedContractsDialog}
      onClose={() => {
        onSetIsOpenAffectedContractsDialog(false);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Update contracts with this detail now?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          List of contracts where project details is different (variance list):
        </Typography>
        <Checkbox
          id="updateAll"
          label="Update all"
          value={updateAll}
          indeterminate={indeterminate}
          onCheck={(_, val) => {
            onSelectAllAffectedContracts(val, allAffectedContractsData);
          }}
        />
        {allAffectedContractsData?.data?.map((contract) => {
          return (
            <Checkbox
              key={contract._id}
              id="contractIds"
              label={contract.title}
              value={selectedAffectedContracts?.includes(contract._id) || false}
              onCheck={(_, val) => {
                onSelectAffectedContracts(contract._id, val);
              }}
            />
          );
        })}
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center', gap: '8px' }}>
        <Button
          onClick={() => {
            onSetIsOpenAffectedContractsDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          loadingText="Updating..."
          defaultText="Update"
          onClick={() => {
            setIsLoading(true);
            mutationHook
              ?.mutateFunc({
                variables: {
                  input: {
                    ...mutationHook?.variables,
                  },
                },
              })
              .then((res) => {
                setIsLoading(false);
                if (res) {
                  onSetIsOpenAffectedContractsDialog(false);
                }
                onReset();
              });
          }}
          containerStyle={{
            marginTop: '0',
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default (memo(AffectedContractsDialog): AbstractComponent<mixed>);
