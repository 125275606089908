/**
 *
 * useProjectsQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import type { GetMyProjectsSummaryQuery } from 'app/services/graphQL/generated-types';
import { projectSummaryFragment } from 'project/graphQL/fragments/projectFragments';

const PROJECTS = gql`
  query GetMyProjectsSummary {
    projects {
      ...projectSummary
    }
  }
  ${projectSummaryFragment}
`;

export type ProjectsViewProjectsReturnType = {
  loading: boolean,
  data: GetMyProjectsSummaryQuery,
  error: any,
  refetch: () => void,
};

export const useProjectsViewProjectsQuery =
  (): ProjectsViewProjectsReturnType => {
    const { loading, data, error, refetch } =
      useQuery<GetMyProjectsSummaryQuery>(PROJECTS);

    return {
      loading,
      data,
      error,
      refetch,
    };
  };

export type ProjectsViewProjectsLazyReturnType = [
  any,
  { loading: boolean, data: GetMyProjectsSummaryQuery, error: any }
];

export const useProjectsViewProjectsLazyQuery =
  (): ProjectsViewProjectsLazyReturnType => {
    const query = useLazyQuery<ProjectsViewProjectsLazyReturnType>(PROJECTS);

    return query;
  };
