/**
 *
 * StandardTermInfo
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const StandardTermInfo: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData } = useContext(ContractContext);

  const { role = '', title = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      role: '',
      title: '',
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body1">{`${title || ''}: ${
              role || ''
            }`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(StandardTermInfo): AbstractComponent<PropsType, mixed>);
