export const disableAuthentication =
  'REACT_APP_DISABLE_AUTHENTICATION' in process.env
    ? !!parseInt(process.env.REACT_APP_DISABLE_AUTHENTICATION, 10)
    : false;

export const enableSentryDebug =
  'REACT_APP_SENTRY_CLIENT_DEBUG' in process.env
    ? !!parseInt(process.env.REACT_APP_SENTRY_CLIENT_DEBUG, 10)
    : false;

// Set to the ory URL Deafults to http://localhost:3001, in which case you need to be running the Ory Tunnel
// https://www.ory.sh/docs/guides/cli/proxy-and-tunnel#ory-tunnel on http://localhost:3001
// e.g. ory tunnel http://localhost:3000 --dev --port 3001
export const oryUrl =
  'REACT_APP_ORY_URL' in process.env
    ? process.env.REACT_APP_ORY_URL
    : 'http://localhost:3001';
