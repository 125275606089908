import { gql } from '@apollo/client';

export const companyDetailsFragment = gql`
  fragment companyDetails on Company {
    _id
    owner
    email
    name
    shortCompanyName
    abn
    acn
    gst
    phone
    jurisdiction
    invoicePaymentTerms
    periodicPaymentTerms
    periodicTerm
    accountingPeriod
    type
    address {
      number
      street
      suburb
      state
      postcode
      country
    }
    contractingParties {
      email
      name
      type
      abn
      acn
      address {
        number
        street
        suburb
        state
        postcode
        country
      }
    }
  }
`;
