import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 15px;
  }
`;

export const Body = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
  }
`;
