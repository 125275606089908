/**
 *
 * useDocumentsQuery
 * @format
 * @flow
 *
 */

import type {
  GetAllDocumentsQuery,
  GetAllDocumentsQueryVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useQuery } from '@apollo/client';

import { documentDetailsFragment } from 'project/graphQL/fragments/documentFragments';

export const DOCUMENTS = gql`
  query GetAllDocuments($company: ObjectID!) {
    documents(company: $company) {
      _id
      ...documentDetailsFragment
    }
  }
  ${documentDetailsFragment}
`;

export type DocumentsReturnType = {
  loading: boolean,
  data: GetAllDocumentsQuery,
  error: any,
  refetch: () => void,
};

export const useDocumentsQuery = ({
  company,
}: GetAllDocumentsQueryVariables): DocumentsReturnType => {
  const { loading, data, error, refetch } = useQuery<GetAllDocumentsQuery>(
    DOCUMENTS,
    {
      variables: { company },
    }
  );

  return {
    loading,
    data,
    error,
    refetch,
  };
};
