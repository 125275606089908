/**
 *
 * CastFilmRateWeekly
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const CastFilmRateWeekly = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    basicFeeTitle = 'Basic Fee',
    residualTitle = 'Pre-Paid Residuals ',
    percentageResidualLabel = '% of Basic Fee',
    amountResidualLabel = 'Pre-Paid Residuals Amount',
    subTotalLabel = 'Sub-Total',
    overtimeTitle = 'Overtime Pre-Paid',
    hoursWeekLabel = 'Hours per Week',
    penaltyLabel = 'Penalty',
    overTimeLabel = 'Overtime Amount',
    totalFeeTitle = 'Total weekly Fee',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    basicFee = '',
    percentageResidual = '',
    amountResidual = '',
    subTotal = '',
    hoursWeek = '',
    penalty = '',
    overTime = '',
    totalFee = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      basicFee: '',
      percentageResidual: '',
      amountResidual: '',
      subTotal: '',
      hoursWeek: '',
      penalty: '',
      overTime: '',
      totalFee: '',
    },
  });

  const updateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">{basicFeeTitle}</Typography>
      </Grid>
      <br />
      <Grid item xs={3}>
        <TextField
          id="basicFee"
          value={basicFee}
          formatMoney
          numberOnly
          startAdornment={<>$</>}
          onBlur={updateData}
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography variant="body1">{residualTitle}</Typography>
      </Grid>
      <br />
      <Grid item xs={1.5}>
        <TextField
          id="percentageResidual"
          label={percentageResidualLabel}
          value={percentageResidual}
          numberOnly
          endAdornment={<>%</>}
          onBlur={updateData}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="amountResidual"
          label={amountResidualLabel}
          value={amountResidual}
          formatMoney
          numberOnly
          startAdornment={<>$</>}
          onBlur={updateData}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="subTotal"
          label={subTotalLabel}
          value={subTotal}
          formatMoney
          numberOnly
          startAdornment={<>$</>}
          onBlur={updateData}
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography variant="body1">{overtimeTitle}</Typography>
      </Grid>
      <br />
      <Grid item xs={1.5}>
        <TextField
          id="hoursWeek"
          label={hoursWeekLabel}
          value={hoursWeek}
          numberOnly
          onBlur={updateData}
        />
      </Grid>
      <Grid item xs={1.5}>
        <TextField
          id="penalty"
          label={penaltyLabel}
          value={penalty}
          numberOnly
          onBlur={updateData}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="overTime"
          label={overTimeLabel}
          value={overTime}
          formatMoney
          numberOnly
          startAdornment={<>$</>}
          onBlur={updateData}
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        <Typography variant="body1">{totalFeeTitle}</Typography>
      </Grid>
      <br />
      <Grid item xs={3}>
        <TextField
          id="totalFee"
          value={totalFee}
          formatMoney
          numberOnly
          startAdornment={<>$</>}
          onBlur={updateData}
        />
      </Grid>
    </>
  );
};

export default (memo(CastFilmRateWeekly): AbstractComponent<PropsType, mixed>);
