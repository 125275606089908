/**
 *
 * ScheduleItem
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

import DatePicker from 'app/components/DatePicker/DatePicker';
import type { PropsType } from './types';
// $FlowFixMe
import { format } from 'date-fns';
import { formatDate } from 'app/utils/general';

const ScheduleItem: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    label,
    startDateId,
    startDateVal,
    endDateId,
    endDateVal,
    handleUpdateData,
    containerStyle,
  } = props;

  const formattedStartDate = formatDate(startDateVal);
  const formattedEndDate = formatDate(endDateVal);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={containerStyle}
    >
      <Grid item xs={6}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={3}>
        {startDateId && (
          <DatePicker
            id={startDateId}
            label=""
            inputFormat={'dd MMM yyyy'}
            value={formattedStartDate}
            disableMaskedInput
            hidePlaceHolder
            onValueChange={(id, val) => {
              handleUpdateData(id, format(new Date(val), 'dd MMM yyyy'));
            }}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {endDateId && (
          <DatePicker
            id={endDateId}
            label=""
            inputFormat={'dd MMM yyyy'}
            value={formattedEndDate}
            disableMaskedInput
            hidePlaceHolder
            onValueChange={(id, val) => {
              handleUpdateData(id, format(new Date(val), 'dd MMM yyyy'));
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default (memo(ScheduleItem): AbstractComponent<PropsType, mixed>);
