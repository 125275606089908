/**
 *
 * ContractTemplatesView
 * @format
 * @flow
 *
 */

import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import type { AbstractComponent } from 'react';
import ContractTemplateCard from 'contract/ContractTemplatesView/ContractTemplateCard/ContractTemplateCard';
import type { ContractTemplatesReturnType } from 'contract/graphQL/useContractTemplatesQuery';
import type { CreateContractFromTemplateReturnType } from 'contract/graphQL/useCreateContractFromTemplateMutation';
import { Grid } from '@mui/material';
import type { PropsType } from './types';
import { Search } from 'app/assets/icons';
import SelectProjectModal from 'project/SelectProjectModal/SelectProjectModal';
import TextField from 'app/components/TextField/TextField';
import Typography from '@mui/material/Typography';
import { useContractTemplatesQuery } from 'contract/graphQL/useContractTemplatesQuery';
import { useCreateContractFromTemplateMutation } from 'contract/graphQL/useCreateContractFromTemplateMutation';
import { useTheme } from '@mui/material/styles';

const ContractTemplatesView: AbstractComponent<PropsType> = () => {
  const theme = useTheme();
  const history = useHistory();
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const { loading, error, data }: ContractTemplatesReturnType =
    useContractTemplatesQuery();
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const {
    createContractFromTemplate,
    // eslint-disable-next-line no-unused-vars
    loading: createContractFromTemplateLoading,
    // eslint-disable-next-line no-unused-vars
    error: createContractFromTemplateError,
  }: CreateContractFromTemplateReturnType = useCreateContractFromTemplateMutation();
  const [searchTemplateName, setSearchTemplateName] = useState('');

  const [isSelectProjectModalOpen, setIsSelectProjectModalOpen] =
    useState(false);

  const onContractSelected = useCallback(
    //TODO: need to also send the project id
    (templateId: string) => {
      setIsSelectProjectModalOpen(true);
      setSelectedTemplateId(templateId);
    },
    []
  );

  const onSelectProject = (projectId: string) => {
    createContractFromTemplate({
      variables: { input: { templateId: selectedTemplateId, projectId } },
    })?.then(({ data }) => {
      const ctid =
        // $FlowFixMe
        data?.createContractFromTemplate?.contract?._id || '';
      history.push({
        pathname: `/dashboard/${cyid}/project/${projectId}/contract/${ctid}`,
        state: {
          isEditMode: true,
        },
      });
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  const { contractTemplates } = data;

  // TODO: implement this later
  // const templatesChip = [
  //   'All',
  //   'Favourites',
  //   'Crew',
  //   'Writers',
  //   'Screenplay',
  //   'Rights Agreement',
  //   'Editing',
  //   'Marketing',
  // ];

  return (
    <Grid container rowSpacing={4}>
      <Grid item container justifyContent="space-between">
        <Grid>
          <Typography variant="h1">Templates</Typography>
        </Grid>
        <Grid sx={{ width: '272px' }}>
          <TextField
            id="search"
            placeholder="Search templates"
            startAdornment={<Search sx={{
              fontSize: 24,
              '& path': {
                stroke: theme.palette.grey['600'],
              },
            }} />}
            value={searchTemplateName}
            onValueChange={(id, val) => {
              setSearchTemplateName(val);
            }}
          />
        </Grid>
      </Grid>
      {/* TODO: implement this later */}
      {/* <Grid item>
        {templatesChip.map((item) => (
          <Chip key={item} label={item} sx={{
    borderRadius: '24px',
    marginRight: '8px',
    background: theme.palette.background.paper,
    color: theme.palette.grey['800'],
    '& span': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
    },
  }} />
        ))}
      </Grid> */}
      <Grid item container spacing={2}>
        {contractTemplates
          .filter((item) =>
            item?.title
              ?.toLocaleLowerCase()
              .includes(searchTemplateName?.toLocaleLowerCase())
          )
          .map((contractTemplate, i) => {
            return (
              <Grid item xs={4} md={4} lg={4} key={contractTemplate?._id}>
                <ContractTemplateCard
                  id={contractTemplate?._id || ''}
                  title={contractTemplate?.title || ''}
                  shortDescription={
                    contractTemplate?.info?.shortDescription || ''
                  }
                  fullDescription={
                    contractTemplate?.info?.fullDescription || ''
                  }
                  useFor={contractTemplate?.info?.useFor || []}
                  dontUseFor={contractTemplate?.info?.dontUseFor || []}
                  alternatives={contractTemplate?.info?.alternatives || ''}
                  usingThisTemplate={
                    contractTemplate?.info?.usingThisTemplate || ''
                  }
                  baInfoLinks={contractTemplate?.info?.baInfoLinks || []}
                  onContractSelected={onContractSelected}
                />
              </Grid>
            );
          })}
      </Grid>
      <SelectProjectModal
        open={isSelectProjectModalOpen}
        onClose={setIsSelectProjectModalOpen}
        onSelectProject={onSelectProject}
        createContractFromTemplateLoading={createContractFromTemplateLoading}
      />
    </Grid>
  );
};

export default ContractTemplatesView;
