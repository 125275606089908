import { styled } from '@mui/material/styles'

export const AddProjectIconSvg = styled('div')(({ theme }) => ({
  fontSize: 24,
  '& path': {
    stroke: theme.palette.primary.main,
  },
}));

export const AddContractIconSvg = styled('div')(({ theme }) => ({
  fontSize: 24,
  '& path': {
    stroke: theme.palette.blue.main,
  },
}));
