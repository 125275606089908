import {
  FormControl as MUIFormControl,
  Select as MUISelect,
} from '@mui/material';
import { styled } from '@mui/system';

export const FormControl = styled(MUIFormControl)`
  && {
    width: 100%;
    .MuiFormHelperText-root.Mui-error {
      text-align: right;
      color: red;
      margin-left: 0px;
      margin-right: 0px;
    }
    .expandMore {
      top: calc(50% - 12px);
      right: 10px;
      position: absolute;
      pointer-events: none;
    }
  }
`;

export const Select = styled(MUISelect)`
  && {
    width: 100%;
    .MuiSelect-root {
      border-bottom-width: 0px;
    }
  }
`;

export const SelectContainer = styled('div')`
  && {
    display: flex;
    align-items: center;
  }
`;
