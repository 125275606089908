import { gql } from '@apollo/client';
import { userInfoFragment } from './fragments';

export const GET_USER_DATA = gql`
  query User {
    user {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;
