/**
 *
 * DragDropContainer
 * @format
 * @flow
 *
 */
import React, { memo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DraggableItemContainer } from './styles';

const DragDropContainer = ({ items, onDragEnd }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={`${item.description}-${index}`}
                draggableId={`${item.description}-${index}`}
                index={index}
                isDragDisabled={item.isDragDisabled}
              >
                {(provided, snapshot) => (
                  <DraggableItemContainer
                    className="draggable-item-here"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {item.content}
                  </DraggableItemContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default (memo(DragDropContainer): AbstractComponent<mixed>);
