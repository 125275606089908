/**
 *
 * RadioButton
 * @format
 * @flow
 *
 */

import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { forwardRef, memo, useState } from 'react';
import { RadioButtonContainer } from './styles';
import type { PropsType } from './types';

const RadioButton = (props: PropsType, ref): Node => {
  const {
    id,
    label,
    value,
    checked,
    onValueChange,
    contentDialog,
    containerStyle,
    ...rest
  } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const onOpenDialog = () => setIsOpenDialog(true);

  const onCloseDialog = () => setIsOpenDialog(false);

  const renderDialog = () => {
    return (
      <Dialog open={isOpenDialog} onClose={onCloseDialog} maxWidth="md">
        <DialogTitle>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{contentDialog}</DialogContent>
      </Dialog>
    );
  };

  return (
    <RadioButtonContainer style={containerStyle}>
      {/* $FlowFixMe */}
      <FormControlLabel
        id={id}
        value={value}
        checked={checked}
        onChange={(e) => onValueChange && onValueChange(id, e.target.value)}
        control={<Radio />}
        label={
          <Typography variant="body1" color="primary">
            {label}
          </Typography>
        }
        onClick={() => {
          if (contentDialog) {
            onOpenDialog();
          }
        }}
        {...rest}
        ref={ref}
      />
      {contentDialog && renderDialog()}
    </RadioButtonContainer>
  );
};

// export default RadioButton;
export default (memo(forwardRef(RadioButton)): AbstractComponent<
  PropsType,
  mixed
>);
