/**
 *
 * OptionedWork
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import { Grid } from '@mui/material';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import PredictiveTextField from 'app/components/PredictiveTextField/PredictiveTextField';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import Textarea from 'app/components/Textarea/Textarea';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const OptionedWork = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermProps,
    contractTermData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    listSelect = '',
    name = '',
    shortDescription = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const { items = [] }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      listSelect: '',
      name: '',
      shortDescription: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12}>
        <TextField
          id="name"
          label="Title (name)"
          value={name}
          onBlur={onUpdateData}
        />
      </Grid>
      <Grid item xs={12}>
        <PredictiveTextField
          id="listSelect"
          label="Type"
          freeSolo
          options={items}
          inputValue={listSelect}
          onInputChange={(selected, newInputValue) =>
            onUpdateData('listSelect', newInputValue)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Textarea
          id="shortDescription"
          label="Short Description"
          maxRows={5}
          minRows={2}
          value={shortDescription}
          onBlur={onUpdateData}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(OptionedWork): AbstractComponent<PropsType, mixed>);
