/**
 *
 * LoadingButton
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { CircularProgressStyled, GridStyled } from './styles';
import React, { memo } from 'react';

import Button from 'app/components/core/Button/Button';
import type { PropsType } from './types';

const LoadingButton: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const {
    variant = 'primary',
    isLoading = false,
    disabled = false,
    loadingText = '',
    defaultText = '',
    onClick,
    containerStyle,
    buttonStyle,
    circularStyle,
    startIcon,
    ...rest
  } = props;

  return (
    <GridStyled {...rest} style={containerStyle}>
      <Button variant={variant} disabled={disabled} onClick={onClick} startIcon={startIcon}>
        {isLoading ? loadingText : defaultText}
      </Button>
      {isLoading && (
        <CircularProgressStyled size="1.5em" style={circularStyle} />
      )}
    </GridStyled>
  );
};

export default (memo(LoadingButton): AbstractComponent<PropsType, mixed>);
