/**
 *
 * Turnaround
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const Turnaround = ({ contractTermKeyName }: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const { numberOfPeriod = '', termsOfPeriod = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      numberOfPeriod: '',
      termsOfPeriod: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={4}>
            <TextField
              id="numberOfPeriod"
              label="Turnaround Period"
              value={numberOfPeriod}
              numberOnly
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              id="termsOfPeriod"
              value={termsOfPeriod}
              onSelectOption={(item) => {
                !Array.isArray(item) &&
                  onUpdateData('termsOfPeriod', item.value);
              }}
              options={[
                { value: 'months', label: 'months' },
                { value: 'years', label: 'years' },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default (memo(Turnaround): AbstractComponent<PropsType, mixed>);
