/**
 *
 * SuggestionsRepeating
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import { largestItemId } from 'app/utils/general.js';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const SuggestionsRepeating = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { suggestionValues = [] }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const { items = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      items: [{ itemId: '1', suggestion: '' }],
    },
  });

  const handleAddClick = () => {
    const itemId = parseInt(largestItemId(items), 10) + 1;
    const defaultDataElement = {
      itemId: itemId.toString(),
      suggestion: '',
    };
    onUpdate(contractTermKeyName, {
      items: [...items, defaultDataElement],
    });
  };

  const handleDelete = (index) => {
    // Filter out the removed item, then re-assign optionId numbers
    const newItems = items
      .filter((item, i) => i !== index)
      .sort((a, b) => parseInt(a.itemId, 10) - parseInt(b.itemId, 10))
      .map((item, index) => ({ ...item, itemId: (index + 1).toString() }));
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const handleChangeData = (fieldValue, fieldName, i) => {
    const newItems = [...items];
    const currItem = newItems[i];
    const newItem = {
      ...currItem,
      [fieldName]: fieldValue,
    };
    newItems[i] = newItem;
    onUpdate(contractTermKeyName, { items: newItems });
  };

  return (
    <Grid container>
      {items.map((item, i) => {
        const { suggestion } = item;
        return (
          <React.Fragment key={i}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={11}>
                <TextAreaSuggestions
                  id={'suggestion'}
                  label={`Item ${i + 1}`}
                  value={suggestion}
                  suggestions={suggestionValues || []}
                  onSubmitDescription={(id, val) => {
                    handleChangeData(val, id, i);
                  }}
                  onChangeTextarea={(id, val) => {
                    handleChangeData(val, id, i);
                  }}
                />
              </Grid>
              {i >= 1 && (
                <Grid item xs={1}>
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(i)}
                  ></Button>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12}>
        <Button
          onClick={() => handleAddClick()}
          startIcon={<AddIcon />}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default (memo(SuggestionsRepeating): AbstractComponent<
  PropsType,
  mixed
>);
