import DashboardPage from 'app/pages/DashboardPage/DashboardPage';
import InfoPage from 'app/pages/InfoPage/InfoPage';
import LandingPage from 'app/pages/LandingPage/LandingPage';

const pageRoutes = [
  {
    restricted: true,
    path: [
      '/dashboard/:cyid',
      '/dashboard/:cyid/contracts',
      '/dashboard/:cyid/contract/:ctid',
      '/dashboard/:cyid/company',
      '/dashboard/:cyid/project//new',
      '/dashboard/:cyid/project/:ptid/edit',
      '/dashboard/:cyid/projects',
      '/dashboard/:cyid/project/:ptid',
      '/dashboard/:cyid/project/:ptid/contract/:ctid',
      '/dashboard/:cyid/project/:ptid/contract-templates',
      '/dashboard/profile',
      '/dashboard/account',
      '/dashboard/:cyid/contract-templates',
    ],
    exact: true,
    renderWithProps: DashboardPage,
  },
  {
    path: '/info',
    exact: true,
    renderWithProps: LandingPage,
    renderWithPropsAuthenticated: InfoPage,
  },
  {
    path: '/',
    exact: true,
    renderWithProps: LandingPage,
    // uncomment this and comment authenticatedRedirectPath when we
    // want to add an account view (also update the menuData.js)
    // renderWithPropsAuthenticated: DashboardPage,
    // if authenticated, redirect to the selected company page
    authenticatedRedirectPath: '/dashboard/:cyid/projects',
  },
];

export default pageRoutes;
