// @flow

import LoadingImg from 'app/assets/svgs/loading.svg';
import React from 'react';

const Loading = (): React$Element<any> => {
  return (
    <div>
      <img src={LoadingImg} alt="Loading" />
    </div>
  );
};

export default Loading;
