/**
 *
 * Button
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button as MuiButton, Typography } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';

const Button: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    variant = 'primary',
    disabled = false,
    color = 'default',
    endIcon = null,
    startIcon = null,
    fullWidth = false,
    onClick,
    children,
    minWidth,
  } = props;

  let muiVariant;

  switch (variant) {
    case 'secondary':
      muiVariant = 'outlined';
      break;
    case 'tertiary':
      muiVariant = 'text';
      break;
    default:
      muiVariant = 'contained';
  }

  const isTextOnly = typeof children === 'string';

  return (
    <>
      <MuiButton
        variant={muiVariant}
        disabled={disabled}
        onClick={onClick}
        {...(color !== 'default' ? { color: color } : {})}
        endIcon={endIcon}
        startIcon={startIcon}
        fullWidth={fullWidth}
        disableElevation
        disableRipple
        sx={{ height: 40, padding: '10px 16px', minWidth }}
      >
        {isTextOnly ? (
          <Typography
            variant="buttonSemiBold"
            textTransform="capitalize"
            className="ButtonTypographyClass"
          >
            {children}
          </Typography>
        ) : (
          children
        )}
      </MuiButton>
    </>
  );
};

export default (memo(Button): AbstractComponent<PropsType, mixed>);
