/**
 *
 * NamedPerson
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import { Grid, Typography } from '@mui/material';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';

const NamedPerson = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    firstName,
    lastName,
    letterRequiredCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const {
    headerTitle = '',
    letterRequiredCheckboxText = '',
    linkData,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};
  const { renderLinkData } = useLinkDataComponent();

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      firstName: '',
      lastName: '',
      letterRequiredCheckboxTextValue: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">{headerTitle}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="firstName"
          label="First Name"
          value={firstName}
          onBlur={onUpdateData}
          renderLinkData={linkData?.showSync && renderLinkData(firstName)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="lastName"
          label="Last Name"
          value={lastName}
          onBlur={onUpdateData}
        />
      </Grid>
      <Grid item xs={12}>
        <TextGeneratingCheckbox
          id="letterRequiredCheckboxTextValue"
          label="Inducement letter required"
          value={letterRequiredCheckboxTextValue}
          textGenerating={letterRequiredCheckboxText}
          onCheck={onUpdateData}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(NamedPerson): AbstractComponent<PropsType, mixed>);
