import { Grid, Typography } from '@mui/material';
import React, { useRef } from 'react';

import AccountSetupDialog from 'app/components/AccountSetupDialog/AccountSetupDialog';
import Button from 'app/components/core/Button/Button';
import type { Node } from 'react';
import { useUserQuery } from 'user/graphQL/useUserQuery';

const AccountView = (): Node => {
  const dialogRef = useRef(null);
  const { data: userData } = useUserQuery();
  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="h1">Nothing here yet</Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="title">
            Please add some basic details to get started
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ mt: '20px' }}
          >
            <Grid item lg={12} md={12} xs={12}>
              <Button
                variant="primary"
                onClick={() => {
                  dialogRef?.current?.open(true);
                }}
              >
                Create Production Company
              </Button>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Typography variant="title">
                Create projects and contracts
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AccountSetupDialog
        dialogRef={dialogRef}
        userId={userData?.user?._id || ''}
        userGivenName={userData?.user?.givenName || ''}
        userFamilyName={userData?.user?.familyName || ''}
      />
    </>
  );
};

export default AccountView;
