/**
 *
 * useCompanyContractsQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import type { GetCompanyContractsQuery } from 'app/services/graphQL/generated-types';
import contractSummaryFragment from 'contract/graphQL/fragments/contractSummaryFragment';

const COMPANY_CONTRACTS = gql`
  query GetCompanyContracts {
    contracts {
      ...contractSummary
    }
  }
  ${contractSummaryFragment}
`;

export type ContractDetailsReturnType = {
  loading: boolean,
  data: GetCompanyContractsQuery,
  error: any,
  refetch: () => void,
};

export const useCompanyContractsQuery = (): ContractDetailsReturnType => {
  const { loading, data, error, refetch } =
    useQuery<GetCompanyContractsQuery>(COMPANY_CONTRACTS);

  return {
    loading,
    data,
    error,
    refetch,
  };
};

export type ContractDetailsLazyReturnType = [
  any,
  { loading: boolean, data: GetCompanyContractsQuery, error: any }
];

export const useCompanyContractsLazyQuery =
  (): ContractDetailsLazyReturnType => {
    const query =
      useLazyQuery<ContractDetailsLazyReturnType>(COMPANY_CONTRACTS);

    return query;
  };
