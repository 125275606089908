/**
 *
 * RadioButtonGroup
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { ErrorContainer, StyledRadioGroup } from './styles';
import React, { memo } from 'react';

import type { PropsType } from './types';
import { Typography } from '@mui/material';

const RadioButtonGroup: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { id, label, value, onValueChange, errorMessage, children, row } =
    props;

  return (
    <div>
      {label && (
        <Typography
          variant="body2"
          sx={{ marginBottom: 1, color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {label}
        </Typography>
      )}
      <StyledRadioGroup
        value={value}
        onChange={(e) => onValueChange(id, e.target.value)}
        row={row}
      >
        {children}
      </StyledRadioGroup>
      {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
    </div>
  );
};

export default (memo(RadioButtonGroup): AbstractComponent<PropsType, mixed>);
