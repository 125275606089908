/**
 *
 * NetProfitPay
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import { Grid } from '@mui/material';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import TextField from 'app/components/TextField/TextField';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import type { PropsType } from '../types';

const NetProfitPay = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    netProfitLabel = 'Net Profit Share',
    accountingPeriodLabel = 'Revenue share payable',
    checkboxLabel = 'Reduced for Proportional Use of Script',
    showCheckbox = true,
    accountingPeriodOptions = [],
    approvalCheckboxText = '',
    // $FlowFixMe
    linkData = [],
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    netProfitShare = '',
    accountingPeriod = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const { renderLinkData, getLinkDataProps } = useLinkDataComponent();
  const { fieldId } = getLinkDataProps(linkData, 'accountingPeriod');

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      netProfitShare: '',
      accountingPeriod: '',
      approvalCheckboxTextValue: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid item xs={1.5}>
        <TextField
          id="netProfitShare"
          label={netProfitLabel}
          value={netProfitShare}
          numberOnly
          endAdornment={<>%</>}
          onBlur={onUpdateData}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <TextAreaSuggestions
              id="accountingPeriod"
              label={accountingPeriodLabel}
              suggestions={accountingPeriodOptions}
              value={accountingPeriod}
              onSubmitDescription={(id, val) => {
                onUpdateData('accountingPeriod', val);
              }}
              onChangeTextarea={(id, val) => {
                onUpdateData('accountingPeriod', val);
              }}
            />
          </Grid>
          <Grid item xs="auto">
            {renderLinkData(
              accountingPeriod,
              fieldId,
              (companyDetailValue) => {
                if (
                  (companyDetailValue && !accountingPeriod) ||
                  accountingPeriod === ''
                ) {
                  onUpdateData('accountingPeriod', companyDetailValue);
                }
              }
            )}
          </Grid>
        </Grid>
      </Grid>
      {showCheckbox && (
        <TextGeneratingCheckbox
          id="approvalCheckboxTextValue"
          label={checkboxLabel}
          value={approvalCheckboxTextValue}
          textGenerating={approvalCheckboxText}
          onCheck={onUpdateData}
        />
      )}
    </>
  );
};

export default (memo(NetProfitPay): AbstractComponent<PropsType, mixed>);
