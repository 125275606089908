/**
 *
 * useTermTermplatesQuery
 * @format
 * @flow
 *
 */

import { gql, useQuery } from '@apollo/client';

export const TERM_TEMPLATES = gql`
  query GetTermTemplates {
    termTemplates {
      name
      contractSection
      rows {
        arrayDataLocation
        cellData {
          text
          alignment
          dataLocation
          renderCondition
          titlePadding
        }
      }
    }
  }
`;

export const useTermTermplatesQuery = () => {
  const { loading, data, error } = useQuery(TERM_TEMPLATES);

  return {
    loading,
    data,
    error,
  };
};
