/**
 *
 * useProjectDetailsQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import type { GetMyProjectDetailsQuery } from 'app/services/graphQL/generated-types';
import { projectDetailsFragment } from 'project/graphQL/fragments/projectFragments';

const PROJECTS = gql`
  query GetMyProjectDetails($_id: ObjectID!) {
    project(_id: $_id) {
      ...projectDetails
    }
  }
  ${projectDetailsFragment}
`;

export type ProjectDetailsReturnType = {
  loading: boolean,
  data: GetMyProjectDetailsQuery,
  error: any,
  refetch: () => void,
};

export const useProjectDetailsQuery = ({
  _id,
  skip = false,
}): ProjectDetailsReturnType => {
  const { loading, data, error, refetch } = useQuery<GetMyProjectDetailsQuery>(
    PROJECTS,
    { variables: { _id }, skip }
  );

  return {
    loading,
    data,
    error,
    refetch,
  };
};

export type ProjectDetailsLazyReturnType = [
  any,
  { loading: boolean, data: GetMyProjectDetailsQuery, error: any }
];

export const useProjectDetailsLazyQuery = (): ProjectDetailsLazyReturnType => {
  const query = useLazyQuery<GetMyProjectDetailsQuery>(PROJECTS);

  return query;
};
