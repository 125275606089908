/**
 *
 * Spv
 * @format
 * @flow
 *
 */

import { Grid, Paper, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { useTheme } from '@mui/material/styles';
import type { PropsType } from './types';

const Spv: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const theme = useTheme();
  const { spvs } = props;

  const { companyName, abn, acn, address } = spvs?.[0] || {};
  const { street, suburb, state, postcode, country } = address || {};
  const acnString = acn ? acn?.toString() : '';
  const abnString = abn?.toString() || '';
  const formattedACN = acnString
    ? `
    ${acnString?.slice(0, 3)} ${' '}
    ${acnString?.slice(3, 6)} ${' '}
    ${acnString?.slice(6, 9)}
  `
    : '--';
  const formattedABN = abnString
    ? `
    ${abnString?.slice(0, 2)} ${' '}
    ${abnString?.slice(2, 5)} ${' '}
    ${abnString?.slice(5, 8)} ${' '}
    ${abnString?.slice(8, 11)}
  `
    : '--';

  return (
    <>
      <Grid container sx={{ marginBottom: '16px' }}>
        <Typography variant="h2" color={theme.palette.grey['800']}>
          Project (SPV) Company
        </Typography>
      </Grid>
      <Paper sx={{ width: '100%' }} elevation={0}>
        <Grid container gap="24px">
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Company full name
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {companyName || '--'}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Street
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {street || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                City / Suburb
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {suburb || '--'}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                State
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {state || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Postcode
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {postcode || '--'}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Country
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {country || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                ABN
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {formattedABN}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                ACN
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {formattedACN}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default (memo(Spv): AbstractComponent<PropsType, mixed>);
