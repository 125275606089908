/**
 *
 * ContractTemplateCard
 * @format
 * @flow
 *
 */

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import React, { memo, useRef } from 'react';

import type { AbstractComponent } from 'react';
// import { Bookmark } from 'app/assets/icons';
import Button from 'app/components/core/Button/Button';
import ContractTemplateDialog from 'contract/ContractTemplatesView/ContractTemplateDialog/ContractTemplateDialog';
// import IconButton from '@mui/material/IconButton';
import type { PropsType } from './types';

const ContractTemplateCard: AbstractComponent<PropsType> = ({
  id,
  title,
  subheading = '',
  shortDescription,
  fullDescription,
  useFor = [],
  dontUseFor = [],
  alternatives = '',
  usingThisTemplate = '',
  baInfoLinks = [],
  onContractSelected,
}) => {
  const dialogRef = useRef(null);

  return (
    <>
      <Card>
        <CardHeader
          // TODO: Replace the value with template type
          // title={<Chip label={'CREW'} size="small" />}
          // TODO: Implement bookmark later
          // action={
          //   <IconButton aria-label="add to favourites">
          //     <Bookmark />
          //   </IconButton>
          // }
          sx={{ padding: 0 }}
        />
        <CardContent sx={{ height: 100, padding: 0 }}>
          <Typography
            variant="h2"
            color="text.primary"
            component="p"
            sx={{ mb: '16px', mt: '16px' }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="text.primary" component="p">
            {shortDescription}
          </Typography>
        </CardContent>

        <CardActions
          disableSpacing
          sx={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            component="p"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              // $FlowFixMe
              dialogRef?.current?.open(true);
            }}
          >
            Details
          </Typography>
          <Button
            variant="secondary"
            color="primary"
            onClick={() => onContractSelected(id)}
          >
            Use Template
          </Button>
        </CardActions>
      </Card>
      <ContractTemplateDialog
        dialogRef={dialogRef}
        title={`Template | ${title}`}
        fullDescription={fullDescription}
        useFor={useFor}
        dontUseFor={dontUseFor}
        alternatives={alternatives}
        usingThisTemplate={usingThisTemplate}
        baInfoLinks={baInfoLinks}
        onClickOne={() => {
          // $FlowFixMe
          dialogRef?.current?.open(false);
          onContractSelected(id);
        }}
      />
    </>
  );
};

export default (memo(ContractTemplateCard): AbstractComponent<
  PropsType,
  mixed
>);
