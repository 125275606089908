/**
 *
 * PurchasePrice
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useCallback, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import { Grid } from '@mui/material';
import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import NetBudget from 'app/components/NetBudget/NetBudget';
import TextGeneratingRadioButtonGroup from 'app/components/TextGeneratingRadioButtonGroup/TextGeneratingRadioButtonGroup';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import isEqual from 'lodash/isEqual';
import type { PropsType } from '../types';

const PurchasePrice = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    radioValue = '',
    textValue1 = '',
    textValue2,
    fee = '',
    budget = '',
    minBudget = '',
    maxBudget = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const radioButton1Text = 'Fixed Fee';
  const radioButton2Text = 'Net Budget Share';
  const checkbox1Text =
    'Less and amounts paid to Contributor before production';

  const {
    radioLabel1 = radioButton1Text,
    radioValue1 = radioButton1Text,
    textGenerating1 = radioButton1Text,
    radioLabel2 = radioButton2Text,
    radioValue2 = radioButton2Text,
    textGenerating2 = radioButton2Text,
    checkboxLabel = checkbox1Text,
    showCheckbox = true,
    maxLabel = 'Maximum',
    minLabel = 'Minimum',
    approvalCheckboxText = checkbox1Text,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      radioValue: radioValue1,
      textValue1: textGenerating1,
      textValue2: '',
      fee: '',
      budget: '',
      minBudget: '',
      maxBudget: '',
      approvalCheckboxTextValue: '',
    },
  });

  const updateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkRadio1DataIsEmpty = () => {
    const { fee } = contractTermData[contractTermKeyName] || {};
    const termData = { fee };
    const termDefaultData = { fee: '' };
    return isEqual(termData, termDefaultData);
  };

  const checkRadio2DataIsEmpty = () => {
    const { budget, minBudget, maxBudget, approvalCheckboxTextValue } =
      contractTermData[contractTermKeyName] || {};
    const termData = {
      budget,
      minBudget,
      maxBudget,
      approvalCheckboxTextValue,
    };
    const termDefaultData = {
      budget: '',
      minBudget: '',
      maxBudget: '',
      approvalCheckboxTextValue: '',
    };
    return isEqual(termData, termDefaultData);
  };

  return (
    <>
      <TextGeneratingRadioButtonGroup
        radioValue={radioValue}
        textValue1={textValue1}
        textValue2={textValue2}
        radioLabel1={radioLabel1}
        radioValue1={radioValue1}
        textGenerating1={textGenerating1}
        radioLabel2={radioLabel2}
        radioValue2={radioValue2}
        textGenerating2={textGenerating2}
        radio1ResetData={{ fee: '' }}
        radio2ResetData={{
          budget: '',
          minBudget: '',
          maxBudget: '',
          approvalCheckboxTextValue: '',
        }}
        checkRadio1DataIsEmpty={checkRadio1DataIsEmpty}
        checkRadio2DataIsEmpty={checkRadio2DataIsEmpty}
        onUpdate={onUpdateData}
      />
      {radioValue === 'Fixed Fee' && (
        <Grid item xs={3}>
          <CurrencyField
            id="fee"
            label="Fee"
            value={fee}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={updateData}
          />
        </Grid>
      )}
      {radioValue === 'Net Budget Share' && (
        <NetBudget
          maxLabel={maxLabel}
          minLabel={minLabel}
          checkboxLabel={checkboxLabel}
          showCheckbox={showCheckbox}
          approvalCheckboxText={approvalCheckboxText}
          budget={budget}
          minBudget={minBudget}
          maxBudget={maxBudget}
          approvalCheckboxTextValue={approvalCheckboxTextValue}
          onUpdate={updateData}
        />
      )}
    </>
  );
};

export default (memo(PurchasePrice): AbstractComponent<PropsType, mixed>);
