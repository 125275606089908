/**
 *
 * ContractsTable
 * @format
 * @flow
 *
 */

import { Box, Grid, Typography } from '@mui/material';
import {
  GreyFile as FileIcon,
  MoreVertical as MoreActionsIcon,
} from 'app/assets/icons';
import { Link, useParams } from 'react-router-dom';
import React, { useState } from 'react';

import Menu from 'app/components/core/Menu/Menu';
import type { Node } from 'react';
import Table from 'app/components/core/Table/Table';
import { getContractPartyName } from 'app/utils/contract';
import { useTheme } from '@mui/material/styles';

const ContractsTable = ({ data }): Node => {
  const { cyid, ptid } = useParams();

  const theme = useTheme();

  const [actionsEl, setActionsEl] = useState(null);
  const columnOptions = {
    sort: false,
  };

  const filterOutColumns = ['Project'];

  const columns = [
    {
      name: '_id',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'ptid',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'terms',
      label: 'Name',
      options: {
        ...columnOptions,
        customBodyRender: (value, tableMeta, updateValue) => {
          const partyName = getContractPartyName({ terms: value });

          const _ctid = tableMeta.rowData[0];
          const ptid = tableMeta.rowData[1];
          const link = ptid
            ? `/dashboard/${cyid}/project/${ptid}/contract/${_ctid}`
            : `/dashboard/${cyid}/contract/${_ctid}`;

          return (
            <Link
              to={link}
              style={{
                textDecoration: 'none',
                color: theme.palette.text.primary,
                fontWeight: '600',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '32px 1fr',
                  gridTemplateAreas: `"icon name"`,
                  gap: 2,
                  width: '100%',
                }}
              >
                <Box sx={{ gridArea: 'icon' }}>
                  <FileIcon
                    style={{
                      height: '32px',
                      width: '32px',
                      padding: '6px',
                      borderRadius: '8px',
                    }}
                    className="icon"
                  />
                </Box>
                <Box sx={{ gridArea: 'name' }}>{partyName}</Box>
              </Box>
            </Link>
          );
        },
      },
    },
    {
      name: 'contractTemplateName',
      label: 'Template',
      options: {
        ...columnOptions,
      },
    },
    {
      name: 'title',
      label: 'Project',
      options: {
        ...columnOptions,
      },
    },
    {
      name: '',
      label: '',
      options: {
        ...columnOptions,
        customBodyRender: () => {
          // TODO: Action buttons
          return (
            <>
              <MoreActionsIcon
                aria-controls="table-actions"
                aria-haspopup="true"
                onClick={(e) => setActionsEl(e.currentTarget)}
                style={{ cursor: 'pointer' }}
              />
              <Menu
                id="table-actions"
                anchorEl={actionsEl}
                keepMounted
                open={Boolean(actionsEl)}
                onClose={() => setActionsEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {/* TODO: Add actions here */}
              </Menu>
            </>
          );
        },
      },
    },
  ].filter((c) => (ptid ? !filterOutColumns.includes(c.label) : c));

  const options = {
    filterType: 'checkbox',
    selectableRows: false,
    pagination: true,
  };

  return (
    <Grid>
      <Table
        data={data.map((p, index) => {
          const { _id, contractTemplateName, project, terms } = p;
          const ptid = project ? project._id : null;
          const projectTitle = project ? project.title : null;
          return [_id, ptid, terms, contractTemplateName, projectTitle];
        })}
        columns={columns}
        tableOptions={options}
        tableTitle={
          ptid ? (
            <Typography
              variant="body2"
              sx={{ marginBottom: 2, color: theme.palette.text.secondary }}
            >
              Contributor Contracts
            </Typography>
          ) : (
            <Typography
              variant="h1"
              sx={{ marginBottom: 2, color: theme.palette.grey['800'] }}
            >
              Contracts
            </Typography>
          )
        }
      />
    </Grid>
  );
};

export default ContractsTable;
