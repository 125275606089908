/* eslint-disable */
// @generated
// @flow

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {|
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any,
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any,
  ObjectID: any,
|};

export type AccessCode = {|
  objectId: $ElementType<Scalars, 'ObjectID'>,
  objectType: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

export type AccessCodeInput = {|
  objectId: $ElementType<Scalars, 'ObjectID'>,
  objectType: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

export type AddressType = {|
  country: $ElementType<Scalars, 'String'>,
  number: $ElementType<Scalars, 'Int'>,
  postcode: $ElementType<Scalars, 'String'>,
  state?: ?$ElementType<Scalars, 'String'>,
  street: $ElementType<Scalars, 'String'>,
  suburb?: ?$ElementType<Scalars, 'String'>,
|};

export type AddressTypeInput = {|
  country: $ElementType<Scalars, 'String'>,
  number: $ElementType<Scalars, 'Int'>,
  postcode: $ElementType<Scalars, 'String'>,
  state?: ?$ElementType<Scalars, 'String'>,
  street: $ElementType<Scalars, 'String'>,
  suburb?: ?$ElementType<Scalars, 'String'>,
|};

export const AlignmentTypeValues = Object.freeze({
  Center: 'Center',
  Distribute: 'Distribute',
  Justify: 'Justify',
  JustifyHigh: 'JustifyHigh',
  JustifyLow: 'JustifyLow',
  JustifyMedium: 'JustifyMedium',
  Left: 'Left',
  Right: 'Right',
  ThaiJustify: 'ThaiJustify',
});

export type AlignmentType = $Values<typeof AlignmentTypeValues>;

export type ArrayFilter = {|
  field: $ElementType<Scalars, 'String'>,
  operator?: ?$ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

export type BaInfoLinks = {|
  href: $ElementType<Scalars, 'String'>,
  text: $ElementType<Scalars, 'String'>,
|};

export type BankAccountType = {|
  address: AddressType,
  bsb: $ElementType<Scalars, 'Int'>,
  label?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  number: $ElementType<Scalars, 'Int'>,
  signatory?: ?$ElementType<Scalars, 'String'>,
|};

export type BankAccountTypeInput = {|
  address: AddressTypeInput,
  bsb: $ElementType<Scalars, 'Int'>,
  label?: ?$ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  number: $ElementType<Scalars, 'Int'>,
  signatory?: ?$ElementType<Scalars, 'String'>,
|};

export type BudgetInfo = {|
  amount: $ElementType<Scalars, 'Int'>,
  documents: Array<Document>,
|};

export type BudgetInfoInput = {|
  amount: $ElementType<Scalars, 'Int'>,
  documents?: ?Array<CreateDocumentInput>,
|};

export type CellData = {|
  alignment?: ?AlignmentType,
  dataLocation?: ?Array<$ElementType<Scalars, 'String'>>,
  renderCondition?: ?$ElementType<Scalars, 'String'>,
  text?: ?Array<$ElementType<Scalars, 'String'>>,
  titlePadding?: ?$ElementType<Scalars, 'Int'>,
  widthCm?: ?$ElementType<Scalars, 'Float'>,
|};

export type ClauseParagraph = {|
  isList: $ElementType<Scalars, 'Boolean'>,
  text: $ElementType<Scalars, 'String'>,
|};

export type ClauseParagraphInput = {|
  isList: $ElementType<Scalars, 'Boolean'>,
  text: $ElementType<Scalars, 'String'>,
|};

export type Company = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  abn?: ?$ElementType<Scalars, 'String'>,
  accountingPeriod?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?AddressType,
  contractingParties?: ?Array<ContractingPartyType>,
  createdAt?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  gst?: ?$ElementType<Scalars, 'Int'>,
  invoicePaymentTerms?: ?$ElementType<Scalars, 'String'>,
  jurisdiction?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  owner?: ?$ElementType<Scalars, 'ObjectID'>,
  periodicPaymentTerms?: ?$ElementType<Scalars, 'String'>,
  periodicTerm?: ?$ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  shortCompanyName?: ?$ElementType<Scalars, 'String'>,
  type?: ?EntityType,
  updatedAt?: ?$ElementType<Scalars, 'String'>,
|};

export type CompanyMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    company?: ?Company,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type Contract = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  company?: ?Company,
  contractTemplateName?: ?$ElementType<Scalars, 'String'>,
  contractingParties?: ?Array<ContractingParties>,
  createdAt?: ?$ElementType<Scalars, 'String'>,
  documents?: ?Array<Document>,
  project?: ?Project,
  sectionNames?: ?Array<$ElementType<Scalars, 'String'>>,
  standardTermSections?: ?Array<StandardTermSection>,
  terms?: ?Array<ContractTerm>,
  title?: ?$ElementType<Scalars, 'String'>,
  updatedAt?: ?$ElementType<Scalars, 'String'>,
  users: Array<$ElementType<Scalars, 'ObjectID'>>,
|};

export type ContractDetailsSelectValue = {|
  predictiveTextFieldCrewRoleValues: Array<SelectValue>,
|};

/**
 * This type defines information about the contract that can be used to inform the user
 * on how to use the contract.
 */
export type ContractInfo = {|
  alternatives?: ?$ElementType<Scalars, 'String'>,
  baInfoLinks?: ?Array<BaInfoLinks>,
  dontUseFor?: ?Array<$ElementType<Scalars, 'String'>>,
  fullDescription: $ElementType<Scalars, 'String'>,
  mainCategory: $ElementType<Scalars, 'String'>,
  shortDescription: $ElementType<Scalars, 'String'>,
  subCategory: $ElementType<Scalars, 'String'>,
  useFor?: ?Array<$ElementType<Scalars, 'String'>>,
  usingThisTemplate?: ?$ElementType<Scalars, 'String'>,
|};

export type ContractMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    contract?: ?Contract,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type ContractTemplate = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  createdAt: $ElementType<Scalars, 'String'>,
  info: ContractInfo,
  sectionNames: Array<$ElementType<Scalars, 'String'>>,
  standardTermSections: Array<StandardTermSection>,
  terms: Array<ContractTerm>,
  /** The title of the contract. */
  title: $ElementType<Scalars, 'String'>,
  updatedAt: $ElementType<Scalars, 'String'>,
|};

export type ContractTerm = {|
  arrayData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  boolData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  name: $ElementType<Scalars, 'String'>,
  numberData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  objectData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  props?: ?$ElementType<Scalars, 'JSONObject'>,
  stringData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  termId: $ElementType<Scalars, 'String'>,
  uiComponentName: $ElementType<Scalars, 'String'>,
  uiComponentSectionIndex: Array<$ElementType<Scalars, 'Int'>>,
|};

export type ContractTermInput = {|
  arrayData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  boolData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  name: $ElementType<Scalars, 'String'>,
  numberData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  objectData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  props?: ?$ElementType<Scalars, 'JSONObject'>,
  stringData?: ?Array<$ElementType<Scalars, 'JSONObject'>>,
  termId: $ElementType<Scalars, 'String'>,
  uiComponentName: $ElementType<Scalars, 'String'>,
  uiComponentSectionIndex: Array<$ElementType<Scalars, 'Int'>>,
|};

export type ContractWordDocMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    document?: ?Document,
    downloadUrl?: ?$ElementType<Scalars, 'String'>,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type ContractingParties = {|
  _id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  namedPersonFamilyName: $ElementType<Scalars, 'String'>,
  namedPersonGivenName: $ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
  type: $ElementType<Scalars, 'String'>,
|};

export type ContractingPartiesInput = {|
  _id: $ElementType<Scalars, 'String'>,
  name: $ElementType<Scalars, 'String'>,
  namedPersonFamilyName: $ElementType<Scalars, 'String'>,
  namedPersonGivenName: $ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
  type: $ElementType<Scalars, 'String'>,
|};

export type ContractingPartyInput = {|
  abn?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?AddressTypeInput,
  bankAccounts?: ?Array<BankAccountTypeInput>,
  email: $ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?EntityType,
|};

export type ContractingPartyType = {|
  abn?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?AddressType,
  bankAccounts?: ?Array<?BankAccountType>,
  email: $ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  type?: ?EntityType,
|};

export type Contributor = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  abn?: ?$ElementType<Scalars, 'String'>,
  company?: ?Company,
  createdAt?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  namedPersonFamilyName?: ?$ElementType<Scalars, 'String'>,
  namedPersonGivenName?: ?$ElementType<Scalars, 'String'>,
  updatedAt?: ?$ElementType<Scalars, 'String'>,
|};

export type CreateCompanyContractingPartyInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  contractingPartyDetails: ContractingPartyInput,
|};

export type CreateCompanyMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    company?: ?Company,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
    user?: ?User,
  |},
|};

export type CreateContractFromTemplateInput = {|
  projectId: $ElementType<Scalars, 'ObjectID'>,
  templateId: $ElementType<Scalars, 'ObjectID'>,
|};

export type CreateContributorDetailsInput = {|
  abn: $ElementType<Scalars, 'String'>,
  email: $ElementType<Scalars, 'String'>,
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  namedPersonFamilyName?: ?$ElementType<Scalars, 'String'>,
  namedPersonGivenName?: ?$ElementType<Scalars, 'String'>,
|};

export type CreateContributorMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    contributor?: ?Contributor,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type CreateDocumentInput = {|
  contract?: ?$ElementType<Scalars, 'String'>,
  contractType?: ?$ElementType<Scalars, 'String'>,
  fileName?: ?$ElementType<Scalars, 'String'>,
  fileType?: ?$ElementType<Scalars, 'String'>,
  mimeType?: ?$ElementType<Scalars, 'String'>,
  notes?: ?$ElementType<Scalars, 'String'>,
  partyName?: ?$ElementType<Scalars, 'String'>,
  project?: ?$ElementType<Scalars, 'String'>,
  tags: Array<$ElementType<Scalars, 'String'>>,
|};

export type CreateFunctionalCompanyInput = {|
  abn?: ?$ElementType<Scalars, 'String'>,
  accountingPeriod?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?ShortAddressInputType,
  contractingParties?: ?Array<ContractingPartyInput>,
  email: $ElementType<Scalars, 'String'>,
  gst?: ?$ElementType<Scalars, 'Int'>,
  invoicePaymentTerms?: ?$ElementType<Scalars, 'String'>,
  jurisdiction?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  periodicPaymentTerms?: ?$ElementType<Scalars, 'String'>,
  periodicTerm?: ?$ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  shortCompanyName?: ?$ElementType<Scalars, 'String'>,
  type?: ?EntityType,
|};

export type CreateProjectInput = {|
  company?: ?$ElementType<Scalars, 'ObjectID'>,
  companySPVs?: ?Array<SpvCompanyInput>,
  currency: $ElementType<Scalars, 'String'>,
  developmentBudgets?: ?Array<$ElementType<Scalars, 'Int'>>,
  duration: $ElementType<Scalars, 'Int'>,
  form: $ElementType<Scalars, 'String'>,
  /**
   * Used by the client to do checks on the actual type without relying on strings, e.g. the client may want to check if the
   * selected dropdown is of type other, using this method we can check for its typeid as this will never change even if the
   * display text does
   */
  genreTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  initialReleasePlatformTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  noOfEpisodes: $ElementType<Scalars, 'Int'>,
  productionBudget?: ?$ElementType<Scalars, 'Int'>,
  schedule?: ?ScheduleInput,
  shootLocation?: ?$ElementType<Scalars, 'String'>,
  shortDescription?: ?$ElementType<Scalars, 'String'>,
  single: $ElementType<Scalars, 'Boolean'>,
  title: $ElementType<Scalars, 'String'>,
|};

export type CreateProjectSpvCompanyInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  spvDetails: SpvCompanyInput,
|};

export type CreateRoleMutationResponse = {|
  ...MutationResponse,
  ...{|
    Role?: ?Role,
    code: $ElementType<Scalars, 'String'>,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type CreateUserInput = {|
  /** The email address of the user. */
  email: $ElementType<Scalars, 'String'>,
  /** The family name of the user. */
  familyName?: ?$ElementType<Scalars, 'String'>,
  /** The given name of the user. */
  givenName?: ?$ElementType<Scalars, 'String'>,
|};

export type CreateWordDocumentInput = {|
  contractId: $ElementType<Scalars, 'ObjectID'>,
  tags: Array<$ElementType<Scalars, 'String'>>,
|};

export type DeleteCompanyInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type DeleteUserInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type Delivery = {|
  date: $ElementType<Scalars, 'Date'>,
  name: $ElementType<Scalars, 'String'>,
|};

export type DeliveryInput = {|
  date: $ElementType<Scalars, 'Date'>,
  name: $ElementType<Scalars, 'String'>,
|};

export type Document = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  /** The id of the company that owns this record. */
  company?: ?$ElementType<Scalars, 'ObjectID'>,
  contract?: ?$ElementType<Scalars, 'ObjectID'>,
  contractType?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'String'>,
  fileData?: ?FileData,
  fileName?: ?$ElementType<Scalars, 'String'>,
  fileType?: ?$ElementType<Scalars, 'String'>,
  mimeType?: ?$ElementType<Scalars, 'String'>,
  notes?: ?$ElementType<Scalars, 'String'>,
  partyName?: ?$ElementType<Scalars, 'String'>,
  project?: ?$ElementType<Scalars, 'ObjectID'>,
  status?: ?$ElementType<Scalars, 'String'>,
  tags: Array<$ElementType<Scalars, 'String'>>,
  updatedAt?: ?$ElementType<Scalars, 'String'>,
  uploadedBy?: ?$ElementType<Scalars, 'String'>,
  whoDownloaded?: ?Array<WhoDownloaded>,
|};

export type DocumentMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    document?: ?Document,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type DocumentUrl = {|
  url?: ?$ElementType<Scalars, 'String'>,
|};

export const EntityTypeValues = Object.freeze({
  Company: 'COMPANY',
  Individual: 'INDIVIDUAL',
});

export type EntityType = $Values<typeof EntityTypeValues>;

export type FileData = {|
  downloadUrl?: ?$ElementType<Scalars, 'String'>,
  uploadUrl?: ?$ElementType<Scalars, 'String'>,
|};

export type InfoPage = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  content: $ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'String'>,
  items?: ?Array<InfoPageItems>,
  slug: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
  updatedAt?: ?$ElementType<Scalars, 'String'>,
|};

export type InfoPageItems = {|
  content: $ElementType<Scalars, 'String'>,
  items?: ?Array<InfoPageItems>,
  slug: $ElementType<Scalars, 'String'>,
  title: $ElementType<Scalars, 'String'>,
|};

export type Mutation = {|
  createCompany: CreateCompanyMutationResponse,
  /** Creates a new contracting party in the contracting party array */
  createCompanyContractingParty: CompanyMutationResponse,
  createContractFromTemplate: ContractMutationResponse,
  createContributor: CreateContributorMutationResponse,
  createDocument: DocumentMutationResponse,
  createProject: ProjectMutationResponse,
  createRole?: ?CreateRoleMutationResponse,
  /** Creates a new SPV in the SPV array */
  createSPV: ProjectMutationResponse,
  createUser: UserMutationResponse,
  createWordDocument: ContractWordDocMutationResponse,
  deleteCompany: CompanyMutationResponse,
  deleteUser: UserMutationResponse,
  /** Replaces the contractingParties array with the given details */
  updateCompanyContractingParties: CompanyMutationResponse,
  updateCompanyDetails: CompanyMutationResponse,
  /**
   * Update the contract with _id.
   * If deleteMissingObjectAttributes is provided and set to true, any embedded objects in the provided data
   * will overwrite existing embedded objects completely. Otherwise only the attributes of the provided embedded
   * object will be updated leaving any other attributes of the embedded object untouched.
   */
  updateContract: ContractMutationResponse,
  updateContributor: CreateContributorMutationResponse,
  updateProject: ProjectMutationResponse,
  updateProjectBudgets: ProjectMutationResponse,
  updateProjectContracts?: ?ProjectMutationResponse,
  updateProjectGeneralDetails: ProjectMutationResponse,
  /** Replaces the SPV array with the given details */
  updateProjectSPVs: ProjectMutationResponse,
  updateProjectSchedule: ProjectMutationResponse,
  updateUser: UserMutationResponse,
|};

export type MutationCreateCompanyArgs = {|
  input: CreateFunctionalCompanyInput,
|};

export type MutationCreateCompanyContractingPartyArgs = {|
  input: CreateCompanyContractingPartyInput,
|};

export type MutationCreateContractFromTemplateArgs = {|
  input: CreateContractFromTemplateInput,
|};

export type MutationCreateContributorArgs = {|
  input: CreateContributorDetailsInput,
|};

export type MutationCreateDocumentArgs = {|
  input: CreateDocumentInput,
|};

export type MutationCreateProjectArgs = {|
  input: CreateProjectInput,
|};

export type MutationCreateRoleArgs = {|
  input?: ?RoleInput,
|};

export type MutationCreateSpvArgs = {|
  input: CreateProjectSpvCompanyInput,
|};

export type MutationCreateUserArgs = {|
  input: CreateUserInput,
|};

export type MutationCreateWordDocumentArgs = {|
  input: CreateWordDocumentInput,
|};

export type MutationDeleteCompanyArgs = {|
  input: DeleteCompanyInput,
|};

export type MutationDeleteUserArgs = {|
  input: DeleteUserInput,
|};

export type MutationUpdateCompanyContractingPartiesArgs = {|
  input: UpdateCompanyContractingPartyInput,
|};

export type MutationUpdateCompanyDetailsArgs = {|
  input: UpdateCompanyDetailsInput,
|};

export type MutationUpdateContractArgs = {|
  input: UpdateContractInput,
|};

export type MutationUpdateContributorArgs = {|
  input: UpdateContributorDetailsInput,
|};

export type MutationUpdateProjectArgs = {|
  input: UpdateProjectInput,
|};

export type MutationUpdateProjectBudgetsArgs = {|
  input: ProjectBudgetDetailsInput,
|};

export type MutationUpdateProjectContractsArgs = {|
  input: UpdateProjectContractsInput,
|};

export type MutationUpdateProjectGeneralDetailsArgs = {|
  input: ProjectGeneralDetailsInput,
|};

export type MutationUpdateProjectSpVsArgs = {|
  input: UpdateProjectSpvCompanyInput,
|};

export type MutationUpdateProjectScheduleArgs = {|
  input: ProjectScheduleInput,
|};

export type MutationUpdateUserArgs = {|
  input: UpdateUserInput,
|};

export type MutationResponse = {|
  /** A string that represents the status of the data transfer. */
  code: $ElementType<Scalars, 'String'>,
  /** A human-readable string that describes the result of the mutation. It is intended to be used in the UI of the product. */
  message: $ElementType<Scalars, 'String'>,
  /** A boolean that indicates whether the mutation was successful. This allows a coarse check by the client to know if there were failures. */
  success: $ElementType<Scalars, 'Boolean'>,
|};

export type Node = {|
  id: $ElementType<Scalars, 'ObjectID'>,
|};

export type Project = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  company?: ?Company,
  companySPVs?: ?Array<SpvCompany>,
  currency: $ElementType<Scalars, 'String'>,
  developmentBudgets: Array<BudgetInfo>,
  duration?: ?$ElementType<Scalars, 'Int'>,
  form?: ?$ElementType<Scalars, 'String'>,
  /**
   * Used by the client to do checks on the actual type without relying on strings, e.g. the client may want to check if the
   * selected dropdown is of type other, using this method we can check for its typeid as this will never change even if the
   * display text does
   */
  genreTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  /** Returns the list of genre values to populate the dropdown */
  genreValues: Array<SelectValue>,
  initialReleasePlatformTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  noOfEpisodes?: ?$ElementType<Scalars, 'Int'>,
  /** The string representation of number of episodes and duration, e.g. 10 x 45 */
  numberAndDuration?: ?$ElementType<Scalars, 'String'>,
  productionBudget?: ?BudgetInfo,
  /** Returns the list of release platform values to populate the dropdown */
  releasePlatformValues?: ?Array<SelectValue>,
  schedule?: ?Schedule,
  shootLocation?: ?$ElementType<Scalars, 'String'>,
  shortDescription?: ?$ElementType<Scalars, 'String'>,
  single?: ?$ElementType<Scalars, 'Boolean'>,
  title?: ?$ElementType<Scalars, 'String'>,
|};

export type ProjectBudgetDetailsInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  developmentBudgets?: ?Array<$ElementType<Scalars, 'Int'>>,
  productionBudget?: ?$ElementType<Scalars, 'Int'>,
|};

/** The UI select values configuration for the project detail page */
export type ProjectDetailSelectValue = {|
  genreValues: Array<SelectValue>,
  projectStages: Array<SelectValue>,
  releasePlatformValues: Array<SelectValue>,
|};

export type ProjectGeneralDetailsInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  currency?: ?$ElementType<Scalars, 'String'>,
  duration?: ?$ElementType<Scalars, 'Int'>,
  form?: ?$ElementType<Scalars, 'String'>,
  /**
   * Used by the client to do checks on the actual type without relying on strings, e.g. the client may want to check if the
   * selected dropdown is of type other, using this method we can check for its typeid as this will never change even if the
   * display text does
   */
  genreTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  initialReleasePlatformTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  noOfEpisodes?: ?$ElementType<Scalars, 'Int'>,
  shootLocation?: ?$ElementType<Scalars, 'String'>,
  shortDescription?: ?$ElementType<Scalars, 'String'>,
  single?: ?$ElementType<Scalars, 'Boolean'>,
  title?: ?$ElementType<Scalars, 'String'>,
|};

export type ProjectMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    message: $ElementType<Scalars, 'String'>,
    project?: ?Project,
    success: $ElementType<Scalars, 'Boolean'>,
  |},
|};

export type ProjectScheduleInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  finalMaterialsDeliveryEnd?: ?$ElementType<Scalars, 'Date'>,
  finalMaterialsDeliveryStart?: ?$ElementType<Scalars, 'Date'>,
  fineCutDelivery?: ?Array<DeliveryInput>,
  postProductionEnd?: ?$ElementType<Scalars, 'Date'>,
  postProductionStart?: ?$ElementType<Scalars, 'Date'>,
  preProductionEnd?: ?$ElementType<Scalars, 'Date'>,
  preProductionStart?: ?$ElementType<Scalars, 'Date'>,
  productionEnd?: ?$ElementType<Scalars, 'Date'>,
  productionStart?: ?$ElementType<Scalars, 'Date'>,
  roughCutDelivery?: ?Array<DeliveryInput>,
  stageTypeId?: ?$ElementType<Scalars, 'Int'>,
|};

export type Query = {|
  /** Returns an array of companies that the logged in user belongs to */
  companies?: ?Array<Company>,
  company?: ?Company,
  companyUsers: Array<User>,
  contract?: ?Contract,
  contractTemplates: Array<ContractTemplate>,
  contracts: Array<Contract>,
  contributor?: ?Contributor,
  contributors?: ?Array<Contributor>,
  document?: ?Document,
  documents: Array<Document>,
  infoPage?: ?Array<InfoPage>,
  project?: ?Project,
  projectChangedAffectedContracts: Array<Contract>,
  projectContracts: Array<Contract>,
  projectDocuments: Array<Document>,
  projects: Array<Project>,
  role?: ?Role,
  roles?: ?Array<Role>,
  termTemplates?: ?Array<TermTemplate>,
  /**
   * Returns the config values for the select dropdowns, i.e. the
   * database config with type: selectValues
   */
  uiSelectValuesConfig?: ?UiSelectValuesConfig,
  /** This query returns a user with extended references */
  user?: ?User,
  users: Array<User>,
|};

export type QueryCompanyArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryContractArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryContributorArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryContributorsArgs = {|
  company?: ?$ElementType<Scalars, 'ObjectID'>,
|};

export type QueryDocumentArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryDocumentsArgs = {|
  company?: ?$ElementType<Scalars, 'ObjectID'>,
|};

export type QueryProjectArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryProjectChangedAffectedContractsArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  arrayDataFilters?: ?Array<ArrayFilter>,
  fieldKey?: ?$ElementType<Scalars, 'String'>,
  fieldValue?: ?$ElementType<Scalars, 'String'>,
  uiComponentName: $ElementType<Scalars, 'String'>,
|};

export type QueryProjectContractsArgs = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryProjectDocumentsArgs = {|
  project: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryProjectsArgs = {|
  company?: ?$ElementType<Scalars, 'ObjectID'>,
|};

export type QueryRoleArgs = {|
  id: $ElementType<Scalars, 'ObjectID'>,
|};

export type QueryUserArgs = {|
  _id?: ?$ElementType<Scalars, 'ObjectID'>,
|};

export type Reference = {|
  conditionalValue: $ElementType<Scalars, 'String'>,
  /** ToDo this could be represented with an Enum */
  operator: $ElementType<Scalars, 'String'>,
  termDataIndex: $ElementType<Scalars, 'String'>,
  termId: $ElementType<Scalars, 'String'>,
|};

export type ReferenceInput = {|
  conditionalValue: $ElementType<Scalars, 'String'>,
  /** ToDo this could be represented with an Enum */
  operator: $ElementType<Scalars, 'String'>,
  termDataIndex: $ElementType<Scalars, 'String'>,
  termId: $ElementType<Scalars, 'String'>,
|};

export type Role = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  name: $ElementType<Scalars, 'String'>,
  rules: Array<Rule>,
|};

export type RoleInput = {|
  name: $ElementType<Scalars, 'String'>,
  rules: Array<RuleInput>,
|};

export type Rule = {|
  action: Array<$ElementType<Scalars, 'String'>>,
  conditions?: ?$ElementType<Scalars, 'JSONObject'>,
  fields?: ?Array<$ElementType<Scalars, 'String'>>,
  inverted?: ?$ElementType<Scalars, 'Boolean'>,
  subject: Array<$ElementType<Scalars, 'String'>>,
|};

export type RuleInput = {|
  action: Array<$ElementType<Scalars, 'String'>>,
  conditions?: ?$ElementType<Scalars, 'JSONObject'>,
  fields?: ?Array<$ElementType<Scalars, 'String'>>,
  inverted?: ?$ElementType<Scalars, 'Boolean'>,
  subject: Array<$ElementType<Scalars, 'String'>>,
|};

export type SpvCompany = {|
  abn?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?AddressType,
  bankAccounts?: ?Array<BankAccountType>,
  companyName?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
|};

export type SpvCompanyInput = {|
  abn?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?AddressTypeInput,
  bankAccounts?: ?Array<BankAccountTypeInput>,
  companyName?: ?$ElementType<Scalars, 'String'>,
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  type?: ?$ElementType<Scalars, 'String'>,
|};

export type Schedule = {|
  finalMaterialsDeliveryEnd?: ?$ElementType<Scalars, 'Date'>,
  finalMaterialsDeliveryStart?: ?$ElementType<Scalars, 'Date'>,
  fineCutDelivery?: ?Array<Delivery>,
  postProductionEnd?: ?$ElementType<Scalars, 'Date'>,
  postProductionStart?: ?$ElementType<Scalars, 'Date'>,
  preProductionEnd?: ?$ElementType<Scalars, 'Date'>,
  preProductionStart?: ?$ElementType<Scalars, 'Date'>,
  productionEnd?: ?$ElementType<Scalars, 'Date'>,
  productionStart?: ?$ElementType<Scalars, 'Date'>,
  roughCutDelivery?: ?Array<Delivery>,
  stage?: ?$ElementType<Scalars, 'String'>,
  stageTypeId?: ?$ElementType<Scalars, 'Int'>,
|};

export type ScheduleInput = {|
  finalMaterialsDeliveryEnd?: ?$ElementType<Scalars, 'Date'>,
  finalMaterialsDeliveryStart?: ?$ElementType<Scalars, 'Date'>,
  fineCutDelivery?: ?Array<DeliveryInput>,
  postProductionEnd?: ?$ElementType<Scalars, 'Date'>,
  postProductionStart?: ?$ElementType<Scalars, 'Date'>,
  preProductionEnd?: ?$ElementType<Scalars, 'Date'>,
  preProductionStart?: ?$ElementType<Scalars, 'Date'>,
  productionEnd?: ?$ElementType<Scalars, 'Date'>,
  productionStart?: ?$ElementType<Scalars, 'Date'>,
  roughCutDelivery?: ?Array<DeliveryInput>,
  stageTypeId?: ?$ElementType<Scalars, 'Int'>,
|};

/**
 * The core type of a UI select value. These are not the human readable values, but the values that are used in the UI.
 * The id is the unique identifier that identifies this type. The type is a unique string representation of the type value, such as 'other', or 'project_contributor'. The purpose is to allow the client to identify a specific selected type, for example, 'other' probably has special meaning wherever it is used, i.e. it may result in the client opening a dialogue box to capture the other information. Rather than relying on string matches or 'Other' everywhere it is implemented, we can use the single type that has its type set to 'other' in all cases, so we don't have to worry about typos (except in the one instance of the SelectType that is other
 */
export type SelectType = {|
  id: $ElementType<Scalars, 'Int'>,
  type: $ElementType<Scalars, 'String'>,
|};

/** The human readable value of a UI select value. This embeds the typeInfo which is the core type of the value. */
export type SelectValue = {|
  /** The value to be displayed in the UI. */
  displayValue: $ElementType<Scalars, 'String'>,
  /** The group name to be use in the UI */
  idGroup?: ?$ElementType<Scalars, 'String'>,
  /** The display order number of the value in the UI. */
  order: $ElementType<Scalars, 'Int'>,
  /** The core type of the value. */
  typeInfo: SelectType,
|};

export type SettingsSelectValue = {|
  paymentTermsAccountingPeriodValues: Array<SelectValue>,
  paymentTermsInvoiceValues: Array<SelectValue>,
  paymentTermsJurisdictionLawValues: Array<SelectValue>,
  paymentTermsPeriodicValues: Array<SelectValue>,
|};

export type ShortAddressInputType = {|
  state: $ElementType<Scalars, 'String'>,
|};

export type ShortAddressType = {|
  state?: ?$ElementType<Scalars, 'String'>,
|};

export type StandardTerm = {|
  amendment?: ?$ElementType<Scalars, 'String'>,
  clauseParagraphs: Array<ClauseParagraph>,
  name: $ElementType<Scalars, 'String'>,
  references: Array<Reference>,
|};

export type StandardTermInput = {|
  amendment?: ?$ElementType<Scalars, 'String'>,
  clauseParagraphs: Array<ClauseParagraphInput>,
  name: $ElementType<Scalars, 'String'>,
  references: Array<ReferenceInput>,
|};

export type StandardTermSection = {|
  terms: Array<StandardTerm>,
  title: $ElementType<Scalars, 'String'>,
|};

export type StandardTermSectionInput = {|
  terms: Array<StandardTermInput>,
  title: $ElementType<Scalars, 'String'>,
|};

export type TermTemplate = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  contractSection?: ?$ElementType<Scalars, 'String'>,
  createdAt?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  rows?: ?Array<TermTemplateRow>,
  title?: ?$ElementType<Scalars, 'String'>,
  updatedAt?: ?$ElementType<Scalars, 'String'>,
|};

export type TermTemplateRow = {|
  arrayDataLocation?: ?$ElementType<Scalars, 'String'>,
  cellData?: ?Array<CellData>,
|};

/** This 'Project' type defines the queryable fields for every project in our data source */
export type UiSelectValuesConfig = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  /** The select values for the contract detail page */
  contractDetailSelectValues?: ?ContractDetailsSelectValue,
  /** The select values for the project detail page */
  projectDetailSelectValues?: ?ProjectDetailSelectValue,
  settingsSelectValues?: ?SettingsSelectValue,
|};

export type UpdateCompanyContractingPartyInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  contractingPartyDetails: Array<ContractingPartyInput>,
|};

export type UpdateCompanyDetailsInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  abn?: ?$ElementType<Scalars, 'String'>,
  accountingPeriod?: ?$ElementType<Scalars, 'String'>,
  acn?: ?$ElementType<Scalars, 'Int'>,
  address?: ?AddressTypeInput,
  email?: ?$ElementType<Scalars, 'String'>,
  gst?: ?$ElementType<Scalars, 'Int'>,
  invoicePaymentTerms?: ?$ElementType<Scalars, 'String'>,
  jurisdiction?: ?$ElementType<Scalars, 'String'>,
  name?: ?$ElementType<Scalars, 'String'>,
  periodicPaymentTerms?: ?$ElementType<Scalars, 'String'>,
  periodicTerm?: ?$ElementType<Scalars, 'String'>,
  phone?: ?$ElementType<Scalars, 'String'>,
  shortCompanyName?: ?$ElementType<Scalars, 'String'>,
  type?: ?EntityType,
|};

export type UpdateContractDetailsInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  contractTemplateName?: ?$ElementType<Scalars, 'String'>,
  /** Optional contractingParties. If included it will replace the existing array. */
  contractingParties?: ?Array<ContractingPartiesInput>,
  /** Optional section names. If included it will replace the existing array. */
  sectionNames?: ?Array<$ElementType<Scalars, 'String'>>,
  /** Optional standardTermSections. If included it will replace the existing array. */
  standardTermSections?: ?Array<StandardTermSectionInput>,
  /** Optional terms. If included it will replace the existing array. */
  terms?: ?Array<ContractTermInput>,
  title?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateContractInput = {|
  contract: UpdateContractDetailsInput,
  deleteMissingObjectAttributes?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type UpdateContributorDetailsInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  abn?: ?$ElementType<Scalars, 'String'>,
  email?: ?$ElementType<Scalars, 'String'>,
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  namedPersonFamilyName?: ?$ElementType<Scalars, 'String'>,
  namedPersonGivenName?: ?$ElementType<Scalars, 'String'>,
|};

export type UpdateProjectContractsInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  contractIds?: ?Array<$ElementType<Scalars, 'ObjectID'>>,
  fieldKey: $ElementType<Scalars, 'String'>,
  fieldValue: $ElementType<Scalars, 'String'>,
  sourceFieldKey: $ElementType<Scalars, 'String'>,
  termId: $ElementType<Scalars, 'String'>,
  uiComponentName: $ElementType<Scalars, 'String'>,
|};

export type UpdateProjectInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  company?: ?$ElementType<Scalars, 'ObjectID'>,
  companySPVs?: ?Array<SpvCompanyInput>,
  currency: $ElementType<Scalars, 'String'>,
  developmentBudgets?: ?Array<$ElementType<Scalars, 'Int'>>,
  duration: $ElementType<Scalars, 'Int'>,
  form: $ElementType<Scalars, 'String'>,
  /**
   * Used by the client to do checks on the actual type without relying on strings, e.g. the client may want to check if the
   * selected dropdown is of type other, using this method we can check for its typeid as this will never change even if the
   * display text does
   */
  genreTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  initialReleasePlatformTypeNames?: ?Array<$ElementType<Scalars, 'String'>>,
  noOfEpisodes: $ElementType<Scalars, 'Int'>,
  productionBudget?: ?$ElementType<Scalars, 'Int'>,
  schedule?: ?ScheduleInput,
  shootLocation?: ?$ElementType<Scalars, 'String'>,
  shortDescription?: ?$ElementType<Scalars, 'String'>,
  single: $ElementType<Scalars, 'Boolean'>,
  title: $ElementType<Scalars, 'String'>,
|};

export type UpdateProjectSpvCompanyInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  spvDetails: Array<SpvCompanyInput>,
|};

/**
 * The UpdateUserInput contains the information required to update a user. Note that
 * a user can only be created by registering with the system. This is done in the
 * server rather than via an API call.
 */
export type UpdateUserInput = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  /** The email address of the user. */
  email?: ?$ElementType<Scalars, 'String'>,
  /** The family name of the user. */
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  selectedCompany?: ?$ElementType<Scalars, 'String'>,
|};

/** The "User" type defines the queryable fields for every User in our data */
export type User = {|
  _id: $ElementType<Scalars, 'ObjectID'>,
  accessCodes: Array<AccessCode>,
  accountIdentifier: $ElementType<Scalars, 'String'>,
  /**
   * The companies this user belongs to. Note if you only query for the fields in the
   * extended reference (_id, givenName, familyName, companyName), the results will come from the user
   * collection, otherwise they will come from the company collection
   */
  companies?: ?Array<Company>,
  emails: Array<$ElementType<Scalars, 'String'>>,
  familyName?: ?$ElementType<Scalars, 'String'>,
  givenName?: ?$ElementType<Scalars, 'String'>,
  idProvider?: ?$ElementType<Scalars, 'String'>,
  roles?: ?Array<Role>,
  rules?: ?Array<Rule>,
  selectedCompany?: ?$ElementType<Scalars, 'String'>,
|};

export type UserMutationResponse = {|
  ...MutationResponse,
  ...{|
    code: $ElementType<Scalars, 'String'>,
    message: $ElementType<Scalars, 'String'>,
    success: $ElementType<Scalars, 'Boolean'>,
    user?: ?User,
  |},
|};

export type WhoDownloaded = {|
  downloadedBy?: ?$ElementType<Scalars, 'String'>,
  downloadedDate?: ?$ElementType<Scalars, 'String'>,
|};

type $Pick<Origin: Object, Keys: Object> = $ObjMapi<
  Keys,
  <Key>(k: Key) => $ElementType<Origin, Key>
>;

export type InfoPageItemsFragment = $Pick<
  InfoPageItems,
  {| slug: *, title: *, content: * |}
>;

export type InfoPageItemsRecursiveFragment = {
  ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
  ...{|
    items?: ?Array<{
      ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
      ...{|
        items?: ?Array<{
          ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
          ...{|
            items?: ?Array<
              $Pick<InfoPageItems, {| slug: *, title: *, content: * |}>
            >,
          |},
        }>,
      |},
    }>,
  |},
};

export type InfoPageDetailsFragment = {
  ...$Pick<InfoPage, {| _id: *, slug: *, title: *, content: * |}>,
  ...{|
    items?: ?Array<{
      ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
      ...{|
        items?: ?Array<{
          ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
          ...{|
            items?: ?Array<{
              ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
              ...{|
                items?: ?Array<
                  $Pick<InfoPageItems, {| slug: *, title: *, content: * |}>
                >,
              |},
            }>,
          |},
        }>,
      |},
    }>,
  |},
};

export type GetInfoPageQueryVariables = {};

export type GetInfoPageQuery = {|
  infoPage?: ?Array<{
    ...$Pick<InfoPage, {| _id: *, slug: *, title: *, content: * |}>,
    ...{|
      items?: ?Array<{
        ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
        ...{|
          items?: ?Array<{
            ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
            ...{|
              items?: ?Array<{
                ...$Pick<InfoPageItems, {| slug: *, title: *, content: * |}>,
                ...{|
                  items?: ?Array<
                    $Pick<InfoPageItems, {| slug: *, title: *, content: * |}>
                  >,
                |},
              }>,
            |},
          }>,
        |},
      }>,
    |},
  }>,
|};

export type CompanyDetailsFragment = {
  ...$Pick<
    Company,
    {|
      _id: *,
      owner?: *,
      email?: *,
      name?: *,
      shortCompanyName?: *,
      abn?: *,
      acn?: *,
      gst?: *,
      phone?: *,
      jurisdiction?: *,
      invoicePaymentTerms?: *,
      periodicPaymentTerms?: *,
      periodicTerm?: *,
      accountingPeriod?: *,
      type?: *,
    |}
  >,
  ...{|
    address?: ?$Pick<
      AddressType,
      {| number: *, street: *, suburb?: *, state?: *, postcode: *, country: * |}
    >,
    contractingParties?: ?Array<{
      ...$Pick<
        ContractingPartyType,
        {| email: *, name?: *, type?: *, abn?: *, acn?: * |}
      >,
      ...{|
        address?: ?$Pick<
          AddressType,
          {|
            number: *,
            street: *,
            suburb?: *,
            state?: *,
            postcode: *,
            country: *,
          |}
        >,
      |},
    }>,
  |},
};

export type GetMyCompanyDetailsQueryVariables = {
  _id: $ElementType<Scalars, 'ObjectID'>,
};

export type GetMyCompanyDetailsQuery = {|
  company?: ?{
    ...$Pick<
      Company,
      {|
        _id: *,
        owner?: *,
        email?: *,
        name?: *,
        shortCompanyName?: *,
        abn?: *,
        acn?: *,
        gst?: *,
        phone?: *,
        jurisdiction?: *,
        invoicePaymentTerms?: *,
        periodicPaymentTerms?: *,
        periodicTerm?: *,
        accountingPeriod?: *,
        type?: *,
      |}
    >,
    ...{|
      address?: ?$Pick<
        AddressType,
        {|
          number: *,
          street: *,
          suburb?: *,
          state?: *,
          postcode: *,
          country: *,
        |}
      >,
      contractingParties?: ?Array<{
        ...$Pick<
          ContractingPartyType,
          {| email: *, name?: *, type?: *, abn?: *, acn?: * |}
        >,
        ...{|
          address?: ?$Pick<
            AddressType,
            {|
              number: *,
              street: *,
              suburb?: *,
              state?: *,
              postcode: *,
              country: *,
            |}
          >,
        |},
      }>,
    |},
  },
|};

export type GetCompanyUsersQueryVariables = {};

export type GetCompanyUsersQuery = {|
  companyUsers: Array<{
    ...$Pick<
      User,
      {|
        _id: *,
        emails: *,
        givenName?: *,
        familyName?: *,
        selectedCompany?: *,
      |}
    >,
    ...{|
      roles?: ?Array<{
        ...$Pick<Role, {| _id: *, name: * |}>,
        ...{|
          rules: Array<
            $Pick<
              Rule,
              {|
                action: *,
                subject: *,
                conditions?: *,
                fields?: *,
                inverted?: *,
              |}
            >
          >,
        |},
      }>,
      rules?: ?Array<
        $Pick<
          Rule,
          {| action: *, subject: *, conditions?: *, fields?: *, inverted?: * |}
        >
      >,
      companies?: ?Array<$Pick<Company, {| _id: *, name?: *, type?: * |}>>,
    |},
  }>,
|};

export type CreateCompanyMutationVariables = {
  input: CreateFunctionalCompanyInput,
};

export type CreateCompanyMutation = {|
  createCompany: {| company?: ?$Pick<Company, {| _id: * |}> |},
|};

export type UpdateCompanyContractingPartiesMutationVariables = {
  input: UpdateCompanyContractingPartyInput,
};

export type UpdateCompanyContractingPartiesMutation = {|
  updateCompanyContractingParties: {|
    company?: ?{
      ...$Pick<
        Company,
        {|
          _id: *,
          owner?: *,
          email?: *,
          name?: *,
          shortCompanyName?: *,
          abn?: *,
          acn?: *,
          gst?: *,
          phone?: *,
          jurisdiction?: *,
          invoicePaymentTerms?: *,
          periodicPaymentTerms?: *,
          periodicTerm?: *,
          accountingPeriod?: *,
          type?: *,
        |}
      >,
      ...{|
        address?: ?$Pick<
          AddressType,
          {|
            number: *,
            street: *,
            suburb?: *,
            state?: *,
            postcode: *,
            country: *,
          |}
        >,
        contractingParties?: ?Array<{
          ...$Pick<
            ContractingPartyType,
            {| email: *, name?: *, type?: *, abn?: *, acn?: * |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
      |},
    },
  |},
|};

export type UpdateCompanyDetailsMutationVariables = {
  input: UpdateCompanyDetailsInput,
};

export type UpdateCompanyDetailsMutation = {|
  updateCompanyDetails: {|
    company?: ?{
      ...$Pick<
        Company,
        {|
          _id: *,
          owner?: *,
          email?: *,
          name?: *,
          shortCompanyName?: *,
          abn?: *,
          acn?: *,
          gst?: *,
          phone?: *,
          jurisdiction?: *,
          invoicePaymentTerms?: *,
          periodicPaymentTerms?: *,
          periodicTerm?: *,
          accountingPeriod?: *,
          type?: *,
        |}
      >,
      ...{|
        address?: ?$Pick<
          AddressType,
          {|
            number: *,
            street: *,
            suburb?: *,
            state?: *,
            postcode: *,
            country: *,
          |}
        >,
        contractingParties?: ?Array<{
          ...$Pick<
            ContractingPartyType,
            {| email: *, name?: *, type?: *, abn?: *, acn?: * |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
      |},
    },
  |},
|};

export type ContractMetaFragment = {
  ...$Pick<
    Contract,
    {| _id: *, title?: *, contractTemplateName?: *, sectionNames?: * |}
  >,
  ...{|
    documents?: ?Array<{
      ...$Pick<
        Document,
        {| _id: *, fileName?: *, fileType?: *, createdAt?: * |}
      >,
      ...{| fileData?: ?$Pick<FileData, {| downloadUrl?: * |}> |},
    }>,
  |},
};

export type ContractStandardTermSectionsFragment = {|
  standardTermSections?: ?Array<{
    ...$Pick<StandardTermSection, {| title: * |}>,
    ...{|
      terms: Array<{
        ...$Pick<StandardTerm, {| name: *, amendment?: * |}>,
        ...{|
          clauseParagraphs: Array<
            $Pick<ClauseParagraph, {| text: *, isList: * |}>
          >,
          references: Array<
            $Pick<
              Reference,
              {|
                termId: *,
                termDataIndex: *,
                operator: *,
                conditionalValue: *,
              |}
            >
          >,
        |},
      }>,
    |},
  }>,
|};

export type ContractSummaryFragment = {
  ...$Pick<Contract, {| _id: *, title?: *, contractTemplateName?: * |}>,
  ...{|
    project?: ?$Pick<Project, {| _id: *, title?: * |}>,
    terms?: ?Array<
      $Pick<ContractTerm, {| uiComponentName: *, stringData?: * |}>
    >,
  |},
};

export type ContractTermsFragment = {|
  terms?: ?Array<
    $Pick<
      ContractTerm,
      {|
        termId: *,
        name: *,
        uiComponentName: *,
        uiComponentSectionIndex: *,
        props?: *,
        stringData?: *,
        boolData?: *,
        numberData?: *,
        objectData?: *,
        arrayData?: *,
      |}
    >
  >,
|};

export type GetCompanyContractsQueryVariables = {};

export type GetCompanyContractsQuery = {|
  contracts: Array<{
    ...$Pick<Contract, {| _id: *, title?: *, contractTemplateName?: * |}>,
    ...{|
      project?: ?$Pick<Project, {| _id: *, title?: * |}>,
      terms?: ?Array<
        $Pick<ContractTerm, {| uiComponentName: *, stringData?: * |}>
      >,
    |},
  }>,
|};

export type GetContractDetailsQueryVariables = {
  _id: $ElementType<Scalars, 'ObjectID'>,
};

export type GetContractDetailsQuery = {|
  contract?: ?{
    ...$Pick<
      Contract,
      {| _id: *, title?: *, contractTemplateName?: *, sectionNames?: * |}
    >,
    ...{|
      documents?: ?Array<{
        ...$Pick<
          Document,
          {| _id: *, fileName?: *, fileType?: *, createdAt?: * |}
        >,
        ...{| fileData?: ?$Pick<FileData, {| downloadUrl?: * |}> |},
      }>,
      terms?: ?Array<
        $Pick<
          ContractTerm,
          {|
            termId: *,
            name: *,
            uiComponentName: *,
            uiComponentSectionIndex: *,
            props?: *,
            stringData?: *,
            boolData?: *,
            numberData?: *,
            objectData?: *,
            arrayData?: *,
          |}
        >
      >,
      standardTermSections?: ?Array<{
        ...$Pick<StandardTermSection, {| title: * |}>,
        ...{|
          terms: Array<{
            ...$Pick<StandardTerm, {| name: *, amendment?: * |}>,
            ...{|
              clauseParagraphs: Array<
                $Pick<ClauseParagraph, {| text: *, isList: * |}>
              >,
              references: Array<
                $Pick<
                  Reference,
                  {|
                    termId: *,
                    termDataIndex: *,
                    operator: *,
                    conditionalValue: *,
                  |}
                >
              >,
            |},
          }>,
        |},
      }>,
    |},
  },
|};

export type GetAllContractTemplatesInfoQueryVariables = {};

export type GetAllContractTemplatesInfoQuery = {|
  contractTemplates: Array<{
    ...$Pick<ContractTemplate, {| _id: *, title: * |}>,
    ...{|
      info: {
        ...$Pick<
          ContractInfo,
          {|
            shortDescription: *,
            fullDescription: *,
            useFor?: *,
            dontUseFor?: *,
            alternatives?: *,
            usingThisTemplate?: *,
          |}
        >,
        ...{|
          baInfoLinks?: ?Array<$Pick<BaInfoLinks, {| href: *, text: * |}>>,
        |},
      },
    |},
  }>,
|};

export type CreateContractFromTemplateMutationVariables = {
  input: CreateContractFromTemplateInput,
};

export type CreateContractFromTemplateMutation = {|
  createContractFromTemplate: {
    ...$Pick<ContractMutationResponse, {| code: *, success: *, message: * |}>,
    ...{|
      contract?: ?{
        ...$Pick<
          Contract,
          {| _id: *, title?: *, contractTemplateName?: *, sectionNames?: * |}
        >,
        ...{|
          documents?: ?Array<{
            ...$Pick<
              Document,
              {| _id: *, fileName?: *, fileType?: *, createdAt?: * |}
            >,
            ...{| fileData?: ?$Pick<FileData, {| downloadUrl?: * |}> |},
          }>,
          terms?: ?Array<
            $Pick<
              ContractTerm,
              {|
                termId: *,
                name: *,
                uiComponentName: *,
                uiComponentSectionIndex: *,
                props?: *,
                stringData?: *,
                boolData?: *,
                numberData?: *,
                objectData?: *,
                arrayData?: *,
              |}
            >
          >,
        |},
      },
    |},
  },
|};

export type CreateWordDocMutationVariables = {
  input: CreateWordDocumentInput,
};

export type CreateWordDocMutation = {|
  createWordDocument: $Pick<
    ContractWordDocMutationResponse,
    {| code: *, success: *, message: *, downloadUrl?: * |}
  >,
|};

export type GetProjectChangedAffectedContractsQueryVariables = {
  _id: $ElementType<Scalars, 'ObjectID'>,
  uiComponentName: $ElementType<Scalars, 'String'>,
  fieldKey: $ElementType<Scalars, 'String'>,
  fieldValue: $ElementType<Scalars, 'String'>,
  arrayDataFilters?: ?Array<ArrayFilter> | ArrayFilter,
};

export type GetProjectChangedAffectedContractsQuery = {|
  projectChangedAffectedContracts: Array<{
    ...$Pick<Contract, {| _id: *, title?: *, contractTemplateName?: * |}>,
    ...{|
      project?: ?$Pick<Project, {| _id: *, title?: * |}>,
      terms?: ?Array<
        $Pick<ContractTerm, {| uiComponentName: *, stringData?: * |}>
      >,
    |},
  }>,
|};

export type GetProjectContractsQueryVariables = {
  _id: $ElementType<Scalars, 'ObjectID'>,
};

export type GetProjectContractsQuery = {|
  projectContracts: Array<{
    ...$Pick<Contract, {| _id: *, title?: *, contractTemplateName?: * |}>,
    ...{|
      project?: ?$Pick<Project, {| _id: *, title?: * |}>,
      terms?: ?Array<
        $Pick<ContractTerm, {| uiComponentName: *, stringData?: * |}>
      >,
    |},
  }>,
|};

export type SaveContractAndCreateWordDocMutationVariables = {
  updateContractInput: UpdateContractInput,
  createWordDocumentInput: CreateWordDocumentInput,
};

export type SaveContractAndCreateWordDocMutation = {|
  createWordDocument: {
    ...$Pick<
      ContractWordDocMutationResponse,
      {| code: *, success: *, message: *, downloadUrl?: * |}
    >,
    ...{|
      document?: ?{
        ...$Pick<
          Document,
          {|
            _id: *,
            company?: *,
            contract?: *,
            project?: *,
            tags: *,
            fileName?: *,
            fileType?: *,
            mimeType?: *,
            uploadedBy?: *,
            notes?: *,
            createdAt?: *,
            updatedAt?: *,
          |}
        >,
        ...{|
          whoDownloaded?: ?Array<
            $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
          >,
          fileData?: ?$Pick<FileData, {| downloadUrl?: * |}>,
        |},
      },
    |},
  },
  updateContract: {
    ...$Pick<ContractMutationResponse, {| code: *, success: *, message: * |}>,
    ...{|
      contract?: ?{
        ...$Pick<
          Contract,
          {| _id: *, title?: *, contractTemplateName?: *, sectionNames?: * |}
        >,
        ...{|
          documents?: ?Array<{
            ...$Pick<
              Document,
              {| _id: *, fileName?: *, fileType?: *, createdAt?: * |}
            >,
            ...{| fileData?: ?$Pick<FileData, {| downloadUrl?: * |}> |},
          }>,
          terms?: ?Array<
            $Pick<
              ContractTerm,
              {|
                termId: *,
                name: *,
                uiComponentName: *,
                uiComponentSectionIndex: *,
                props?: *,
                stringData?: *,
                boolData?: *,
                numberData?: *,
                objectData?: *,
                arrayData?: *,
              |}
            >
          >,
        |},
      },
    |},
  },
|};

export type UpdateContractMutationVariables = {
  input: UpdateContractInput,
};

export type UpdateContractMutation = {|
  updateContract: {
    ...$Pick<ContractMutationResponse, {| code: *, success: *, message: * |}>,
    ...{|
      contract?: ?{
        ...$Pick<
          Contract,
          {| _id: *, title?: *, contractTemplateName?: *, sectionNames?: * |}
        >,
        ...{|
          documents?: ?Array<{
            ...$Pick<
              Document,
              {| _id: *, fileName?: *, fileType?: *, createdAt?: * |}
            >,
            ...{| fileData?: ?$Pick<FileData, {| downloadUrl?: * |}> |},
          }>,
          terms?: ?Array<
            $Pick<
              ContractTerm,
              {|
                termId: *,
                name: *,
                uiComponentName: *,
                uiComponentSectionIndex: *,
                props?: *,
                stringData?: *,
                boolData?: *,
                numberData?: *,
                objectData?: *,
                arrayData?: *,
              |}
            >
          >,
          standardTermSections?: ?Array<{
            ...$Pick<StandardTermSection, {| title: * |}>,
            ...{|
              terms: Array<{
                ...$Pick<StandardTerm, {| name: *, amendment?: * |}>,
                ...{|
                  clauseParagraphs: Array<
                    $Pick<ClauseParagraph, {| text: *, isList: * |}>
                  >,
                  references: Array<
                    $Pick<
                      Reference,
                      {|
                        termId: *,
                        termDataIndex: *,
                        operator: *,
                        conditionalValue: *,
                      |}
                    >
                  >,
                |},
              }>,
            |},
          }>,
        |},
      },
    |},
  },
|};

export type DocumentDetailsFragmentFragment = {
  ...$Pick<
    Document,
    {|
      company?: *,
      contract?: *,
      project?: *,
      tags: *,
      fileName?: *,
      fileType?: *,
      mimeType?: *,
      uploadedBy?: *,
      notes?: *,
      createdAt?: *,
      updatedAt?: *,
    |}
  >,
  ...{|
    whoDownloaded?: ?Array<
      $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
    >,
    fileData?: ?$Pick<FileData, {| downloadUrl?: * |}>,
  |},
};

export type ProjectDetailsFragment = {
  ...$Pick<
    Project,
    {|
      _id: *,
      title?: *,
      genreTypeNames?: *,
      single?: *,
      noOfEpisodes?: *,
      duration?: *,
      numberAndDuration?: *,
      currency: *,
      form?: *,
      initialReleasePlatformTypeNames?: *,
      shortDescription?: *,
      shootLocation?: *,
    |}
  >,
  ...{|
    company?: ?$Pick<Company, {| _id: * |}>,
    developmentBudgets: Array<{
      ...$Pick<BudgetInfo, {| amount: * |}>,
      ...{|
        documents: Array<{
          ...$Pick<
            Document,
            {|
              _id: *,
              company?: *,
              contract?: *,
              project?: *,
              tags: *,
              fileName?: *,
              fileType?: *,
              mimeType?: *,
              uploadedBy?: *,
              notes?: *,
              partyName?: *,
              contractType?: *,
              status?: *,
            |}
          >,
          ...{|
            whoDownloaded?: ?Array<
              $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
            >,
            fileData?: ?$Pick<FileData, {| uploadUrl?: *, downloadUrl?: * |}>,
          |},
        }>,
      |},
    }>,
    productionBudget?: ?{
      ...$Pick<BudgetInfo, {| amount: * |}>,
      ...{|
        documents: Array<{
          ...$Pick<
            Document,
            {|
              _id: *,
              company?: *,
              contract?: *,
              project?: *,
              tags: *,
              fileName?: *,
              fileType?: *,
              mimeType?: *,
              uploadedBy?: *,
              notes?: *,
              partyName?: *,
              contractType?: *,
              status?: *,
            |}
          >,
          ...{|
            whoDownloaded?: ?Array<
              $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
            >,
            fileData?: ?$Pick<FileData, {| uploadUrl?: *, downloadUrl?: * |}>,
          |},
        }>,
      |},
    },
    companySPVs?: ?Array<{
      ...$Pick<
        SpvCompany,
        {|
          givenName?: *,
          familyName?: *,
          companyName?: *,
          type?: *,
          abn?: *,
          acn?: *,
          email?: *,
        |}
      >,
      ...{|
        address?: ?$Pick<
          AddressType,
          {|
            number: *,
            street: *,
            suburb?: *,
            state?: *,
            postcode: *,
            country: *,
          |}
        >,
        bankAccounts?: ?Array<{
          ...$Pick<
            BankAccountType,
            {| name: *, number: *, bsb: *, signatory?: *, label?: * |}
          >,
          ...{|
            address: $Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
      |},
    }>,
    schedule?: ?{
      ...$Pick<
        Schedule,
        {|
          stageTypeId?: *,
          stage?: *,
          preProductionStart?: *,
          preProductionEnd?: *,
          productionStart?: *,
          productionEnd?: *,
          postProductionStart?: *,
          postProductionEnd?: *,
          finalMaterialsDeliveryStart?: *,
          finalMaterialsDeliveryEnd?: *,
        |}
      >,
      ...{|
        roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
        fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
      |},
    },
    genreValues: Array<{
      ...$Pick<SelectValue, {| displayValue: *, order: * |}>,
      ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
    }>,
    releasePlatformValues?: ?Array<{
      ...$Pick<SelectValue, {| displayValue: *, order: * |}>,
      ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
    }>,
  |},
};

export type CreateProjectDetailsFragment = {
  ...$Pick<
    Project,
    {|
      _id: *,
      title?: *,
      genreTypeNames?: *,
      single?: *,
      noOfEpisodes?: *,
      duration?: *,
      numberAndDuration?: *,
      currency: *,
      form?: *,
      initialReleasePlatformTypeNames?: *,
      shortDescription?: *,
      shootLocation?: *,
    |}
  >,
  ...{|
    company?: ?$Pick<Company, {| _id: * |}>,
    companySPVs?: ?Array<{
      ...$Pick<
        SpvCompany,
        {|
          givenName?: *,
          familyName?: *,
          companyName?: *,
          type?: *,
          abn?: *,
          acn?: *,
          email?: *,
        |}
      >,
      ...{|
        address?: ?$Pick<
          AddressType,
          {|
            number: *,
            street: *,
            suburb?: *,
            state?: *,
            postcode: *,
            country: *,
          |}
        >,
      |},
    }>,
    schedule?: ?{
      ...$Pick<
        Schedule,
        {|
          stageTypeId?: *,
          stage?: *,
          preProductionStart?: *,
          preProductionEnd?: *,
          productionStart?: *,
          productionEnd?: *,
          postProductionStart?: *,
          postProductionEnd?: *,
          finalMaterialsDeliveryStart?: *,
          finalMaterialsDeliveryEnd?: *,
        |}
      >,
      ...{|
        roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
        fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
      |},
    },
  |},
};

export type ProjectSummaryFragment = $Pick<
  Project,
  {|
    _id: *,
    title?: *,
    genreTypeNames?: *,
    numberAndDuration?: *,
    initialReleasePlatformTypeNames?: *,
    duration?: *,
    noOfEpisodes?: *,
    shootLocation?: *,
  |}
>;

export type SelectValueFragment = {
  ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
  ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
};

export type ProjectUiSelectValuesFragment = {
  ...$Pick<UiSelectValuesConfig, {| _id: * |}>,
  ...{|
    projectDetailSelectValues?: ?{|
      genreValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
      releasePlatformValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
      projectStages: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
    |},
    contractDetailSelectValues?: ?{|
      predictiveTextFieldCrewRoleValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
    |},
    settingsSelectValues?: ?{|
      paymentTermsPeriodicValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
      paymentTermsInvoiceValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
      paymentTermsAccountingPeriodValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
      paymentTermsJurisdictionLawValues: Array<{
        ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
    |},
  |},
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
};

export type CreateDocumentMutation = {|
  createDocument: {|
    document?: ?{
      ...$Pick<
        Document,
        {|
          _id: *,
          company?: *,
          contract?: *,
          project?: *,
          tags: *,
          fileName?: *,
          fileType?: *,
          mimeType?: *,
          uploadedBy?: *,
          notes?: *,
        |}
      >,
      ...{|
        fileData?: ?$Pick<FileData, {| uploadUrl?: *, downloadUrl?: * |}>,
      |},
    },
  |},
|};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
};

export type CreateProjectMutation = {|
  createProject: {|
    project?: ?{
      ...$Pick<
        Project,
        {|
          _id: *,
          title?: *,
          genreTypeNames?: *,
          single?: *,
          noOfEpisodes?: *,
          duration?: *,
          numberAndDuration?: *,
          currency: *,
          form?: *,
          initialReleasePlatformTypeNames?: *,
          shortDescription?: *,
          shootLocation?: *,
        |}
      >,
      ...{|
        company?: ?$Pick<Company, {| _id: * |}>,
        companySPVs?: ?Array<{
          ...$Pick<
            SpvCompany,
            {|
              givenName?: *,
              familyName?: *,
              companyName?: *,
              type?: *,
              abn?: *,
              acn?: *,
              email?: *,
            |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
        schedule?: ?{
          ...$Pick<
            Schedule,
            {|
              stageTypeId?: *,
              stage?: *,
              preProductionStart?: *,
              preProductionEnd?: *,
              productionStart?: *,
              productionEnd?: *,
              postProductionStart?: *,
              postProductionEnd?: *,
              finalMaterialsDeliveryStart?: *,
              finalMaterialsDeliveryEnd?: *,
            |}
          >,
          ...{|
            roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
            fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
          |},
        },
      |},
    },
  |},
|};

export type GetAllDocumentsQueryVariables = {
  company: $ElementType<Scalars, 'ObjectID'>,
};

export type GetAllDocumentsQuery = {|
  documents: Array<{
    ...$Pick<
      Document,
      {|
        _id: *,
        company?: *,
        contract?: *,
        project?: *,
        tags: *,
        fileName?: *,
        fileType?: *,
        mimeType?: *,
        uploadedBy?: *,
        notes?: *,
        createdAt?: *,
        updatedAt?: *,
      |}
    >,
    ...{|
      whoDownloaded?: ?Array<
        $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
      >,
      fileData?: ?$Pick<FileData, {| downloadUrl?: * |}>,
    |},
  }>,
|};

export type GetMyProjectDetailsQueryVariables = {
  _id: $ElementType<Scalars, 'ObjectID'>,
};

export type GetMyProjectDetailsQuery = {|
  project?: ?{
    ...$Pick<
      Project,
      {|
        _id: *,
        title?: *,
        genreTypeNames?: *,
        single?: *,
        noOfEpisodes?: *,
        duration?: *,
        numberAndDuration?: *,
        currency: *,
        form?: *,
        initialReleasePlatformTypeNames?: *,
        shortDescription?: *,
        shootLocation?: *,
      |}
    >,
    ...{|
      company?: ?$Pick<Company, {| _id: * |}>,
      developmentBudgets: Array<{
        ...$Pick<BudgetInfo, {| amount: * |}>,
        ...{|
          documents: Array<{
            ...$Pick<
              Document,
              {|
                _id: *,
                company?: *,
                contract?: *,
                project?: *,
                tags: *,
                fileName?: *,
                fileType?: *,
                mimeType?: *,
                uploadedBy?: *,
                notes?: *,
                partyName?: *,
                contractType?: *,
                status?: *,
              |}
            >,
            ...{|
              whoDownloaded?: ?Array<
                $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
              >,
              fileData?: ?$Pick<FileData, {| uploadUrl?: *, downloadUrl?: * |}>,
            |},
          }>,
        |},
      }>,
      productionBudget?: ?{
        ...$Pick<BudgetInfo, {| amount: * |}>,
        ...{|
          documents: Array<{
            ...$Pick<
              Document,
              {|
                _id: *,
                company?: *,
                contract?: *,
                project?: *,
                tags: *,
                fileName?: *,
                fileType?: *,
                mimeType?: *,
                uploadedBy?: *,
                notes?: *,
                partyName?: *,
                contractType?: *,
                status?: *,
              |}
            >,
            ...{|
              whoDownloaded?: ?Array<
                $Pick<WhoDownloaded, {| downloadedBy?: *, downloadedDate?: * |}>
              >,
              fileData?: ?$Pick<FileData, {| uploadUrl?: *, downloadUrl?: * |}>,
            |},
          }>,
        |},
      },
      companySPVs?: ?Array<{
        ...$Pick<
          SpvCompany,
          {|
            givenName?: *,
            familyName?: *,
            companyName?: *,
            type?: *,
            abn?: *,
            acn?: *,
            email?: *,
          |}
        >,
        ...{|
          address?: ?$Pick<
            AddressType,
            {|
              number: *,
              street: *,
              suburb?: *,
              state?: *,
              postcode: *,
              country: *,
            |}
          >,
          bankAccounts?: ?Array<{
            ...$Pick<
              BankAccountType,
              {| name: *, number: *, bsb: *, signatory?: *, label?: * |}
            >,
            ...{|
              address: $Pick<
                AddressType,
                {|
                  number: *,
                  street: *,
                  suburb?: *,
                  state?: *,
                  postcode: *,
                  country: *,
                |}
              >,
            |},
          }>,
        |},
      }>,
      schedule?: ?{
        ...$Pick<
          Schedule,
          {|
            stageTypeId?: *,
            stage?: *,
            preProductionStart?: *,
            preProductionEnd?: *,
            productionStart?: *,
            productionEnd?: *,
            postProductionStart?: *,
            postProductionEnd?: *,
            finalMaterialsDeliveryStart?: *,
            finalMaterialsDeliveryEnd?: *,
          |}
        >,
        ...{|
          roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
          fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
        |},
      },
      genreValues: Array<{
        ...$Pick<SelectValue, {| displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
      releasePlatformValues?: ?Array<{
        ...$Pick<SelectValue, {| displayValue: *, order: * |}>,
        ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
      }>,
    |},
  },
|};

export type GetMyProjectsSummaryQueryVariables = {};

export type GetMyProjectsSummaryQuery = {|
  projects: Array<
    $Pick<
      Project,
      {|
        _id: *,
        title?: *,
        genreTypeNames?: *,
        numberAndDuration?: *,
        initialReleasePlatformTypeNames?: *,
        duration?: *,
        noOfEpisodes?: *,
        shootLocation?: *,
      |}
    >
  >,
|};

export type GetProjectUiSelectValuesQueryVariables = {};

export type GetProjectUiSelectValuesQuery = {|
  uiSelectValuesConfig?: ?{
    ...$Pick<UiSelectValuesConfig, {| _id: * |}>,
    ...{|
      projectDetailSelectValues?: ?{|
        genreValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
        releasePlatformValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
        projectStages: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
      |},
      contractDetailSelectValues?: ?{|
        predictiveTextFieldCrewRoleValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
      |},
      settingsSelectValues?: ?{|
        paymentTermsPeriodicValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
        paymentTermsInvoiceValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
        paymentTermsAccountingPeriodValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
        paymentTermsJurisdictionLawValues: Array<{
          ...$Pick<SelectValue, {| idGroup?: *, displayValue: *, order: * |}>,
          ...{| typeInfo: $Pick<SelectType, {| id: *, type: * |}> |},
        }>,
      |},
    |},
  },
|};

export type UpdateProjectContractsMutationVariables = {
  input: UpdateProjectContractsInput,
};

export type UpdateProjectContractsMutation = {|
  updateProjectContracts?: ?{|
    project?: ?{
      ...$Pick<
        Project,
        {|
          _id: *,
          title?: *,
          genreTypeNames?: *,
          numberAndDuration?: *,
          initialReleasePlatformTypeNames?: *,
          duration?: *,
          noOfEpisodes?: *,
          shootLocation?: *,
        |}
      >,
      ...{|
        schedule?: ?$Pick<
          Schedule,
          {|
            preProductionStart?: *,
            preProductionEnd?: *,
            productionStart?: *,
            productionEnd?: *,
            postProductionStart?: *,
            postProductionEnd?: *,
            finalMaterialsDeliveryStart?: *,
            finalMaterialsDeliveryEnd?: *,
          |}
        >,
      |},
    },
  |},
|};

export type UpdateProjectGeneralDetailsMutationVariables = {
  input: ProjectGeneralDetailsInput,
};

export type UpdateProjectGeneralDetailsMutation = {|
  updateProjectGeneralDetails: {|
    project?: ?{
      ...$Pick<
        Project,
        {|
          _id: *,
          title?: *,
          genreTypeNames?: *,
          single?: *,
          noOfEpisodes?: *,
          duration?: *,
          numberAndDuration?: *,
          currency: *,
          form?: *,
          initialReleasePlatformTypeNames?: *,
          shortDescription?: *,
          shootLocation?: *,
        |}
      >,
      ...{|
        company?: ?$Pick<Company, {| _id: * |}>,
        companySPVs?: ?Array<{
          ...$Pick<
            SpvCompany,
            {|
              givenName?: *,
              familyName?: *,
              companyName?: *,
              type?: *,
              abn?: *,
              acn?: *,
              email?: *,
            |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
        schedule?: ?{
          ...$Pick<
            Schedule,
            {|
              stageTypeId?: *,
              stage?: *,
              preProductionStart?: *,
              preProductionEnd?: *,
              productionStart?: *,
              productionEnd?: *,
              postProductionStart?: *,
              postProductionEnd?: *,
              finalMaterialsDeliveryStart?: *,
              finalMaterialsDeliveryEnd?: *,
            |}
          >,
          ...{|
            roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
            fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
          |},
        },
      |},
    },
  |},
|};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
};

export type UpdateProjectMutation = {|
  updateProject: {|
    project?: ?{
      ...$Pick<
        Project,
        {|
          _id: *,
          title?: *,
          genreTypeNames?: *,
          single?: *,
          noOfEpisodes?: *,
          duration?: *,
          numberAndDuration?: *,
          currency: *,
          form?: *,
          initialReleasePlatformTypeNames?: *,
          shortDescription?: *,
          shootLocation?: *,
        |}
      >,
      ...{|
        company?: ?$Pick<Company, {| _id: * |}>,
        companySPVs?: ?Array<{
          ...$Pick<
            SpvCompany,
            {|
              givenName?: *,
              familyName?: *,
              companyName?: *,
              type?: *,
              abn?: *,
              acn?: *,
              email?: *,
            |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
        schedule?: ?{
          ...$Pick<
            Schedule,
            {|
              stageTypeId?: *,
              stage?: *,
              preProductionStart?: *,
              preProductionEnd?: *,
              productionStart?: *,
              productionEnd?: *,
              postProductionStart?: *,
              postProductionEnd?: *,
              finalMaterialsDeliveryStart?: *,
              finalMaterialsDeliveryEnd?: *,
            |}
          >,
          ...{|
            roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
            fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
          |},
        },
      |},
    },
  |},
|};

export type UpdateProjectSpVsMutationVariables = {
  input: UpdateProjectSpvCompanyInput,
};

export type UpdateProjectSpVsMutation = {|
  updateProjectSPVs: {|
    project?: ?{
      ...$Pick<
        Project,
        {|
          _id: *,
          title?: *,
          genreTypeNames?: *,
          single?: *,
          noOfEpisodes?: *,
          duration?: *,
          numberAndDuration?: *,
          currency: *,
          form?: *,
          initialReleasePlatformTypeNames?: *,
          shortDescription?: *,
          shootLocation?: *,
        |}
      >,
      ...{|
        company?: ?$Pick<Company, {| _id: * |}>,
        companySPVs?: ?Array<{
          ...$Pick<
            SpvCompany,
            {|
              givenName?: *,
              familyName?: *,
              companyName?: *,
              type?: *,
              abn?: *,
              acn?: *,
              email?: *,
            |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
        schedule?: ?{
          ...$Pick<
            Schedule,
            {|
              stageTypeId?: *,
              stage?: *,
              preProductionStart?: *,
              preProductionEnd?: *,
              productionStart?: *,
              productionEnd?: *,
              postProductionStart?: *,
              postProductionEnd?: *,
              finalMaterialsDeliveryStart?: *,
              finalMaterialsDeliveryEnd?: *,
            |}
          >,
          ...{|
            roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
            fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
          |},
        },
      |},
    },
  |},
|};

export type UpdateProjectScheduleMutationVariables = {
  input: ProjectScheduleInput,
};

export type UpdateProjectScheduleMutation = {|
  updateProjectSchedule: {|
    project?: ?{
      ...$Pick<
        Project,
        {|
          _id: *,
          title?: *,
          genreTypeNames?: *,
          single?: *,
          noOfEpisodes?: *,
          duration?: *,
          numberAndDuration?: *,
          currency: *,
          form?: *,
          initialReleasePlatformTypeNames?: *,
          shortDescription?: *,
          shootLocation?: *,
        |}
      >,
      ...{|
        schedule?: ?{
          ...$Pick<
            Schedule,
            {|
              stageTypeId?: *,
              stage?: *,
              preProductionStart?: *,
              preProductionEnd?: *,
              productionStart?: *,
              productionEnd?: *,
              postProductionStart?: *,
              postProductionEnd?: *,
              finalMaterialsDeliveryStart?: *,
              finalMaterialsDeliveryEnd?: *,
            |}
          >,
          ...{|
            roughCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
            fineCutDelivery?: ?Array<$Pick<Delivery, {| name: *, date: * |}>>,
          |},
        },
        company?: ?$Pick<Company, {| _id: * |}>,
        companySPVs?: ?Array<{
          ...$Pick<
            SpvCompany,
            {|
              givenName?: *,
              familyName?: *,
              companyName?: *,
              type?: *,
              abn?: *,
              acn?: *,
              email?: *,
            |}
          >,
          ...{|
            address?: ?$Pick<
              AddressType,
              {|
                number: *,
                street: *,
                suburb?: *,
                state?: *,
                postcode: *,
                country: *,
              |}
            >,
          |},
        }>,
      |},
    },
  |},
|};

export type UserDetailsFragment = {
  ...$Pick<
    User,
    {| _id: *, emails: *, givenName?: *, familyName?: *, selectedCompany?: * |}
  >,
  ...{|
    roles?: ?Array<{
      ...$Pick<Role, {| _id: *, name: * |}>,
      ...{|
        rules: Array<
          $Pick<
            Rule,
            {|
              action: *,
              subject: *,
              conditions?: *,
              fields?: *,
              inverted?: *,
            |}
          >
        >,
      |},
    }>,
    rules?: ?Array<
      $Pick<
        Rule,
        {| action: *, subject: *, conditions?: *, fields?: *, inverted?: * |}
      >
    >,
    companies?: ?Array<$Pick<Company, {| _id: *, name?: *, type?: * |}>>,
  |},
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserMutation = {|
  createUser: {|
    user?: ?{
      ...$Pick<
        User,
        {|
          _id: *,
          emails: *,
          givenName?: *,
          familyName?: *,
          selectedCompany?: *,
        |}
      >,
      ...{|
        roles?: ?Array<{
          ...$Pick<Role, {| _id: *, name: * |}>,
          ...{|
            rules: Array<
              $Pick<
                Rule,
                {|
                  action: *,
                  subject: *,
                  conditions?: *,
                  fields?: *,
                  inverted?: *,
                |}
              >
            >,
          |},
        }>,
        rules?: ?Array<
          $Pick<
            Rule,
            {|
              action: *,
              subject: *,
              conditions?: *,
              fields?: *,
              inverted?: *,
            |}
          >
        >,
        companies?: ?Array<$Pick<Company, {| _id: *, name?: *, type?: * |}>>,
      |},
    },
  |},
|};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateUserMutation = {|
  updateUser: {|
    user?: ?{
      ...$Pick<
        User,
        {|
          _id: *,
          emails: *,
          givenName?: *,
          familyName?: *,
          selectedCompany?: *,
        |}
      >,
      ...{|
        roles?: ?Array<{
          ...$Pick<Role, {| _id: *, name: * |}>,
          ...{|
            rules: Array<
              $Pick<
                Rule,
                {|
                  action: *,
                  subject: *,
                  conditions?: *,
                  fields?: *,
                  inverted?: *,
                |}
              >
            >,
          |},
        }>,
        rules?: ?Array<
          $Pick<
            Rule,
            {|
              action: *,
              subject: *,
              conditions?: *,
              fields?: *,
              inverted?: *,
            |}
          >
        >,
        companies?: ?Array<$Pick<Company, {| _id: *, name?: *, type?: * |}>>,
      |},
    },
  |},
|};

export type GetUserQueryVariables = {};

export type GetUserQuery = {|
  user?: ?{
    ...$Pick<
      User,
      {|
        _id: *,
        emails: *,
        givenName?: *,
        familyName?: *,
        selectedCompany?: *,
      |}
    >,
    ...{|
      roles?: ?Array<{
        ...$Pick<Role, {| _id: *, name: * |}>,
        ...{|
          rules: Array<
            $Pick<
              Rule,
              {|
                action: *,
                subject: *,
                conditions?: *,
                fields?: *,
                inverted?: *,
              |}
            >
          >,
        |},
      }>,
      rules?: ?Array<
        $Pick<
          Rule,
          {| action: *, subject: *, conditions?: *, fields?: *, inverted?: * |}
        >
      >,
      companies?: ?Array<$Pick<Company, {| _id: *, name?: *, type?: * |}>>,
    |},
  },
|};
