import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddDetails } from 'app/assets/icons';

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  marginBottom: '20px',
  borderRadius: '20px',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
}));

export const ClauseParagraphsContainer = styled('div')`
  && {
    list-style: none;
    counter-reset: alphabet-counter;
    padding-left: 0;
    ol {
      padding-inline-start: 15px;
    }
    p {
      margin-block-start: 0;
    }
    li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 5px;
      list-style-type: none;
      &::before {
        content: '( ' counter(alphabet-counter, lower-alpha) ' )';
        position: absolute;
        left: 0;
        margin-left: -20px;
        counter-increment: alphabet-counter;
      }
    }
  }
`;

export const AddDetailsIcon = styled(AddDetails)(({ theme }) => ({
  width: 20,
  height: 20,
  '& path': {
    fill: theme.palette.grey['750'],
    cursor: 'pointer',
  },
}));
