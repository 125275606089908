/**
 *
 * LinkData
 * @format
 * @flow
 *
 */

import type { AbstractComponent } from 'react';
import React, { memo, useContext, useEffect, useState } from 'react';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  LoopOutlined as LoopOutlinedIcon,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LinkDataContext } from './contexts/LinkDataContext';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from './types';
import Tooltip from 'app/components/Tooltip/Tooltip';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { CircularProgress } from '@mui/material';

/**
 * LinkData component can be used to any component (e.g TextField)
 * that needs sychronising
 * with database data
 * */
const LinkData: AbstractComponent<PropsType> = ({
  value,
  fieldName,
  applyDefaultSettings,
  linkPropsFilter,
}): Node => {
  const theme = useTheme();
  const {
    onSetIsOpenNotSynchronizeDialog,
    linkDataProps,
    sourceData,
    componentName: { contractTermKeyName },
    setNewFieldValue,
    setFieldName,
    isDataLoading,
    onSetAdditionalLinkDataPropsFilter,
  } = useContext(LinkDataContext);
  const { onUpdateContractTermData } = useContext(ContractContext) || {};

  const [isSynchronized, setIsSychronized] = useState<boolean>(false);
  const { linkData } = linkDataProps || {};
  const {
    isDataSynchronized,
    getToolTipBody,
    loadLazyQuery,
    getDescendantSourceValue,
    getLinkDataProps,
  } = useLinkDataComponent();

  const { source, sourceFieldId, fieldId } =
    getLinkDataProps(linkData, fieldName, linkPropsFilter) || {};

  useEffect(() => {
    if (source) {
      loadLazyQuery(source); // load project/company details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, value]);

  const setDefaultSettings = (sourceData) => {
    if (sourceData?.data) {
      // callback function to set the component default value
      if (applyDefaultSettings) {
        applyDefaultSettings(
          getDescendantSourceValue(sourceData?.data, sourceFieldId)
        );
      } else {
        onUpdateContractTermData(contractTermKeyName, {
          [fieldId]: getDescendantSourceValue(sourceData?.data, sourceFieldId),
        });
      }
    }
  };
  useEffect(() => {
    setIsSychronized(
      isDataSynchronized(value, sourceData?.data, sourceFieldId)
    );

    // upon create contract, this will set the default values for project/company settings
    setDefaultSettings(sourceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceData]);

  useEffect(() => {
    setIsSychronized(
      isDataSynchronized(value, sourceData?.data, sourceFieldId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {isDataLoading ? (
        <CircularProgress
          size="1.5em"
          sx={{ alignSelf: 'center', marginLeft: 3 }}
        />
      ) : (
        <Tooltip body={getToolTipBody(isSynchronized)}>
          {isSynchronized ? (
            <CheckCircleOutlineOutlinedIcon
              sx={{ color: theme.palette.primary.main }}
            />
          ) : (
            <Box
              onClick={() => {
                onSetIsOpenNotSynchronizeDialog(true);
                setFieldName(fieldName);
                setNewFieldValue(value);
                onSetAdditionalLinkDataPropsFilter(linkPropsFilter);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <LoopOutlinedIcon sx={{ color: theme.palette.warning.main }} />
            </Box>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default (memo(LinkData): AbstractComponent<PropsType, mixed>);
