import { Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AutocompleteStyled = styled(Autocomplete)({
  width: '100%',
});

export const PredictiveTextFieldContainer = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '5px',
  input: {
    position: 'relative',
    bottom: '6px',
  },
});
