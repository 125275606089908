/**
 *
 * @format
 * @flow
 *
 */

import * as React from 'react';

import { MenuItemEndIcon, MenuItemStartIcon, MenuItemTitle } from './styles';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type { LinkMenuItemPropsType } from './types';
import { ListItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const LinkMenuItem: React.AbstractComponent<LinkMenuItemPropsType> = ({
  title,
  startIcon,
  to,
  variant,
}: LinkMenuItemPropsType): React.Node => {
  const theme = useTheme();

  return (
    <ListItem
      sx={{
        margin: '0px',
        padding: '0px',
        border: '0px',
        '&:hover': {
          '#menuItemTitle': {
            color: `${theme.palette.secondary.light}`,
          },
        },
      }}
    >
      <NavLink
        to={to}
        style={{
          padding: '4px 9px',
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.text.secondary,
        }}
        activeStyle={{ color: theme.palette.secondary.main }}
      >
        <MenuItemStartIcon>
          <ChevronRightIcon visibility="hidden" />
        </MenuItemStartIcon>
        <MenuItemTitle className="expMenuItem" variant={variant ?? 'body2'}>
          {title}
        </MenuItemTitle>
        <MenuItemEndIcon>
          <ChevronRightIcon visibility="hidden" />
        </MenuItemEndIcon>
      </NavLink>
    </ListItem>
  );
};

export default (React.memo(LinkMenuItem): React.AbstractComponent<
  LinkMenuItemPropsType,
  mixed
>);
