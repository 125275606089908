/**
 *
 * useCreateProjectMutation
 * @format
 * @flow
 *
 */

import type {
  CreateProjectMutation,
  CreateProjectMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { createProjectDetailsFragment } from 'project/graphQL/fragments/projectFragments';

const CREATE_PROJECT = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        ...createProjectDetails
      }
    }
  }
  ${createProjectDetailsFragment}
`;

export type CreateProjectReturnType = {
  createProject: ({
    variables: CreateProjectMutationVariables,
  }) => void,
  loading: boolean,
  data: CreateProjectMutation,
  error: any,
};

export const useCreateProjectMutation = (): CreateProjectReturnType => {
  const [createProject, { data, loading, error }] = useMutation(CREATE_PROJECT);

  return {
    createProject,
    loading,
    data,
    error,
  };
};
