/**
 *
 * ProjectTitle
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useCallback } from 'react';

import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import TextField from 'app/components/TextField/TextField';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { ContractContext } from 'app/contexts/Contract/Contract';

const ProjectTitle: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    onUpdateContractTermData: onUpdate,
    contractTermProps,
  } = useContext(ContractContext);

  const { renderLinkData, getLinkDataProps } = useLinkDataComponent();
  const { linkData } = contractTermProps[contractTermKeyName];

  const { fieldId, showSync } = getLinkDataProps(linkData, 'projectName');

  const termData = contractTermData[contractTermKeyName] || {};
  const fieldValue = termData['projectName'] || '';

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      projectName: '',
    },
  });

  const onUpdateData = useCallback(
    (fieldName: string, fieldVal: string) => {
      onUpdate(contractTermKeyName, { [fieldName]: fieldVal });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <TextField
      id={fieldId}
      value={fieldValue}
      onBlur={(fieldId, fieldVal) => {
        onUpdateData(fieldId, fieldVal);
      }}
      renderLinkData={
        showSync &&
        renderLinkData(fieldValue, fieldId, (projectDetailValue) => {
          if (projectDetailValue && !fieldValue) {
            onUpdateData(fieldId, projectDetailValue);
          }
        })
      }
    />
  );
};

export default (memo(ProjectTitle): AbstractComponent<PropsType, mixed>);
