/**
 *
 * PartyDetailsEmployee
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid } from '@mui/material';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PartyDetailsEmployee: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    firstName = '',
    lastName = '',
    address = '',
    address2 = '',
    city = '',
    state = '',
    postcode = '',
    country = '',
    email = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      partyName: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
      email: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) => {
    const updateObj: { [fieldName: string]: any } = {
      [fieldName]: fieldValue,
    };

    onUpdate(contractTermKeyName, updateObj);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              label="First Name"
              value={firstName}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name"
              value={lastName}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              label="Address line 1"
              value={address}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              label="Address line 2"
              value={address2}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              label="City/Suburb"
              value={city}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              label="State"
              value={state}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="postcode"
              label="Postcode"
              value={postcode}
              numberOnly
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              label="Country"
              value={country}
              onBlur={onUpdateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              type="email"
              label="Email"
              value={email}
              onBlur={onUpdateData}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default (memo(PartyDetailsEmployee): AbstractComponent<
  PropsType,
  mixed
>);
