/**
 *
 * RightToWriteRelatedProds
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import DropdownAddOwn from 'app/containers/DropdownAddOwn/DropdownAddOwn';
import type { PropsType } from '../types';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import { Typography } from '@mui/material';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const RightToWriteRelatedProds = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    dropDownValues = [],
    groupTitle,
    approvalCheckboxText = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    feeForSameFormatAsTheProject = '',
    feeForSameFormatAsTheProjectSpecify = '',
    feeForDifferentFormatAsTheProject = '',
    feeForDifferentFormatAsTheProjectSpecify = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      approvalCheckboxTextValue: '',
      feeForSameFormatAsTheProject: '',
      feeForSameFormatAsTheProjectSpecify: '',
      feeForDifferentFormatAsTheProject: '',
      feeForDifferentFormatAsTheProjectSpecify: '',
    },
  });

  const onUpdateData = (fieldName, fieldValue) => {
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });
  };

  return (
    <>
      <Typography variant="body1">{groupTitle}</Typography>
      <DropdownAddOwn
        selectId="feeForSameFormatAsTheProject"
        textAreaId="feeForSameFormatAsTheProjectSpecify"
        selectValue={feeForSameFormatAsTheProject}
        textAreaValue={feeForSameFormatAsTheProjectSpecify}
        config={
          dropDownValues.find(
            (item) => item.label === 'Fee for same format as the project'
          ) || { label: '', options: [] }
        }
        onUpdate={(fieldName, fieldValue) =>
          onUpdateData(fieldName, fieldValue)
        }
      />
      <DropdownAddOwn
        selectId="feeForDifferentFormatAsTheProject"
        textAreaId="feeForDifferentFormatAsTheProjectSpecify"
        selectValue={feeForDifferentFormatAsTheProject}
        textAreaValue={feeForDifferentFormatAsTheProjectSpecify}
        config={
          dropDownValues.find(
            (item) => item.label === 'Fee for different format to the project'
          ) || { label: '', options: [] }
        }
        onUpdate={(fieldName, fieldValue) =>
          onUpdateData(fieldName, fieldValue)
        }
      />
      <TextGeneratingCheckbox
        id="approvalCheckboxTextValue"
        label="Right subject to financier approval"
        value={approvalCheckboxTextValue}
        textGenerating={approvalCheckboxText}
        onCheck={(id, val) => onUpdate(contractTermKeyName, { [id]: val })}
      />
    </>
  );
};

export default (memo(RightToWriteRelatedProds): AbstractComponent<
  PropsType,
  mixed
>);
