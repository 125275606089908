/**
 *
 * AutocompleteMultiple
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ItemsDataType, PropsType } from './types';
import { Autocomplete as MUIAutocomplete, TextField } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import { Clear as ClearIcon } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';

const filter = createFilterOptions();

const AutocompleteMultiple = (props: PropsType): Node => {
  const theme = useTheme();
  const {
    id,
    label,
    optionLabel = 'title',
    freeSolo = false,
    options,
    values,
    sortByGroup = false,
    onChange,
    onBlur,
    disableFreeInput = false,
  } = props;

  const currentValues = values ? values?.split(', ') : [];
  const [autocompleteValues, setAutocompleteValues] = useState(currentValues);

  const [inputVal, setInputVal] = useState('');

  useEffect(() => {
    if (values) {
      setAutocompleteValues(values?.split(', ') || []);
    }
  }, [values]);

  // take a copy of the options before sorting
  const sortedOptionsGroup = (optionsData: ItemsDataType[]) => {
    return [...optionsData].sort(
      (a: ItemsDataType, b: ItemsDataType) =>
        -b?.idGroup?.localeCompare(a?.idGroup)
    );
  };

  const handleChange = (_, values) => {
    let newValues;
    newValues = values?.map((item) => {
      if (typeof item === 'string') {
        return { [optionLabel]: item };
      } else if (item?.inputValue) {
        return { [optionLabel]: item?.inputValue };
      }
      return item;
    });
    if (disableFreeInput) {
      const whereTitleExistsInOptions = (value) =>
        options.some((option) => option.title === value.title);
      newValues = newValues.filter(whereTitleExistsInOptions);
    }

    onChange(id, newValues);
  };

  const handleInputChange = (e, value) => {
    setInputVal(value);
  };

  const onTextBlur = () => {
    if (onBlur) {
      onBlur(id, inputVal);
      setInputVal('');
    }
  };

  return (
    <>
      <MUIAutocomplete
        id={id}
        multiple
        freeSolo={freeSolo}
        inputValue={inputVal}
        value={autocompleteValues?.map((item) => {
          return { [optionLabel]: item };
        })}
        options={
          sortByGroup
            ? // $FlowFixMe
              sortedOptionsGroup(options)
            : options
        }
        groupBy={(option) => sortByGroup && option?.idGroup}
        getOptionLabel={(option) =>
          option?.[optionLabel] ? option?.[optionLabel] : ''
        }
        isOptionEqualToValue={(option, value) =>
          option?.[optionLabel] === value?.[optionLabel]
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option?.[optionLabel]
          );
          if (!isExisting && !disableFreeInput) {
            filtered.push({
              inputValue,
              [optionLabel]: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        clearIcon={
          <div
            style={{ display: 'flex' }}
            onClick={() => {
              setAutocompleteValues([]);
            }}
          >
            <ClearIcon fontSize="small" />
          </div>
        }
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <>
            <Typography
              variant="body2"
              sx={{ marginBottom: 1, color: 'rgba(0, 0, 0, 0.6)' }}
            >
              {label}
            </Typography>
            <TextField {...params} onBlur={onTextBlur} />
          </>
        )}
        ChipProps={{
          deleteIcon: (
            <ClearIcon
              fontSize="small"
              style={{ fill: theme.palette.grey['750'] }}
            />
          ),
          sx: {
            backgroundColor: theme.palette.grey['200'],
            height: '25px',
            borderRadius: '6px',
            flexDirection: 'row',
            bottom: '6px',
          },
        }}
        sx={{ gap: '4px' }}
      />
    </>
  );
};

export default (memo(AutocompleteMultiple): AbstractComponent<
  PropsType,
  mixed
>);
