/**
 *
 * TextSuggestions
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const TextSuggestions = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { creditLabel = 'Credit', suggestions = [] }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const { textSelection = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      textSelection: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <TextAreaSuggestions
        id="textSelection"
        label={creditLabel}
        value={textSelection}
        suggestions={suggestions}
        onSubmitDescription={(id, val) => {
          onUpdateData(id, val);
        }}
        onChangeTextarea={(id, val) => {
          onUpdateData(id, val);
        }}
      />
    </>
  );
};

export default (memo(TextSuggestions): AbstractComponent<PropsType, mixed>);
