/**
 *
 * InitialReleaseInput
 * @format
 * @flow
 *
 */

import { Autocomplete, Chip, Grid } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useMemo } from 'react';

import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import TextField from 'app/components/TextField/TextField';
import type { PropsType } from './types';

const filter = createFilterOptions();

const InitialReleaseInput: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const theme = useTheme();
  const {
    initialReleasePlatformTypeNames,
    initialReleasePlatformValues,
    handleStateChange,
  } = props;

  const initialReleasePlatformTypeNamesMemo = useMemo(
    () => initialReleasePlatformTypeNames?.map((title) => ({ title })),
    [initialReleasePlatformTypeNames]
  );

  const options = useMemo(
    () =>
      initialReleasePlatformValues?.map((item) => ({
        title: item.displayValue,
      })),
    [initialReleasePlatformValues]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Autocomplete
          multiple
          id="initialReleasePlatformTypeNames"
          value={initialReleasePlatformTypeNamesMemo || []}
          freeSolo
          clearOnBlur
          selectOnFocus
          onChange={(event, newValue) => {
            handleStateChange('initialReleasePlatformTypeNames', [
              ...newValue.map((item) => {
                if (typeof item === 'string') {
                  return item;
                } else if (item.inputValue) {
                  return item.inputValue;
                } else {
                  return item.title;
                }
              }),
            ]);
          }}
          options={options || []}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExistingInOptions = options?.some(
              (option) => inputValue === option.title
            );

            const isExistingInitialReleasePlatformTypeNamesMemo =
              initialReleasePlatformTypeNamesMemo?.some(
                (option) => inputValue === option.title
              );

            if (
              inputValue !== '' &&
              !isExistingInOptions &&
              !isExistingInitialReleasePlatformTypeNamesMemo &&
              !filtered?.length
            ) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          isOptionEqualToValue={(option, value) =>
            option?.title === value?.title
          }
          getOptionLabel={(option) => option.title}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.title}
                {...getTagProps({ index })}
                sx={{
                  flexDirection: 'row',
                  backgroundColor: theme.palette.grey['200'],
                  height: '25px',
                  borderRadius: '6px',
                  bottom: '6px',
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} onFocus={() => {}} label="Initial Release" />
          )}
          sx={{
            '#initialReleasePlatformTypeNames': { paddingTop: 0 },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(InitialReleaseInput): AbstractComponent<PropsType, mixed>);
