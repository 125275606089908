import * as React from 'react';

import { Box } from '@mui/material';
import SideMenu from './SideMenu/SideMenu';
import TopMenu from './TopMenu/TopMenu';
import ViewRouter from 'app/routes/ViewRouter';
import dashboardRoutes from 'app/routes/dashboardRoutes';
import menuData from './menuData.json';

const DashboardPage = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <TopMenu onToggleDrawClick={handleDrawerToggle} />
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          paddingTop: '56px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <SideMenu
            mobile
            open={mobileOpen}
            onClose={handleDrawerToggle}
            container={container}
            data={menuData}
          />
          <SideMenu data={menuData} />
          <Box sx={{ width: '100%' }} component="main">
            <Box
              sx={{
                padding: '40px 24px 50px',
                '&:has(.contract-view)': {
                  padding: '0',
                },
                '&:has(.project-view)': {
                  padding: '0',
                },
                '&:has(.company-view)': {
                  padding: '0',
                },
              }}
            >
              <ViewRouter routeArray={dashboardRoutes} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardPage;
