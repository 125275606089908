/**
 *
 * Tooltip
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Body, Title } from './styles';
import { ClickAwayListener, Tooltip as MUITooltip } from '@mui/material';
import React, { memo, useState } from 'react';

import type { PropsType } from './types';
import { useTheme } from '@mui/material/styles';

const Tooltip: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    children,
    placement = 'top',
    trigger = 'hover',
    arrow = true,
    title,
    body,
    htmlBody,
  } = props;

  const theme = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipStyle = {
    sx: {
      bgcolor: '#ffffff',
      color: `${theme.palette.primary.main}`,
      maxWidth: 674,
      borderRadius: '8px',
      border: `0.1px solid #c0c0c0`,
      padding: 2,
      marginTop: '16px',
    },
  };

  const arrowStyle = {
    sx: {
      fontSize: 20,
      '&::before': {
        backgroundColor: `${theme.palette.primary.light}`,
      },
    },
  };

  const renderTooltipContent = () => (
    <>
      {title && <Title>{title}</Title>}
      {body && <Body>{body}</Body>}
      {htmlBody && <>{htmlBody}</>}
    </>
  );

  return (
    <>
      {trigger === 'hover' && (
        <MUITooltip
          arrow={arrow}
          title={renderTooltipContent()}
          placement={placement}
          componentsProps={{
            tooltip: tooltipStyle,
            arrow: arrowStyle,
          }}
        >
          {children}
        </MUITooltip>
      )}

      {trigger === 'click' && (
        <ClickAwayListener
          onClickAway={() => {
            setTooltipOpen(false);
          }}
        >
          <MUITooltip
            arrow={arrow}
            onClose={() => {
              setTooltipOpen(false);
            }}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={renderTooltipContent()}
            placement={placement}
            componentsProps={{
              tooltip: tooltipStyle,
              arrow: arrowStyle,
            }}
          >
            {React.cloneElement(children, {
              onClick: () => {
                setTooltipOpen(true);
              },
            })}
          </MUITooltip>
        </ClickAwayListener>
      )}
    </>
  );
};

export default (memo(Tooltip): AbstractComponent<PropsType, mixed>);
