/**
 *
 * Users
 * @format
 * @flow
 *
 */

import { Fab, Grid } from '@mui/material';
import React, { useState } from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import type { Node } from 'react';
import Tooltip from 'app/components/Tooltip/Tooltip';
import UsersModal from './UsersModal/UsersModal';
import UsersTable from './UsersTable/UsersTable';

const Users = (): Node => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item xs={12}>
          <UsersTable />
        </Grid>
        <Grid item xs={12} sx={{ alignSelf: 'flex-end', marginTop: 2 }}>
          <Tooltip body="Add new user" placement="top">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <UsersModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

export default Users;
