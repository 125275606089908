import { RadioGroup, RadioGroupProps } from '@mui/material';
import { styled } from '@mui/system';

export const RadioButtonGroupLabel = styled('div')`
  font-weight: bold;
  margin: 9px 0;
`;

export const ErrorContainer = styled('div')`
  text-align: right;
  padding-top: 9px;
  font-size: 0.9rem;
  color: #d50808;
  border-top: solid 2px #d50808;
  line-height: 1.66;
  margin: 9px 0;
`;

type StyledRadioGroupProps = RadioGroupProps & { row?: boolean };

export const StyledRadioGroup = styled(RadioGroup)`
  && {
    flex-direction: ${(props: StyledRadioGroupProps) =>
      props.row ? 'row' : 'column'};

    .MuiFormControlLabel-root {
      margin-right: 43px;
    }
  }
`;
