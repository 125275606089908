// @flow
import * as React from 'react';

import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = { children: any };

const ComponentWrapper = ({ children }: Props): React.Node => {
  const theme = useTheme();
  return (
    <Paper elevation={2} sx={{
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(4),
    }}>
      {children}
    </Paper>
  );
};

export default ComponentWrapper;
