import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  marginBottom: '20px',
  borderRadius: '20px',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
}));
