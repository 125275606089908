import {
  ArrowSort as Icon,
  PlusSmall as PlusSmallIcon,
} from 'app/assets/icons';

import DrawerBase from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import ListBase from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import Menu from 'app/components/core/Menu/Menu';

const Drawer = styled(DrawerBase)(({ theme, mobile }) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: '68px',
  top: '56px',
  zIndex: '20',
  willChange: 'margin',
  [theme.breakpoints.up('lg')]: {
    marginRight: '256px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  '& .MuiDrawer-paper': {
    overflowX: 'hidden',
    bottom: '0px',
    height: 'auto',
    width: '70px',
    padding: '20px 16px',
    willChange: 'margin, box-shadow',
    // transition: 'width 195ms cubic-bezier(0.4, 0, 1, 1) 0ms',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      width: '256px',
    },
    [theme.breakpoints.down('lg')]: {
      // slide the paper out on hover and override the styles
      // for the ExpandableMenuItem title and end icon so they
      // become visible again
      '&:hover': {
        width: '256px',
        boxShadow: 'rgb(0 0 0 / 10%) 16px 0px 16px',
        '& [class*="expMenuItem"]': {
          opacity: 1,
        },
        '& [id*="expandMenuButton"]': {
          opacity: 1,
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      // mobile draw will be visible
      // so hide the menu button
      '& [id*="expandMenuButton"]': {
        opacity: 0,
      },
    },
  },
  '& .MuiSpeedDialAction-staticTooltipLabel': {
    fontSize: 10,
    width: '120px'
  },
}));

const MobileDraw = styled(DrawerBase)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    top: '56px',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    width: '256px',
    bottom: '0px',
    height: 'auto',
    padding: '24px 16px',
    '& [class*="expMenuItem"]': {
      opacity: 1,
    },
    '& [id*="expandMenuButton"]': {
      opacity: 1,
    },
  },
}));

const List = styled(ListBase)(({ theme }) => ({
  padding: '0',
}));

const MenuItemTitle = styled(Typography)(({ theme }) => ({
  opacity: 1,
  [theme.breakpoints.down('lg')]: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: '10px',
  minWidth: '2rem',
}));

const MenuItemStartIcon = styled('span')(({ theme }) => ({
  width: '25px',
  display: 'inline-flex',
  alignItems: 'center',
  flexShrink: '0',
  margin: '0',
  transition:
    'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
}));

const MenuItemEndIcon = styled('span')(({ theme }) => ({
  opacity: 1,
  [theme.breakpoints.down('lg')]: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      opacity: 1,
    },
  },
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: '0',
  marginLeft: 'auto',
  color: 'rgb(150, 152, 157)',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <MenuItemEndIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  [theme.breakpoints.down('lg')]: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const ExpandableMenuItemRoot = styled('button', {
  shouldForwardProp: (prop) => prop !== 'activeChild' && prop !== 'ownerState',
})(
  ({ theme, activeChild }) => `
  display: flex;
  background-color: transparent;
  width: 100%;
  padding: 8px;
  color: ${
    activeChild ? theme.palette.primary.light : theme.palette.text.secondary
  };
  align-items: center;
  border: none;

  &.MuiButtonBase-root:hover {
    background-color: 'transparent';
  }

  &:hover {
    color: ${theme.palette.primary.main};
  }

  &.active {
    background-color: 'transparent';
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  `
);

const ArrowSort = styled(Icon)(({ theme }) => ({
  height: '24px',
  width: '24px',
  cursor: 'pointer',
}));

const CompanySwitchContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: '0 8px 8px',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'noWrap',
    paddingLeft: '3px',
  },
}));

const CompanyListContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'createOrg',
})(({ theme, createOrg }) => ({
  display: 'flex',
  padding: '12px 16px',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'noWrap',
  },
  '&:hover': {
    background: theme.palette.grey['200'],
    borderRadius: '10px',
  },
  gap: createOrg ? '12px' : '',
}));

const CompanySwitchMenuContainer = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '300px',
    padding: '10px',
  },
}));

const CreateOrgContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '12px 16px',
  cursor: 'pointer',
  gap: '12px',
}));

const PlusSmall = styled(PlusSmallIcon)(({ theme }) => ({
  height: '24px',
  width: '24px',
  cursor: 'pointer',
  background: theme.palette.grey['300'],
  borderRadius: '20px',
  '& path': {
    stroke: theme.palette.grey['750'],
  },
}));

const CompanySwitchTitle = styled(Typography)(({ theme }) => ({
  opacity: 1,
  width: '174px',
  [theme.breakpoints.down('lg')]: {
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export {
  ArrowSort,
  CompanyListContainer,
  CompanySwitchContainer,
  CompanySwitchMenuContainer,
  CompanySwitchTitle,
  CreateOrgContainer,
  Drawer,
  ExpandMore,
  ExpandableMenuItemRoot,
  List,
  MenuItemEndIcon,
  MenuItemStartIcon,
  MenuItemTitle,
  MobileDraw,
  PlusSmall
};
