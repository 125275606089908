/**
 *
 * AccountDetails
 * @format
 * @flow
 *
 */

import { Alert, Grid, Paper, Snackbar } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect, useState } from 'react';
import type { AccountDetailsInfoType, PropsType } from './types';

import AddressInfo from 'app/components/AddressInfo/AddressInfo';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import { filterObjectDataRemovingNulls } from 'app/utils/general';
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import type { UpdateCompanyDetailsReturnType } from 'company/graphQL/useUpdateCompanyDetailsMutation';
import { useUpdateCompanyDetailsMutation } from 'company/graphQL/useUpdateCompanyDetailsMutation';
import { useParams } from 'react-router-dom';

const AccountDetails: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const {
    data: companyDetailsData,
    loading,
    error,
  } = useCompanyDetailsQuery({
    _id: cyid,
  });
  const {
    updateCompanyDetails,
    data: updateCompanyDetailsData,
    loading: companyDetailsIsSaving,
  }: UpdateCompanyDetailsReturnType = useUpdateCompanyDetailsMutation();

  const [data, setData] = useState<AccountDetailsInfoType>({
    name: '',
    shortCompanyName: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    abn: '',
    email: '',
    type: 'COMPANY',
  });
  const {
    name,
    shortCompanyName,
    street,
    suburb,
    state,
    postcode,
    country,
    abn,
    email,
    type,
  } = data;

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  useEffect(() => {
    if (companyDetailsData) {
      const company = companyDetailsData.company || {};
      // $FlowFixMe
      const { __typename, ...restCompanyData } = company;
      const filteredCompanyDataWithNullsRemoved = filterObjectDataRemovingNulls(
        restCompanyData,
        ['name', 'shortCompanyName', 'abn', 'email', 'type'],
        restCompanyData?.address
      );

      // $FlowFixMe
      setData((prevState) => ({
        ...prevState,
        ...filteredCompanyDataWithNullsRemoved,
      }));
    }
  }, [companyDetailsData]);

  useEffect(() => {
    if (!!updateCompanyDetailsData?.updateCompanyDetails?.company) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the company details!',
      });
      setSnackbarOpen(true);
    }
    if (updateCompanyDetailsData?.updateCompanyDetails?.company === null) {
      setAlertInfo({
        severity: 'error',
        message: 'Error saving the company details!',
      });
      setSnackbarOpen(true);
    }
  }, [updateCompanyDetailsData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!companyDetailsData?.company) return <p>Not found</p>;

  const handleStateChange = (fieldName: string, fieldValue: any) => {
    // $FlowFixMe
    setData((prevState) => {
      let newData = {
        ...prevState,
        [fieldName]: fieldValue,
      };

      return newData;
    });
  };

  const handleUpdateCompanyDetails = () => {
    updateCompanyDetails({
      variables: {
        input: {
          _id: cyid,
          name,
          shortCompanyName,
          address: {
            number: 0,
            street: street || '',
            suburb,
            state,
            postcode: postcode || '',
            country: country || '',
          },
          abn,
          email,
          type,
        },
      },
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Paper elevation={1}>
          <Grid container justifyContent="space-between">
            <Grid item lg={12} md={12} xs={12}>
              <TextField
                id="name"
                label="Name"
                value={name}
                onBlur={handleStateChange}
                tooltipBody="Include full name company name here (including Pty Ltd etc)."
              />
            </Grid>
            <Select
              id="type"
              label="Type"
              value={type}
              onSelectOption={(item) => {
                !Array.isArray(item) && handleStateChange('type', item.value);
              }}
              options={[
                { value: 'COMPANY', label: 'Company (e.g. Pty Ltd)' },
                { value: 'INDIVIDUAL', label: 'No Company use my details' },
              ]}
            />
            <Grid item lg={12} md={12} xs={12}>
              <TextField
                id="shortCompanyName"
                label="Company short name"
                value={shortCompanyName}
                onBlur={handleStateChange}
              />
            </Grid>
          </Grid>
          <AddressInfo
            street={street}
            suburb={suburb}
            state={state}
            postcode={postcode}
            country={country}
            onUpdateData={handleStateChange}
          />
          <Grid container>
            <Grid item lg={5.4} md={5.4} xs={5.4}>
              <TextField
                id="abn"
                label="ABN"
                value={abn}
                numberOnly
                onBlur={handleStateChange}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <TextField
                id="email"
                label="Email Contact"
                value={email}
                onBlur={handleStateChange}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs>
        <LoadingButton
          isLoading={companyDetailsIsSaving}
          loadingText="Saving..."
          defaultText="Save"
          onClick={handleUpdateCompanyDetails}
        />
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default (memo(AccountDetails): AbstractComponent<PropsType, mixed>);
