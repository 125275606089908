/**
 *
 * TextGeneratingRadioButton
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import type { PropsType } from './types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import { nanoid } from 'nanoid';

const TextGeneratingRadioButton = (props: PropsType): Node => {
  const { id, label, value, textGenerating, checked, onValueChange } = props;

  return (
    <RadioButton
      id={id}
      label={label}
      value={value || nanoid()}
      checked={checked}
      onValueChange={(id, val) => onValueChange(id, val, textGenerating)}
    />
  );
};

export default (memo(TextGeneratingRadioButton): AbstractComponent<
  PropsType,
  mixed
>);
