/**
 *
 * FurtherWorkOption
 * @format
 * @flow
 *
 */

import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Divider, Grid } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';
import type {
  ComponentDataType,
  ComponentPropsType,
  FurtherWorkOptionItemType,
} from './types';

import Typography from '@mui/material/Typography';
import CurrencyField from 'app/components/CurrencyField/CurrencyField';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import type { PropsType } from '../types';

const FurtherWorkOption: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    suggestionValues1 = [],
    suggestionValues2 = [],
    textAreaLabel1 = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const { items = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      items: [
        {
          description: '',
          fee: '',
          notification: '',
          delivery: '',
          weeks: '',
        },
      ],
    },
  });

  const onAddFurtherDraftItem = () => {
    const furtherDraftItem = {
      description: '',
      fee: '',
      notification: '',
      delivery: '',
      weeks: '',
    };
    const newItems = [...items, furtherDraftItem];
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const onRemoveFurtherDraftItem = (
    selectedItem: FurtherWorkOptionItemType
  ) => {
    const newItems = items.filter((item) => item !== selectedItem);
    onUpdate(contractTermKeyName, { items: newItems });
  };

  const onUpdateData = (
    fieldName: string,
    fieldValue: string | boolean,
    index?: number = 0
  ) => {
    if (fieldName === 'switchVal' && typeof fieldValue === 'boolean') {
      onUpdate(contractTermKeyName, { [fieldName]: fieldValue });
    } else {
      const newItems = [...items];
      const currItem = newItems[index];
      const newItem = {
        ...currItem,
        [fieldName]: fieldValue,
      };
      newItems[index] = newItem;
      onUpdate(contractTermKeyName, { items: newItems });
    }
  };

  return (
    <Grid
      container
      direction="row-reverse"
      alignItems="center"
      justifyContent="space-between"
    >
      {items.map((item, index) => {
        const { description, fee, notification, delivery, weeks } = item;
        return (
          <React.Fragment key={index}>
            {index >= 1 && (
              <Grid item p={4} xs={12}>
                <Divider />
              </Grid>
            )}
            <br />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                  Item {index + 1}
                </Typography>
              </Grid>
              {index >= 1 && (
                <Grid item>
                  <Button
                    onClick={() => onRemoveFurtherDraftItem(item)}
                    size="large"
                    startIcon={<DeleteIcon />}
                  ></Button>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextAreaSuggestions
                  id={'description'}
                  label="Description"
                  value={description}
                  suggestions={suggestionValues1 || []}
                  onSubmitDescription={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                  onChangeTextarea={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <CurrencyField
                  id="fee"
                  label="Fee"
                  value={fee}
                  required
                  formatMoney
                  numberOnly
                  startAdornment={<>$</>}
                  onBlur={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                />
              </Grid>
              <br />

              <Grid item sm={12}>
                <Typography variant="subtitle1">Payable</Typography>
              </Grid>
              <Grid item sm={6}>
                <CurrencyField
                  id="notification"
                  label={textAreaLabel1 || ''}
                  numberOnly
                  value={notification}
                  required
                  endAdornment={<>%</>}
                  onBlur={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <CurrencyField
                  id="delivery"
                  label="Delivery"
                  numberOnly
                  value={delivery}
                  required
                  endAdornment={<>%</>}
                  onBlur={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextAreaSuggestions
                  id={'weeks'}
                  label="Delivery period"
                  value={weeks}
                  suggestions={suggestionValues2 || []}
                  onSubmitDescription={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                  onChangeTextarea={(id, val) => {
                    onUpdateData(id, val, index);
                  }}
                  disabled={weeks === 'TBA'}
                  required
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}

      <Grid item xs={12}>
        <Button
          onClick={() => onAddFurtherDraftItem()}
          size="large"
          startIcon={<AddIcon />}
          sx={{ justifyContent: 'start' }}
        ></Button>
      </Grid>
    </Grid>
  );
};

export default (memo(FurtherWorkOption): AbstractComponent<PropsType, mixed>);
