/**
 *
 * Contract utilities
 * @format
 * @flow
 *
 */

import type {
  Contract,
  ContractTerm,
  Scalars,
  StandardTermSection,
} from '../services/graphQL/generated-types';

import lodash from 'lodash';

/**
 *
 * parse the attribute pattern of component data into flat object
 *
 * example:
 *
 * input data
 * {"stringData": [{ "k": "firstName", "v": "Smith" }]}
 * {"numberData": [{ "k": "duration", "v": 1 }]}
 * {"boolData": [{ "k": "switchVal", "v": false }]}
 * ...
 *
 * output data
 * { "firstName": "Smith", "duration": 1, "switchVal": false }
 */
export const parseComponentData = (
  componentData: Array<{ k: string, v: string | number | boolean | any }>
): Object => {
  return componentData?.reduce((acc, attrPattern) => {
    const { k, v } = attrPattern;
    return {
      ...acc,
      [k]: v,
    };
  }, {});
};

/**
 *
 * converts back the component data from object into attribute pattern
 *
 * example:
 *
 * input data
 * { "firstName": "Smith", "duration": 1, "switchVal": false }
 *
 * output data
 * {"stringData": [{ "k": "firstName", "v": "Smith" }]}
 * {"numberData": [{ "k": "duration", "v": 1 }]}
 * {"boolData": [{ "k": "switchVal", "v": false }]}
 * ...
 */
export const convertComponentDataToAttributePattern = (
  componentData: Array<$ElementType<Scalars, 'JSONObject'>>
): Object => {
  const termComponentData = Object.entries(componentData || {});
  return termComponentData.reduce(
    (acc, item) => {
      let keyDataName = '';
      const [key, val] = item;

      switch (typeof val) {
        case 'string':
          keyDataName = 'stringData';
          break;
        case 'boolean':
          keyDataName = 'boolData';
          break;
        case 'number':
          keyDataName = 'numberData';
          break;
        case 'object':
          if (Array.isArray(val)) {
            keyDataName = 'arrayData';
          } else {
            keyDataName = 'objectData';
          }
          break;
        default:
          break;
      }

      return {
        ...acc,
        [`${keyDataName}`]: [...acc[`${keyDataName}`], { k: key, v: val }],
      };
    },
    {
      stringData: [],
      numberData: [],
      boolData: [],
      objectData: [],
      arrayData: [],
    }
  );
};

export const prepareContractDetails = (
  contractDetails: Contract,
  contractTermData: Object,
  contractStandardTermSections: StandardTermSection[]
): {
  _id: string,
  sectionNames: Array<string>,
  terms: Array<ContractTerm>,
  title: string,
  standardTermSections: StandardTermSection[],
} => {
  const cloneContractDetails = lodash.cloneDeep(contractDetails);

  const termDataInAttributePattern = {};

  Object.entries(contractTermData).forEach((value, index: number) => {
    const [termKey, termData]: [string, Object] = value;
    const termId = termKey.split('-')[1];
    const convertedComponentData =
      convertComponentDataToAttributePattern(termData);
    termDataInAttributePattern[termId] = convertedComponentData;
  });

  const newTerms = cloneContractDetails.terms.map((item) => {
    // $FlowFixMe
    const { __typename, ...restItem } = item;
    return {
      ...restItem,
      ...termDataInAttributePattern[item.termId],
    };
  });

  const newStandardTermSections = contractStandardTermSections.map(
    (standardTermSection) => {
      // $FlowFixMe
      const { __typename, ...restStandardTermSection } = standardTermSection;
      return {
        ...restStandardTermSection,
        terms: standardTermSection.terms.map((term) => {
          // $FlowFixMe
          const { __typename, amendment, ...restTerm } = term;
          return {
            ...restTerm,
            ...(amendment && { amendment }),
            clauseParagraphs: term.clauseParagraphs.map((clauseParagraph) => {
              // $FlowFixMe
              const { __typename, ...restClauseParagraph } = clauseParagraph;
              return {
                ...restClauseParagraph,
              };
            }),
            references: term.references.map((reference) => {
              // $FlowFixMe
              const { __typename, ...restReference } = reference;
              return {
                ...restReference,
              };
            }),
          };
        }),
      };
    }
  );

  cloneContractDetails.terms = newTerms;
  // $FlowFixMe
  cloneContractDetails.standardTermSections = newStandardTermSections;

  const {
    _id,
    sectionNames,
    terms,
    title,
    standardTermSections = [],
  } = cloneContractDetails;

  return {
    _id,
    sectionNames,
    terms,
    title,
    // $FlowFixMe
    standardTermSections,
  };
};

export const getContractPartyName = (contractDetails: Contract) => {
  let partyName;
  const employeeContract = contractDetails?.terms?.find(
    (d) => d.uiComponentName === 'PartyDetailsEmployee'
  );

  const contractorContract = contractDetails?.terms?.find(
    (d) => d.uiComponentName === 'PartyDetailsContractor'
  );

  if (employeeContract) {
    const { stringData } = employeeContract;
    const { firstName, lastName } = parseComponentData(stringData);
    partyName = firstName || lastName ? `${firstName} ${lastName}` : '-';
  }

  if (contractorContract) {
    const { stringData } = contractorContract;
    const { companyName, firstName, lastName } = parseComponentData(stringData);
    partyName = companyName
      ? companyName
      : firstName || lastName
      ? `${firstName} ${lastName}`
      : '-';
  }
  return partyName;
};

export const scrollIntoView = (el, yOffset) => {
  const y = el?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
};
