/**
 *
 * useUpdateProjectMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { createProjectDetailsFragment } from 'project/graphQL/fragments/projectFragments';

const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        ...createProjectDetails
      }
    }
  }
  ${createProjectDetailsFragment}
`;

export type UpdateProjectReturnType = {
  updateProject: ({
    variables: UpdateProjectMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateProjectMutation,
  error: any,
};

export const useUpdateProjectMutation = (): UpdateProjectReturnType => {
  const [updateProject, { data, loading, error }] = useMutation(UPDATE_PROJECT);

  return {
    updateProject,
    loading,
    data,
    error,
  };
};
