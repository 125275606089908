import { Paper as MPaper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'Paper';

const classes = {
  paper: `${PREFIX}-paper`
};

const StyledMPaper = styled(MPaper)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    width: '100%',
  }
}));

const Paper = ({ children }) => {

  return <StyledMPaper className={classes.MPaper}>{children}</StyledMPaper>;
};

export default Paper;
