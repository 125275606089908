/**
 *
 * ProjectContributorContracts
 * @format
 * @flow
 *
 */

import type { ContractDetailsReturnType } from 'contract/graphQL/useProjectContractsQuery';
import ContractsTable from 'contract/components/ContractsTable/ContractsTable';
import { Grid } from '@mui/material';
import type { Node } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useProjectContractsQuery } from 'contract/graphQL/useProjectContractsQuery';

const ProjectContributorContracts = (): Node => {
  const routeParams = useParams();
  const ptid = routeParams.ptid || '';
  const { loading, error, data }: ContractDetailsReturnType =
    useProjectContractsQuery({ _id: ptid ?? '' });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  const { projectContracts } = data;

  return (
    <Grid container>
      <Grid item xs={12}>
        <ContractsTable data={projectContracts} />
      </Grid>
    </Grid>
  );
};

export default ProjectContributorContracts;
