/**
 *
 * UsersTable
 * @format
 * @flow
 *
 */

import type { CompanyUsersReturnType } from 'company/graphQL/useCompanyUsersQuery';
import { Grid } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import type { Node } from 'react';
import React from 'react';
import { useCompanyUsersQuery } from 'company/graphQL/useCompanyUsersQuery';

const UsersTable = (): Node => {
  const { loading, error, data }: CompanyUsersReturnType =
    useCompanyUsersQuery();
  const columns = [
    {
      name: '_id',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'givenName',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'familyName',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data) return <p>Not found</p>;

  const { companyUsers } = data;

  return (
    <Grid>
      <MUIDataTable
        title="Users"
        data={companyUsers?.map((item) => {
          const {
            _id = '',
            givenName = '',
            familyName = '',
            emails = [],
          } = item || {};
          return [_id, givenName, familyName, emails?.[0]];
        })}
        columns={columns}
        options={options}
      ></MUIDataTable>
    </Grid>
  );
};

export default UsersTable;
