/**
 *
 * PredictiveTextField
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';

import { Grid } from '@mui/material';
import PredictiveTextFieldBase from 'app/components/PredictiveTextField/PredictiveTextField';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import { useUISelectValuesConfigQuery } from 'project/graphQL/useUISelectValuesConfigQuery';
import type { PropsType } from '../types';

const PredictiveTextField = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermProps,
    contractTermData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);
  const { renderLinkData, getLinkDataProps } = useLinkDataComponent();

  // $FlowFixMe
  const { predictiveValue = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const {
    label = 'Type',
    linkData,
    items = [],
    configFieldName,
  }: // $FlowFixMe,
  ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const { data: configData } = useUISelectValuesConfigQuery({
    skip: items?.length > 0,
  });

  const predictiveTextFieldValues =
    configData?.uiSelectValuesConfig?.contractDetailSelectValues?.[
      configFieldName
    ] || [];

  const { showSync, fieldId } =
    getLinkDataProps(linkData, 'predictiveValue') || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      predictiveValue: '',
    },
  });

  const predictiveTextFieldOptions = predictiveTextFieldValues?.map((item) => ({
    idGroup: item?.idGroup || '',
    selectVal: item.displayValue,
  }));

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <PredictiveTextFieldBase
          id="predictiveValue"
          label={label}
          freeSolo
          options={items?.length > 0 ? items : predictiveTextFieldOptions}
          sortByGroup
          inputValue={predictiveValue}
          onBlur={(id, value) => onUpdateData('predictiveValue', value)}
          renderLinkData={
            showSync &&
            renderLinkData(predictiveValue, fieldId, (sourceFieldValue) => {
              if (sourceFieldValue !== null && predictiveValue === '') {
                onUpdateData('predictiveValue', sourceFieldValue);
              }
            })
          }
        />
      </Grid>
    </Grid>
  );
};

export default (memo(PredictiveTextField): AbstractComponent<PropsType, mixed>);
