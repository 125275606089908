/**
 *
 * General
 * @format
 * @flow
 *
 */

import { anyPass, isEmpty, isNil } from 'ramda';
// $FlowFixMe
import { format, isValid, parse } from 'date-fns';
import { pickBy, sample } from 'lodash';

const isNilOrEmpty: (...args: Array<?(any | string | Array<any>)>) => boolean =
  anyPass([isNil, isEmpty]);

const largestItemId = (items = []): string =>
  items.reduce(
    (prev, cur) =>
      parseInt(cur.itemId, 10) > parseInt(prev, 10) ? cur.itemId : prev,
    '0'
  );

const isOtherType = (typeId) => {
  return parseInt(typeId, 10) === 0;
};

/**
 * Filters the target object removing any keys that are not in the keysToKeep array. It then adds
 * the keys from the additionalObject and finally removes any null values, returning the result.
 *
 *
 * @param {object} targetObject - The object to filter
 * @param {array} keysToKeep - The keys to keep in the targetObject
 * @param {object} additionalObject - The object to add to the targetObject
 * @returns {object} - The filtered object
 */
const filterObjectDataRemovingNulls = (
  targetObject: { ... },
  keysToKeep: Array<string>,
  additionalObject: ?{ ... }
): { ... } => {
  return pickBy({
    ...pickBy(targetObject, (value, key) => keysToKeep.includes(key)),
    ...additionalObject,
  });
};

const formatDate = (date) => {
  let formattedDate;
  const yearMonthDayFormat = 'yyyy-MM-dd';
  const parsedDate = parse(date, 'dd MMM yyyy', new Date());

  /**
   * since the date saved to contracts is in the format dd MMM yyyy,
   * we need to convert it to yyyy-MM-dd
   * to check it against the value from project details
   * */
  if (date) {
    if (isValid(parsedDate)) {
      formattedDate = format(parsedDate, yearMonthDayFormat);
    } else {
      formattedDate = format(
        parse(date, yearMonthDayFormat, new Date()),
        yearMonthDayFormat
      );
    }
  }
  return formattedDate;
};

const randomColorPicker = () => {
  const colorList = [
    '#F3E3FB',
    '#EAF0FE',
    '#FDF5ED',
    '#F4CE9B',
    '#B4E5BC',
    '#DEE2E6',
    '#E1E7DD',
  ];
  return sample(colorList);
};

const getUserColor = () => {
  return localStorage.getItem('user-color');
};

export {
  filterObjectDataRemovingNulls,
  formatDate,
  getUserColor,
  isNilOrEmpty,
  isOtherType,
  largestItemId,
  randomColorPicker,
};
