/**
 *
 * PredicativeTextAndSuggestionsFields
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useCallback, useContext } from 'react';

import AutocompleteMultiple from 'app/components/AutocompleteMultiple/AutocompleteMultiple';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PredicativeTextAndSuggestionsFields = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermProps,
    contractTermData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { workMaterial = '', shortDescription = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const {
    predicativeTextId = 'workMaterial',
    predicativeTextLabel = 'Work/Material Type',
    suggestionTextId = 'shortDescription',
    suggestionTextLabel = 'Short Description',
    predicativeValues = [],
    suggestionValues = [],
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      items: [],
      workMaterial: '',
      shortDescription: '',
    },
  });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteMultiple
          id={predicativeTextId}
          label={predicativeTextLabel}
          freeSolo
          values={workMaterial}
          options={predicativeValues}
          onChange={(id, values) => {
            const newValues = values
              .map((item) => {
                return item.title;
              })
              .join(', ');
            onUpdate(contractTermKeyName, { [id]: newValues });
          }}
          onBlur={(id, value) => {
            if (value) {
              onUpdate(contractTermKeyName, {
                [id]: `${workMaterial}, ${value}`,
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextAreaSuggestions
          id={suggestionTextId}
          label={suggestionTextLabel}
          value={shortDescription}
          suggestions={suggestionValues || []}
          onSubmitDescription={(id, val) => {
            onUpdateData({ [id]: val });
          }}
          onChangeTextarea={(id, val) => {
            onUpdateData({ [id]: val });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(PredicativeTextAndSuggestionsFields): AbstractComponent<
  PropsType,
  mixed
>);
