/**
 *
 * @format
 * @flow
 *
 */

import * as IconLib from 'app/assets/icons';
import * as React from 'react';

import type { SideMenuDataItem, SideMenuItemsPropsType } from './types';

import ExpandableMenuItem from './ExpandableMenuItem';
import LinkMenuItem from './LinkMenuItem';
import { useTheme } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';

const activeItems = (itemArray: Array<SideMenuDataItem>, currentPath: string) =>
  itemArray.reduce((prev, curr, index) => {
    if (curr.to && activeLink(curr.to, currentPath)) {
      return [...prev, index];
    }
    return prev;
  }, []);

const activeLink = (to: string, currentPath: string) => {
  // replace the id placeholder (which can be :id, :cyid, etc) with numeric for
  // regex and add ^ and $ to match the 'to' exactly
  const idRegEx = new RegExp(':.*?id', 'g');
  const toRegEx = new RegExp(`^${to.replace(idRegEx, '[0-9a-fA-F]{24}')}$`);
  const matched = currentPath.match(toRegEx);
  return matched !== null;
};

const replacePlaceholders = (
  templatePath: ?string,
  matchParamArray: { [any]: string }
) => {
  let newPath = '';
  const urlTokens = templatePath ? templatePath.split('/') : [];
  urlTokens.forEach((element, index) => {
    let token = element;
    if (element.startsWith(':')) {
      token = matchParamArray[element.slice(1)]
        ? matchParamArray[element.slice(1)]
        : element;
    }
    newPath += `${token}`;
    if (index < urlTokens.length - 1) {
      newPath += '/';
    }
  });
  return newPath;
};

const SideMenuItems: React.AbstractComponent<SideMenuItemsPropsType> = ({
  data,
  location,
  match,
  history,
}: SideMenuItemsPropsType): React.Node => {
  const theme = useTheme();
  const pathname = location && location.pathname ? location.pathname : 'test';
  // Icons that are supported in the menu definition (see menuData.json)
  // if more need to be supported in the definition, add them here first

  const menuData = React.useMemo(() => {
    const menuConfig = data.filter((config) => {
      return config.path.some((path) => {
        const regex = new RegExp(path, 'i');
        return pathname.match(regex);
      });
    });

    return menuConfig.length ? menuConfig[0].menuData : [];
  }, [data, pathname]);

  return menuData.map((item, index) => {
    const { type, to, iconComponent, title, items } = item;
    const Icon = IconLib[iconComponent];
    switch (type) {
      case 'group':
        const activeItemIndexes = items ? activeItems(items, pathname) : [];
        return (
          <ExpandableMenuItem
            title={title}
            startIcon={
              //FIXME: setting color here overrides the navlink style so we don't get the highlight colour
              // when the url matches. Instead of this set some css that targets a svg in a <a with class .navLink
              <Icon strokeWidth="1.7" color={theme.palette.text.secondary} />
            }
            key={index}
            expandable
            activeChild={activeItemIndexes.length > 0}
          >
            {items &&
              items.map((linkItem, idx) => {
                const { to: liTo, title: liTitle } = linkItem;
                const newPath = replacePlaceholders(liTo, match.params);
                return (
                  <LinkMenuItem
                    title={liTitle}
                    to={newPath}
                    key={index + idx}
                    variant="body2"
                  />
                );
              })}
          </ExpandableMenuItem>
        );
      case 'link':
        const newPath = replacePlaceholders(to, match.params);
        return (
          <ExpandableMenuItem
            title={title}
            startIcon={
              //FIXME: setting color here overrides the navlink style so we don't get the highlight colour
              // when the url matches. Instead of this set some css that targets a svg in a <a with class .navLink
              <Icon strokeWidth="1.7" color={theme.palette.text.secondary} />
            }
            to={newPath}
            key={index}
            activeChild={activeLink(newPath, pathname)}
          />
        );
      default:
        return <div key={index}></div>;
    }
  });
};

export default withRouter(SideMenuItems);
