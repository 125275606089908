/**
 *
 * ShootLocationDescriptionInput
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import { Grid } from '@mui/material';
import type { PropsType } from './types';
import TextArea from 'app/components/Textarea/Textarea';
import TextField from 'app/components/TextField/TextField';

const ShootLocationDescriptionInput: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { shootLocation, shortDescription, handleStateChange } = props;

  return (
    <>
      <Grid item xs={12}>
        <TextField
          id="shootLocation"
          label="Shoot Location"
          value={shootLocation}
          onBlur={handleStateChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextArea
          id="shortDescription"
          label="Description"
          placeholder="Add description"
          maxLength={300}
          value={shortDescription}
          onBlur={handleStateChange}
          maxRows={5}
          minRows={2}
        />
      </Grid>
    </>
  );
};

export default (memo(ShootLocationDescriptionInput): AbstractComponent<
  PropsType,
  mixed
>);
