/**
 *
 * useUserQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import type { GetUserQuery } from 'app/services/graphQL/generated-types';
import { userDetailsFragment } from 'user/graphQL/fragments/userFragments';

const USER_DETAILS = gql`
  query GetUser {
    user {
      ...userDetails
    }
  }
  ${userDetailsFragment}
`;

export type UserReturnType = {
  loading: boolean,
  data: GetUserQuery,
  error: any,
  refetch: () => void,
};

export const useUserQuery = (): UserReturnType => {
  const { loading, data, error, refetch } =
    useQuery<GetUserQuery>(USER_DETAILS);

  return {
    loading,
    data,
    error,
    refetch,
  };
};

export type LazyUserReturnType = [
  any,
  { loading: boolean, data: GetUserQuery, error: any }
];

export const useLazyUserQuery = (): LazyUserReturnType => {
  const query = useLazyQuery<GetUserQuery>(USER_DETAILS);

  return query;
};
