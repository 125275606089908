import { useContext, useEffect, useMemo } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';

const useSetContractTermDefaultData = ({
  contractTermKeyName = '',
  defaultValues = {},
  dependency = [],
}) => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
    onSetContractTermDefaultData: onSetDefaultData,
  } = useContext(ContractContext);

  const { switchValue } = contractTermProps[contractTermKeyName] || {};

  const memoizedDefaultValues = useMemo(
    () => ({
      switchVal: switchValue ?? true,
      additionalDetail: '',
      ...defaultValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependency.length ? dependency : []
  );

  useEffect(() => {
    onSetDefaultData(contractTermKeyName, memoizedDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedDefaultValues]);

  useEffect(() => {
    const checkEmptyContract =
      Object.keys(contractTermData[contractTermKeyName] || {}).length === 0;
    if (checkEmptyContract) {
      onUpdate(contractTermKeyName, memoizedDefaultValues);
    }
  }, [contractTermData, onUpdate, contractTermKeyName, memoizedDefaultValues]);
};

export default useSetContractTermDefaultData;
