/**
 *
 * Creative
 * @format
 * @flow
 *
 */

import { Button, Chip, Grid, Paper, Stack, Typography } from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Edit as EditIcon } from 'app/assets/icons';
import type { PropsType } from './types';

const Creative: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const routeParams = useParams();
  const history = useHistory();
  const theme = useTheme();
  const cyid = routeParams.cyid || '';
  const ptid = routeParams.ptid || '';
  const { projectDetails } = props;

  const {
    genreTypeNames,
    single,
    noOfEpisodes,
    duration,
    initialReleasePlatformTypeNames,
    shootLocation,
    shortDescription,
  } = projectDetails || {};

  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography variant="h2" color={theme.palette.grey['800']}>
          Creative
        </Typography>
        <Button
          onClick={() => {
            history.push(`/dashboard/${cyid}/project/${ptid}/edit`);
          }}
          size="small"
          startIcon={<EditIcon />}
        >
          <Typography
            variant="body2"
            color={theme.palette.grey['800']}
            sx={{ textTransform: 'none' }}
          >
            Edit
          </Typography>
        </Button>
      </Grid>
      <Paper sx={{ width: '100%', marginBottom: '32px' }} elevation={0}>
        <Grid container gap="24px">
          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Genre
              </Typography>
              <Stack direction="row" spacing={1}>
                {genreTypeNames?.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    sx={{
                      flexDirection: 'row',
                      color: theme.palette.grey['750'],
                      backgroundColor: theme.palette.grey['200'],
                      fontWeight: '700',
                      height: '20px',
                      borderRadius: '16px',
                    }}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Initial Release
              </Typography>
              <Stack direction="row" spacing={1}>
                {initialReleasePlatformTypeNames?.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    sx={{
                      flexDirection: 'row',
                      color: theme.palette.grey['750'],
                      backgroundColor: theme.palette.grey['200'],
                      fontWeight: '700',
                      height: '20px',
                      borderRadius: '16px',
                    }}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>

          <Grid container flexDirection="row">
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Duration
              </Typography>
              <Typography variant="body" sx={{ fontWeight: '600' }}>
                {`${single ? 'Single' : 'Series'} | ${
                  noOfEpisodes && !single
                    ? `${noOfEpisodes} x ${duration}`
                    : `1 x ${duration}`
                }  min`}
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Shoot Location
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {shootLocation || '--'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container flexDirection="row">
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body" color={theme.palette.grey['750']}>
                Description
              </Typography>
              <Typography
                variant="body"
                color={theme.palette.grey['800']}
                sx={{ fontWeight: '600' }}
              >
                {shortDescription || '--'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default (memo(Creative): AbstractComponent<PropsType, mixed>);
