import React, { Fragment, useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import type { LazyUserReturnType } from 'user/graphQL/useUserQuery';
import Loading from 'app/components/Loading/Loading';
import { Redirect } from 'react-router-dom';
import { disableAuthentication } from 'app/utils/config';
import { useIsAuthenticated } from 'app/services/ory/api';
import { useLazyUserQuery } from 'user/graphQL/useUserQuery';

type PageRouterInfo = {
  id: string,
  path: string,
  component: any,
  pathTo: ?string,
  collapse: ?boolean,
  exact: ?boolean,
  redirect: ?boolean,
  redirectPathTo: ?string,
  restricted: ?boolean,
  renderWithProps: ?any,
  renderWithPropsAuthenticated: ?any,
  authenticatedRedirectPath: ?string,
  props: ?any,
};

type PageRouterProps = {
  routeArray: Array<PageRouterInfo>,
};

const PageRouter = ({ routeArray }: PageRouterProps): React$Element<any> => {
  const { authenticated, checkingAuth } = useIsAuthenticated();

  const [getUser, { loading, error, data }]: LazyUserReturnType =
    useLazyUserQuery();
  const [userData, setUserData] = useState(undefined);

  useEffect(() => {
    if (loading === false && data) {
      setUserData(data?.user);
    } else if (!loading && !error && !data && authenticated) {
      getUser();
    }
  }, [data, error, getUser, authenticated, loading, setUserData]);

  console.log(`Authenticated: ${authenticated}`);
  if (authenticated) {
    if (loading) return <Loading />;
    if (error) {
      console.log({ error });
      // probably not the best way to handle this but currently authenticated becomes true before
      // the getUser is accepted, so for now just return an empty div
      const { graphQLErrors } = error;
      if (
        graphQLErrors &&
        graphQLErrors.some(
          (e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED'
        )
      ) {
        return <p>Error :(</p>;
      }
      return <p>Error :(</p>;
    }
    if (!userData) return <p>Error :(</p>;
  }

  if (checkingAuth) return <Loading />;

  return (
    <Fragment>
      <Switch>
        {routeArray.map((route, key) => {
          const {
            props,
            restricted,
            renderWithProps: RenderWithProps,
            renderWithPropsAuthenticated: RenderWithPropsAuth,
            authenticatedRedirectPath,
            exact,
            path,
            redirect,
            component,
          } = route;
          console.log(`rendering route ${path}, restricted: ${restricted}`);
          const propsSpread = props || {};
          if (restricted) {
            if (authenticated || disableAuthentication) {
              console.log('Rendering route as restricted but authenticated');
              return RenderWithProps ? (
                <Route
                  component={component}
                  render={(props) => (
                    <RenderWithProps {...props} {...propsSpread} />
                  )}
                  path={path}
                  exact={exact}
                  key={key}
                />
              ) : (
                <Route
                  exact={exact}
                  path={path}
                  component={component}
                  redirectTo={redirect}
                  key={key}
                />
              );
            }

            console.log(
              'Rendering redirect as restricted and not authenticated'
            );
            return (
              <Route exact={exact} path={path} key={key}>
                <Redirect
                  to={{ pathname: '/', state: { from: undefined } }}
                  key={key}
                />
              </Route>
            );
          } else {
            console.log(`Rendering route as not restricted`);
            console.log(
              `RenderWithProps: ${!!RenderWithProps} authenticated: ${authenticated} key: ${key}`,
              {
                exact,
                path,
                props,
                propsSpread,
                key,
                authenticatedRedirectPath,
              }
            );
            return RenderWithProps ? (
              <Route
                exact={exact}
                path={path}
                render={(props) => {
                  if (authenticated) {
                    console.log(
                      `authenticated so rendering authenticatedRedirect path ${authenticatedRedirectPath}`
                    );

                    // if no companies created yet
                    if (!userData?.companies?.length) {
                      return (
                        <Redirect
                          to={{
                            pathname: '/dashboard/account',
                          }}
                        />
                      );
                    }

                    return authenticatedRedirectPath ? (
                      <Redirect
                        to={{
                          // replace the company id placeholder in the path with the one the user
                          // has selected
                          pathname: authenticatedRedirectPath.replace(
                            ':cyid',
                            userData.selectedCompany
                          ),
                        }}
                      />
                    ) : (
                      <RenderWithPropsAuth {...props} {...propsSpread} />
                    );
                  }

                  return <RenderWithProps {...props} {...propsSpread} />;
                }}
                key={key}
              />
            ) : (
              <Route
                exact={exact}
                path={path}
                component={component}
                key={key}
              />
            );
          }
        })}
      </Switch>
    </Fragment>
  );
};

export default withRouter(PageRouter);
