import { useParams } from 'react-router-dom';

const useGetRouteParam = (param) => {
  const routeParams = useParams();
  const selectedParam = routeParams?.[param] || '';

  return selectedParam;
};

export default useGetRouteParam;
