import { gql } from '@apollo/client';

export default gql`
  fragment contractSummary on Contract {
    _id
    title
    contractTemplateName
    project {
      _id
      title
    }
    terms {
      uiComponentName
      stringData
    }
  }
`;
//TODO: Need to add detail, stage and status - this also needs adding to the API!
